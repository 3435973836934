import { useState, useEffect } from 'react'
import { TypographyElement, blue } from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Text from './text.json'
import { calculateTimeLeft } from 'helpers'

const useStyle = createUseStyles(style)

const everyValueIsZeroOrLess = obj => Object.keys(obj).every(value => obj[value] <= 0)

export default ({ plannedDate, endDate }) => {
  const { textContainer } = useStyle()
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 })

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = calculateTimeLeft(plannedDate)
      if (everyValueIsZeroOrLess(remainingTime)) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 })
        clearInterval(interval)
      } else { setTimeLeft(remainingTime) }
    }, 1000)
    return () => clearInterval(interval)
  }, [plannedDate])

  if (endDate !== null) return null

  return (
    <TypographyElement
      align='left'
      fontSize='47px'
      color={blue[0]}
      fontWeight={550}
      display='flex'
      className={textContainer}
      spacing='0 0 36px 0'
    >
      {
      timeLeft
        ? (
          <>
            {timeLeft.days > 0 ? <>{timeLeft.days} <TypographyElement component='span' fontSize={20} color={blue[0]} fontWeight={550}>{Text.days}</TypographyElement>{' : '}</> : null}
            {timeLeft.hours}<TypographyElement component='span' fontSize={20} color={blue[0]} fontWeight={550}>{Text.hours}</TypographyElement>{' : '}
            {timeLeft.minutes}<TypographyElement component='span' fontSize={20} color={blue[0]} fontWeight={550}>{Text.minutes}</TypographyElement>
            {timeLeft.days <= 0 ? <>{' : '}{timeLeft.seconds} <TypographyElement component='span' fontSize={20} color={blue[0]} fontWeight={550}>{Text.seconds}</TypographyElement></> : null}
          </>
          )
        : null
      }
    </TypographyElement>
  )
}
