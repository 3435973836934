import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { searchInputStyle } from './searchInput.style'
import TextInput from '../textInput/textInput'
import InputIcon from '../inputIcon/inputIcon'
import { Icons } from '../../constants'

const useStyle = createUseStyles(searchInputStyle)

const SearchInput = (props) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState('')
  const [inputFocused, setInputFocused] = useState(false)
  const handleChange = e => {
    const value = e && e.target && e.target.value ? e.target.value : ''
    setSearchValue(value)
    return props.handleChange(value)
  }
  const classes = useStyle({ ...props, inputFocused })
  return (
    <div className={classes.root}>
      <div
        className={classes.inputGroup}
        onMouseEnter={props.handleMouseOver}
        onMouseLeave={props.handleMouseLeave}
      >
        <TextInput
          disabled={props.disabled}
          height={props.height}
          label={props.label}
          searchInputResult={props.openResults}
          value={searchValue}
          id={props.id}
          type='text'
          placeholder={props.placeHolder || 'Search'}
          handleChange={handleChange}
          dataTest={props.dataTest}
          onHandleMouseOver={props.handleMouseOver}
          handleBlur={(e) => {
            setInputFocused(false)
            props.onBlur && props.onBlur(e)
          }}
          onFocus={(e) => {
            setInputFocused(true)
            props.onFocus && props.onFocus(e)
          }}
          typography={props.labelTypography}
          inputPadding='0 42px 0 16px'
        />
        <InputIcon
          height={props.height}
          cursor='pointer'
          color={props.iconColor || theme.primary[0]}
          iconName={searchValue.length === 0 ? Icons.search : Icons.close}
          handleClick={searchValue.length === 0 ? props.handleIconClick : handleChange}
          size={props.iconSize}
        />
      </div>
      {
        props.openResults &&
        (
          <div className={classes.results}>
            <div className={classes.devider} />
            {props.children}
          </div>
        )
      }
    </div>
  )
}

SearchInput.propTypes = {
  /**  input unique identifier */
  id: PropTypes.string,
  /** the method to be used by onChange event of the textinput */
  handleChange: PropTypes.func,
  /** the method to be used by the on mouse over event */
  handleMouseOver: PropTypes.func,
  /** the method to be used by the on mouse over */
  handleMouseLeave: PropTypes.func,
  /** the method to be used by on icon click event */
  handleIconClick: PropTypes.func,
  /** the placeholder explanation text to be displayed when input not filled yet */
  placeHolder: PropTypes.string.isRequired,
  /** the method that controls the blur of the input */
  onBlur: PropTypes.func,
  /** the method that controls the focus of the input */
  onFocus: PropTypes.func,
  /** when search result are in the children on this component use openResults to hide and show the results */
  openResults: PropTypes.bool,
  /** the height of the search input */
  height: PropTypes.number,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string
}

export default SearchInput
