import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { passwordInputStyle } from './passwordInput.style'
import InputIcon from '../inputIcon/inputIcon'
import { Icons, Status, textColorMap } from '../../constants'
import IconAndMessage from '../iconAndMessage/iconAndMessage'
import classNames from 'classnames'
import TypographyElement from '../typographyElement/typographyElement'

const PasswordInput = props => {
  const theme = useTheme()
  const useStyle = createUseStyles(passwordInputStyle)
  const { inputGroup, inputAndIcon, input } = useStyle(props)
  const [inputType, setInputType] = useState('password')
  const changeInputType = () => setInputType(inputType === 'password' ? 'text' : 'password')
  return (
    <div className={inputGroup}>
      {props.label &&
        (
          <TypographyElement
            color={textColorMap(theme, props.status)}
            variant='label'
            spacing='0 0 8px 0'
            {...props.typography}
          >{props.label}
          </TypographyElement>
        )}
      <div className={inputAndIcon}>
        <input
          disabled={props.disabled}
          id={props.id}
          name={props.name}
          className={classNames(input, props.PasswordInputClassName)}
          type={inputType}
          placeholder={props.placeholder}
          onChange={props.handleChange}
          data-test={props.dataTest}
          value={props.value}
          onBlur={props.handleBlur}
          onFocus={props.onFocus}
          onKeyDown={props.onKeyDown}
          onKeyUp={props.onKeyUp}
          onClick={props.onClick}
        />
        <InputIcon
          cursor='pointer'
          status={props.status}
          type={inputType}
          label={props.label}
          handleClick={changeInputType}
          iconName={inputType === 'password' ? Icons.hidePassword : Icons.eyeOpen}
          dataTest={props.IconDataTest}
          color={theme.neutral[200]}
        />
      </div>
      {
        (props.status && props.status !== Status.success)
          ? (
            <IconAndMessage
              background
              message={props.message}
              iconName={Icons[props.status]}
              status={props.status}
            />
            )
          : ''
      }
    </div>
  )
}

PasswordInput.propTypes = {
  /**  input unique identifier */
  id: PropTypes.string,
  /** the placeholder explanation text to be displayed when input not filled yet */
  placeholder: PropTypes.string.isRequired,
  /**  the control label text to be displayed */
  label: PropTypes.string.isRequired,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** the state of the input */
  status: PropTypes.oneOf(['info', 'success', 'error', 'alert', 'text']),
  /** the hint message that appears under the input */
  message: PropTypes.string,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  /** the test Id of the icon for end to end testing */
  IconDataTest: PropTypes.string
}

PasswordInput.defaultProps = {
  placeholder: 'input placeholder',
  label: 'input label',
  disabled: false
}

export default PasswordInput
