import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { searchableSelectListStyle } from './searchableSelectList.style'
import { TextInput, InputIcon, Icons } from '../../index'
import SuggestionGroup from './suggestionGroup'
import SuggestionItem from './suggestionItem'
import EmptySuggestionItem from './emptySuggestionItem'
import { textColorMap, blue } from '../../constants'

const filterItems = (items, selectedItem, idField, valueField) => {
  return items.filter((suggestion) =>
    suggestion[valueField].toLowerCase().indexOf(selectedItem.toLowerCase()) === 0
  )
}

const useStyles = createUseStyles(searchableSelectListStyle)

export const SearchableSelectList = ({ idField = 'Id', valueField = 'Value', ...props }) => {
  const theme = useTheme()
  const node = useRef()
  const listRef = useRef()
  const [toggleSuggestionList, setToggleSuggestionList] = useState(false)
  const classes = useStyles({ ...props, toggleSuggestionList })
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideContainer)
    return () => {
      document.removeEventListener('click', handleClickOutsideContainer)
    }
  }, [])
  const handleClickOutsideContainer = e => (node && node.current && !node.current.contains(e.target) && listRef && listRef.current && !listRef.current.contains(e.target) && !toggleSuggestionList) && setToggleSuggestionList(false)
  const filteredItems = filterItems(props.items, props.selectedItem, idField, valueField)
  return (
    <div className={classes.searchableSelectList} ref={node}>
      <div className={classes.inputAndIcon}>
        <TextInput
          noIcon
          id={props.id}
          value={props.selectedItem}
          status={props.status}
          message={props.message}
          label={props.label}
          handleChange={(e) => {
            if (!toggleSuggestionList) setToggleSuggestionList(true)
            props.handleChange(e)
          }}
          onHandleBlur={props.handleBlur}
          placeholder={props.placeholder}
          typography={props.typography}
          inputPadding='0 42px 0 16px'
          dataTest={props.dataTest}
          searchInputResult={toggleSuggestionList}
          disableHoverEffect={toggleSuggestionList}
        />
        <InputIcon
          dataTest={props.iconDataTest}
          color={props.status ? textColorMap(theme, props.status) : blue[0]}
          handleClick={() => setToggleSuggestionList(!toggleSuggestionList)}
          label={props.label}
          iconName={Icons.roundedDown}
        />
      </div>
      {
        toggleSuggestionList
          ? (
            <div className={classes.suggestionGroupContainer} id={props.id}>
              <SuggestionGroup ref={listRef}>
                {
                  filteredItems.length
                    ? filteredItems.map((option, i) =>
                      <SuggestionItem
                        selected={props.selectedItem === option[valueField]}
                        idField={idField}
                        valueField={valueField}
                        for={props.id}
                        key={i}
                        id={option[idField]}
                        value={option[valueField]}
                        handleClick={() => {
                          setToggleSuggestionList(!toggleSuggestionList)
                          props.onSelectedItem(option)
                        }}
                      />
                      )
                    : (
                      <EmptySuggestionItem
                        value='not found'
                      />
                      )
                }
              </SuggestionGroup>
            </div>
            )
          : null
      }
    </div>
  )
}

SearchableSelectList.propTypes = {
  /** the state of the input */
  status: PropTypes.oneOf(['info', 'success', 'error', 'alert', 'text']),
  /** the hint message that appears under the input */
  message: PropTypes.string,
  /** Array of items [{id, value}] */
  items: PropTypes.array.isRequired,
  /** id unique for controlling suggestion group */
  id: PropTypes.string.isRequired,
  /** Selected item */
  selectedItem: PropTypes.string,
  /** function that handle the change in the input text */
  handleChange: PropTypes.func.isRequired,
  /** function that handle the blur in the input text */
  handleBlur: PropTypes.func,
  /** function that handle the event when click an item in the list */
  onSelectedItem: PropTypes.func.isRequired,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  iconDataTest: PropTypes.string
}

export default SearchableSelectList
