import { MediaQueries } from '../../constants'
import { config } from './config'

const mediaQueries = {
  onSmall: `@media (min-width: ${config.breakPoints.sm}px)`,
  onMedium: `@media (min-width: ${config.breakPoints.md}px)`,
  onLarge: `@media (min-width: ${config.breakPoints.lg}px)`,
  onXlarge: `@media (min-width: ${config.breakPoints.xl}px)`
}
const mediaQueriesForCol = {
  onNormal: `@media (min-width: ${(config.breakPoints.md)}px)`,
  onSmall: `@media (max-width: ${(config.breakPoints.sm - 1)}px)`,
  onMedium: `@media (min-width: ${config.breakPoints.sm}px) and (max-width: ${(config.breakPoints.md - 1)}px)`
}

const Styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: ({ noBackground, headerBackground }) => !noBackground && headerBackground && `transparent url(${headerBackground}) top center no-repeat`
  },
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    boxSizing: 'border-box',
    width: '100%',
    height: ({ fill }) => fill ? '100%' : 'auto',
    maxWidth: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    position: 'relative',
    [`${mediaQueries.onLarge}`]: {
      maxWidth: config.containerMaxWidth.xl
    },
    [`${MediaQueries.desktop}`]: {
      paddingLeft: ({ nopadding }) => nopadding ? 0 : 17,
      paddingRight: ({ nopadding }) => nopadding ? 0 : 17
    },
    [`${MediaQueries.tablet}`]: {
      paddingLeft: ({ nopadding }) => nopadding ? 0 : 17,
      paddingRight: ({ nopadding }) => nopadding ? 0 : 17
    },
    [`${MediaQueries.mobile}`]: {
      paddingLeft: ({ nopadding }) => nopadding ? 0 : 17,
      paddingRight: ({ nopadding }) => nopadding ? 0 : 17
    }
  },
  row: {
    display: 'grid',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateColumns: ({ repeatCols }) => `repeat(${repeatCols || config.gridColumns}, 1fr)`,
    columnGap: config.gutterWidth,
    rowGap: ({ gap }) => gap,
    [`${MediaQueries.tablet}`]: {
      columnGap: config.gutterWidthResponsive,
      rowGap: ({ tabletGap }) => tabletGap,
      gridTemplateColumns: ({ mrepeatCols }) => `repeat(${mrepeatCols || config.gridColumns}, 1fr)`
    },
    [`${MediaQueries.mobile}`]: {
      columnGap: config.gutterWidthResponsive,
      rowGap: ({ mobileGap }) => mobileGap,
      gridTemplateColumns: ({ srepeatCols }) => `repeat(${srepeatCols || config.gridColumns}, 1fr)`
    }
  },
  col: ({ align, display, pos, posTablet, posMobile, mgrid, sgrid, grid, direction, debug, gap, mobileGap, tabletGap, alignTablet, alignMobile, start, startTablet, startMobile, directionTablet, directionMobile, takeGapRight, takeGapLeft, takeGapRightTablet, takeGapLeftTablet, takeGapRightMobile, takeGapLeftMobile }) => ({
    gap: gap || null,
    alignItems: alignItems(align),
    flexDirection: direction,
    backgroundColor: debug && 'rgba(255, 0, 0, 0.4)',
    textAlign: debug && 'center',
    gridColumn: `${start}/span ${grid}`,
    marginRight: takeGapRight ? `-${config.gutterWidth}px` : 0,
    marginLeft: takeGapLeft ? `-${config.gutterWidth}px` : 0,
    [`${MediaQueries.tablet}`]: {
      gap: tabletGap || 0,
      justifyContent: justifyContentMap(posTablet),
      alignItems: alignItems(alignTablet) || 0,
      flexDirection: directionTablet || direction,
      marginRight: takeGapRightTablet ? `-${config.gutterWidthResponsive}px` : 0,
      marginLeft: takeGapLeftTablet ? `-${config.gutterWidthResponsive}px` : 0
    },
    [`${MediaQueries.mobile}`]: {
      gap: mobileGap || 0,
      justifyContent: justifyContentMap(posMobile),
      alignItems: alignItems(alignMobile) || 0,
      flexDirection: directionMobile || direction,
      marginRight: takeGapRightMobile ? `-${config.gutterWidthResponsive}px` : 0,
      marginLeft: takeGapLeftMobile ? `-${config.gutterWidthResponsive}px` : 0
    },
    [`${mediaQueriesForCol.onNormal}`]: {
      gridColumn: `${start}/span ${GetCorrectGrid(1, grid)}`
    },
    [`${mediaQueriesForCol.onSmall}`]: {
      gridColumn: `${startMobile}/span ${GetCorrectGrid(grid, sgrid)}`
    },
    [`${mediaQueriesForCol.onMedium}`]: {
      gridColumn: `${startTablet}/span ${GetCorrectGrid(grid, mgrid)}`
    },
    display: dispayMap(display),
    justifyContent: justifyContentMap(pos)
  })
}

const GetCorrectGrid = (grid, otherGrid) => otherGrid || grid

const dispayMap = display => {
  switch (display) {
    case 'inline':
      return 'inline'
    case 'flex':
      return 'flex'
    case 'inline-block':
      return 'inline-block'
    default:
      return 'inline-flex'
  }
}

const justifyContentMap = pos => {
  switch (pos) {
    case 'center':
      return 'center'
    case 'left':
      return 'flex-start'
    case 'right':
      return 'flex-end'
    case 'evenly':
      return 'space-evenly'
    case 'between':
      return 'space-between'
    case 'around':
      return 'space-around'
    case 'end':
      return 'end'
    default:
      return 'unset'
  }
}

const alignItems = align => {
  switch (align) {
    case 'top':
      return 'flex-start'
    case 'mid':
      return 'center'
    case 'bot':
      return 'flex-end'
    default:
      return 'unset'
  }
}

export default Styles
