export const RateStarSize = {
  extraBig: 'extraBig',
  big: 'big',
  small: 'small',
  extraSmall: 'extraSmall'
}

export const RateStarSizeMap = {
  extraBig: {
    width: 26,
    height: 26
  },
  big: {
    width: 15,
    height: 15
  },
  small: {
    width: 10,
    height: 10
  },
  extraSmall: {
    width: 8,
    height: 8
  }
}
