import {
  Container,
  Row,
  TypographyElement,
  Button,
  Col,
  TextInput,
  PasswordInput
} from 'UI'
import style from './style'
import Text from './text.json'
import { createUseStyles } from 'react-jss'
import { useFormik } from 'formik'
import { validateNewB2bAccountPassword } from 'helpers/validator'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { OnBoardingFirstPath } from 'navigation/Routes'

const useStyle = createUseStyles(style)

export default ({
  Email,
  submit,
  nextStep,
  goTo
}) => {
  const location = useLocation()
  const {
    rootClass,
    formRootClass,
    formRowClass
  } = useStyle()
  const { handleChange: onFormikHandleChange, handleSubmit, errors, values } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: Email,
      password: ''
    },
    validate: validateNewB2bAccountPassword,
    onSubmit: values => submit(values, nextStep)
  })
  if (!location.state?.validateInfos) goTo(OnBoardingFirstPath)
  return (
    <Container className={rootClass}>
      <div>
        <TypographyElement
          component='h3'
          variant='heading1'
        >
          {Text.header}
        </TypographyElement>
        <form className={formRootClass}>
          <Row className={formRowClass}>
            <Col direction='column' grid={12}>
              <TextInput
                label={Text.inputLabels.email}
                placeholder={Text.inputPlaceHolder.email}
                value={values.email}
                name='email'
                gutterBottom={16}
                disabled
              />
              <PasswordInput
                label={Text.inputLabels.password}
                placeholder={Text.inputPlaceHolder.password}
                handleChange={onFormikHandleChange}
                name='password'
                id='password'
                gutterBottom={16}
                value={values.password}
                status={errors.password ? 'error' : null}
                message={errors.password}
              />
            </Col>
          </Row>
        </form>
      </div>
      <Row justify='right'>
        <Col grid={5} pos='right' mgrid={12} sgrid={12}>
          <Button
            width='100%'
            height={54}
            disabled={!isEmpty(errors)}
            onHandleClick={handleSubmit}
            label={Text.save}
          />
        </Col>
      </Row>
    </Container>
  )
}
