export default {
  mainContent: {
    background: 'linear-gradient(180deg, #E3E8F5 0%, rgba(255,255,255,0) 25%)'
  },
  rightShape: {
    position: 'fixed',
    zIndex: -1,
    right: -450,
    top: 200
  },
  leftShape: {
    position: 'fixed',
    zIndex: -1,
    left: -400,
    top: 250
  }
}
