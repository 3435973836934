import { Typography } from '../../constants'
export const modalStyle = theme => ({
  overlay: {
    position: 'fixed',
    width: '100vw',
    height: '100%',
    background: `${theme.primary[900]}DD`,
    top: 0,
    left: 0,
    zIndex: '99999',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  modalWrapper: {
    display: 'flex',
    justifyContent: ({ justify }) => justify || 'center',
    alignItems: ({ align }) => align || 'center',
    margin: '24px 0',
    minHeight: 'calc(100% - 48px)'
  },
  modalInner: {
    margin: ({ margin }) => margin || 0,
    borderRadius: 6,
    boxShadow: '0 26px 26px 0 rgba(10, 31, 68, 0.12), 0 0 1px 0 rgba(10, 31, 68, 0.1)',
    padding: '25px',
    position: 'relative',
    background: theme.neutral[400],
    animation: '$modalAnimation 250ms ease-in-out',
    '& .icon': {
      display: 'inline-block',
      marginBottom: 21
    }
  },
  titleStyle: {
    fontFamily: Typography.fontFamily,
    fontSize: Typography.mediumTitle,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.4,
    letterSpacing: '0.5px',
    margin: [0, 0, 10, 0]
  },
  cross: {
    position: 'absolute',
    top: 12,
    right: 18,
    display: 'inline-block',
    cursor: 'pointer',
    fontSize: 24,
    color: theme.neutral[0]
  },
  modalFade: {
    '&-enter': {
      opacity: 0.01
    },
    '&-enter &-enter-active': {
      opacity: 0.1,
      transition: 'opacity 5000ms ease-in'
    },
    '&-leave': {
      opacity: 1
    },
    '&-leave &-leave-active': {
      opacity: 0.01,
      transition: 'opacity 300ms ease-in'
    },
    '&-appear': {
      opacity: 3
    },
    '&-appear &-appear-active': {
      opacity: 0.1,
      transition: 'opacity 5000ms ease-in'
    }
  },
  '@keyframes modalAnimation': {
    '0%': { opacity: 0, transform: 'scale(0)' },
    '100%': { opacity: 1, transform: 'scale(1)' }
  }
})
