import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { suggestionItemStyle } from './suggestionItem.style'
import { TypographyElement } from '../../..'
import { Spacing } from '../../../constants'

export const SuggestionItem = ({ handleClick, value, dataTest, selected }) => {
  const theme = useTheme()
  const useStyle = createUseStyles(suggestionItemStyle)
  const { suggestionItem } = useStyle({ selected })
  return (
    <div
      className={suggestionItem}
      onClick={handleClick}
      data-test={dataTest}
    >
      <TypographyElement
        variant='body2'
        cursor='pointer'
        spacing={Spacing(4)}
        color={selected ? theme.neutral[400] : theme.neutral[200]}
      >
        {value}
      </TypographyElement>
    </div>
  )
}

SuggestionItem.propTypes = {
  /** the click handler to fire when a user click on the item */
  handleClick: PropTypes.func.isRequired,
  /** the text to show in the item */
  value: PropTypes.string.isRequired
}

export default SuggestionItem
