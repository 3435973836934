import { connect } from 'react-redux'
import { signup } from 'identity/userManager'
import UI from './UI'
import { setTrialState } from 'components/Profile/store'
import { initOnboarding } from 'pages/OnBoardingNext/store'
import { OnBoardingStateEnum } from 'constants/'
import { decorateUrl } from '../UTMscript'
import { push } from 'connected-react-router'

const mapStateToProps = ({ support, user, identity }) => ({
  phone: support.Phone,
  email: support.Email,
  isConnected: identity.isConnected,
  activeSubscription: user?.Subscription?.IsActive,
  isElligableForTrial: user.isElligableForTrial
})

const mapDispatchToProps = (dispatch) => ({
  signup,
  setTrialState: (data) => dispatch(setTrialState(data)),
  startTrialOnboarding: () => dispatch(initOnboarding(OnBoardingStateEnum.Start)),
  goToLink: (url) => dispatch(push(decorateUrl(url)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
