export const defaultCurrency = 'EUR'

export const morrocanCurrency = 'DH'

const initialState = {
  initialCurrency: defaultCurrency,
  alternativeCurrency: ''
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SET_ALTERNATIVE_CURRENCY:
      return {
        ...state,
        alternativeCurrency: data
      }
    case SWITCH_DEFAULT_CURRENCY:
      return {
        ...state,
        initialCurrency: state.initialCurrency === state.alternativeCurrency ? defaultCurrency : state.alternativeCurrency
      }
    case SWITCH_TO_ALTERNATIVE_CURRENCY:
      return {
        ...state,
        initialCurrency: state.alternativeCurrency
      }
    default:
      return state
  }
}

export const setAlternativeCurrency = alternativeCurrency => ({ type: SET_ALTERNATIVE_CURRENCY, data: alternativeCurrency })
export const switchDefaultCurrencyAction = () => ({ type: SWITCH_DEFAULT_CURRENCY })
export const switchToAlternativeCurrencyAction = () => ({ type: SWITCH_TO_ALTERNATIVE_CURRENCY })

export const SET_ALTERNATIVE_CURRENCY = 'SET_ALTERNATIVE_CURRENCY'
export const SWITCH_DEFAULT_CURRENCY = 'SWITCH_DEFAULT_CURRENCY'
export const SWITCH_TO_ALTERNATIVE_CURRENCY = 'SWITCH_TO_ALTERNATIVE_CURRENCY'
