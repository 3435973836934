import {
  MediaQueries
} from 'UI/'

export default (theme) => ({
  paper: {
    width: 270,
    height: 81,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    backgroundColor: theme.neutral[400]
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
    fontSize: 18,
    color: theme.secondary[0],
    lineHeight: 0,
    paddingRight: 16
  },
  container: {
    position: 'relative',
    padding: '23px 0px',
    backgroundColor: theme.secondary[0]
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 82
  },
  [`${MediaQueries.tablet}`]: {
    paper: {
      width: '100%',
      padding: 24,
      marginBottom: 7
    },
    container: {
      marginBottom: 16
    }
  },
  [`${MediaQueries.mobile}`]: {
    paper: {
      width: '100%',
      padding: 24,
      marginBottom: 7
    },
    container: {
      marginBottom: 16
    }
  },
  leftMotif: {
    position: 'absolute',
    bottom: 0,
    left: 60,
    height: 125,
    width: 130,
    opacity: '25%',
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    backgroundColor: theme.primary[0]
  },
  topRightMotif: {
    position: 'absolute',
    top: 0,
    right: 290,
    height: 72,
    width: 83,
    borderBottomLeftRadius: '50%',
    borderBottomRightRadius: '50%',
    backgroundColor: theme.primary[0]
  },
  bottomRightMotif: {
    position: 'absolute',
    bottom: 0,
    right: 84,
    height: 72,
    width: 69,
    opacity: '25%',
    borderTopLeftRadius: '50%',
    borderTopRightRadius: '50%',
    backgroundColor: theme.primary[0]
  },
  border: {
    borderRadius: 8
  }
})
