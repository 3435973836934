import {
  TypographyElement
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import { amazonBucket } from 'constants/'

const useStyle = createUseStyles(style)

export default ({
  name,
  categoryId,
  photo,
  backgroundColor,
  textColor
}) => {
  const { container, image } = useStyle({ categoryId, backgroundColor })
  return (
    <div className={container}>
      {photo && <img src={`${amazonBucket.categories}${photo}`} alt={name} className={image} />}
      <TypographyElement
        component='h4'
        variant='extraSmallText'
        fontSize={10}
        lineHeight='13px'
        color={textColor || null}
      >
        {name}
      </TypographyElement>
    </div>
  )
}
