import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { InputDatePickerStyle } from './inputDatePicker.style'
import TextInput from '../textInput/textInput'
import DatePicker from '../datePicker/datePicker'
import InputIcon from '../inputIcon/inputIcon'
import { Icons } from '../../constants'
import classNames from 'classnames'

import {
  formatDate,
  parseDate
} from 'react-day-picker/moment'

const format = 'L'
const locale = 'fr'
const currentYear = new Date().getFullYear()
const fromMonth = new Date(currentYear - 100, 0)
const toMonth = new Date(currentYear + 10, 11)

const YearMonthForm = ({ date, localeUtils, onChange, className }) => {
  const months = localeUtils.getMonths()

  const years = []
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i)
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form
    const formattedDate = formatDate(new Date(year.value, month.value), format, locale)
    onChange(formattedDate)
  }

  return (
    <form className={classNames('DayPicker-Caption', className)}>
      <select name='month' onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name='year' onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  )
}

const InputDatePicker = (props) => {
  const useStyle = createUseStyles(InputDatePickerStyle)
  const { select, inputDatePickerContainer } = useStyle(props)
  const handleChange = (e) => {
    const date = e.target.value
    if (date.trim() === '') {
      props.onInputChange(date)
      return
    }
    const parsedDate = parseDate(date, format, locale)
    if (!parsedDate) {
      props.onInputChange(date)
      return
    }
    props.onInputChange(formatDate(parsedDate, format, locale))
  }

  const handleClick = (day) => {
    props.handleDayClick(formatDate(day, format, locale))
  }

  return (
    <div className={inputDatePickerContainer}>
      <TextInput
        status={props.status}
        message={props.message}
        placeholder={props.placeholder}
        value={props.day}
        id={props.id}
        label={props.label}
        disabled={props.disabled}
        handleChange={handleChange}
        onHandleBlur={props.handleBlur}
      />
      <InputIcon
        label={props.label}
        handleClick={props.toggleCalendar}
        iconName={Icons.calendar}
      />
      <DatePicker
        id={props.id}
        handleDayClick={handleClick}
        selectedDay={parseDate(props.day, format, locale)}
        show={props.show}
        month={parseDate(props.day, format, locale)}
        fromMonth={fromMonth}
        toMonth={toMonth}
        captionElement={({ date, localeUtils }) => (
          <YearMonthForm
            className={select}
            date={date}
            localeUtils={localeUtils}
            onChange={props.handleYearMonthChange}
          />
        )}
      />
    </div>
  )
}

InputDatePicker.propTypes = {
  /** the state of the input */
  status: PropTypes.oneOf(['info', 'success', 'error', 'alert', 'text']),
  /** the hint message that appears under the input */
  message: PropTypes.string,
  /** the placeholder explanation text to be displayed when input not filled yet */
  placeholder: PropTypes.string,
  /**  input unique identifier */
  id: PropTypes.string,
  /**  the control label text to be displayed */
  label: PropTypes.string,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** the method that controls the blur of the input */
  handleBlur: PropTypes.func,
  /** function that handle the icon click */
  toggleCalendar: PropTypes.func,
  /** show and hide the date picker */
  show: PropTypes.bool,
  /** function that handle the date click */
  handleDayClick: PropTypes.func,
  /** to show selected Day  */
  day: PropTypes.string
}

export default InputDatePicker
