import {
  MediaQueries
} from 'UI'

export default {
  rootClass: {
    height: '100% !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 16
  },
  formRootClass: {
    width: '100%',
    marginTop: 24
  },
  formRowClass: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20
  },
  [`${MediaQueries.tablet}`]: {
    formRowClass: {
      flexDirection: 'column',
      gap: 0
    },
    formRootClass: {
      boxShadow: '0 0 36px 0 rgba(194, 212, 230, 0.23)',
      borderRadius: 4,
      padding: 24
    },
    horizantalDivider: {
      display: 'none'
    }
  },
  [`${MediaQueries.mobile}`]: {
    formRowClass: {
      flexDirection: 'column',
      gap: 0
    },
    formRootClass: {
      boxShadow: '0 0 36px 0 rgba(194, 212, 230, 0.23)',
      borderRadius: 4,
      padding: 24
    },
    horizantalDivider: {
      display: 'none'
    }
  }
}
