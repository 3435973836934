export const tableStyle = (theme) => ({
  root: props => ({
    width: props.width || '100%',
    background: theme.neutral[400],
    border: `1px solid ${theme.neutral[0]}`,
    borderRadius: 8
  }),
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr': {
        borderBottom: `1px solid ${theme.neutral[0]}`,
        '& td': {
          padding: ({ headCellPadding }) => headCellPadding || '16px 78px 16px 16px'
        }
      }
    },
    '& tbody': {
      '& tr': {
        borderBottom: `1px solid ${theme.neutral[0]}`,
        '&:nth-child(2n)': {
          color: theme.primary[1300] + ' !important'
        },
        '&:nth-child(2n + 1)': {
          color: theme.neutral[100] + ' !important'
        },
        '& td': {
          padding: ({ tableCellPadding }) => tableCellPadding || '14px 78px 14px 16px'
        }
      }
    }
  },
  footer: {
    minHeight: 8
  }
})
