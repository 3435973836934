import { Spacing } from '../..'

export const cardStyle = theme => ({
  shadowCard: {
  },
  cardGroup: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: () => Spacing(5),
    height: ({ height }) => height || 'auto'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: () => Spacing(0, 0, 4, 0)
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  newBadge: {
    width: 65,
    border: `1px solid ${theme.error[200]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    textTransform: 'uppercase'
  },
  pathIcon: {
    color: theme.neutral[0],
    fontSize: 8,
    backgroundColor: theme.warning[0],
    borderRadius: 8,
    marginRight: 8,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  coursesText: {
    marginTop: 'auto'
  }
})
