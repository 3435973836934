import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { bannerStyle } from './popUpBanner.style'
import { Icons, Spacing } from '../../constants'
import classNames from 'classnames'
import Icon from '../icon/icon'
import TypographyElement from '../typographyElement/typographyElement'

const IconsMap = {
  error: Icons.error,
  success: Icons.info,
  alert: Icons.info
}

const PopUpBanner = props => {
  const theme = useTheme()
  const useStyle = createUseStyles(bannerStyle)
  const { bannerGroup, cross, icon, box } = useStyle(props)

  useEffect(() => {
    if (props.timeOut && props.isOpen) {
      setTimeout(() => props.handleTimeOut(), props.timeOut)
    }
  }, [props.isOpen])
  if (props.isOpen) {
    return (
      <div
        className={classNames(bannerGroup, props.className)}
        data-test={props.dataTest}
      >
        <div className={box} data-test={props.dataTest}>
          <Icon iconName={IconsMap[props.status]} style={classNames(icon, props.style)} />
          <TypographyElement
            color={theme.neutral[400]}
            variant='body1'
            spacing={Spacing(0, 0, 0, 3)}
          >
            {props.message}
          </TypographyElement>
        </div>
        <Icon iconName={Icons.close} style={cross} onHandleIconClick={props.handleClose} />
      </div>
    )
  }
  return <></>
}

PopUpBanner.propTypes = {
  /** set popUpBanner to dark or light colors */
  mode: PropTypes.oneOf(['dark', 'light']),
  /** the message that gets displayed in the popUpBanner */
  message: PropTypes.string.isRequired,
  /** if set to true the popUpBanner will be visible */
  isOpen: PropTypes.bool,
  /** the status of the popUpBanner (info, success, error ...) */
  status: PropTypes.string,
  /** the time taken before fade out */
  timeOut: PropTypes.number,
  /** handle the close icon when it clicked */
  handleClose: PropTypes.func,
  /** handle the close icon when time out */
  handleTimeOut: PropTypes.func,
  /** Set the top position of the banner */
  topPos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the right position of the banner */
  rightPos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the left position of the banner */
  leftPos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the bottom position of the banner */
  bottomPos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the Tablet top position of the banner */
  topPosTablet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the Tablet right position of the banner */
  rightPosTablet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the Tablet left position of the banner */
  leftPosTablet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the Tablet bottom position of the banner */
  bottomPosTablet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the mobile version top position of the banner */
  topPosMobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the mobile version right position of the banner */
  rightPosMobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the mobile version left position of the banner */
  leftPosMobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the mobile version bottom position of the banner */
  bottomPosMobile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Set the position of the banner relative to the parent div */
  position: PropTypes.string,
  /** Set the banner's tag */
  dataTest: PropTypes.string
}

PopUpBanner.defaultProps = {
  message: 'Please specifiy a message using the message prop',
  status: 'info'
}

export default PopUpBanner
