import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { RateStarsStyle } from './rateStars.style'
import { RateStarSize, RateStarSizeMap } from '../../constants/rateStarSize'
import classNames from 'classnames'

const RateStars = ({ className, numberOfStars, size, value, secondary, margin = 0 }) => {
  const width = RateStarSizeMap[size].width * numberOfStars
  const height = RateStarSizeMap[size].height
  const pourcentage = `calc(${value / numberOfStars * 100}% - 1%)`
  const useStyle = createUseStyles(RateStarsStyle)
  const { rateStarsGroup, starFill } = useStyle({ width, height, pourcentage, secondary, margin })
  return (
    <div className={classNames(rateStarsGroup, className)}>
      <span className={starFill} />
    </div>
  )
}

RateStars.propTypes = {
  /** the value of the rate */
  value: PropTypes.number,
  /** the number of stars to render */
  numberOfStars: PropTypes.number,
  /** the size of stars to render */
  size: PropTypes.string
}

RateStars.defaultProps = {
  value: 0,
  numberOfStars: 5,
  size: RateStarSize.big
}

export default RateStars
