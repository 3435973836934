import { connect } from 'react-redux'
import UI from './UI'
import { registerToWebinarAction } from 'pages/Webinar/store'

const mapStateToProps = ({ webinar, identity }) => ({
  categories: webinar.data.Categories,
  title: webinar.data.Title,
  subtitle: webinar.data.SubTitle,
  plannedDate: webinar.data.PlannifiedWebinarDate,
  endDate: webinar.data.EndDate,
  isUserParticipating: webinar.data.IsUserParticipating,
  webinarId: webinar.data.Id,
  isConnected: identity.isConnected,
  isLoading: webinar.isWebinarLoading
})

const mapDispatchToProps = dispatch => ({
  registerToWebinar: id => dispatch(registerToWebinarAction(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
