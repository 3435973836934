import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { menuItemStyle } from './menuItem.style'

const MenuItem = (props) => {
  const { Component = 'span' } = props
  const useStyle = createUseStyles(menuItemStyle)
  const { menuItemGroup, input, selector, menuItem } = useStyle(props)
  return (
    <div className={menuItemGroup}>
      <input
        checked={props.checked}
        onChange={props.handleChange}
        name={props.name}
        value={props.value}
        id={props.id}
        type='radio'
        className={input}
        disabled={props.disabled}
      />
      <div className={selector} />
      <div className={menuItem}>
        <Component>{props.children}{props.number && <span>({props.number})</span>}</Component>
      </div>
    </div>
  )
}

MenuItem.propTypes = {
  /** the value true or false to signal if menuItem is disabled or not */
  disabled: PropTypes.bool,
  /** element's identifier */
  id: PropTypes.string,
  /** the name identifier to group multiple menuItems */
  name: PropTypes.string.isRequired,
  /** the value shipped with the menuItem */
  value: PropTypes.string.isRequired,
  /** set menuItem checked by default */
  checked: PropTypes.bool,
  /** the method that control the state of the menuItem */
  handleChange: PropTypes.func,
  /** number of articles in one menuItem */
  number: PropTypes.number,
  /** all prop its a menu item that shows all articles */
  all: PropTypes.bool
}

export default MenuItem
