import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { CheckoutButtonStyle } from './checkoutButton.style'
import { ButtonSize, ButtonVariation, ButtonType, ButtonIconAlignemement, Icons } from '../../constants'
import Button from '../button/button'
import Icon from '../icon/icon'

const CheckoutButton = (props) => {
  const useStyle = createUseStyles(CheckoutButtonStyle)
  const { button, iconAndText, span, price } = useStyle(props)
  return (
    <Button
      Component={props.Component}
      href={props.href}
      openOnNewTab={props.openOnNewTab}
      type={props.type}
      onHandleClick={props.handleClick}
      className={button}
      disabled={props.disabled}
      variation={ButtonVariation.secondary}
      size={props.size}
    >
      <div className={iconAndText}>
        <Icon iconName={Icons.shoppingCart} style={span} />
        <span>{props.label}</span>
      </div>
      <div className={price}>
        {props.price}{props.currency}
      </div>
    </Button>
  )
}

CheckoutButton.propTypes = {
  /**  the type of the button */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** the size of the Button */
  size: PropTypes.oneOf(['big', 'small']),
  /** the text that gonna be displayed on the button */
  label: PropTypes.string.isRequired,
  /** the method that handle the click event */
  handleClick: PropTypes.func,
  /** the price of unit */
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** the currency */
  currency: PropTypes.string,
  /** boolean that tell if theres a promo or not */
  promo: PropTypes.bool
}

CheckoutButton.defaultProps = {
  type: ButtonType.button,
  size: ButtonSize.big,
  alignement: ButtonIconAlignemement.left
}

export default CheckoutButton
