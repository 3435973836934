import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { persistStore, persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'

import rootSaga from './rootSaga'
import createLogger from 'redux-logger'

/* Reducers */

import { reducer as identityReducer, initIdentityStore } from 'identity/store'
import { reducer as catalog } from 'components/Catalog/store'
import { reducer as support } from 'components/common/Support/store'
import { reducer as trainer } from 'pages/Trainer/store'
import { reducer as user } from 'components/Profile/store'
import { reducer as learning } from 'components/Learning/store'
import { reducer as certificate } from 'pages/Certificate/store'
import { reducer as search } from 'components/common/Searchbar/store'
import { reducer as course } from 'pages/Course/store'
import { reducer as quiz } from 'pages/Course/Quizz/store'
import { reducer as referential } from './referential'
import { reducer as currency } from './currency'
import { reducer as cart } from 'components/Cart/store'
import { reducer as subscriptionCart } from 'components/CheckoutSubscription/store'
import { initDataStore } from './initDataStore'
import { reducer as payment } from './payment'
import { reducer as config } from 'store/config'
import { reducer as onBoarding } from 'pages/OnBoardingNext/store'
import { reducer as notification } from 'components/common/Notification/store'
import { reducer as learningPath } from 'pages/LearningPath/store'
import { reducer as marketing } from './marketing'
import { reducer as webinar } from 'pages/Webinar/store'
import { reducer as webinars } from 'pages/WebinarCatalog/store'
import { reducer as publicHome } from 'components/Home/store'
import { reducer as topic } from 'pages/Topic/store'

export const history = createBrowserHistory()

const reducers = {
  identity: identityReducer,
  router: connectRouter(history),
  catalog,
  support,
  trainer,
  user,
  search,
  learning,
  certificate,
  course,
  referential,
  cart,
  currency,
  payment,
  subscriptionCart,
  config,
  onBoarding,
  notification,
  quiz,
  learningPath,
  marketing,
  webinar,
  webinars,
  publicHome,
  topic
}

const rootReducer = persistCombineReducers({
  key: 'root',
  storage,
  blacklist: ['config', 'subscriptionCart', 'quiz']
}, reducers)

const middlewares = []
const enhancers = []

/* Saga */
const sagaMiddleware = createSagaMiddleware({})

middlewares.push(sagaMiddleware)
middlewares.push(routerMiddleware(history))

window?.AlphormConfiguration?.Debug === true && middlewares.push(createLogger)
window?.AlphormConfiguration?.Debug === true && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? enhancers.push(applyMiddleware(...middlewares)) : enhancers.push(applyMiddleware(...middlewares))

/* Create Store */

export const store = createStore(
  rootReducer,
  compose(...enhancers)
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

initIdentityStore(store.dispatch)
initDataStore(store.dispatch)
