import {
  TypographyElement
} from 'UI'
import style from './style'
import { createUseStyles, useTheme } from 'react-jss'

export default ({ handleTagClick, Name, NumberOfCourses, Id, Slug, active, margin, path, loading }) => {
  const theme = useTheme()
  const useStyle = createUseStyles(style)
  const { tag } = useStyle({ active, margin, loading })

  return (
    <div onClick={() => handleTagClick({ Name, NumberOfCourses, Id, Slug })} className={tag}>
      <TypographyElement
        variant='body1'
        color={active ? theme.neutral[400] : theme.warning[0]}
        Component='a'
        href={path}
        handleClick={e => e.preventDefault()}
        spacing='14px 30px'
        lineHeight='21px !important'
      >
        {`${Name}${NumberOfCourses ? ` (${NumberOfCourses})` : ''}`}
      </TypographyElement>
    </div>
  )
}
