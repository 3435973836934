import { Spacing } from '../..'

export const endOfCourseQuizStyle = (theme) => ({
  root: {
    padding: () => Spacing(5)
  },
  wrapper: {
    borderRadius: 8,
    backgroundColor: ({ isActive, isDisabled }) => isDisabled ? theme.neutral[0] : isActive ? theme.primary[0] : theme.neutral[0],
    color: ({ isDisabled }) => isDisabled && theme.neutral[300],
    display: 'flex',
    alignItems: 'center',
    padding: () => Spacing(4),
    cursor: 'pointer'
  },
  icon: {
    color: ({ isDisabled }) => isDisabled ? theme.neutral[300] : theme.secondary[0],
    marginRight: 8
  }
})
