import { InputColors, Typography, textColorMap, Status } from '../../constants'
import mediaQueries from '../../constants/mediaQueries'

export default theme => ({
  '@keyframes input-error-shake': {
    '0%': {
      transform: 'translateX(0px)'
    },
    '33%': {
      transform: 'translateX(8px)'
    },
    '66%': {
      transform: 'translateX(-8px)'
    },
    '100%': {
      transform: 'translateX(0px)'
    }
  },
  inputGroup: {
    width: '100%',
    height: ({ inputHeight }) => inputHeight,
    display: 'flex',
    justifyContent: ({ justifyContent }) => justifyContent || 'space-between',
    gap: ({ sectionGap }) => sectionGap,
    margin: ({ margin }) => margin || 0
  },
  input: {
    width: ({ inputWidth }) => inputWidth,
    height: '100%',
    borderRadius: 4,
    border: 'solid 1px',
    borderColor: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputborder,
    outline: 'none',
    color: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputext,
    transition: 'all .2s linear',
    background: theme.neutral[400],
    fontFamily: Typography.fontFamily,
    fontSize: ({ inputHeight }) => inputHeight,
    appearance: 'none',
    '&:disabled': {
      background: theme.neutral[400],
      borderColor: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputDisabledBorder
    },
    '&:hover': {
      borderColor: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputHoverBorder
    },
    transform: ({ status }) => status === Status.error ? 'translateX(10px)' : 'translateX(0px)',
    animation: ({ status }) => status === Status.error ? '$input-error-shake .25s ease forwards' : 'none',
    textAlign: 'center'
  },
  sectionClass: {
    display: 'flex',
    gap: ({ inputGap }) => inputGap
  },
  [`${mediaQueries.tablet}`]: {
    inputGroup: {
      height: ({ inputHeightTablet }) => inputHeightTablet
    },
    input: {
      fontSize: ({ inputHeightTablet }) => inputHeightTablet
    }
  },
  [`${mediaQueries.mobile}`]: {
    inputGroup: {
      height: ({ inputHeightMobile }) => inputHeightMobile
    },
    input: {
      fontSize: ({ inputHeightMobile }) => inputHeightMobile
    }
  }
})
