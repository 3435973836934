import {
  Slider
} from 'UI/'
import Text from './text.json'
import { CourseNeutralPath } from 'navigation/Routes'
import style from './style'
import { createUseStyles } from 'react-jss'
import CardRenderer from 'components/common/CardRenderer'
import { chunk } from 'lodash'

const useStyle = createUseStyles(style)

export const viewCards = ({ view, courseToShow, goToLink }) => courseToShow.map((item, key) =>
  (
    <div key={key} className={view}>
      {
        item.map((course, key) => (
          <CardRenderer
            key={course?.Id || key}
            loading={course?.loading}
            path={`${CourseNeutralPath}/${course?.Slug}`}
            course={course}
            handleCardClick={() => goToLink(`${CourseNeutralPath}/${course?.Slug}`)}
            width='calc(25% - 33px)'
            tabletWidth={234}
            mobileWidth={234}
            height={245}
          />
        ))
      }
    </div>
  )
)

export default ({ title = '', similarCourses = [], goToLink, titleProps, margin = 0, padding = 0, loading, courseLoading, hideController }) => {
  const splicedCourses = chunk(similarCourses, 4).map(chunkCourses => {
    chunkCourses.push(...Array(4 - chunkCourses.length))
    return chunkCourses
  })
  const courseToShow = loading || courseLoading ? [[...Array(4).fill({ loading: true })]] : splicedCourses
  const { root, view } = useStyle({ margin, padding })
  return (
    <div className={root}>
      <Slider
        title={title || Text.title}
        views={viewCards({ view, courseToShow, goToLink })}
        screen='desktop'
        hideController={hideController}
        secondaryController
        gap={43}
        gapTablet={16}
        gapMobile={16}
        titleProps={titleProps}
      />
    </div>
  )
}
