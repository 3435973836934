import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { paperStyle } from './paper.style'
import classNames from 'classnames'

const Paper = ({ className, onClick, loading, children, noHover = false, ...props }) => {
  const useStyle = createUseStyles(paperStyle)
  const { paper } = useStyle({ loading, noHover })
  return (
    <div
      className={classNames(className, paper)}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  )
}

Paper.propTypes = {
  /** provide a className to put your style */
  className: PropTypes.string,
  /** provide a onClick function to the paper */
  onClick: PropTypes.func,
  /** provide a loading to the paper */
  loading: PropTypes.bool,
  /** provide a children to the paper */
  children: PropTypes.node
}

export default Paper
