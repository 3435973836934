import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { DatePickerStyle } from './datePicker.style.js'
import DayPicker from 'react-day-picker'

const DatePicker = (props) => {
  const useStyle = createUseStyles(DatePickerStyle)
  const { datePicker } = useStyle(props)
  return (
    <div className={datePicker} id={props.id}>
      <DayPicker
        onDayClick={props.handleDayClick}
        selectedDays={props.selectedDay}
        {...props}
      />
    </div>
  )
}

DatePicker.propTypes = {
  /** show and hide the date picker */
  show: PropTypes.bool,
  /** function that handle the date click */
  handleDayClick: PropTypes.func,
  /** to show selected Day passe new Date() object with the selected date */
  selectedDay: PropTypes.object
}

export default DatePicker
