import { MediaQueries } from '@alphorm/ui'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    gap: 35,
    flexWrap: 'unset !important'
  },
  col: {
    flexShrink: '1 !important',
    gap: 30
  },
  [`${MediaQueries.tablet}`]: {
    row: {
      flexDirection: 'column'
    }
  },
  [`${MediaQueries.mobile}`]: {
    row: {
      flexDirection: 'column'
    }
  }
}
