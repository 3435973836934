const spaces = [0, 2, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 256]

export const Spacing = (...args) => {
  switch (args.length) {
    case 0:
      return []
    case 1:
      return `${spaces[args[0]]}px ${spaces[args[0]]}px ${spaces[args[0]]}px ${spaces[args[0]]}px`
    case 2:
      return `${spaces[args[0]]}px ${spaces[args[1]]}px ${spaces[args[0]]}px ${spaces[args[1]]}px`
    case 3:
      return `${spaces[args[0]]}px ${spaces[args[1]]}px ${spaces[args[2]]}px ${spaces[args[1]]}px`
    default:
      return `${spaces[args[0]]}px ${spaces[args[1]]}px ${spaces[args[2]]}px ${spaces[args[3]]}px`
  }
}
