import { red, green } from './.'

export const WebinarCardVariation = {
  live: 'live',
  coming: 'coming',
  passed: 'passed'
}

export const webinarStateColorMap = {
  [`${WebinarCardVariation.live}`]: red[7],
  [`${WebinarCardVariation.coming}`]: red[8],
  [`${WebinarCardVariation.passed}`]: green[2]
}

export const WebinarCardSizeMap = {
  big: {
    width: 406,
    height: 505,
    imageHeight: 188,
    iconFontSize: 15,
    titleFontSize: 18,
    detailsMargin: '0 0 16px 0',
    titleMargin: '0 0 16px 0',
    titleWidth: 318,
    titleHeight: 40,
    descriptionTitle: 16,
    descriptionMargin: '0 0 16px 0',
    clockIconTop: -1,
    clockIconFontSize: 12,
    marginRightUserClock: 20,
    marginTopUserClock: 1,
    stateText: {
      size: 10,
      lineHeight: '13px',
      height: 20,
      padding: '4px  21px'
    }
  },
  small: {
    width: 373,
    height: 325,
    imageHeight: 140,
    detailsFontSize: 12,
    iconFontSize: 16,
    titleFontSize: 18,
    detailsMargin: '0 0 13px 0',
    titleMargin: '0 0 13px 0',
    titleWidth: 220,
    titleHeight: 40,
    clockIconTop: 0,
    clockIconFontSize: 10,
    marginRightUserClock: 18,
    marginTopUserClock: 0,
    stateText: {
      size: 10,
      lineHeight: '13px',
      height: 12,
      padding: '4px  21px'
    }
  },
  extraSmall: {
    width: 237,
    height: 185,
    imageHeight: 89,
    detailsFontSize: 8,
    iconFontSize: 16,
    titleFontSize: 8,
    detailsMargin: '0 0 13px 0',
    titleMargin: '0 0 13px 0',
    titleWidth: 145,
    titleHeight: 27,
    clockIconTop: 0,
    clockIconFontSize: 8,
    marginRightUserClock: 8,
    marginTopUserClock: 0,
    stateText: {
      size: 6,
      weight: 700,
      lineHeight: '12px',
      height: 12,
      padding: '4px  6px'
    }
  }
}
