import React from 'react'
import PropTypes from 'prop-types'
import { ButtonStyle } from './button.style'
import { ButtonSize, ButtonVariation, ButtonType } from '../../constants'
import classNames from 'classnames'
import { TypographyElement } from '../..'
import { createUseStyles } from 'react-jss'

const Button = ({ textProps = {}, ...props }) => {
  const useStyle = createUseStyles(ButtonStyle)
  const { button, text } = useStyle(props)
  const { Component = 'button' } = props
  return (
    <Component
      type={props.type}
      data-test={props.dataTest}
      onClick={e => {
        if (!props.disablePreventDefault && Component === 'a') {
          e.preventDefault()
        }
        props.onHandleClick && props.onHandleClick(e)
      }}
      className={classNames(button, props.className)}
      disabled={props.disabled}
      {...(Component === 'a' ? { href: props.href, target: props.openOnNewTab ? '_blank' : null, rel: 'noopener noreferrer' } : null)}
    >
      {props.children}
      {
        props.label && (
          <TypographyElement
            className={text}
            variant='button'
            align='center'
            spacing='0px 20px 0 20px'
            {...textProps}
          >
            {props.label}
          </TypographyElement>
        )
      }
    </Component>
  )
}

Button.propTypes = {
  /** text show in the button */
  label: PropTypes.string,
  /**  the type of the button */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**  the variation of the button, (primary,secondary) */
  variation: PropTypes.oneOf([
    'primary',
    'secondary',
    'important',
    'confirmation',
    'text'
  ]),
  /** the size of the Button */
  size: PropTypes.oneOf(['big', 'small', 'extraSmall']),
  /** the method that handle the click event */
  onHandleClick: PropTypes.func,
  /** to render another html tag instead off button */
  Component: PropTypes.string,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  /** open link in new tab if using anchor tag */
  openOnNewTab: PropTypes.bool,
  /** width of the button */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Radius TopLeft of the button */
  topLeft: PropTypes.number,
  /** Radius TopRight of the button */
  topRight: PropTypes.number,
  /** Radius bottomLeft of the button */
  bottomLeft: PropTypes.number,
  /** Radius bottomRight of the button */
  bottomRight: PropTypes.number,
  /** Replace the button with a skeleton loader */
  loading: PropTypes.bool,
  /** disable button when true */
  disabled: PropTypes.bool
}

Button.defaultProps = {
  type: ButtonType.button,
  size: ButtonSize.big,
  variation: ButtonVariation.primary,
  topLeft: 8,
  topRight: 8,
  bottomLeft: 8,
  bottomRight: 8
}

export default Button
