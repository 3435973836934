import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { switchButtonStyle } from './switchButton.style'

const SwitchButton = (props) => {
  const useStyle = createUseStyles(switchButtonStyle)
  const { inputGroup, input, switchButton, label } = useStyle(props)
  return (
    <div className={inputGroup}>
      <input
        readOnly={props.readOnly}
        checked={props.checked}
        onChange={props.handleChange}
        value={props.value}
        id={props.id}
        type='checkbox'
        className={input}
        disabled={props.disabled}
      />
      <label className={switchButton} />
      {
        props.label && <label className={label}>{props.label}</label>
      }
    </div>
  )
}

SwitchButton.propTypes = {
  readOnly: PropTypes.bool,
  /**  the control label text to be displayed */
  label: PropTypes.string,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** element's identifier */
  id: PropTypes.string,
  /** the value shipped with the input when submitted */
  value: PropTypes.string,
  /** set checkbox checked by default */
  checked: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** transparent with white borders */
  transparent: PropTypes.bool
}

SwitchButton.defaultProps = {
  disabled: false
}

export default SwitchButton
