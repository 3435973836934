import NavBar from 'components/common/Navbar'
import Footer from 'components/common/Footer'
import { createUseStyles } from 'react-jss'
import styles from './style'
import classNames from 'classnames'

const useStyles = createUseStyles(styles)

export default ({
  children,
  hideSearchBar,
  hideShopingCart,
  becomeTrainer,
  setBecomeTrainer,
  noFooter,
  setIndex,
  inProfile,
  courseStarted,
  activeNavbar,
  customGoBackAction,
  hideNavbar,
  mainContentClass,
  hideSupport,
  marginFooter = 0
}) => {
  const { mainContent } = useStyles()
  return (
    <>
      <NavBar inProfile={inProfile} setIndex={setIndex} hideSearchBar={hideSearchBar} hideShopingCart={hideShopingCart} courseStarted={courseStarted} activeNavbar={activeNavbar} customGoBackAction={customGoBackAction} hideNavbar={hideNavbar} />
      <main className={classNames(mainContent, mainContentClass)}>
        {children}
      </main>
      {!noFooter ? <Footer marginFooter={marginFooter} setBecomeTrainer={setBecomeTrainer} becomeTrainer={becomeTrainer} hideSupport={hideSupport} /> : null}
    </>
  )
}
