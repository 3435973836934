import Category from 'components/Catalog/Category'
import { allWebinars, otherWebinars } from 'pages/WebinarCatalog/store'
import { Col, MediaQuery } from '@alphorm/ui'
import { WebinarsPath } from 'navigation/Routes'
import CategoryResponsive from 'components/Catalog/CategoryResponsive'
import style from './style'
import { createUseStyles } from 'react-jss'
import { otherWebinarCategory } from 'assets'

const useStyles = createUseStyles(style)

const DesktopWebinarCategories = ({ categories, loadingCategories, goToLink, handleChangeCategory, categorySlug, setHasCategory }) => {
  return (
    <Col direction='column' grid={3} mgrid={12} sgrid={12}>
      <Category
        all
        key={`${allWebinars.Id}-${loadingCategories}`}
        {...allWebinars}
        loading={loadingCategories}
        active={categorySlug === allWebinars.Slug}
        handleClick={() => {
          goToLink(WebinarsPath)
          handleChangeCategory(allWebinars.Slug)
          setHasCategory(true)
        }}
      />
      {categories.map(category =>
        <Category
          key={`${category.Id}-${loadingCategories}`}
          {...category}
          loading={loadingCategories}
          active={categorySlug === category.Slug}
          handleClick={() => {
            goToLink(`${WebinarsPath}/${encodeURIComponent(category.Slug)}`)
            handleChangeCategory(category.Slug)
          }}
        />
      )}
      <Category
        other
        key={`${otherWebinars.Id}-${loadingCategories}-0`}
        {...otherWebinars}
        loading={loadingCategories}
        active={categorySlug === otherWebinars.Slug}
        handleClick={() => {
          goToLink(`${WebinarsPath}/${encodeURIComponent(otherWebinars.Slug)}`)
          handleChangeCategory(otherWebinars.Slug)
          setHasCategory(false)
        }}
        Photo={otherWebinarCategory}
      />
    </Col>
  )
}

const MobileWebinarCategories = ({ categories, loadingCategories, goToLink, handleChangeCategory, categorySlug, setHasCategory }) => {
  const { col } = useStyles()
  return (
    <Col className={col} grid={12} mgrid={12} sgrid={12}>
      <CategoryResponsive
        catalog
        key={`${allWebinars.Id}-${loadingCategories}`}
        {...allWebinars}
        loading={loadingCategories}
        active={categorySlug === allWebinars.Slug}
        handleClick={() => {
          goToLink(WebinarsPath)
          handleChangeCategory(allWebinars.Slug)
          setHasCategory(true)
        }}
      />
      {categories.map(category =>
        <CategoryResponsive
          key={`${category.Id}-${loadingCategories}`}
          {...category}
          hideNumber
          loading={loadingCategories}
          active={categorySlug === category.Slug}
          handleClick={() => {
            goToLink(`${WebinarsPath}/${encodeURIComponent(category.Slug)}`)
            handleChangeCategory(category.Slug)
          }}
        />
      )}
      <CategoryResponsive
        other
        key={`${otherWebinars.Id}-${loadingCategories}-0`}
        {...otherWebinars}
        loading={loadingCategories}
        active={categorySlug === otherWebinars.Slug}
        handleClick={() => {
          goToLink(WebinarsPath)
          handleChangeCategory(otherWebinars.Slug)
          setHasCategory(false)
        }}
        Photo={otherWebinarCategory}
      />
    </Col>
  )
}

export default ({ categories, loadingCategories, goToLink, handleChangeCategory, categorySlug, setHasCategory }) => {
  return (
    <>
      <MediaQuery ShowOnDesktop>
        <DesktopWebinarCategories categories={categories} loadingCategories={loadingCategories} goToLink={goToLink} handleChangeCategory={handleChangeCategory} categorySlug={categorySlug} setHasCategory={setHasCategory} />
      </MediaQuery>
      <MediaQuery HideOnDesktop>
        <MobileWebinarCategories categories={categories} loadingCategories={loadingCategories} goToLink={goToLink} handleChangeCategory={handleChangeCategory} categorySlug={categorySlug} setHasCategory={setHasCategory} />
      </MediaQuery>
    </>
  )
}
