import {
  TypographyElement,
  Typography
} from 'UI'
import style from './style'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import { defaultCategory, freeCoursesCategory } from '../store'

const textColor = ({
  allCategory,
  freeCategory,
  theme
}) => {
  switch (true) {
    case allCategory:
      return theme.primary[0]
    case freeCategory:
      return theme.success[0]
    default:
      return theme.primary[1000]
  }
}

export default ({ handleClick, Name, AlternativeName, active, path, loading, all, margin, padding }) => {
  const theme = useTheme()
  const allCategory = Name === defaultCategory.Name || all
  const freeCategory = Name === freeCoursesCategory.Name
  const categoryColor = textColor({ theme, allCategory, freeCategory })
  const useStyle = createUseStyles(style)
  const { category, freeCategoryClass, nameClass } = useStyle({ active, loading, allCategory, margin })

  return (
    <div
      onClick={!loading ? handleClick : undefined}
      className={classNames(
        category,
        freeCategory && freeCategoryClass
      )}
      data-test={allCategory ? 'all-categories' : 'category-name'}
    >
      <TypographyElement
        variant='heading6'
        fontSize={Typography.body1.fontSize}
        handleClick={e => e.preventDefault()}
        color={active ? theme.primary[0] : categoryColor}
        Component='a'
        spacing={padding ?? '15px 0'}
        spacingTablet='12px 32px'
        spacingMobile='12px 32px'
        href={path}
        className={nameClass}
        fontWeight={allCategory ? 800 : undefined}
      >
        {AlternativeName || Name}
      </TypographyElement>
    </div>
  )
}
