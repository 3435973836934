
import { setTrialState } from 'components/Profile/store'
import { connect } from 'react-redux'
import UI from './UI'
import { completeOnboardingAction } from '../store'

const mapStateToProps = ({ user, onBoarding, subscriptionCart }) => ({
  userId: user.Id,
  subscription: user.Subscription,
  subscribedFromOnboarding: subscriptionCart.fromOnboarding,
  isTrial: user.showOnboardingTrial,
  checkoutOnboardingSkiped: onBoarding.checkoutOnboardingSkiped
})

const mapDispatchToProps = dispatch => ({
  completeOnboardingAction: (b2bNewUser, nextStep) => dispatch(completeOnboardingAction(b2bNewUser, nextStep)),
  setTrialState: (data) => dispatch(setTrialState(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
