import { Helmet } from 'react-helmet'

export default _ =>
  <Helmet>
    <script type='application/ld+json'>
      {
        `{
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.alphorm.com", 
            "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.alphorm.com/Search/Index?query={search_term_string}", 
                "query-input": "required name=search_term_string" 
            }
          }
        `
      }
    </script>
  </Helmet>
