import Header from './Header'
import Copyrights from './Copyrights'
import { createUseStyles } from 'react-jss'
import style from './style'
import Desktop from './Desktop'
import IntersectionComponent from 'components/common/IntersectionComponent'
import { Container, MediaQuery } from 'UI'
import Support from '../Support'

const useStyle = createUseStyles(style)

export default ({ goToUrl, hideSupport, marginFooter }) => {
  const { container, footerContent, footer } = useStyle({ marginFooter })
  return (

    <IntersectionComponent component={() => (
      <>
        {!hideSupport ? <Support /> : null}
        <footer className={footer}>
          <Container className={container}>
            <Header />
          </Container>
          <MediaQuery HideOnMobile HideOnTablet>
            <div className={footerContent}>
              <Desktop />
            </div>
            <Container>
              <Copyrights goToUrl={goToUrl} />
            </Container>
          </MediaQuery>
        </footer>
      </>
    )}
    />
  )
}
