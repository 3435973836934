import {
  Icon,
  Icons
} from '@alphorm/ui'
import style from './style'
import { createUseStyles } from 'react-jss'
import { popUpMock } from 'assets'
import { checkUTMsInURL } from 'helpers'
import moment from 'moment'
import { useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { decorateUrl } from '../UTMscript'

const useStyles = createUseStyles(style)

const shouldShowPopUp = (popUp, pathname, userTag, scrollPercentageValue, visitedRoutes, userClosePopUp) => {
  const isDateInRange = moment().isBetween(moment(popUp?.StartDate), moment(popUp?.EndDate))
  const isUrlExcluded = popUp?.ExcludedUrls?.some((url) => pathname.includes(url))
  const isUserTagIncluded = popUp?.BuyerPersonas?.includes(userTag)
  const isUtmExcluded = popUp?.ExcludedUtmSources?.some((utm) => checkUTMsInURL(window.location.href, utm))
  const widgetStatus = popUp?.WidgetStatus
  const numberOfPagesTrigger = popUp?.NumberOfPagesTrigger

  return (
    isDateInRange &&
    !isUrlExcluded &&
    !isUtmExcluded &&
    widgetStatus &&
    isUserTagIncluded &&
    !userClosePopUp &&
    (scrollPercentageValue >= popUp?.ScrollDepth || visitedRoutes.length >= numberOfPagesTrigger)
  )
}

export default ({
  popUpOpen,
  popUp,
  userTag,
  switchPopUpState,
  goToUrl,
  userClosePopUp,
  userClosePopUpAction,
  userVisitedRoutes,
  addVisitedRouteAction
}) => {
  const { pathname } = useLocation()
  const [scrollPercentageValue, setScrollPercentageValue] = useState(0)

  const { overlay, overlayContent, closeButton, imageStyle } = useStyles()

  const handleScroll = () => {
    const scrollPosition = window.scrollY
    const pageHeight = document.documentElement.scrollHeight
    const windowHeight = window.innerHeight
    const scrollPercentage = (scrollPosition / (pageHeight - windowHeight)) * 100
    setScrollPercentageValue(scrollPercentage)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    const isUtmExcluded = popUp?.ExcludedUtmSources?.some((utm) => checkUTMsInURL(window.location.href, utm))
    const isUserTagIncluded = popUp?.BuyerPersonas?.includes(userTag)
    if (!userClosePopUp && !isUtmExcluded && isUserTagIncluded) addVisitedRouteAction(pathname)
  }, [pathname])

  const showPopUp = useMemo(() => shouldShowPopUp(popUp, pathname, userTag, scrollPercentageValue, userVisitedRoutes, userClosePopUp),
    [popUp,
      pathname,
      userTag,
      scrollPercentageValue,
      userVisitedRoutes,
      userClosePopUp])
  !userClosePopUp && popUp?.WidgetStatus && switchPopUpState(showPopUp)
  if (!popUpOpen) return null
  return (
    <div className={overlay}>
      <div
        className={overlayContent}
        onClick={() => {
          popUp?.ActionExternal
            ? window.open(decorateUrl(popUp?.ActionLink), '_blank')
            : goToUrl(popUp?.ActionLink)
          switchPopUpState(false)
        }}
      >
        <Icon
          iconName={Icons.close} style={closeButton}
          handleIconClick={(e) => {
            e.stopPropagation()
            switchPopUpState(false)
            userClosePopUpAction()
          }}
        />
        <img src={popUpMock} alt='PopUp Image' className={imageStyle} />
      </div>
    </div>
  )
}
