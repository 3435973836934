import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import { tableRowStyle } from './tableRow.style'

const TableRow = (props) => {
  const useStyle = createUseStyles(tableRowStyle)
  const { row } = useStyle(props)
  return (
    <tr className={row}>
      {props.children}
    </tr>
  )
}

TableRow.propTypes = {
  /** ellement is hoverable */
  hover: PropTypes.bool

}

export default TableRow
