import {
  MediaQueries,
  Spacing,
  loadingSkeleton,
  loadingSkeletonAnimation
} from 'UI'

export default theme => ({
  category: ({ loading, active, allCategory, margin }) => ({
    margin: margin || Spacing(0, 0, 3, 0),
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .15s ease',
    backgroundColor: theme.neutral[400],
    borderRadius: 8,
    border: allCategory || loading ? 'none' : `1px solid ${theme.neutral[0]}`,
    borderColor: active ? theme.primary[0] : theme.neutral[0],
    '&:hover': {
      borderColor: theme.primary[0]
    },
    ...(loading && loadingSkeleton().core)
  }),
  freeCategoryClass: ({ active }) => ({
    borderColor: (active ? theme.primary[0] : theme.success[0]) + ' !important'
  }),
  nameClass: {},
  [`${MediaQueries.tablet}`]: {
    category: {
      border: ({ loading }) => loading ? 'none' : `1px solid ${theme.neutral[0]}`
    },
    nameClass: {
      textWrap: 'nowrap'
    }
  },
  [`${MediaQueries.mobile}`]: {
    category: {
      border: ({ loading }) => loading ? 'none' : `1px solid ${theme.neutral[0]}`
    },
    nameClass: {
      textWrap: 'nowrap'
    }
  },
  ...loadingSkeletonAnimation
})
