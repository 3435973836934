import fetch from 'core/fetch'
import { takeLatest, put, select } from 'redux-saga/effects'
import { BASE_URL_WEB, paymentMethodTypesDataLayer } from 'constants/'
import { LOGOUT_SUCCESS } from 'identity/store'
import { DeletePayementMedthodDataLayer } from 'helpers'
import { openFailNotification, openSuccessNotification } from 'components/common/Notification/store'
import Text from './text.json'
import { decorateUrl } from 'components/common/UTMscript'

const initialState = {
  creditCards: [],
  loadingCreditCards: false,
  addingCreditCard: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CREDIT_CARD_FETCH_REQUESTED:
      return {
        ...state,
        loadingCreditCards: true
      }
    case CREDIT_CARD_FETCH_SUCCESS:
      return {
        ...state,
        creditCards: data,
        loadingCreditCards: false
      }
    case ADD_CREDITCARD_REQUESTED:
      return {
        ...state,
        addingCreditCard: true
      }
    case ADD_CREDITCARD_SUCCESS:
      return {
        ...state,
        addingCreditCard: false,
        creditCards: [
          ...state.creditCards,
          data
        ]
      }
    case ADD_CREDITCARD_FAIL:
      return {
        ...state,
        addingCreditCard: false
      }
    case CHANGE_DEFAULT_PAYMENT_METHOD_SUCCEEDED:
      return {
        ...state,
        creditCards: data
      }
    case DELETE_PAYPAL_SUCCEEDED:
    case DELETE_CREDITCARD_SUCCEEDED:
      return {
        ...state,
        creditCards: data
      }
    case LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export const fetchCardsAction = _ => ({ type: CREDIT_CARD_FETCH_REQUESTED })
const getCreditCards = ({ payment }) => payment.creditCards
const getUserId = ({ identity }) => identity.user?.Id

export function * paymentRootSaga () {
  yield takeLatest(CREDIT_CARD_FETCH_REQUESTED, fetchCards)
  yield takeLatest(ADD_CREDITCARD_REQUESTED, AddCard)
  yield takeLatest(ADD_PAYPAL_REQUESTED, AddPaypal)
  yield takeLatest(CHANGE_DEFAULT_PAYMENT_METHOD, ChangeDefaultPaymentMethod)
  yield takeLatest(DELETE_CREDITCARD_REQUESTED, DeleteCreditCard)
  yield takeLatest(DELETE_PAYPAL_REQUESTED, DeletePaypal)
}

function * fetchCards () {
  try {
    const response = yield fetch(queries.getPaymentMethods)
    yield put({ type: CREDIT_CARD_FETCH_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: CREDIT_CARD_FETCH_FAIL, err })
  }
}

function * AddCard ({ data, additionalFunction }) {
  const creditCards = yield select(getCreditCards)
  const user = yield select(getUser)
  try {
    if (!data) throw new Error('token is not defined')
    const response = yield fetch(queries.addCard, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ token: data }) })
    if (response.PaymentMethod) {
      if (!creditCards.length) response.PaymentMethod.IsDefault = true
      yield put({ type: ADD_CREDITCARD_SUCCESS, data: response.PaymentMethod })
      yield put(openSuccessNotification(Text.addCardSuccess))
      const dataLayer = window.dataLayer || []
      yield dataLayer.push({
        event: 'add_payment_info',
        user_id: user.Id,
        payment_type: 'CB'
      })
    } else {
      yield put(openFailNotification(Text.addCardFailed))
    }
    additionalFunction && additionalFunction()
  } catch (err) {
    yield put({ type: ADD_CREDITCARD_FAIL, err })
    yield put(openFailNotification(Text.addCardFailed))
  }
}

function * AddPaypal ({ data }) {
  const { pathname } = data
  try {
    const response = yield fetch(queries.addPaypal(decorateUrl(pathname)), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    yield put({ type: ADD_PAYPAL_SUCCESS, data })
    window.location = response.BillingAgreementApprovalUrl
  } catch (err) {
    yield put({ type: ADD_PAYPAL_FAIL, err })
  }
}

function * ChangeDefaultPaymentMethod ({ cardId }) {
  const creditCards = yield select(getCreditCards)
  try {
    yield fetch(queries.setPaymentMethodAsDefault, { method: 'put', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ paymentMethodId: cardId }) })
    const changedCreditCards = creditCards.map(creditCard => {
      creditCard.IsDefault = creditCard.Id === cardId
      return creditCard
    })
    yield put({ type: CHANGE_DEFAULT_PAYMENT_METHOD_SUCCEEDED, data: changedCreditCards })
    yield put(openSuccessNotification(Text.changeDefaultPaymentMethodsSuccess))
  } catch (error) {
    yield put({ type: CHANGE_DEFAULT_PAYMENT_METHOD_FAILED })
    yield put(openFailNotification(Text.changeDefaultPaymentMethodsFailed))
  }
}

function * DeletePaypal ({ data }) {
  const creditCards = yield select(getCreditCards)
  const userId = yield select(getUserId)
  try {
    const body = JSON.stringify({ PaymentMethodId: data.Id })
    const response = yield fetch(queries.deletePaypal, { method: 'delete', headers: { 'Content-Type': 'application/json' }, body })
    if (response.IsSuccess) {
      DeletePayementMedthodDataLayer({ paymentType: paymentMethodTypesDataLayer[data.PaymentMethodType], userId })
      const filtredCards = creditCards.filter(card => card.Id !== data.Id)
      yield put({ type: DELETE_PAYPAL_SUCCEEDED, data: filtredCards })
      yield put(openSuccessNotification(Text.deletePaypalSuccess))
    } else {
      throw new Error(response)
    }
  } catch (err) {
    yield put({ type: DELETE_PAYPAL_FAILED, err })
    yield put(openFailNotification(Text.deletePaypalFailed))
  }
}

function * DeleteCreditCard ({ data }) {
  const creditCards = yield select(getCreditCards)
  const userId = yield select(getUserId)
  try {
    const body = JSON.stringify({ cardId: data.Id, paymentMethodTypeDetail: data.PaymentMethodType })
    const response = yield fetch(queries.deleteCreditCard, { method: 'delete', headers: { 'Content-Type': 'application/json' }, body })
    if (response.IsSuccess) {
      DeletePayementMedthodDataLayer({ paymentType: paymentMethodTypesDataLayer[data.PaymentMethodType], userId })
      const filtredCards = creditCards.filter(card => card.Id !== data.Id)
      yield put({ type: DELETE_CREDITCARD_SUCCEEDED, data: filtredCards })
      yield put(openSuccessNotification(Text.deleteCreditCardSuccess))
    } else {
      throw new Error(response)
    }
  } catch (err) {
    yield put({ type: DELETE_CREDITCARD_FAILED, err })
    yield put(openFailNotification(Text.deleteCreditCardFailed))
  }
}

export const getUser = ({ user }) => user
export const addCardAction = (data, additionalFunction) => ({ type: ADD_CREDITCARD_REQUESTED, data, additionalFunction })
export const addPaypalAction = (pathname) => ({ type: ADD_PAYPAL_REQUESTED, data: { pathname } })
export const ChangeDefaultPaymentMethodAction = cardId => ({ type: CHANGE_DEFAULT_PAYMENT_METHOD, cardId })
export const deleteCardAction = (data) => ({ type: DELETE_CREDITCARD_REQUESTED, data })
export const deletePaypalAction = (data) => ({ type: DELETE_PAYPAL_REQUESTED, data })

const CREDIT_CARD_FETCH_REQUESTED = 'CREDIT_CARD_FETCH_REQUESTED'
const CREDIT_CARD_FETCH_SUCCESS = 'CREDIT_CARD_FETCH_SUCCESS'
const CREDIT_CARD_FETCH_FAIL = 'CREDIT_CARD_FETCH_FAIL'
const ADD_CREDITCARD_SUCCESS = 'ADD_CREDITCARD_SUCCESS'
const ADD_CREDITCARD_FAIL = 'ADD_CREDITCARD_FAIL'
const ADD_CREDITCARD_REQUESTED = 'ADD_CREDITCARD_REQUESTED'
const ADD_PAYPAL_REQUESTED = 'ADD_PAYPAL_REQUESTED'
const ADD_PAYPAL_SUCCESS = 'ADD_PAYPAL_SUCCESS'
const ADD_PAYPAL_FAIL = 'ADD_PAYPAL_FAIL'
const CHANGE_DEFAULT_PAYMENT_METHOD = 'CHANGE_DEFAULT_PAYMENT_METHOD'
const CHANGE_DEFAULT_PAYMENT_METHOD_SUCCEEDED = 'CHANGE_DEFAULT_PAYMENT_METHOD_SUCCEEDED'
const CHANGE_DEFAULT_PAYMENT_METHOD_FAILED = 'CHANGE_DEFAULT_PAYMENT_METHOD_FAILED'
const DELETE_CREDITCARD_REQUESTED = 'DELETE_CREDITCARD_REQUESTED'
const DELETE_PAYPAL_REQUESTED = 'DELETE_PAYPAL_REQUESTED'
const DELETE_CREDITCARD_SUCCEEDED = 'DELETE_CREDITCARD_SUCCEEDED'
const DELETE_PAYPAL_SUCCEEDED = 'DELETE_CREDITCARD_SUCCEEDED'
const DELETE_CREDITCARD_FAILED = 'DELETE_CREDITCARD_FAILED'
const DELETE_PAYPAL_FAILED = 'DELETE_CREDITCARD_FAILED'

const queries = {
  addCard: `${BASE_URL_WEB}/payment/creditCard/add/`,
  addPaypal: (pathname) => `${BASE_URL_WEB}/payment/paypal/add?frontPage=${pathname}`,
  getPaymentMethods: `${BASE_URL_WEB}/User/getPaymentMethods`,
  setPaymentMethodAsDefault: `${BASE_URL_WEB}/User/setPaymentMethodAsDefault`,
  deleteCreditCard: `${BASE_URL_WEB}/Payment/creditCard`,
  deletePaypal: `${BASE_URL_WEB}/Payment/paypal`
}
