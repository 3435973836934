import { getCaptchaToken } from 'helpers'
import { getUser } from 'identity/userManager'
import { store } from 'store'
import { defaultCurrency } from 'store/currency'

export default async (...p) => {
  const args = p[1] || {}

  args.headers = args.headers || {}

  const user = await getUser()

  const bearer = user ? user.access_token : null

  args.headers.Authorization = bearer && `Bearer ${bearer}`

  const shopId = store?.getState()?.support?.Id

  const currency = store?.getState().currency.initialCurrency === defaultCurrency

  args.headers['X-ALPHORM-SHOP'] = shopId

  args.headers['X-ALPHORM-USE-EURO'] = currency

  args.headers['X-SESSION-ID'] = store?.getState()?.config?.sessionId

  args.headers['X-REQUEST-ID'] = store?.getState()?.router?.location?.pathname

  if (args.useCaptcha) {
    args.headers['X-CAPTCHA'] = await getCaptchaToken()
  }

  p[1] = args

  if (args.nojson === true) {
    const result = await window.fetch(...p)
    const isSuccess = result?.ok
    if (!isSuccess) throw new Error(result)
    else return result
  } else {
    const result = await window.fetch(...p)
    const isSuccess = result?.ok
    if (result.status === 204) return {}
    else if (!isSuccess) throw new Error(result)
    return result.json()
  }
}
