import {
  blue,
  neutral,
  Spacing
} from '@alphorm/ui'

export default {
  icon: {
    lineHeight: 0,
    fontSize: 10,
    color: blue[0]
  },
  socialMediaContainer: {
    display: 'flex',
    justifyContent: 'unset',
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    padding: () => Spacing(0, 0, 8, 0)
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  socialMedia: {
    backgroundColor: neutral[1],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 18,
    width: ({ iconSize }) => iconSize || 30,
    height: ({ iconSize }) => iconSize || 30,
    borderRadius: 4
  },
  linkClass: {
    textDecoration: 'none',
    lineHeight: 0,
    color: neutral[0]
  }
}
