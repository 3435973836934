import {
  MediaQueries
} from 'UI'

export default (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100% !important'
  },
  codeVerifierClass: {
    justifyContent: 'unset !important',
    marginBottom: '16px !important',
    flex: '1 1 100%',
    '& > div': {
      flex: '1 1 100%'
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  image: {
    margin: '29px 0 72px 0'
  },
  textInputClassName: {
    flexBasis: '100%',
    width: '100% !important',
    padding: '4% !important',
    borderRadius: '8px !important'
  },
  validationPhoneNumberClass: {
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0 24px 0'
  },
  validationPhoneNumberImage: {
    maxWidth: 145
  },
  [`${MediaQueries.tablet}`]: {
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    textInputClassName: {
      flexBasis: '100%',
      width: '100% !important',
      padding: '4% !important'
    }
  },
  [`${MediaQueries.mobile}`]: {
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column-reverse'
    },
    textInputClassName: {
      flexBasis: '100%',
      width: '100% !important',
      padding: '4% !important'
    }
  }
})
