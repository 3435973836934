export const Icons = {
  info: 'fas fa-solid fa-circle-info',
  alert: 'fas fa-solid fa-circle-info',
  error: 'fas fa-solid fa-circle-xmark',
  success: 'fas fa-solid fa-circle-info',
  showPwd: 'fas fa-solid fa-eye',
  plus: 'fas fa-solid fa-plus',
  download: 'fas fa-solid fa-download',
  user: 'fas fa-solid fa-user',
  smartPhone: 'fas fa-solid fa-mobile-screen-button',
  envelope: 'fas fa-solid fa-envelope',
  support: 'fas fa-solid fa-headset',
  shoppingCart: 'fas fa-solid fa-cart-shopping',
  play: 'fas fa-solid fa-play',
  search: 'fas fa-solid fa-magnifying-glass',
  magnifyingGlass: 'fas fa-solid fa-magnifying-glass-plus',
  facebook: 'fas fa-brands fa-facebook-f',
  google: 'fas fa-brands fa-google',
  instagram: 'fas fa-brands fa-instagram',
  linkedin: 'fas fa-brands fa-linkedin-in',
  lock: 'fas fa-solid fa-lock',
  unlock: 'fas fa-solid fa-lock-open',
  check: 'fas fa-solid fa-check',
  creditCard: 'fas fa-solid fa-credit-card',
  paypal: 'fas fa-brands fa-paypal',
  badge: 'alphorm-icon-badge',
  home: 'fas fa-solid fa-house',
  clock: 'fas fa-solid fa-clock',
  expand: 'fas fa-solid fa-expand',
  heart: 'fas fa-solid fa-heart',
  close: 'fas fa-solid fa-xmark',
  eyeOpen: 'fas fa-solid fa-eye',
  calendar: 'fas fa-solid fa-calendar',
  bin: 'fas fa-solid fa-trash',
  edit: 'fas fa-solid fa-pen',
  navigationMenu: 'fas fa-solid fa-bars',
  playalt: 'fas fa-solid fa-play',
  pause: 'fas fa-solid fa-pause',
  resize: 'fas fa-solid fa-up-right-and-down-left-from-center',
  users: 'fas fa-solid fa-users',
  courseDone: 'alphorm-icon-course-done',
  progression: 'alphorm-icon-progression',
  hidePassword: 'fas fa-solid fa-eye-slash',
  lastActivity: 'alphorm-icon-last-activity',
  roundedUp: 'fas fa-solid fa-angle-up',
  roundedLeft: 'fas fa-solid fa-angle-left',
  roundedRight: 'fas fa-solid fa-angle-right',
  roundedDown: 'fas fa-solid fa-angle-down',
  video: 'fas fa-solid fa-circle-play',
  offline: 'fas fa-solid fa-wifi',
  description: 'fas fa-solid fa-align-left',
  review: 'alphorm-icon-review',
  start: 'fas fa-solid fa-star',
  inBookmark: 'fas fa-solid fa-bookmark',
  bookmark: 'fas fa-regular fa-bookmark',
  dashboard: 'fas fa-solid fa-gauge',
  path: 'fas fa-solid fa-arrows-turn-to-dots',
  quiz: 'fas fa-solid fa-circle-question',
  apple: 'fas fa-brands fa-apple',
  android: 'fas fa-brands fa-android',
  arrowLeft: 'fas fa-solid fa-arrow-left',
  arrowRight: 'fas fa-solid fa-arrow-right',
  arrowUp: 'fas fa-solid fa-arrow-up',
  arrowDown: 'fas fa-solid fa-arrow-down',
  shield: 'fas fa-solid fa-shield-halved',
  locationDot: 'fas fa-solid fa-location-dot',
  twitter: 'fas fa-brands fa-x-twitter',
  comments: 'fas fa-solid fa-comments',
  gauge: 'fas fa-solid fa-gauge',
  certificat: 'fas fa-solid fa-certificate',
  clockLeft: 'fas fa-solid fa-clock-rotate-left',
  list: 'fas fa-solid fa-list',
  brain: 'fas fa-solid fa-brain',
  hourGlass: 'fas fa-solid fa-hourglass-start',
  videoCamera: 'fas fa-solid fa-video',
  personChalkboard: 'fas fa-solid fa-person-chalkboard',
  volumeHigh: 'fas fa-solid fa-volume-high',
  faceSmile: 'fas fa-regular fa-face-smile',
  faceNeutral: 'fas fa-regular fa-face-meh',
  faceSad: 'fas fa-regular fa-face-frown',
  circleCheck: 'fas fa-solid fa-circle-check',
  ban: 'fas fa-solid fa-ban',
  xmarkCalendar: 'fas fa-solid fa-calendar-xmark'
}
