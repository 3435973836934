import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default () => {
  const UserTagRef = useSelector(({ referential }) => referential.UserTags) || {}
  const { FirstName, LastName, Email, Subscription, UserSegment, UserTag } = useSelector(({ user }) => user)
  useEffect(() => {
    if (window.gsc) {
      window.gsc('params', {
        Email,
        FirstName,
        LastName,
        Segment: UserSegment,
        AccountType: UserTagRef[UserTag]
      })
    }
  }, [Email, FirstName, LastName, Subscription, UserSegment, UserTag, UserTagRef])

  return null
}
