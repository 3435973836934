import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { badgeStyles } from './badge.styles'
import classNames from 'classnames'
import TypographyElement from '../typographyElement/typographyElement'

const badgeSettings = (theme) => ({
  primary: {
    backgroundColor: theme.primary[0],
    color: theme.neutral[400]
  },
  secondary: {
    backgroundColor: theme.secondary[0],
    color: theme.primary[900]
  },
  success: {
    backgroundColor: theme.success[0],
    color: theme.neutral[400]
  },
  warning: {
    backgroundColor: theme.warning[0],
    color: theme.neutral[400]
  },
  error: {
    backgroundColor: theme.error[200],
    color: theme.neutral[400]
  },
  info: {
    backgroundColor: theme.info[0],
    color: theme.neutral[400]
  },
  light: {
    backgroundColor: theme.secondary[200],
    color: theme.neutral[400]
  },
  dark: {
    backgroundColor: theme.primary[1000],
    color: theme.neutral[400]
  }
})

const Badge = ({ label, status = 'primary', className }) => {
  const theme = useTheme()

  const settings = badgeSettings(theme)[status]

  const useStyle = createUseStyles(badgeStyles)
  const { badgeGroup } = useStyle({ backgroundColor: settings.backgroundColor })
  return (
    <div className={classNames(badgeGroup, className)}>
      <TypographyElement color={settings.color} variant='body2'>
        {label}
      </TypographyElement>
    </div>
  )
}

Badge.propTypes = {
  /** the label to display */
  label: PropTypes.string,
  /** the status of the badge (success, error ...) */
  status: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'warning',
    'error',
    'info',
    'light',
    'dark'
  ]),
  /** className */
  className: PropTypes.string
}

Badge.defaultProps = {
  status: 'primary',
  label: 'Badge'
}

export default Badge
