import { connect } from 'react-redux'
import UI from './UI'
import { closeNotification } from './store'

const mapStateToProps = ({ notification }) => ({
  open: notification.open,
  message: notification.message,
  status: notification.status,
  time: notification.time
})

const mapDispatchToProps = dispatch => ({
  handleClose: () => dispatch(closeNotification())
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
