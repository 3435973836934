
import { Container, MediaQuery } from 'UI'
import Desktop from './Desktop'
import classNames from 'classnames'
import style from './style'
import { createUseStyles } from 'react-jss'

const useStyle = createUseStyles(style)

export default (props) => {
  const { container, border } = useStyle()
  return (
    <>
      <MediaQuery HideOnMobile HideOnTablet>
        <div className={container}>
          <Desktop {...props} />
        </div>
      </MediaQuery>
      <MediaQuery HideOnDesktop>
        <Container>
          <div className={classNames(container, border)}>
            <Desktop {...props} />
          </div>
        </Container>
      </MediaQuery>
    </>
  )
}
