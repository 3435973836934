import React, { memo, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import TypographyElement from '../typographyElement/typographyElement'
import Icon from '../icon/icon'

import { quizQuestionStyle } from './quizQuestion.style'
import classNames from 'classnames'
import { getQuizChoiceIcon, quizChoiceStatus } from '../../constants/quizQuestion'

const useStyle = createUseStyles(quizQuestionStyle)

export const quizChoiceColorMap = (theme, status) => {
  switch (status) {
    case quizChoiceStatus.default:
      return {
        border: theme.neutral[0],
        background: theme.neutral[0],
        icon: theme.neutral[400],
        text: theme.primary[1000]
      }
    case quizChoiceStatus.active:
      return {
        border: theme.primary[0],
        background: theme.neutral[400],
        icon: theme.primary[0],
        text: theme.primary[0]
      }
    case quizChoiceStatus.correct:
      return {
        border: theme.success[0],
        background: theme.neutral[400],
        icon: theme.success[0],
        text: theme.success[0]
      }
    case quizChoiceStatus.incorrect:
      return {
        border: theme.error[0],
        background: theme.neutral[400],
        icon: theme.error[0],
        text: theme.error[0]
      }
    default:
      return {}
  }
}

const QuizQuestionChoice = memo(({
  children,
  name,
  value,
  checked,
  onChange,
  multiple,
  choiceStatus = quizChoiceStatus.default,
  disabled = false
}) => {
  const theme = useTheme()
  const currentCheckedStatus = checked ? quizChoiceStatus.active : choiceStatus
  const { wrapper, icon, iconAndInput } = useStyle({ multiple, choiceStatus: currentCheckedStatus, disabled })

  const iconName = getQuizChoiceIcon(currentCheckedStatus)

  return (
    <div className={wrapper}>
      <label>
        <div className={iconAndInput}>
          <input
            type={multiple === true ? 'checkbox' : 'radio'}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            disabled={disabled}
          />
          <div className={icon}>
            {iconName && (<Icon iconName={iconName} />)}
          </div>
        </div>
        <TypographyElement variant='body1' color={quizChoiceColorMap(theme, currentCheckedStatus).text}>
          {children}
        </TypographyElement>
      </label>
    </div>
  )
})

const QuizQuestion = ({
  name,
  multiple = false,
  choices = [],
  answers = [],
  submittedAnswers = [],
  onChoiceChange = () => { },
  className
}) => {
  const { quizQuestionsGroup } = useStyle({ choiceStatus: quizChoiceStatus.default })
  const [selectedAnswers, setSelectedAnswers] = useState([])

  const disabled = answers.length > 0

  const getChoiceStatus = useCallback((value) => {
    if (!disabled) return quizChoiceStatus.default

    if (answers.includes(value)) {
      return quizChoiceStatus.correct
    }
    if (!answers.includes(value) && submittedAnswers.includes(value)) {
      return quizChoiceStatus.incorrect
    }
    return quizChoiceStatus.default
  }, [disabled, answers, submittedAnswers])

  useEffect(() => {
    onChoiceChange(selectedAnswers)
  }, [selectedAnswers, onChoiceChange])

  const handleOnChange = useCallback((e) => {
    const { value } = e.target

    let newSelectedAnswers

    if (!multiple) {
      newSelectedAnswers = [value]
    } else {
      newSelectedAnswers = selectedAnswers.filter((answer) => answer !== value)
      if (newSelectedAnswers.length === selectedAnswers.length) {
        newSelectedAnswers.push(value)
      }
    }

    setSelectedAnswers(newSelectedAnswers)
  }, [multiple, selectedAnswers])

  return (
    <div className={classNames(quizQuestionsGroup, className)}>
      {
        choices.map(({ value, display }, index) => (
          <QuizQuestionChoice
            key={index}
            name={name}
            value={value}
            multiple={multiple}
            choiceStatus={getChoiceStatus(value)}
            disabled={disabled}
            checked={!disabled && selectedAnswers.includes(value)}
            onChange={handleOnChange}
          >
            {display}
          </QuizQuestionChoice>
        ))
      }
    </div>
  )
}

QuizQuestion.propTypes = {
  /** enable the user to select multiple choices */
  multiple: PropTypes.bool,
  /** the choices to be displayed */
  choices: PropTypes.array,
  /** the name identifier */
  name: PropTypes.string.isRequired,
  /** quiz answers */
  answers: PropTypes.array,
  /** user-submitted responses */
  submittedAnswers: PropTypes.array,
  /** Event handler for selecting a choice */
  onChoiceChange: PropTypes.func,
  /** className of the component */
  className: PropTypes.string
}

export default QuizQuestion
