import { MediaQueries } from 'UI/constants'

export const viewStyle = {
  view: {
    minWidth: ({ viewWidth }) => viewWidth || '100%'
  },
  [`${MediaQueries.tablet}`]: {
    view: {
      minWidth: ({ viewWidthTablet }) => viewWidthTablet || 'unset'
    }
  },
  [`${MediaQueries.mobile}`]: {
    view: {
      minWidth: ({ viewWidthMobile }) => viewWidthMobile || 'unset'
    }
  }
}
