import { MediaQueries, Spacing } from '../..'

export default (theme) => ({
  reviewGroup: {
    background: theme.neutral[400],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    padding: '24px 24px 29px 24px',
    margin: () => Spacing(0, 0, 4, 0)
  },
  review: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  },
  reviewCore: {
    display: 'flex'
  },
  avatarAndName: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  reviewSide: {
    marginLeft: 16
  },
  avatarAndRating: {
    display: 'flex',
    flexDirection: 'column'
  },
  reviewBody: {
    overflow: 'hidden'
  },
  reponse: {
    display: 'flex',
    background: theme.neutral[0],
    padding: '24px 24px 21px 24px',
    margin: () => Spacing(0, 0, 4, 0),
    borderColor: theme.primary[1300] + ' !important',
    alignItems: 'flex-start'
  },
  responseBody: {
    marginLeft: 8
  },
  ratingCore: {
    display: 'flex',
    alignItems: 'center'
  },
  time: {
    display: 'flex',
    alignItems: 'baseline'
  },
  iconStyle: {
    fontSize: 16,
    lineHeight: 0,
    paddingRight: 8,
    color: theme.secondary[0]
  },
  [`${MediaQueries.tablet}`]: {
    reviewCore: {
      flexDirection: 'column'
    },
    reponse: {
      flexDirection: 'column'
    },
    avatarAndRating: {
      display: 'none'
    },
    reviewSide: {
      marginLeft: 0
    },
    responseBody: {
      marginLeft: 0
    },
    avatarAndName: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  [`${MediaQueries.mobile}`]: {
    reviewCore: {
      flexDirection: 'column'
    },
    reponse: {
      flexDirection: 'column'
    },
    avatarAndRating: {
      display: 'none'
    },
    reviewSide: {
      marginLeft: 0
    },
    responseBody: {
      marginLeft: 0
    },
    avatarAndName: {
      display: 'flex',
      alignItems: 'center'
    }
  }
})
