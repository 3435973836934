import { Spacing } from '../../constants'
import mediaQueries from '../../constants/mediaQueries'

export default (theme) => ({
  paper: {
    borderRadius: 8,
    borderLeft: `solid 1px ${theme.neutral[0]}`,
    borderRight: `solid 1px ${theme.neutral[0]}`,
    borderBottom: `solid 1px ${theme.neutral[0]}`,
    padding: '28px 23px 24px 23px',
    width: props => props.width || '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    boxShadow: props => `0 -2px 0 0 ${props.shadowColor || theme.warning[0]}`,
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  expenderIcon: {
    transition: 'all 0.3s ease-in-out',
    transform: props => props.open ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 8
  },
  infoClassContainer: {
    transition: 'all 0.3s ease-in'
  },
  priceAndBanner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    marginBottom: 12,
    flexWrap: 'wrap'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.neutral[400],
    marginBottom: ({ priceAndBannerMarginExtra }) => priceAndBannerMarginExtra ? 76 : 33
  },
  switchSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  infoClass: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 8
  },
  iconClass: {
    color: theme.secondary[0],
    fontSize: 12,
    padding: '0 4px 0 0'
  },
  titleAndPromo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  promoTagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    backgroundColor: theme.neutral[400],
    borderRadius: 4,
    border: `solid 1px ${theme.warning[100]}`,
    height: 21,
    width: 65
  },
  priceClass: {
    display: 'flex !important',
    alignItems: 'baseline',
    animation: '$slideIn 0.2s cubic-bezier(0.39, 0.58, 0.57, 1)'
  },
  freeMonthsBanner: {
    backgroundColor: theme.success[0],
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 8px'
  },
  freeMonthsBannerPromo: {
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 21,
    padding: '3px 8px',
    backgroundColor: theme.error[0]
  },
  priceAndPromo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginRight: 8
  },
  '@keyframes slideIn': {
    '0%': {
      transform: 'translateX(50px)',
      opacity: 0
    },
    '100%': {
      transform: 'translateX(0px)',
      opacity: 1
    }
  },
  infoStyle: {
    padding: () => Spacing(5, 0, 0, 0)
  },
  [`${mediaQueries.mobile}`]: {
    header: {
      marginBottom: 0 + ' !important'
    },
    infoClassContainer: {
      overflow: 'hidden',
      maxHeight: props => props.open ? 1000 : 0,
      visibility: props => props.open ? 'visible' : 'hidden',
      padding: () => Spacing(0)
    },
    priceAndBanner: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginBottom: 16,
      gap: 15
    }
  },
  [`${mediaQueries.tablet}`]: {
    header: {
      marginBottom: 0 + ' !important'
    },
    infoClassContainer: {
      overflow: 'hidden',
      maxHeight: props => props.open ? 1000 : 0,
      visibility: props => props.open ? 'visible' : 'hidden',
      padding: () => Spacing(0)
    },
    priceAndBanner: {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginBottom: 16,
      gap: 15
    }
  }
})
