import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { TypographyElementStyle } from './typographyElement.style'
import classNames from 'classnames'

const intitlizeProps = props => ({
  variantTablet: props.variantTablet || props.variant,
  variantMobile: props.variantMobile || props.variant,
  colorTablet: props.colorTablet || props.color,
  fontSizeTablet: props.fontSizeTablet || props.fontSize,
  fontWeightTablet: props.fontWeightTablet || props.fontWeight,
  lineHeightTablet: props.lineHeightTablet || props.lineHeight,
  alignTablet: props.alignTablet || props.align,
  spacingTablet: props.spacingTablet || props.spacing,
  marginTablet: props.marginTablet || props.margin,
  displayTablet: props.displayTablet || props.display,
  colorMobile: props.colorMobile || props.color,
  fontSizeMobile: props.fontSizeMobile || props.fontSize,
  fontWeightMobile: props.fontWeightMobile || props.fontWeight,
  lineHeightMobile: props.lineHeightMobile || props.lineHeight,
  alignMobile: props.alignMobile || props.align,
  spacingMobile: props.spacingMobile || props.spacing,
  marginMobile: props.marginMobile || props.margin,
  displayMobile: props.displayMobile || props.display
})

const createMarkup = (p) => ({ __html: p })

const TypographyElement = ({ handleClick, ...props }) => {
  const useStyle = createUseStyles(TypographyElementStyle)
  const { text } = useStyle({ ...props, ...intitlizeProps(props) })
  const { Component = 'p', component } = props
  const RenderComponent = component || Component
  return (
    <RenderComponent
      data-test={props.dataTest}
      ref={props.ref}
      className={classNames(text, props.className)}
      onClick={handleClick}
      {...(props.href && props.href !== '' ? { href: props.href } : null)}
      {...(props.target && props.target !== '' ? { target: props.target } : null)}
      {...(props.dangerouslySetText ? { dangerouslySetInnerHTML: createMarkup(props.dangerouslySetText) } : null)}
      {...(props.rel ? { rel: 'noopener noreferrer' } : null)}
      htmlFor={props.htmlFor}
      title={props.title}
    >
      {props.children}
    </RenderComponent>
  )
}

TypographyElement.propTypes = {
  /** used to align the text to certain dirrection */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /** use different font sizes based on the order */
  variant: PropTypes.oneOf([
    'heading1',
    'heading2',
    'heading3',
    'heading4',
    'heading5',
    'heading6',
    'body1',
    'body2',
    'button',
    'caption',
    'label',
    'link',
    'tab'
  ]),
  /** used to specify the spacing */
  variantTablet: PropTypes.oneOf([
    'heading1',
    'heading2',
    'heading3',
    'heading4',
    'heading5',
    'heading6',
    'body1',
    'body2',
    'button',
    'caption',
    'label',
    'link',
    'tab'
  ]),
  variantMobile: PropTypes.oneOf([
    'heading1',
    'heading2',
    'heading3',
    'heading4',
    'heading5',
    'heading6',
    'body1',
    'body2',
    'button',
    'caption',
    'label',
    'link',
    'tab'
  ]),
  spacing: PropTypes.string,
  /** add cursor to link */
  cursor: PropTypes.string,
  /** used to specify the outer spacing */
  margin: PropTypes.string,
  /** add color if u want tp use other then default one */
  color: PropTypes.string,
  /** set the display */
  display: PropTypes.oneOf(['inline', 'inline-flex', 'block', '-webkit-box', 'inline-block', 'unset', 'none', 'flex']),
  /** set the font style */
  fontStyle: PropTypes.oneOf(['normal', 'italic', 'oblique']),
  /** set the weight */
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** set the weight */
  dangerouslySetText: PropTypes.string,
  /** set tag */
  dataTest: PropTypes.string,
  /** set max lines */
  maxLines: PropTypes.number
}

TypographyElement.defaultProps = {
  align: 'left',
  variant: 'body1'
}

export default TypographyElement
