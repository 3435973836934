import { Suspense } from 'react'
import { useInView } from 'react-intersection-observer'
import { IsCurrentUserBot } from '@alphorm/js'

export default ({ lazy, forceRender, component: Component, ...props }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '0px 0px 50px 0px'
  })
  if (IsCurrentUserBot()) {
    return <Component {...props} />
  }
  if (lazy) {
    return (
      <Suspense fallback={<div />}>
        <div ref={ref}>
          {inView || forceRender ? <Component {...props} /> : null}
        </div>
      </Suspense>
    )
  }

  return (
    <div ref={ref}>
      {inView || forceRender ? <Component {...props} /> : null}
    </div>
  )
}
