import React from 'react'
import { createUseStyles } from 'react-jss'
import { tableStyle } from './table.style'

const Table = (props) => {
  const useStyle = createUseStyles(tableStyle)
  const { root, table, footer } = useStyle(props)
  return (
    <div className={root}>
      <table className={table}>
        {props.children}
      </table>
      <footer className={footer}>
        {props.footer}
      </footer>
    </div>
  )
}

export default Table
