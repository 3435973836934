import { MediaQueries } from '@alphorm/ui'

export default {
  row: {
    gap: 30,
    flexWrap: 'unset !important'
  },
  textStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center'
  },
  container: {
    flexDirection: 'column'
  },
  [`${MediaQueries.tablet}`]: {
    row: {
      gap: 16
    }
  },
  [`${MediaQueries.mobile}`]: {
    row: {
      gap: 16
    }
  }
}
