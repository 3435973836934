import { useTheme } from 'react-jss'
import Text from '../text.json'
import { PricingPath, ContactPath, CategoryNeutralPath, CatalogLearningPathsPath } from 'navigation/Routes'
import { ExternalAlphormPages } from 'constants/'
import { decorateUrl } from 'components/common/UTMscript'
import { Col, Container, Row, TypographyElement } from 'UI'

const Title = ({ children }) => {
  const theme = useTheme()
  return (
    <TypographyElement
      spacing='0 0 16px 0'
      color={theme.primary[200]}
      variant='heading6'
      fontSize='19px'
      lineHeight='24px'
    >
      {children}
    </TypographyElement>
  )
}

const SubTitle = ({ children, handleClick, href, target, spacing }) => {
  const theme = useTheme()
  return (
    <TypographyElement
      color={theme.neutral[100]}
      cursor='pointer'
      variant='body1'
      handleClick={e => {
        handleClick && !target && e.preventDefault()
        handleClick && !target && handleClick && handleClick()
      }}
      href={decorateUrl(href)}
      Component={href && 'a'}
      rel={!!href}
      target={target}
      spacing={spacing}
    >
      {children}
    </TypographyElement>
  )
}

export default ({ isConnected, france, email, phone, categories, goToUrl, activeSubscription, isAlgeria }) => {
  return (
    <Container>
      <Row align='center'>
        <Col grid={3} pos='left' direction='column'>
          <Title>{Text.courses} </Title>
          {
            categories.length
              ? [{ Id: 'freeId', Slug: 'gratuites', Name: 'Formations gratuites' }, ...categories].map((category, key) =>
                <SubTitle
                  key={category.Id}
                  handleClick={() => goToUrl(`${CategoryNeutralPath}/${category.Slug}`)}
                  href={`${CategoryNeutralPath}/${category.Slug}`}
                >
                  {category.Name}
                </SubTitle>
                )
              : null
          }
        </Col>
        <Col grid={3} pos='left' direction='column'>
          <Title>{Text.coursesPath} </Title>
          {
            categories.length
              ? categories.map((category, key) =>
                <SubTitle
                  key={category.Id}
                  handleClick={() => goToUrl(`${CatalogLearningPathsPath}/${category.Slug}`)}
                  href={`${CatalogLearningPathsPath}/${category.Slug}`}
                >
                  {category.Name}
                </SubTitle>
                )
              : null
          }
        </Col>
        <Col grid={3} pos='left' direction='column'>
          <Title>{Text.solutions}</Title>
          {
            !activeSubscription
              ? (
                <>
                  <SubTitle handleClick={() => goToUrl(PricingPath)} href={PricingPath}>{Text.pricing}</SubTitle>
                  <SubTitle href={isAlgeria ? ExternalAlphormPages.AlgerianBusinessOffer : ExternalAlphormPages.BusinessOffer} target='_blank'>{Text.offers}</SubTitle>
                  {france && <SubTitle href={ExternalAlphormPages.Funding} target='_blank'>{Text.funding}</SubTitle>}
                </>
                )
              : null
          }
          <SubTitle href={ExternalAlphormPages.BecomeTrainer} target='_blank'>{Text.becomeTutor}</SubTitle>
          <SubTitle href={ExternalAlphormPages.Blog} target='_blank'>{Text.blog}</SubTitle>
        </Col>
        <Col grid={3} pos='left' direction='column'>
          <Title>{Text.support}</Title>
          <SubTitle href={ExternalAlphormPages.Support} target='_blank'>{Text.help}</SubTitle>
          <SubTitle handleClick={() => goToUrl(ContactPath)} href={ContactPath}>{Text.contactUs}</SubTitle>
          <SubTitle href={ExternalAlphormPages.FAQ} target='_blank'>{Text.FAQ}</SubTitle>
          <SubTitle href={`tel:${phone}`}>{phone}</SubTitle>
          <SubTitle href={`mailto:${email}`}>{email}</SubTitle>
        </Col>
      </Row>
    </Container>
  )
}
