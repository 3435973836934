import { useState } from 'react'
import { neutral } from '../constants'

const defaultTake = 10

export const usePagination = (take) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(take || defaultTake)
  return {
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    skip: page * rowsPerPage
  }
}

export const useSort = (fieldName) => {
  const [field, setField] = useState(fieldName)
  const [asc, setAsc] = useState(true)
  return {
    field,
    setField,
    asc,
    setAsc
  }
}

export const loadingSkeleton = ({ borderRadius = 4 } = {}) => ({
  core: {
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: neutral[0],
      borderRadius
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: neutral[17],
      opacity: 0.23,
      borderRadius,
      animation: '$blink 1s ease-in-out 0.5s infinite normal none running'
    }
  }
})

export const loadingSkeletonAnimation = {
  '@keyframes blink': {
    '0%': {
      opacity: 1
    },
    '50%': {
      opacity: 0.6
    },
    '100%': {
      opacity: 1
    }
  }
}

export const NameInitials = (name = '') => {
  const initials = name?.split(' ').map(word => word[0]).join('').toUpperCase()
  return initials
}
