import { MediaQuery } from '@alphorm/ui'
import { Suspense, lazy } from 'react'
const DesktopNavbar = lazy(() => import('./DesktopNavbar'))
const ResponsiveNavbar = lazy(() => import('./ReponsiveNavbar'))

export default ({ hideSearchBar, hideShopingCart, setIndex, inProfile, courseStarted, activeNavbar, customGoBackAction, hideNavbar }) =>
  <Suspense fallback={<div />}>
    <MediaQuery HideOnTablet HideOnMobile>
      <DesktopNavbar />
    </MediaQuery>
    <MediaQuery HideOnDesktop>
      <ResponsiveNavbar setIndex={setIndex} inProfile={inProfile} hideSearchBar={hideSearchBar} hideShopingCart={hideShopingCart} courseStarted={courseStarted} activeNavbar={activeNavbar} customGoBackAction={customGoBackAction} hideNavbar={hideNavbar} />
    </MediaQuery>
  </Suspense>
