import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ webinar }) => ({
  linkedCourses: webinar.data.Courses
})

const mapDispatchToProps = dispatch => ({
  goto: (url) => dispatch(push((url)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
