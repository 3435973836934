import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { avatarStyle } from './avatar.style'
import classNames from 'classnames'
import Icon from '../icon/icon'
import { Icons } from 'UI/constants'

const Avatar = ({ imageAsBackground = true, ...propsOther }) => {
  const props = { ...propsOther, imageAsBackground }
  const useStyle = createUseStyles(avatarStyle)
  const { avatarGroup, container } = useStyle(props)
  return (
    imageAsBackground
      ? (
        <div
          className={classNames(container, props.className)}
          onClick={props.handleClick}
          alt={props.alt}
          title={props.title}
        >
          {
        props.img
          ? null
          : (
            <span
              onClick={props.handleClick}
              className={classNames(avatarGroup, props.className)}
            >
              {!props.icon && props.name}
              {props.icon && <Icon iconName={Icons.user} />}
            </span>
            )
      }
        </div>
        )
      : <img src={props.img} className={classNames(container, props.className)} alt={props.alt.trim()} title={props.title.trim()} onClick={props.handleClick} />
  )
}

Avatar.propTypes = {
  /**  the name to be displayed insted of image */
  name: PropTypes.string,
  /**  the alt  the of image */
  alt: PropTypes.string,
  /**  the title of image */
  title: PropTypes.string,
  /**  the path of the img_avatar */
  img: PropTypes.string,
  /**  the function to trigger if the avatar is clicked */
  handleClick: PropTypes.func
}

Avatar.defaultProps = {
  name: 'TA'
}

export default Avatar
