import { loadingSkeleton, loadingSkeletonAnimation } from '../../helpers'

export const paperStyle = (theme) => ({
  paper: ({ loading, noHover }) => ({
    borderRadius: 8,
    display: 'flex',
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.neutral[0],
    '@media (hover: hover) and (pointer: fine)': {
      '&:hover': {
        borderWidth: 1,
        borderColor: noHover ? theme.neutral[0] : theme.primary[1300]
      }
    },
    ...(loading && loadingSkeleton().core)
  }),
  ...loadingSkeletonAnimation
})
