import {
  blue,
  neutral
} from '@alphorm/ui'

export default {
  video: {
    '& > .vjs-big-play-button': {
      top: 'calc(50% - 2em / 2)',
      left: 'calc(50% - 2em / 2)',
      backgroundColor: neutral[0] + ' !important',
      height: '2em',
      width: '2em',
      borderRadius: '50%',
      display: ({ started }) => started ? 'none' : 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 0,
      color: blue[0] + ' !important',
      '& > .vjs-icon-placeholder::before': {
        position: 'unset',
        width: 'unset',
        height: 'unset'
      }
    },
    '& .vjs-menu-button-popup .vjs-menu .vjs-menu-content': {
      maxHeight: '18em'
    }
  }
}
