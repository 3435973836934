import { Colors } from '../../constants/color'
import { Typography } from '../../constants/typography'
import { DropdownmenuBordersMap } from '../../constants'
export const dropdownMenuStyle = {
  root: {
    width: '100%',
    position: 'relative',
    borderColor: Colors.core.borderColorTag,
    borderStyle: 'solid',
    borderWidth: ({ borders }) => DropdownmenuBordersMap[borders],
    backgroundColor: Colors.core.white
  },
  checkbox: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    width: '100%',
    opacity: 0,
    height: 48,
    '&:checked + p span ': {
      transform: 'rotate(0deg)'

    },
    '&:checked ~ div ': {
      transform: 'scaleY(1)',
      height: ({ itemHeight, itemCount, links }) => `calc(${itemCount || (links || []).length}*${itemHeight}px)`
    }
  },
  title: {
    wdith: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [0, 10],
    height: 48,
    fontFamily: Typography.fontFamily,
    fontSize: Typography.smallTitle,
    fontWeight: Typography.fontWeight.semibold,
    '& span': {
      transform: 'rotate(180deg)'
    }
  },
  expandIcon: {
    fontSize: 18,
    color: Colors.text.black,
    fontWeight: Typography.fontWeight.light,
    transition: 'all 0.2s ease-in-out'
  },
  menuItems: {
    width: '100%',
    fontFamily: Typography.fontFamily,
    color: Colors.text.medium,
    fontSize: Typography.body,
    padding: [0, 10],
    transformOrigin: 'top center',
    position: 'relative',
    transform: 'scaleY(0)',
    height: 0,
    transition: 'all 0.2s ease-in-out',
    '& > *': {
      cursor: 'pointer',
      height: ({ itemHeight }) => itemHeight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      flexDirection: 'column'
    }
  }
}
