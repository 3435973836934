import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const queryParams = [
  'utm_medium',
  'utm_source',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'ref'
]

const queryParamsToIgnore = [
  'smclient'
]

export const getQueryParam = (name, customLink = null) => {
  name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(customLink || window.location.search)
  if (name) {
    return decodeURIComponent(name[1])
  }
}

export const decorateUrl = (urlToDecorate, additionalParams) => {
  const collectedQueryParams = []
  queryParams.map(query => {
    if (getQueryParam(query) && queryParamsToIgnore.indexOf(query) === -1) {
      collectedQueryParams.push(`${query}=${getQueryParam(query)}`)
    }
    return null
  })
  if (additionalParams) collectedQueryParams.push(additionalParams)
  const filteredCollectedQueryParams = collectedQueryParams.filter((item, index) => collectedQueryParams.indexOf(item) === index)
  return filteredCollectedQueryParams.length ? `${urlToDecorate}${urlToDecorate.indexOf('?') === -1 ? '?' : '&'}${filteredCollectedQueryParams.join('&')}` : urlToDecorate
}

const decorateLinks = () => {
  const links = document.querySelectorAll('a')
  links.forEach(link => (link.href = decorateUrl(link.href)))
}

export default _ => {
  const path = useLocation()
  useEffect(() => { decorateLinks() }, [path])
  return null
}
