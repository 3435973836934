import {
  Container,
  Row,
  TypographyElement,
  Button,
  Col
} from 'UI'
import style from './style'
import Text from './text.json'
import { createUseStyles } from 'react-jss'
import VideoPlayer from 'components/common/VideoPlayer'
import { CompleteOnboardingDataLayer } from 'helpers'

const useStyle = createUseStyles(style)

export default ({
  nextStep,
  completeOnboardingAction,
  videoLink,
  b2bNewUser,
  userId,
  subscription,
  subscribedFromOnboarding,
  isTrial,
  setTrialState,
  checkoutOnboardingSkiped
}) => {
  const showTrialOnboardingSuccess = isTrial && !checkoutOnboardingSkiped
  const {
    rootClass,
    videoStyle
  } = useStyle({ showTrialOnboardingSuccess })
  return (
    <Container className={rootClass}>
      <TypographyElement
        component='h3'
        variant='heading1'
        spacing='0 0 16px 0'
      >
        {Text.title}
      </TypographyElement>
      <TypographyElement
        component='h3'
        variant='body1'
        spacing='0 0 16px 0'
      >
        {Text.subTitle}
      </TypographyElement>
      <VideoPlayer videoLink={videoLink} mp4 externalClass={videoStyle} />
      <Row repeatCols={8} mrepeatCols={12} srepeatCols={12}>
        <Col grid={3} mgrid={12} sgrid={12} start={6}>
          <Button
            marginButton='32px 0 0 0'
            height={54}
            width='100%'
            onHandleClick={() => {
              !b2bNewUser && CompleteOnboardingDataLayer({ userId: userId, userSubscription: subscription.IsActive && subscribedFromOnboarding ? subscription.subscriptionLabel : null })
              completeOnboardingAction(b2bNewUser, nextStep)
              showTrialOnboardingSuccess && setTrialState(false)
            }}
            label={Text.save}
          />
        </Col>
      </Row>
    </Container>
  )
}
