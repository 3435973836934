import {
  Spacing
} from '@alphorm/ui'
import { categoriesColor } from 'constants/'
export default {
  container: {
    padding: Spacing(2, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ categoryId, backgroundColor }) => categoryId ? categoriesColor[categoryId]?.backgroundColor : backgroundColor,
    borderRadius: 4,
    minWidth: 70
  },
  image: {
    height: 15,
    width: 16,
    margin: () => Spacing(0, 2, 0, 0)
  }
}
