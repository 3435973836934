import { MediaQuery, SelectList } from '@alphorm/ui'
import style from './style'
import { createUseStyles } from 'react-jss'
import Text from './text.json'
import { useState } from 'react'

const useStyles = createUseStyles(style)
const filtersList = [
  {
    Id: 1,
    Name: Text.all,
    status: 0
  },
  {
    Id: 2,
    Name: Text.coming,
    status: 2
  },
  {
    Id: 3,
    Name: Text.passed,
    status: 3
  }
]
const Chip = ({ label, active, onClick }) => {
  const { chipStyle } = useStyles()
  return (
    <div
      className={`${chipStyle} ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      {label}
    </div>
  )
}

const DesktopCatalogFilter = ({ setSort, WebinarStatus }) => {
  const { container } = useStyles()
  const [activeChip, setActiveChip] = useState(Text.all)
  return (
    <div className={container}>
      <Chip
        label={Text.all}
        active={activeChip === Text.all}
        onClick={() => {
          setActiveChip(Text.all)
          setSort('')
        }}
      />
      <Chip
        label={Text.coming}
        active={activeChip === Text.coming}
        onClick={() => {
          setActiveChip(Text.coming)
          setSort(WebinarStatus.Coming)
        }}
      />
      <Chip
        label={Text.passed}
        active={activeChip === Text.passed}
        onClick={() => {
          setActiveChip(Text.passed)
          setSort(WebinarStatus.Passed)
        }}
      />
    </div>
  )
}

const MobileCatalogFilter = ({ setSort }) => {
  const [value, setValue] = useState(filtersList[0])
  return (
    <SelectList
      items={filtersList}
      selectedItem={value.Name}
      valueField='Name'
      onSelectedItem={(item) => {
        setValue({
          Name: item.Name,
          Id: item.Id
        })
        setSort(item.status)
      }}
    />
  )
}

export default ({ setSort, WebinarStatus }) => {
  return (
    <>
      <MediaQuery ShowOnDesktop>
        <DesktopCatalogFilter setSort={setSort} WebinarStatus={WebinarStatus} />
      </MediaQuery>
      <MediaQuery HideOnDesktop>
        <MobileCatalogFilter setSort={setSort} WebinarStatus={WebinarStatus} />
      </MediaQuery>
    </>
  )
}
