import Layout from 'components/common/Layout'
import { Container, Row, TypographyElement, neutral, Col } from '@alphorm/ui'
import { useWebinarCatalogPagination } from 'hooks'
import { useParams } from 'react-router-dom'
import WebinarsList from 'components/WebinarCatalog/WebinarsList'
import style from './style'
import { createUseStyles } from 'react-jss'
import WebinarsCategories from 'components/WebinarCatalog/WebinarsCategories'
import { allWebinars } from './store'
import Text from './text.json'
import { useEffect } from 'react'
import { WebinarFeature } from 'constants/'
import { HomePath } from 'navigation/Routes'
import Seo from './Seo'
import CatalogFilter from 'components/WebinarCatalog/CatalogFilter'

const useStyles = createUseStyles(style)

export default ({ loadingCategories, isLoading, webinars, webinarsCount, goToLink, categories, WebinarStatus }) => {
  const { row, col, container } = useStyles()
  const { categorySlug } = useParams()
  const { handleLoadMore, handleChangeCategory, setHasCategory, setSort, showLoadMore, categoryTitle, hasCategory } = useWebinarCatalogPagination({ defaultTake: 6, categoryDefault: categorySlug, categories })
  useEffect(() => {
    if (!WebinarFeature) goToLink(HomePath)
  }, [])
  return (
    <Layout>
      <Seo />
      <Container className={container}>
        <TypographyElement
          variant='heading2'
          spacing='56px 0 0 0'
          fontSizeTablet='24px'
          lineHeightTablet='30px'
          spacingTablet='48px 0 0 0'
          spacingMobile='48px 0 0 0'
          Component='h1'
          dataTest={categorySlug === allWebinars.Slug ? 'all-webinars-title' : 'category-title'}
        >
          {categoryTitle}
        </TypographyElement>
        <TypographyElement
          component='h4'
          variant='body1'
          spacing='0 0 32px 0'
          spacingTablet='0 0 24px 0'
          spacingMobile='0 0 24px 0'
          color={neutral[5]}
        >
          {`${webinarsCount} ${Text.webinars}`}
        </TypographyElement>
        <Row className={row}>
          <WebinarsCategories categories={categories} loadingCategories={loadingCategories} goToLink={goToLink} handleChangeCategory={handleChangeCategory} categorySlug={categorySlug} setHasCategory={setHasCategory} setSort={setSort} />
          <Col className={col} direction='column' grid={12}>
            <CatalogFilter setSort={setSort} WebinarStatus={WebinarStatus} />
            <WebinarsList items={webinars} loading={isLoading} handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} setSort={setSort} categorySlug={categorySlug} hasCategory={hasCategory} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
