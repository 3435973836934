import { Row, Col, CardSize, MediaQuery, Button, neutral, TypographyElement } from '@alphorm/ui'
import { chunk } from 'lodash'
import styles from './style'
import { createUseStyles } from 'react-jss'
import Text from './text.json'
import WebinarCardRenderer from 'components/common/WebinarCardRenderer'
import { amazonBucket, categoriesColor } from 'constants/'
import moment from 'moment'
import { DetailedDateFormat, formatSecondsDuration } from 'helpers'
import { otherWebinarCategory } from 'assets'
import { otherWebinars } from 'pages/WebinarCatalog/store'

const useStyles = createUseStyles(styles)

const loadingItems = [...new Array(2).fill({ loading: true })]

const splitIntoChunks = (webinars, chunkSize) => chunk(webinars, chunkSize).map(chunkLearningPath => {
  chunkLearningPath.push(...Array(chunkSize - chunkLearningPath.length))
  return chunkLearningPath
})

const ShowMore = ({ handleLoadMore, showLoadMore, fullWidth }) => {
  const { row } = useStyles()
  if (!showLoadMore) return null
  return (
    <Row className={row} justify='center'>
      <Col grid={12} pos='center'>
        <Button
          variation='secondary'
          size='big'
          label={Text.seeMore}
          handleClick={handleLoadMore}
          width={fullWidth ? '100%' : null}
        />
      </Col>
    </Row>
  )
}

const DesktopCards = ({ items, handleLoadMore, showLoadMore, WebinarStatus, hasCategory, Categories }) => {
  const { row } = useStyles()
  const chunkedWebinars = splitIntoChunks(items, 2)
  return (
    <>
      {
        chunkedWebinars.map((webinarPair, index) => (
          <Row className={row} key={index}>
            {
              webinarPair.map(webinar => (
                <WebinarCardRenderer
                  shadow={webinar === undefined}
                  key={webinar?.Id}
                  image={webinar?.SmallImage}
                  status={webinar?.WebinarStatus}
                  clockText={webinar?.WebinarStatus !== WebinarStatus?.Passed ? moment(webinar?.PlannifiedWebinarDate).format(DetailedDateFormat) : formatSecondsDuration(webinar?.Duration)}
                  title={webinar?.Title}
                  description={webinar?.SubTitle}
                  categoryImage={webinar?.Categories?.length && hasCategory ? `${amazonBucket.categories}${webinar?.Categories?.at(0)?.Photo}` : otherWebinarCategory}
                  categoryName={hasCategory && webinar?.Categories?.length ? webinar?.Categories?.at(0)?.Name : Text.other}
                  categoryBackgroundColor={hasCategory && webinar?.Categories?.length ? webinar?.Categories?.at(0)?.BackgroundColor : categoriesColor[otherWebinars.Id]?.backgroundColor}
                  size={CardSize.big}
                  variant={webinar?.WebinarStatus}
                  webinarLabel='Webinar'
                  loading={webinar?.loading}
                  isUserParticipating={webinar?.IsUserParticipating}
                  slug={webinar?.Slug}
                />
              ))
            }
          </Row>
        ))
      }
      <ShowMore handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} />
    </>
  )
}

const TabletCards = ({ items, handleLoadMore, showLoadMore, WebinarStatus, hasCategory }) => {
  const { row } = useStyles()
  const chunkedWebinars = splitIntoChunks(items, 2)
  return (
    <>
      {
        chunkedWebinars.map((webinarPair, index) => (
          <Row className={row} key={index}>
            {
              webinarPair.map(webinar => (
                <WebinarCardRenderer
                  shadow={webinar === undefined}
                  key={webinar?.Id}
                  image={webinar?.SmallImage}
                  status={webinar?.WebinarStatus}
                  clockText={webinar?.WebinarStatus !== WebinarStatus?.Passed ? moment(webinar?.PlannifiedWebinarDate).format(DetailedDateFormat) : formatSecondsDuration(webinar?.Duration)}
                  title={webinar?.Title}
                  description={webinar?.SubTitle}
                  categoryImage={webinar?.Categories?.length && hasCategory ? `${amazonBucket.categories}${webinar?.Categories?.at(0)?.Photo}` : otherWebinarCategory}
                  categoryName={hasCategory && webinar?.Categories?.length ? webinar?.Categories?.at(0)?.Name : Text.other}
                  categoryBackgroundColor={hasCategory && webinar?.Categories?.length ? webinar?.Categories?.at(0)?.BackgroundColor : categoriesColor[otherWebinars.Id]?.backgroundColor}
                  size={CardSize.small}
                  variant={webinar?.WebinarStatus}
                  webinarLabel='Webinar'
                  loading={webinar?.loading}
                  isUserParticipating={webinar?.IsUserParticipating}
                  slug={webinar?.Slug}
                />
              ))
            }
          </Row>
        ))
      }
      <ShowMore handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} fullWidth />
    </>
  )
}

const MobileCards = ({ items, handleLoadMore, showLoadMore, WebinarStatus, hasCategory }) => {
  const { row } = useStyles()
  const chunkedWebinars = splitIntoChunks(items, 1)
  return (
    <>
      {
        chunkedWebinars.map((webinarPair, index) => (
          <Row className={row} key={index}>
            {
              webinarPair.map(webinar => (
                <WebinarCardRenderer
                  shadow={webinar === undefined}
                  key={webinar?.Id}
                  image={webinar?.SmallImage}
                  status={webinar?.WebinarStatus}
                  clockText={webinar?.WebinarStatus !== WebinarStatus?.Passed ? moment(webinar?.PlannifiedWebinarDate).format(DetailedDateFormat) : formatSecondsDuration(webinar?.Duration)}
                  title={webinar?.Title}
                  description={webinar?.SubTitle}
                  categoryImage={webinar?.Categories?.length && hasCategory ? `${amazonBucket.categories}${webinar?.Categories?.at(0)?.Photo}` : otherWebinarCategory}
                  categoryName={hasCategory && webinar?.Categories?.length ? webinar?.Categories?.at(0)?.Name : Text.other}
                  categoryBackgroundColor={hasCategory && webinar?.Categories?.length ? webinar?.Categories?.at(0)?.BackgroundColor : categoriesColor[otherWebinars.Id]?.backgroundColor}
                  size={CardSize.small}
                  variant={webinar?.WebinarStatus}
                  webinarLabel='Webinar'
                  loading={webinar?.loading}
                  isUserParticipating={webinar?.IsUserParticipating}
                  slug={webinar?.Slug}
                />
              ))
            }
          </Row>
        ))
      }
      <ShowMore handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} fullWidth />
    </>
  )
}

export default ({ items, loading, handleLoadMore, showLoadMore, Categories, WebinarStatus, categorySlug, hasCategory }) => {
  const { textStyle } = useStyles()
  const itemsToDisplay = loading ? [...items, ...loadingItems] : items
  return (
    <>
      {
        items.length > 0 || loading
          ? (
            <>
              <MediaQuery ShowOnDesktop>
                <DesktopCards WebinarStatus={WebinarStatus} Categories={Categories} items={itemsToDisplay} loading={loading} handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} categorySlug={categorySlug} hasCategory={hasCategory} />
              </MediaQuery>
              <MediaQuery ShowOnTablet>
                <TabletCards WebinarStatus={WebinarStatus} Categories={Categories} items={itemsToDisplay} loading={loading} handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} categorySlug={categorySlug} hasCategory={hasCategory} />
              </MediaQuery>
              <MediaQuery ShowOnMobile>
                <MobileCards WebinarStatus={WebinarStatus} Categories={Categories} items={itemsToDisplay} loading={loading} handleLoadMore={handleLoadMore} showLoadMore={showLoadMore} categorySlug={categorySlug} hasCategory={hasCategory} />
              </MediaQuery>
            </>
            )
          : (
            <>
              <div className={textStyle}>
                <TypographyElement
                  component='h4'
                  variant='body1'
                  spacing='0 0 32px 0'
                  spacingTablet='0 0 24px 0'
                  spacingMobile='0 0 24px 0'
                  color={neutral[5]}
                >
                  {Text.empty}
                </TypographyElement>
              </div>
            </>
            )
        }
    </>
  )
}
