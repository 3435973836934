import { MediaQueries } from '../../constants'

export const sliderStyle = {
  root: {
    width: '100%',
    padding: ({ padding }) => padding || 0
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: ({ secondaryController }) => secondaryController ? 'baseline' : 'center',
    marginBottom: 24
  },
  slides: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflowX: 'hidden',
    gap: ({ gap }) => gap || 16,
    scrollBehavior: 'smooth'
  },
  [`${MediaQueries.tablet}`]: {
    root: {
      margin: ({ marginTablet }) => marginTablet || 0
    },
    slides: {
      overflowX: 'scroll',
      gap: ({ gapTablet }) => gapTablet || 16
    },
    header: {
      flexDirection: 'column',
      marginBottom: 16
    }
  },
  [`${MediaQueries.mobile}`]: {
    root: {
      margin: ({ marginMobile }) => marginMobile || 0
    },
    slides: {
      overflowX: 'scroll',
      gap: ({ gapMobile }) => gapMobile || 16
    },
    header: {
      flexDirection: 'column',
      marginBottom: 16
    }
  }
}
