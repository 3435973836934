export default (theme) => ({
  container: {
    width: '100%',
    height: 4,
    backgroundColor: theme.neutral[0],
    position: 'absolute',
    top: 13
  },
  fill: {
    width: ({ step, numberOfSteps }) => `${(step / numberOfSteps * 100)}%`,
    height: 4,
    backgroundColor: theme.primary[0],
    transition: 'all 0.1s linear'
  }
})
