import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { progressionRingStyle } from './progressionRing.style'
import classNames from 'classnames'

const ProgressionRing = ({ children, radius, stroke, backgroundColor, color, progress, childClass, loading, margin = 0 }) => {
  const useStyle = createUseStyles(progressionRingStyle)
  const { progressionRingGroup, circle, child } = useStyle({ children, radius, stroke, backgroundColor, color, progress, childClass, loading, margin })
  const normalizedProgress = progress > 100 ? 100 : progress < 0 ? 0 : progress
  const normalizedRadius = radius - stroke / 2
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - normalizedProgress / 100 * circumference
  const completeStrokeDashOffset = circumference - circumference
  return (
    <div className={progressionRingGroup}>
      <svg
        height={radius * 2}
        width={radius * 2}
      >
        <circle
          stroke={backgroundColor}
          fill='transparent'
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ completeStrokeDashOffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className={circle}
          stroke={color}
          fill='transparent'
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />

      </svg>
      <div className={classNames(child, childClass)}>
        {children}
      </div>
    </div>
  )
}

ProgressionRing.propTypes = {
  radius: PropTypes.number,
  stroke: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  progress: PropTypes.number.isRequired
}

ProgressionRing.defaultProps = {
  radius: 55,
  stroke: 2,
  color: 'white',
  progress: 0,
  backgroundColor: 'rgba(255,255,255,0.25)'
}

export default ProgressionRing
