import { put, takeLatest, select } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB } from 'constants/'
import { ViewItemListDataLayer } from 'helpers'
import { result } from 'lodash'

const initialState = {
  Courses: [],
  LearningPaths: [],
  searchTerm: '',
  error: false,
  searchLoader: false,
  Tag: {}
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case TOPIC_SEARCH_FETCH_REQUESTED:
      return {
        ...state,
        searchTerm: (data && data.searchTerm) ?? ''
      }
    case TOPIC_SEARCH_FETCH_SUCCESS:
      return {
        ...state,
        ...data.response,
        searchLoader: false,
        error: false
      }
    case TOPIC_SEARCH_FETCH_FAIL:
      return {
        ...state,
        err: data,
        Authors: [],
        Tags: [],
        Courses: [],
        LearningPaths: [],
        searchLoader: false
      }
    case TOPIC_SET_LOADER_SEARCH_STATE:
      return {
        ...state,
        ...data
      }
    default:
      return state
  }
}

export const turnOnLoaderSearchAction = () => ({ type: TOPIC_SET_LOADER_SEARCH_STATE, data: { searchLoader: true } })
export const turnOffLoaderSearchAction = () => ({ type: TOPIC_SET_LOADER_SEARCH_STATE, data: { searchLoader: false } })
export const setSearchTermAction = (searchTerm) => ({ type: TOPIC_SEARCH_FETCH_REQUESTED, data: { searchTerm } })

const getShopData = ({ support }) => support

function * fetchSearch ({ data }) {
  const shop = yield select(getShopData)
  const promo = shop?.PromotionCourse
  const { searchTerm } = data
  if (searchTerm.length) {
    try {
      const response = yield fetch(queries.search(searchTerm))
      if (promo && response?.Courses?.length) {
        response.Courses = response?.Courses.map(course => ({
          ...course,
          HasActivePromotion: true,
          PricePromo: promo.DiscountPrice,
          DiscountPrice: promo.DiscountPrice,
          AlternativePricePromo: promo.AlternativePricePromo,
          DiscountPercentage: promo.DiscountPercentage
        }))
      }
      yield put({ type: TOPIC_SEARCH_FETCH_SUCCESS, data: { response, searchTerm } })
      yield put(turnOffLoaderSearchAction())
      if (result) ViewItemListDataLayer({ courses: response.Courses, listName: searchTerm })
    } catch (err) {
      yield put({ type: TOPIC_SEARCH_FETCH_FAIL, err })
      yield put(turnOffLoaderSearchAction())
    }
  } else {
    yield put(turnOffLoaderSearchAction())
  }
}

export function * topicRootSaga () {
  yield takeLatest(TOPIC_SEARCH_FETCH_REQUESTED, fetchSearch)
}

const queries = {
  search: searchTerm => `${BASE_URL_WEB}/tag/${encodeURIComponent(searchTerm)}`
}
const TOPIC_SEARCH_FETCH_SUCCESS = 'TOPIC_SEARCH_FETCH_SUCCESS'
const TOPIC_SEARCH_FETCH_FAIL = 'TOPIC_SEARCH_FETCH_FAIL'
const TOPIC_SEARCH_FETCH_REQUESTED = 'TOPIC_SEARCH_FETCH_REQUESTED'
const TOPIC_SET_LOADER_SEARCH_STATE = 'TOPIC_SET_LOADER_SEARCH_STATE'
