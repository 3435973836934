import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { skeletonStyle } from './skeleton.style'

const useStyle = createUseStyles(skeletonStyle)

const SkeletonLine = ({
  width,
  height
}) => {
  const { line } = useStyle({ width, height })

  return (
    <div className={line} />
  )
}

const Skeleton = ({
  className,
  wrapperWidth,
  width,
  height = 16,
  gap = 8,
  lines = 1,
  borderRadius,
  margin,
  padding = 0,
  heightTablet,
  heightMobile
}) => {
  const { wrapper } = useStyle({ width, height, gap, margin, borderRadius, wrapperWidth, padding, heightTablet, heightMobile })

  return (
    <div className={classNames(wrapper, className)}>{
      [...Array(lines)].map((_, index) => (
        <SkeletonLine
          key={index}
          width={Array.isArray(width) ? width[index] : width}
          height={Array.isArray(height) ? height[index] : height}
        />
      ))
    }
    </div>
  )
}

Skeleton.propTypes = {
  /** className of the component */
  className: PropTypes.string,
  /** width of the skeleton's line */
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  /** height of the skeleton's line */
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array
  ]),
  /** the space between two lines */
  gap: PropTypes.number,
  /** number of lines to be displayed */
  lines: PropTypes.number,
  /** borderRadius of each line */
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** margin of the component */
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Skeleton
