import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { loaderStyle } from './loader.style.js'

export const Loader = (props) => {
  const useStyle = createUseStyles(loaderStyle)
  const { spiner } = useStyle(props)
  if (props.isDisplayed) {
    return (
      <div className={spiner}>
        <div />
        <div />
        <div />
        <div />
      </div>
    )
  } else return <></>
}

Loader.propTypes = {
  /** its a boolen of the display state of loader */
  isDisplayed: PropTypes.bool
}

export default Loader
