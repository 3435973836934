import { certificateImg } from 'assets'
import { blue } from '@alphorm/ui'
import Text from '../../text.json'
export default ({ name, title, date, link, duration }) =>
  <div>
    <svg preserveAspectRatio='none' viewBox='0 0 600 450'>
      <image className='img-circle' xlinkHref={certificateImg} height='100%' width='100%' />
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='12' id='svg_2' y='125' x='70' strokeWidth='0' stroke='#5e5e5e' fill='#5e5e5e'>{Text.certificateTexts.title}</text>
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='10' id='svg_2' y='145' x='70' strokeWidth='0' stroke='#5e5e5e' fill='#5e5e5e'>{date}</text>
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='18' id='svg_2' y='170' x='70' strokeWidth='0' stroke='#000' fill='#000'>{name}</text>
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='10' id='svg_2' y='190' x='70' strokeWidth='0' stroke='#5e5e5e' fill='#5e5e5e'>{Text.certificateTexts.action}</text>
      <foreignObject x='70' y='195' fontSize='14' width='400px' word-wrap='break-word' height='40'>
        <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='14' id='svg_2' y='210' x='70' strokeWidth='0' stroke='#000' fill='#000'>{title}</text>
      </foreignObject>
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='10' id='svg_2' y='240' x='70' strokeWidth='0' stroke='#5e5e5e' fill='#5e5e5e'>{duration}</text>
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='8' id='svg_2' y='275' x='70' strokeWidth='0' stroke='#5e5e5e' fill='#5e5e5e'>{Text.certificateTexts.signature}</text>
      <text xmlSpace='preserve' textAnchor='start' fontFamily='sans-serif' fontSize='8' id='svg_2' y='400' x='75' strokeWidth='0' stroke='#5e5e5e' fill='#5e5e5e'>{Text.certificateTexts.verification}</text>
      <foreignObject x='152' y='392' fontSize='8' fill='#3767da' width='800px' word-wrap='break-word' height='30'>
        <a rel='noopener noreferrer' target='_blank' href={link} style={{ textDecoration: 'none', color: blue[0] }}>
          <text x='70' y='420' fontSize='8' stroke='#3767da' fill='#3767da' textAnchor='middle'>
            {link}
          </text>
        </a>
      </foreignObject>
    </svg>
  </div>
