import { Modal, TypographyElement, neutral, Button, ButtonVariation, blue, MediaQuery } from '@alphorm/ui'
import { cancellatonStep2 } from 'assets'
import style from './style'
import { createUseStyles } from 'react-jss'
import Text from './text.json'

const useStyles = createUseStyles(style)

export default ({ changeUnfollowModalState, modalState, changeFollowState }) => {
  const { modalClass, header, devider, actionSection, content, illustrasionContainer } = useStyles()
  return (
    <Modal
      handleClose={() => changeUnfollowModalState(false)}
      isOpen={modalState}
      className={modalClass}
    >
      <div className={header}>
        <MediaQuery HideOnMobile>
          <div className={illustrasionContainer}>
            <img src={cancellatonStep2} />
          </div>
        </MediaQuery>
        <TypographyElement
          spacing='24px 0 16px 0'
          variant='heading2'
          align='center'
          variantMobile='heading4'
          spacingMobile='24px 0'
        >
          {Text.title}
        </TypographyElement>
      </div>
      <div className={content}>
        <TypographyElement spacing='0 0 16px 0' variant='body1' color={neutral[6]}>{Text.paragraph}</TypographyElement>
        {
          Text.items.map((item, index) => (
            <div key={index}>
              <TypographyElement
                variant='body1'
                spacing='0 12px 0 0'
                color={blue[0]}
                display='inline'
                align='left'
              >
                {Text.dot}
              </TypographyElement>
              <TypographyElement
                variant='body1'
                display='inline'
                color={neutral[5]}
                fontWeight={500}
                colorMobile={neutral[4]}
              >
                {item}
              </TypographyElement>
            </div>
          ))
        }
      </div>
      <MediaQuery HideOnMobile>
        <TypographyElement spacing='16px 0 32px 0' variant='caption1' color={neutral[5]}>{Text.confirmationDialog}</TypographyElement>
        <div className={devider} />
      </MediaQuery>
      <div className={actionSection}>
        <Button
          variation={ButtonVariation.primary}
          label={Text.confirm}
          handleClick={() => {
            changeFollowState()
            changeUnfollowModalState(false)
          }}
          widthMobile='100%'
          marginButtonMobile='0 0 16px 0'
        />
        <Button
          variation={ButtonVariation.secondary}
          label={Text.cancel}
          handleClick={() => changeUnfollowModalState(false)}
          widthMobile='100%'
        />
      </div>
    </Modal>
  )
}
