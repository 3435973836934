import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { tabStyle } from './tab.style'
import { TypographyElement } from '../..'
import classNames from 'classnames'

const useStyles = createUseStyles(tabStyle)

const Tab = ({
  active = false,
  className,
  children,
  handleClick,
  tabWidth
}) => {
  const theme = useTheme()
  const { tab } = useStyles({ tabWidth, active })

  return (
    <div className={classNames(tab, className)} onClick={handleClick}>
      <TypographyElement
        variant='tab'
        color={active ? theme.primary[0] : theme.primary[1000]}
      >{children}
      </TypographyElement>
    </div>
  )
}

Tab.propTypes = {
  /** the active tab got the true with this prop */
  active: PropTypes.bool,
  /** className of the component */
  className: PropTypes.string,
  /** the content of the tab  */
  children: PropTypes.node,
  /** the method that handle the click event */
  handleClick: PropTypes.func,
  /** the width of the tab */
  tabWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])

}

export default Tab
