import moment from 'moment'
import CategoryTag from '../CategoryTag'
import Text from './text.json'
import { red, green, neutral } from '@alphorm/ui'

export default ({ plannedDate, startDate, endDate }) => {
  const showComingTag = moment(plannedDate).isAfter(moment()) && endDate === null
  const showLiveTag = moment(startDate).isSameOrAfter(moment()) && endDate === null
  const passedTag = moment(plannedDate).isBefore(moment()) && endDate !== null
  switch (true) {
    case showComingTag:
      return (
        <CategoryTag
          name={Text.comingSoon}
          backgroundColor={red[8]}
          textColor={neutral[0]}
        />
      )
    case showLiveTag:
      return (
        <CategoryTag
          name={Text.live}
          backgroundColor={red[4]}
          textColor={neutral[0]}
        />
      )
    case passedTag:
      return (
        <CategoryTag
          name={Text.passed}
          backgroundColor={green[2]}
          textColor={neutral[0]}
        />
      )
    default:
      return null
  }
}
