import { put, select, takeLatest } from '@redux-saga/core/effects'
import { decorateUrl } from 'components/common/UTMscript'
import { push } from 'connected-react-router'
import { OnBoardingStateEnum, BASE_URL_WEB } from 'constants/'
import fetch from 'core/fetch'
import { CourseNeutralPath } from 'navigation/Routes'

const initialState = {
  onBoardingState: null,
  b2bNewUserOrganizationInfo: {
    teamName: '',
    adminName: '',
    organizationName: '',
    organizationId: '',
    teamId: '',
    invitationId: ''
  },
  accountInfo: {},
  password: '',
  onBoardingStep: null,
  onBoardingStepsHistory: [],
  onBoardingFreeCourse: false,
  isCheckoutOnboarding: false,
  isThreeTimesPaymentSelected: false,
  checkoutOnboardingSkiped: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case INIT_ONBOARDING_SUCCEEDED:
      return {
        ...state,
        ...initialState,
        onBoardingFreeCourse: state.onBoardingFreeCourse,
        onBoardingState: data
      }
    case COMPLETE_ONBOARDING_SUCCEEDED:
      return {
        ...state,
        ...initialState,
        onBoardingState: OnBoardingStateEnum.Completed
      }
    case SET_ONBORDING_STEP_SUCCEEDED:
      return {
        ...state,
        onBoardingStep: data
      }
    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...data
        }
      }
    case UPDATE_PASSWORD:
      return {
        ...state,
        password: data
      }
    case VERIFY_INVITATION_TOKEN_SUCCEEDED:
      return {
        ...state,
        ...data
      }
    case SET_ONBOARDING_FREE_COURSE:
      return {
        ...state,
        onBoardingFreeCourse: data
      }
    case SET_ONBOARDING_STEPS_HISTORY:
      return {
        ...state,
        onBoardingStepsHistory: data
      }
    case SET_IS_CHECKOUT_ONBOARDING:
      return {
        ...state,
        isCheckoutOnboarding: data
      }
    case SELECT_SUBSCRIPTION_THREE_TIMES_PAYMENT:
      return {
        ...state,
        isThreeTimesPaymentSelected: data
      }
    case SET_CHECKOUT_ONBOARDING_IS_SKIPED:
      return {
        ...state,
        checkoutOnboardingSkiped: data
      }
    default:
      return state
  }
}
export const initOnboarding = (data) => ({ type: INIT_ONBOARDING, data })
export const completeOnboardingAction = (b2bNewUser, nextStep) => ({ type: COMPLETE_ONBOARDING_REQUESTED, b2bNewUser, nextStep })
export const setOnboardingStepAction = (step) => ({ type: SET_ONBORDING_STEP_REQUESTED, step })
export const updateAccountInfoAction = data => ({ type: UPDATE_ACCOUNT_INFO, data })
export const selectSubscriptionThreeTimesPayment = data => ({ type: SELECT_SUBSCRIPTION_THREE_TIMES_PAYMENT, data })
export const updatePasswordInfoAction = () => ({ type: UPDATE_PASSWORD })
export const updateOrganizationInfoAction = () => ({ type: UPDATE_ORGANIZATION })
export const createUserAction = (data, next) => ({ type: CREATE_USER_REQUESTED, data, next })
export const verifyInvitationTokenRequested = token => ({ type: VERIFY_INVITATION_TOKEN_REQUESTED, token })
export const signUpWebhookAction = email => ({ type: SIGN_UP_WEBHOOK_REQUESTED, email })
export const setOnboardingFreeCourseAction = state => ({ type: SET_ONBOARDING_FREE_COURSE, data: state })
export const setOnBoardingStepsHistoryAction = state => ({ type: SET_ONBOARDING_STEPS_HISTORY, data: state })
export const startTrialAction = (data) => ({ type: START_TRIAL_REQUESTED, data })
export const setIsCheckoutOnboarding = (data) => ({ type: SET_IS_CHECKOUT_ONBOARDING, data })
export const setCheckoutOnboardingIsSkiped = (data) => ({ type: SET_CHECKOUT_ONBOARDING_IS_SKIPED, data })

const getUserInfos = ({ onBoarding }) => onBoarding

function * completeOnboarding ({ b2bNewUser, nextStep }) {
  try {
    const { onBoardingFreeCourse } = yield select(getUserInfos)
    if (!b2bNewUser) yield fetch(queries.completeOnboarding, { method: 'post', nojson: true })
    if (onBoardingFreeCourse) {
      const courseSlug = yield select(({ course }) => course?.Course?.Slug)
      if (courseSlug) {
        yield put(push(decorateUrl(`${CourseNeutralPath}/${courseSlug}`)))
      }
    }
    yield put({ type: COMPLETE_ONBOARDING_SUCCEEDED })
    yield put(setCheckoutOnboardingIsSkiped(false))
    nextStep && nextStep()
  } catch (err) {
    yield put({ type: COMPLETE_ONBOARDING_FAILED, err })
  }
}

function * setOnboardingStep ({ step }) {
  try {
    yield put({ type: SET_ONBORDING_STEP_SUCCEEDED, data: step })
  } catch (err) {
    yield put({ type: SET_ONBORDING_STEP_FAILED, err })
  }
}

function * createUser ({ data, next }) {
  const { accountInfo, b2bNewUserOrganizationInfo } = yield select(getUserInfos)
  const dataToSend = {
    firstName: accountInfo.FirstName,
    lastName: accountInfo.LastName,
    email: accountInfo.Email,
    password: data.password,
    adminId: b2bNewUserOrganizationInfo.adminId,
    teamId: b2bNewUserOrganizationInfo.teamId,
    organizationId: b2bNewUserOrganizationInfo.organizationId,
    organizationLicenseType: b2bNewUserOrganizationInfo.organizationLicenseType,
    invitationId: b2bNewUserOrganizationInfo.invitationId,
    UserRole: b2bNewUserOrganizationInfo.role
  }
  try {
    yield fetch(queries.createUser, { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true, useCaptcha: true, body: JSON.stringify(dataToSend) })
    yield put({ type: CREATE_USER_SUCCEEDED })
    next()
  } catch (err) {
    yield put({ type: CREATE_USER_FAILED, err })
  }
}

function * signUpWebhook ({ email }) {
  try {
    yield fetch(queries.signUpWebhook(email), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    yield put({ type: SIGN_UP_WEBHOOK_SUCCEEDED })
  } catch (err) {
    yield put({ type: SIGN_UP_WEBHOOK_FAILED, err })
  }
}

function * verifyInvitationToken ({ token }) {
  try {
    if (token) {
      const result = yield fetch(queries.verifyInvitationToken(token))
      if (result && result.InvitationId) {
        const data = {
          onBoardingState: OnBoardingStateEnum.Start,
          b2bNewUserOrganizationInfo: {
            teamName: result.TeamName,
            adminName: result.AdminName,
            adminId: result.AdminId,
            organizationName: result.OrganizationName,
            organizationId: result.Id,
            teamId: result.TeamId,
            organizationLicenseType: result.OrganizationLicenseType,
            invitationId: result.InvitationId,
            role: result.Role
          },
          accountInfo: {
            Email: result.UserEmail
          }
        }
        yield put({ type: VERIFY_INVITATION_TOKEN_SUCCEEDED, data })
      }
    } else {
      yield put({ type: VERIFY_INVITATION_TOKEN_SUCCEEDED, data: { onBoardingState: null } })
    }
  } catch (err) {
    yield put({ type: VERIFY_INVITATION_TOKEN_FAILED, err })
  }
}

function * initOnboardingAction ({ data }) {
  try {
    yield put({ type: INIT_ONBOARDING_SUCCEEDED, data })
  } catch (err) {
    yield put({ type: INIT_ONBOARDING_FAILED, err })
  }
}

function * startTrial ({ data }) {
  try {
    yield fetch(queries.startTrial(data), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    yield put({ type: START_TRIAL_SUCCEEDED })
  } catch (err) {
    yield put({ type: START_TRIAL_FAILED, err })
  }
}

export function * onboardingRootSaga () {
  yield takeLatest(SET_ONBORDING_STEP_REQUESTED, setOnboardingStep)
  yield takeLatest(COMPLETE_ONBOARDING_REQUESTED, completeOnboarding)
  yield takeLatest(CREATE_USER_REQUESTED, createUser)
  yield takeLatest(VERIFY_INVITATION_TOKEN_REQUESTED, verifyInvitationToken)
  yield takeLatest(INIT_ONBOARDING, initOnboardingAction)
  yield takeLatest(SIGN_UP_WEBHOOK_REQUESTED, signUpWebhook)
  yield takeLatest(START_TRIAL_REQUESTED, startTrial)
}

const queries = {
  completeOnboarding: `${BASE_URL_WEB}/User/completeOnBoarding`,
  createUser: `${BASE_URL_WEB}/organization/learner`,
  signUpWebhook: (email) => `${BASE_URL_WEB}/Zapier/signUp/${email}`,
  startTrial: (billingCycle) => `${BASE_URL_WEB}/User/StartTrial?billingCycle=${billingCycle}`,
  verifyInvitationToken: (token) => `${BASE_URL_WEB}/organization/VerifyInvitationLink?token=${encodeURIComponent(token)}`
}

const COMPLETE_ONBOARDING_REQUESTED = 'COMPLETE_ONBOARDING_REQUESTED'
export const COMPLETE_ONBOARDING_SUCCEEDED = 'COMPLETE_ONBOARDING_SUCCEEDED'
const COMPLETE_ONBOARDING_FAILED = 'COMPLETE_ONBOARDING_FAILED'
const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO'
const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
const CREATE_USER_REQUESTED = 'CREATE_USER_REQUESTED'
const CREATE_USER_SUCCEEDED = 'CREATE_USER_SUCCEEDED'
const CREATE_USER_FAILED = 'CREATE_USER_FAILED'
const INIT_ONBOARDING = 'INIT_ONBOARDING'
const VERIFY_INVITATION_TOKEN_SUCCEEDED = 'VERIFY_INVITATION_TOKEN_SUCCEEDED'
const VERIFY_INVITATION_TOKEN_FAILED = 'VERIFY_INVITATION_TOKEN_FAILED'
const VERIFY_INVITATION_TOKEN_REQUESTED = 'VERIFY_INVITATION_TOKEN_REQUESTED'
const INIT_ONBOARDING_SUCCEEDED = 'INIT_ONBOARDING_SUCCEEDED'
const INIT_ONBOARDING_FAILED = 'INIT_ONBOARDING_FAILED'
const SET_ONBORDING_STEP_SUCCEEDED = 'SET_ONBORDING_STEP_SUCCEEDED'
const SET_ONBORDING_STEP_FAILED = 'SET_ONBORDING_STEP_FAILED'
const SET_ONBORDING_STEP_REQUESTED = 'SET_ONBORDING_STEP_REQUESTED'
const SET_ONBOARDING_FREE_COURSE = 'SET_ONBOARDING_FREE_COURSE'
const SIGN_UP_WEBHOOK_SUCCEEDED = 'SIGN_UP_WEBHOOK_SUCCEEDED'
const SIGN_UP_WEBHOOK_FAILED = 'SIGN_UP_WEBHOOK_FAILED'
const SIGN_UP_WEBHOOK_REQUESTED = 'SIGN_UP_WEBHOOK_REQUESTED'
const SET_ONBOARDING_STEPS_HISTORY = 'SET_ONBOARDING_STEPS_HISTORY'
const START_TRIAL_SUCCEEDED = 'START_TRIAL_SUCCEEDED'
const START_TRIAL_FAILED = 'START_TRIAL_FAILED'
const START_TRIAL_REQUESTED = 'START_TRIAL_REQUESTED'
const SET_IS_CHECKOUT_ONBOARDING = 'SET_IS_CHECKOUT_ONBOARDING'
const SELECT_SUBSCRIPTION_THREE_TIMES_PAYMENT = 'SELECT_SUBSCRIPTION_THREE_TIMES_PAYMENT'
const SET_CHECKOUT_ONBOARDING_IS_SKIPED = 'SET_CHECKOUT_ONBOARDING_IS_SKIPED'
