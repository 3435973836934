import { Typography } from '../../constants/typography'
import { Colors } from '../../constants/color'

export const DatePickerStyle = {
  datePicker: {
    '& .DayPicker': {
      width: '100%'
    },
    position: 'relative',
    top: 1,
    height: ({ show }) => show ? '100%' : '0',
    transform: ({ show }) => show ? 'scaleY(1)' : 'scaleY(0)',
    transition: 'all 0.3s linear',
    transformOrigin: 'top',
    '& > div': {
      fontFamily: Typography.fontFamily,
      borderRadius: 4,
      boxShadow: '0 3px 4px 0 rgba(10, 31, 68, 0.1), 0 0 1px 0 rgba(10, 31, 68, 0.08)',
      backgroundColor: Colors.core.white
    },
    '& .DayPicker .DayPicker-wrapper .DayPicker-NavBar .DayPicker-NavButton': {
      marginTop: 9
    }
  }
}
