import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { suggestionGroupStyle } from './suggestionGroup.style'

export const SuggestionGroup = forwardRef((props, ref) => {
  const useStyle = createUseStyles(suggestionGroupStyle)
  const { suggestionGroup } = useStyle(props)
  return (
    <div className={suggestionGroup} ref={ref}>
      {props.children}
    </div>
  )
})

export default SuggestionGroup
