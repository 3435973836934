import { Status } from './status'

export const InputColors = (theme) => ({
  inputext: theme.primary[400],
  inputlabel: theme.primary[400],
  inputborder: theme.neutral[0],
  inputsuccess: theme.success[0],
  inputerror: theme.error[0],
  inputalert: theme.secondary[0],
  inputhidden: theme.neutral[400],
  inputshown: theme.primary[400],
  inputDisabledBackground: theme.neutral[0],
  inputDisabledBorder: theme.neutral[0],
  inputplaceholder: theme.neutral[0],
  inputfocus: theme.neutral[0],
  inputHoverBorder: theme.primary[1300]
})

export const textColorMap = (theme, status) => {
  switch (status) {
    case Status.error:
      return theme.error[0]
    case Status.success:
      return theme.success[0]
    case Status.alert:
      return theme.secondary[0]
    case Status.info:
      return theme.primary[400]
    case Status.regular:
      return theme.primary[400]
    case Status.text:
      return theme.primary[400]
    default:
      return theme.primary[400]
  }
}
