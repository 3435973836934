import { Status } from 'UI'
import { popUpDefaultTimeOut } from 'constants/'

const initialState = {
  open: false,
  message: '',
  status: '',
  time: popUpDefaultTimeOut
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case OPEN_SUCCESS_NOTIFICATION:
    case OPEN_FAIL_NOTIFICATION:
    case OPEN_ALERT_NOTIFICATION:
      return {
        ...state,
        ...data
      }
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        ...data
      }
    default:
      return state
  }
}

export const openSuccessNotification = (message, time = popUpDefaultTimeOut) => ({ type: OPEN_SUCCESS_NOTIFICATION, data: { message, open: true, status: Status.success, time } })
export const openFailNotification = (message, time = popUpDefaultTimeOut) => ({ type: OPEN_FAIL_NOTIFICATION, data: { message, open: true, status: Status.error, time } })
export const openAlertNotification = (message, time = popUpDefaultTimeOut) => ({ type: OPEN_ALERT_NOTIFICATION, data: { message, open: true, status: Status.alert, time } })
export const closeNotification = () => ({ type: CLOSE_NOTIFICATION, data: { message: '', open: false } })

const OPEN_SUCCESS_NOTIFICATION = 'OPEN_SUCCESS_NOTIFICATION'
const OPEN_FAIL_NOTIFICATION = 'OPEN_FAIL_NOTIFICATION'
const OPEN_ALERT_NOTIFICATION = 'OPEN_ALERT_NOTIFICATION'
const CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION'
