import React from 'react'

const CheckMark = (props) =>
  <svg
    xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 17' width='17px' height='17px' dangerouslySetInnerHTML={{
      __html: `<g fill="none" fill-rule="evenodd">
      <path fill="${props.color}" d="M8.5 15.29498l-1.65826774 1.5416949-.94205853-2.058932-2.12202071.7897488-.08242946-2.2627153-2.26271526-.0824294.78974875-2.1220207-2.05893193-.9420586L1.70502 8.5.16332512 6.84173226l2.05893193-.94205853-.78974875-2.12202071 2.26271526-.08242946.08242946-2.26271526 2.12202071.78974875.94205853-2.05893193L8.5 1.70502 10.1582677.16332512l.9420586 2.05893193 2.1220207-.78974875.0824294 2.26271526 2.2627153.08242946-.7897488 2.12202071 2.058932.94205853L15.29498 8.5l1.5416949 1.6582677-2.058932.9420586.7897488 2.1220207-2.2627153.0824294-.0824294 2.2627153-2.1220207-.7897488-.9420586 2.058932z"/>
      <path fill="#FFF" fill-rule="nonzero" d="M11.0086578 6.0423013c-.9132995.92389586-1.81631402 1.83685373-2.72412821 2.75491599-.0603381.06052357-.15495922.05833597-.21255468-.0058336l-.95649607-1.07119417c-.0569098-.06416956-.15427356-.06854476-.21666865-.010938-.28249203.26251185-.56224141.5228361-.84816174.78899394-.06239509.05833597-.06788037.15823631-.01234189.22313507.63697839.74524197 1.26847149 1.48392117 1.90407856 2.22697547.05553848.0648988.15015959.069274.21118336.0094796C9.42409695 9.70069556 10.6857118 8.45157669 11.950755 7.20027022c.0610238-.05979436.0658234-.1626115.0116562-.22969787-.2516373-.30918062-.498475-.61252764-.746684-.91733306-.0541671-.06635716-.1467313-.07146156-.2070694-.01093799z"/>
    </g>`
    }}
  />

export default CheckMark
