import { decorateUrl } from 'components/common/UTMscript'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ webinar }) => ({
  tags: webinar.data.Tags,
  description: webinar.data.Description,
  speakers: webinar.data.WebinarSpeakers,
  resourcesLink: webinar.data.ResourcesLink,
  isUserParticipating: webinar.data.IsUserParticipating,
  presentationVideoLink: webinar.data.PresentationVideoLink
})

const mapDispatchToProps = dispatch => ({
  handleTagClick: (url) => dispatch(push(decorateUrl(url))),
  goto: (url) => dispatch(push((url)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
