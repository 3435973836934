import React, { useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { TypographyElement, Button } from '../..'
import { Icons } from '../../constants'
import { ExternalAlphormPages } from '../../../constants'
import Icon from '../icon/icon'
import style from './teamSubscriptionVerticalCard.style'
import MediaQuery from '../mediaQuery'

const useStyle = createUseStyles(style)

const teamSubscriptionVerticalCard = ({
  shadowColor,
  title,
  secondTitle,
  phoneNumberMessage,
  phoneNumber,
  phoneAction,
  infos,
  expenderMessage,
  handleButtonClick,
  buttonText,
  width,
  dataTest
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { paper, header, body, infoClass, iconClass, infoStyle, infoClassContainer, expenderIcon } = useStyle({ shadowColor, width, open })
  return (
    <div className={paper}>
      <div className={header}>
        <TypographyElement
          variant='heading4'
          spacing='0 0px 16px 0px'
          color={theme.info[0]}
        >
          {title}
        </TypographyElement>
        <TypographyElement
          variant='body1'
          color={theme.neutral[100]}
        >
          {secondTitle}
        </TypographyElement>
        <TypographyElement
          variant='body1'
          color={theme.neutral[100]}
        >
          {`${phoneNumberMessage} `}
          <TypographyElement
            variant='body1'
            cursor='pointer'
            handleClick={phoneAction}
            display='inline'
            href={`tel:${phoneNumber}`}
            color={theme.primary[0]}
            Component='a'
          >
            {phoneNumber}
          </TypographyElement>
        </TypographyElement>
      </div>
      <div className={body}>
        <MediaQuery HideOnDesktop>
          <div className={infoStyle}>
            <TypographyElement
              variant='link'
              fontWeight={500}
              cursor='pointer'
              spacing={open ? '16px 0 12px 0' : '16px 0 0 0'}
              handleClick={() => setOpen(!open)}
            >
              {expenderMessage}
              <Icon iconName={Icons.roundedUp} style={expenderIcon} />
            </TypographyElement>
          </div>
        </MediaQuery>
        <div className={infoClassContainer}>
          {infos.map(info => (
            <div className={infoClass} key={info}>
              <Icon iconName={Icons.check} style={iconClass} />
              <TypographyElement
                variant='body1'
              >
                {info}
              </TypographyElement>
            </div>
          ))}
          <MediaQuery HideOnTablet HideOnMobile>
            <div className={infoClass} />
          </MediaQuery>
        </div>
        <Button
          dataTest={dataTest}
          label={buttonText}
          width='100%'
          height='54px'
          onHandleClick={handleButtonClick}
          Component='a'
          href={ExternalAlphormPages.BusinessOffer}
          marginButton='16px 0 0 0'
          marginButtonTablet='12px 0 0 0'
          marginButtonMobile='12px 0 0 0'
        />
      </div>
    </div>
  )
}

teamSubscriptionVerticalCard.propTypes = {
  /** the big title */
  title: PropTypes.string,
  /** second Title */
  secondTitle: PropTypes.string,
  /** label of the button start subscription */
  buttonText: PropTypes.string,
  /** function that handle the start subscription */
  handleButtonClick: PropTypes.func,
  /** array of string informations */
  infos: PropTypes.array,
  /** width */
  width: PropTypes.number
}

export default teamSubscriptionVerticalCard
