import { InputColors, Typography, textColorMap, Status, MediaQueries } from '../../constants'

export const passwordInputStyle = (theme) => ({
  '@keyframes input-error-shake': {
    '0%': {
      transform: 'translateX(0px)'
    },
    '33%': {
      transform: 'translateX(10px)'
    },
    '66%': {
      transform: 'translateX(-10px)'
    },
    '100%': {
      transform: 'translateX(0px)'
    }
  },
  inputGroup: {
    height: 'auto',
    width: ({ width }) => width || '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ({ gutterBottom }) => gutterBottom && `${gutterBottom}px`
  },
  input: {
    width: '100%',
    height: 54,
    borderRadius: 8,
    border: 'solid 1px ',
    borderColor: ({ status }) => status ? InputColors(theme)['input' + status] : InputColors(theme).inputborder,
    padding: '0 42px 0 16px',
    outline: 'none',
    color: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputext,
    transition: 'box-shadow .2s linear',
    font: {
      family: Typography.fontFamily,
      size: Typography.inputPlaceholder
    },
    '&::placeholder': {
      font: {
        family: Typography.fontFamily,
        size: Typography.inputPlaceholder
      },
      color: InputColors(theme).placeholder
    },
    '&:disabled': {
      background: InputColors(theme).inputborder,
      borderColor: props => (props.status) ? InputColors(theme)['input' + props.status] : InputColors(theme).inputborder
    },
    '&:hover': {
      borderColor: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputHoverBorder
    },
    '&:focus::placeholder': {
      transform: 'translateX(-100%)',
      opacity: 0,
      transition: 'transform .25s linear,opacity .25s linear'
    },
    transform: ({ status }) => status === Status.error ? 'translateX(10px)' : 'translateX(0px)',
    animation: ({ status }) => status === Status.error ? '$input-error-shake .25s ease forwards' : 'none'
  },
  inputAndIcon: {
    position: 'relative'
  },
  [`${MediaQueries.tablet}`]: {
    inputGroup: {
      width: ({ widthTablet }) => widthTablet || '100%'
    }
  },
  [`${MediaQueries.mobile}`]: {
    inputGroup: {
      width: ({ widthMobile }) => widthMobile || '100%'
    }
  }
})
