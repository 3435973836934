import { ViewState } from '../../constants/videoConst'

export const videoProgressionStyle = (theme) => ({
  videoIcon: {
    display: 'inline-block',
    paddingRight: 8,
    color: ({ viewState }) => viewState === ViewState.done ? theme.success[0] : viewState === ViewState.playing ? theme.neutral[400] : theme.secondary[100],
    background: 'transparent'
  },
  titleGroup: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: ({ viewState }) => viewState === ViewState.playing ? theme.primary[0] : 'unset'
  },
  videoBody: {
    backgroundColor: ({ viewState }) => viewState === ViewState.playing ? theme.primary[0] : theme.neutral[400],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 24px 9px 24px',
    cursor: 'pointer',
    borderTop: `1px solid ${theme.neutral[0]}`,
    '&:hover': {
      backgroundColor: ({ viewState }) => viewState !== ViewState.playing && `${theme.neutral[0]} !important`
    },
    '&:hover > div:nth-child(1) > *': {
      color: ({ viewState }) => viewState !== ViewState.playing && theme.primary[0]
    },
    '&:hover > div:nth-child(2) > p': {
      color: ({ viewState }) => viewState !== ViewState.playing && theme.primary[0],
      borderColor: ({ viewState }) => viewState !== ViewState.playing && theme.primary[0],
      background: ({ viewState }) => viewState !== ViewState.playing && 'transparent'
    }
  },
  time: {
    backgroundColor: ({ viewState }) => viewState === ViewState.playing ? theme.primary[0] : theme.neutral[400],
    borderRadius: 4,
    border: `1px solid ${theme.neutral[0]}`,
    paddingLeft: 8
  },
  videoTitle: {
    cursor: 'pointer',
    textDecoration: 'none'
  },
  courseTime: {
    display: 'flex',
    alignItems: 'center'
  }
})
