import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { cardStyle } from './checkoutHorizontalCard.style'
import { Icons } from '../../constants'
import TypographyElement from '../typographyElement/typographyElement'
import classNames from 'classnames'
import Skeleton from '../skeleton/skeleton'
import Paper from '../paper/paper'
import Icon from '../icon/icon'
import SwitchCurrency from '../switchCurrency/switchCurrency'

const CardSkeleton = ({
  className,
  height,
  heightTablet,
  heightMobile,
  width,
  widthTablet,
  widthMobile
}) => {
  const useStyles = createUseStyles(cardStyle)
  const classes = useStyles({
    progression: 0,
    height,
    heightTablet,
    heightMobile,
    width,
    widthTablet,
    widthMobile
  })
  return (
    <Paper className={classNames(classes.cardGroup, className)}>
      <div className={classes.header}>
        <Skeleton width={24} height={24} wrapperWidth={50} />
        <Skeleton width='50%' height={20} />
      </div>
      <Skeleton width={['100%', '50%', '20%']} height={[20, 20]} lines={3} />
    </Paper>
  )
}

const PriceWithoutPromo = ({
  totalPrice,
  selectedCurrency,
  subTotalText,
  count,
  itemName,
  simplePrice
}) =>
  simplePrice
    ? <TypographyElement variant='caption'>{`${totalPrice} ${selectedCurrency}`}</TypographyElement>
    : <TypographyElement variant='body2'>{`${subTotalText} (${count} ${itemName}) : ${totalPrice} ${selectedCurrency}`}</TypographyElement>

const PriceWithPromo = ({
  totalPrice,
  selectedCurrency,
  subTotalText,
  count,
  itemName,
  simplePrice,
  promotionPrice
}) => {
  const theme = useTheme()
  return (
    <TypographyElement variant='body2'>
      {!simplePrice ? `${subTotalText} (${count} ${itemName}) : ` : ''}
      <TypographyElement
        variant={!simplePrice ? 'body2' : 'caption'}
        textDecoration='line-through'
        display='inline'
        Component='span'
      >
        {`${totalPrice} ${selectedCurrency}`}
      </TypographyElement>
      <TypographyElement
        variant={!simplePrice ? 'body2' : 'caption'}
        display='inline'
        color={theme.success[0]}
        Component='span'
      >
        {` ${promotionPrice} ${selectedCurrency}`}
      </TypographyElement>
    </TypographyElement>
  )
}

const CheckoutHorizontalCard = ({
  handleChangeCurrency,
  currency1,
  currency2,
  selectedCurrency,
  loading,
  shadow,
  className,
  handleCardClick,
  title,
  label = 'Formation',
  dataTest,
  subTotalText,
  count,
  secondaryText,
  itemName,
  totalPrice,
  hideSwitchCurrency,
  controls,
  hideSecondaryText,
  simplePrice,
  promo,
  promotionPrice,
  height = '100%',
  heightTablet = '100%',
  heightMobile = '100%',
  width = '100%',
  widthTablet = '100%',
  widthMobile = '100%',
  titleMargin
}) => {
  const theme = useTheme()
  const useStyles = createUseStyles(cardStyle)
  const { shadowCard, row, cardGroup, header, headerLeft, playIcon, iconClass, iconAndSecondaryText } = useStyles({ loading, hideSecondaryText, height, heightTablet, heightMobile, width, widthTablet, widthMobile, titleMargin })

  if (loading) {
    return (
      <CardSkeleton
        className={className}
        height={height}
        heightTablet={heightTablet}
        heightMobile={heightMobile}
        width={width}
        widthTablet={widthTablet}
        widthMobile={widthMobile}
      />
    )
  } else if (shadow) return (<div className={classNames(shadowCard, className)} />)
  else {
    return (
      <Paper
        onClick={handleCardClick}
        className={classNames(cardGroup, className)}
        title={title}
        data-test={dataTest && `${dataTest}-card`}
      >
        <div className={header}>
          <div className={headerLeft}>
            <Icon iconName={Icons.play} style={playIcon} />
            <TypographyElement variant='body2'>{label}</TypographyElement>
          </div>
          {
            hideSwitchCurrency
              ? null
              : <SwitchCurrency
                  handleChange={handleChangeCurrency}
                  devis={currency1}
                  alterDevis={currency2}
                  checked={currency1 !== selectedCurrency}
                />
          }
          {
            controls && controls()
          }
        </div>
        <div className={row}>
          <TypographyElement variant='heading6' maxLines={3} spacing='0px 8px 0px 0px'>{title}</TypographyElement>
          {
            hideSecondaryText
              ? null
              : (
                <div className={iconAndSecondaryText}>
                  <Icon iconName={Icons.check} style={iconClass} />
                  <TypographyElement
                    variant='body1'
                    color={theme.neutral[100]}
                  >
                    {secondaryText}
                  </TypographyElement>
                </div>
                )
            }
        </div>
        {
          promo
            ? <PriceWithPromo
                totalPrice={totalPrice}
                selectedCurrency={selectedCurrency}
                subTotalText={subTotalText}
                count={count}
                itemName={itemName}
                simplePrice={simplePrice}
                promotionPrice={promotionPrice}
              />
            : <PriceWithoutPromo
                totalPrice={totalPrice}
                selectedCurrency={selectedCurrency}
                subTotalText={subTotalText}
                count={count}
                itemName={itemName}
                simplePrice={simplePrice}
              />
        }
      </Paper>
    )
  }
}

CheckoutHorizontalCard.propTypes = {
  /** provide a className to put your style */
  className: PropTypes.string,
  /** provide a title to the card */
  title: PropTypes.string,
  /** provide a label to the card */
  label: PropTypes.string,
  /** provide a loading to the card */
  loading: PropTypes.bool,
  /** provide a shadow to the card */
  shadow: PropTypes.bool,
  /** provide a dataTest to the card */
  dataTest: PropTypes.string,
  /** provide secondary text inline */
  secondaryText: PropTypes.string
}

export default CheckoutHorizontalCard
