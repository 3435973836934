import { put, takeEvery, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB } from 'constants/'

const initialState = {
  freeCourses: {
    Data: [],
    Count: 0
  },
  learningPaths: {
    Data: [],
    Count: 0,
    FilteredCount: null
  },
  categories: [],
  courses: {},
  selectedCategory: null,
  loadingFreeCourses: true,
  loadingLearningPaths: true,
  loadingCategories: true,
  loadingCourses: true
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_FREE_COURSES_HOME_REQUESTED:
      return {
        ...state,
        loadingFreeCourses: true
      }
    case GET_FREE_COURSES_HOME_SUCCESS:
      return {
        ...state,
        freeCourses: {
          Data: data.Data,
          Count: data.Count
        },
        loadingFreeCourses: false
      }
    case GET_FREE_COURSES_HOME_FAIL:
      return {
        ...state,
        loadingFreeCourses: false
      }
    case GET_LEARNING_PATHS_HOME_REQUESTED:
      return {
        ...state,
        loadingLearningPaths: true
      }
    case GET_LEARNING_PATHS_HOME_SUCCESS:
      return {
        ...state,
        learningPaths: {
          Data: data.Data,
          Count: data.Count,
          FilteredCount: data.FilteredCount
        },
        loadingLearningPaths: false
      }
    case GET_LEARNING_PATHS_HOME_FAIL:
      return {
        ...state,
        loadingLearningPaths: false
      }
    case CATEGORIES_FETCH_HOME_REQUESTED:
      return {
        ...state,
        loadingCategories: true
      }
    case CATEGORIES_FETCH_HOME_SUCCESS:
      return {
        ...state,
        categories: data,
        loadingCategories: false
      }
    case CATEGORIES_FETCH_HOME_FAIL:
      return {
        ...state,
        loadingCategories: false
      }
    case SELECT_CATEGORY_HOME_SUCCESS:
      return {
        ...state,
        selectedCategory: data
      }
    case SELECT_CATEGORY_HOME_FAIL:
      return {
        ...state,
        selectedCategory: null
      }
    case GET_COURSES_HOME_REQUESTED:
      return {
        ...state,
        loadingCourses: true
      }
    case GET_COURSES_HOME_SUCCESS:
      return {
        ...state,
        courses: {
          ...data
        },
        loadingCourses: false
      }
    case GET_COURSES_HOME_FAIL:
      return {
        ...state,
        courses: {
          ...data
        },
        loadingCourses: false
      }
    default:
      return state
  }
}

export const getLearning = ({ learning }) => learning
export const getShop = ({ support }) => support
export const fetchFreeCoursesAction = (take, skip) => ({ type: GET_FREE_COURSES_HOME_REQUESTED, data: { take, skip } })
export const fetchLearningPathsAction = (take, skip) => ({ type: GET_LEARNING_PATHS_HOME_REQUESTED, data: { take, skip } })
export const selectCategoryAction = (category, initialCourses) => ({ type: SELECT_CATEGORY_HOME_REQUESTED, data: { category, initialCourses } })
export const fetchCategoriesAction = () => ({ type: CATEGORIES_FETCH_HOME_REQUESTED })

function * fetchLearningPaths ({ data }) {
  try {
    const response = yield fetch(queries.getLearningPaths(data.take, data.skip))
    yield put({ type: GET_LEARNING_PATHS_HOME_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_LEARNING_PATHS_HOME_FAIL, err })
  }
}

function * fetchFreeCourses ({ data }) {
  try {
    const isFree = true
    const response = yield fetch(queries.getCourses('', data.take, data.skip, isFree))
    yield put({ type: GET_FREE_COURSES_HOME_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_FREE_COURSES_HOME_FAIL, err })
  }
}

function * fetchCategories () {
  try {
    const response = yield fetch(queries.getCategories)
    yield put({ type: CATEGORIES_FETCH_HOME_SUCCESS, data: response?.Items })
    yield put({ type: SELECT_CATEGORY_HOME_SUCCESS, data: { ...response?.Items[0] } })
    yield put({ type: GET_COURSES_HOME_REQUESTED, data: { category: response?.Items[0], take: 8, skip: 0 } })
  } catch (err) {
    yield put({ type: CATEGORIES_FETCH_HOME_FAIL, err })
  }
}

function * fetchCoursesByCategory ({ data }) {
  try {
    const response = yield fetch(queries.getCourses(data.category.Slug, 8, 0))
    yield put({ type: GET_COURSES_HOME_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_COURSES_HOME_FAIL, err })
  }
}

function * selectCategory ({ data }) {
  try {
    yield put({ type: SELECT_CATEGORY_HOME_SUCCESS, data: { ...data.category } })
    yield put({ type: GET_COURSES_HOME_REQUESTED, data: { category: data.category, take: 8, skip: 0 } })
  } catch (err) {
    yield put({ type: SELECT_CATEGORY_HOME_FAIL, err })
  }
}

export function * PublicHomeRootSaga () {
  yield takeEvery(GET_FREE_COURSES_HOME_REQUESTED, fetchFreeCourses)
  yield takeLatest(GET_LEARNING_PATHS_HOME_REQUESTED, fetchLearningPaths)
  yield takeLatest(CATEGORIES_FETCH_HOME_REQUESTED, fetchCategories)
  yield takeLatest(SELECT_CATEGORY_HOME_REQUESTED, selectCategory)
  yield takeLatest(GET_COURSES_HOME_REQUESTED, fetchCoursesByCategory)
}
const queries = {
  getCourses: (category, take, skip, isFree) => `${BASE_URL_WEB}/Course/?${category ? `&CategorySlug=${category}` : ''}${take ? `&Take=${take}` : ''}&Skip=${skip}&OrderedByAsc=false&OrderBy=PublicationDate${isFree ? `&IsFree=${isFree}` : ''}`,
  getLearningPaths: (take, skip) => `${BASE_URL_WEB}/CoursePath?OrderBy=ActivationDate&Take=${take}&Skip=${skip}`,
  getCategories: `${BASE_URL_WEB}/Category/list`
}

const GET_LEARNING_PATHS_HOME_REQUESTED = 'GET_LEARNING_PATHS_HOME_REQUESTED'
const GET_LEARNING_PATHS_HOME_SUCCESS = 'GET_LEARNING_PATHS_HOME_SUCCESS'
const GET_LEARNING_PATHS_HOME_FAIL = 'GET_LEARNING_PATHS_HOME_FAIL'
const GET_FREE_COURSES_HOME_REQUESTED = 'GET_FREE_COURSES_HOME_REQUESTED'
const GET_FREE_COURSES_HOME_SUCCESS = 'GET_FREE_COURSES_HOME_SUCCESS'
const GET_FREE_COURSES_HOME_FAIL = 'GET_FREE_COURSES_HOME_FAIL'
const SELECT_CATEGORY_HOME_REQUESTED = 'SELECT_CATEGORY_HOME_REQUESTED'
const SELECT_CATEGORY_HOME_SUCCESS = 'SELECT_CATEGORY_HOME_SUCCESS'
const SELECT_CATEGORY_HOME_FAIL = 'SELECT_CATEGORY_HOME_FAIL'
const CATEGORIES_FETCH_HOME_REQUESTED = 'CATEGORIES_FETCH_HOME_REQUESTED'
const CATEGORIES_FETCH_HOME_SUCCESS = 'CATEGORIES_FETCH_HOME_SUCCESS'
const CATEGORIES_FETCH_HOME_FAIL = 'CATEGORIES_FETCH_HOME_FAIL'
const GET_COURSES_HOME_SUCCESS = 'GET_COURSES_HOME_SUCCESS'
const GET_COURSES_HOME_FAIL = 'GET_COURSES_HOME_FAIL'
const GET_COURSES_HOME_REQUESTED = 'GET_COURSES_HOME_REQUESTED'
