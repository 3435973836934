import { Typography } from '../../constants/typography'
export const switchCurrencyStyle = (theme) => ({
  inputGroup: {
    position: 'relative',
    width: 73,
    height: 21
  },
  switchButton: {
    position: 'absolute',
    display: 'inline-flex',
    cursor: 'pointer',
    '&::before, &::after': {
      ...Typography.caption,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all .2s linear',
      width: 37,
      height: 21
    },
    '&::before': {
      content: ({ devis }) => `"${devis.toUpperCase()}"`,
      color: ({ checked }) => checked ? theme.neutral[200] : theme.primary[1300],
      backgroundColor: theme.neutral[400],
      borderTop: ({ checked }) => `1px solid ${checked ? theme.neutral[200] : theme.primary[1300]}`,
      borderLeft: ({ checked }) => `1px solid ${checked ? theme.neutral[200] : theme.primary[1300]}`,
      borderBottom: ({ checked }) => `1px solid ${checked ? theme.neutral[200] : theme.primary[1300]}`,
      borderRight: ({ checked }) => `1px solid ${checked ? 'transparent' : theme.primary[1300]}`,
      borderRadius: '2px 0 0 2px'
    },
    '&::after': {
      content: ({ alterDevis }) => `"${alterDevis.toUpperCase()}"`,
      color: ({ checked }) => checked ? theme.primary[1300] : theme.neutral[200],
      backgroundColor: theme.neutral[400],
      borderTop: ({ checked }) => `1px solid ${checked ? theme.primary[1300] : theme.neutral[200]}`,
      borderRight: ({ checked }) => `1px solid ${checked ? theme.primary[1300] : theme.neutral[200]}`,
      borderBottom: ({ checked }) => `1px solid ${checked ? theme.primary[1300] : theme.neutral[200]}`,
      borderLeft: ({ checked }) => `1px solid ${checked ? theme.primary[1300] : 'transparent'}`,
      borderRadius: '0 2px 2px 0'
    }
  }
})
