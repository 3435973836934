import { loadingSkeleton, loadingSkeletonAnimation } from '../../helpers'

export const progressionRingStyle = {
  progressionRingGroup: ({ loading, radius, margin }) => ({
    height: radius * 2,
    width: radius * 2,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: margin,
    ...(loading && loadingSkeleton({ borderRadius: '50%' }).core)
  }),
  child: {
    position: 'absolute'
  },
  circle: {
    transition: 'stroke-dashoffset 0.35s',
    transform: 'rotate(-90deg)',
    transformOrigin: '50% 50%'
  },
  ...loadingSkeletonAnimation
}
