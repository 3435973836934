export default {
  primary: {
    0: '#3767DA',
    100: '#4A67CC',
    200: '#3B53A2',
    300: '#34488F',
    400: '#2C3F7B',
    500: '#263565',
    600: '#161E3D',
    700: '#0E1529',
    800: '#070A14',
    900: '#010511',
    1000: '#4E6BA3',
    1100: '#5F85E1',
    1200: '#7395E5',
    1300: '#87A4E9'
  },
  secondary: {
    0: '#FFDD00',
    100: '#FFE53B',
    200: '#FFEA63',
    300: '#FFF092',
    400: '#FDF9D5',
    500: '#FFFCEA'
  },
  neutral: {
    0: '#F0F5FA',
    100: '#788AA1',
    200: '#C1C4C8',
    300: '#A9ACAF',
    400: '#FFFFFF'
  },
  error: {
    0: '#FF6B6B',
    100: '#E57373',
    200: '#FF6B6B',
    300: '#FFC1C1'
  },
  success: {
    0: '#02C58C',
    100: '#16ECAD',
    200: '#9EF1D9',
    300: '#D5FAF0',
    400: '#CCF3E8'
  },
  warning: {
    0: '#F77D48',
    100: '#FF986B',
    200: '#FFB798',
    300: '#FFD8C8'
  },
  info: {
    0: '#4EC8F1',
    100: '#A1E2F7',
    200: '#D0F3FF',
    300: '#ECFAFF'
  },
  extra: {
    pink: '#F62186',
    violet: '#8211D1'
  }
}
