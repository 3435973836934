import { MediaQueries, Spacing } from 'UI'

export default (theme) => ({
  container: {},
  footer: {
    backgroundColor: theme.primary[700],
    margin: 0
  },
  footerContent: {
    padding: () => Spacing(6, 0, 10, 0)
  },
  [`${MediaQueries.tablet}`]: {
    container: {
      borderBottom: 'none'
    },
    footerContent: {
      padding: () => Spacing(5, 0, 9, 0)
    },
    footer: {
      margin: ({ marginFooter }) => marginFooter || 17,
      borderRadius: 8
    }
  },
  [`${MediaQueries.mobile}`]: {
    container: {
      borderBottom: 'none'
    },
    footerContent: {
      padding: () => Spacing(0, 0, 5, 0)
    },
    footer: {
      margin: ({ marginFooter }) => marginFooter || 17,
      borderRadius: 8
    }
  }
})
