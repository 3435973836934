import { MediaQueries } from 'UI'

export default {
  logo: {
    width: 30,
    height: 30
  },
  stepsClass: {
    margin: '46px 80px 32px 80px'
  },
  spacing: {
    width: '100%',
    height: 48
  },
  paperStyle: {
    padding: '64px 44px ',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 47
  },
  [`${MediaQueries.tablet}`]: {
    stepsClass: {
      width: 'auto',
      margin: '38px 64px 52px 64px'
    },
    container: {
      marginBottom: 32
    },
    paperStyle: {
      padding: 0,
      border: 'none !important'
    }
  },
  [`${MediaQueries.mobile}`]: {
    stepsClass: {
      width: '100%',
      margin: '38px auto 52px auto'
    },
    container: {
      marginBottom: 32
    },
    paperStyle: {
      padding: 0,
      border: 'none !important'
    }
  }
}
