import React, { useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { TypographyElement, Button } from '../..'
import { Icons } from '../../constants'
import Icon from '../icon/icon'
import style from './freePricingVeriticalCard.style'
import MediaQuery from '../mediaQuery'

const useStyle = createUseStyles(style)

const FreePricingVeriticalCard = ({
  shadowColor,
  title,
  secondTitle,
  subtitle,
  infos,
  handleCtaClick,
  ctaText,
  price,
  currency,
  freePrice,
  periodText,
  width,
  expenderMessage
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { paper, header, titleAndPromo, body, infoClass, iconClass, infoClassContainer, infoStyle, expenderIcon, priceClass } = useStyle({ shadowColor, width, open })
  return (
    <div className={paper}>
      <div className={header}>
        <div className={titleAndPromo}>
          <TypographyElement
            variant='heading4'
            color={theme.success[0]}
            spacing='0px 0px 15px 0px'
          >
            {title}
          </TypographyElement>
        </div>
        <TypographyElement
          variant='body1'
          color={theme.neutral[100]}
          spacing='0px 0px 10px 0px'
        >
          {secondTitle}
        </TypographyElement>
        <MediaQuery ShowOnDesktop>
          <TypographyElement
            className={priceClass}
            display='flex'
            variant='heading4'
            color={theme.success[0]}
            key={`${freePrice}-${currency}`}
          >
            {freePrice}
            <TypographyElement
              variant='body1'
              color={theme.success[0]}
              display='inline-block'
              spacing='0 0 0 6px'
              lineHeight='15px'
            >
              {`${currency}/${periodText}`}
            </TypographyElement>
          </TypographyElement>
          <TypographyElement
            variant='caption'
            color={theme.neutral[200]}
            textDecoration='line-through'
          >
            {`${price} ${currency}/${periodText}`}
          </TypographyElement>
        </MediaQuery>
        <MediaQuery HideOnDesktop>
          <TypographyElement
            variant='heading4'
            color={theme.success[0]}
            key={`${freePrice}-${currency}`}
          >
            {freePrice}
            <TypographyElement
              variant='body1'
              color={theme.success[0]}
              display='inline-block'
              spacing='0 0 0 6px'
            >
              {`${currency}/${periodText}`}
            </TypographyElement>
            <TypographyElement
              spacingMobile='0 0 0 39px'
              spacingTablet='0 0 0 39px'
              display='inline-block'
              variant='body1'
              color={theme.neutral[200]}
              textDecoration='line-through'
            >
              {`${price} ${currency}/${periodText}`}
            </TypographyElement>
          </TypographyElement>
        </MediaQuery>
        <TypographyElement
          variant='body2'
          color={theme.neutral[200]}
          spacing='6px 0px 31px 0px'
          spacingMobile='16px 0px 16px 0px'
          spacingTablet='16px 0px 16px 0px'
        >
          {subtitle}
        </TypographyElement>
      </div>
      <div className={body}>
        <MediaQuery HideOnDesktop>
          <div className={infoStyle}>
            <TypographyElement
              variant='link'
              fontWeight={500}
              cursor='pointer'
              spacing={open ? '0 0 12px 0' : '0 0 0 0'}
              handleClick={() => setOpen(!open)}
            >
              {expenderMessage}
              <Icon iconName={Icons.roundedUp} style={expenderIcon} />
            </TypographyElement>
          </div>
        </MediaQuery>
        <div className={infoClassContainer}>
          {
            infos.map(info => (
              <div className={infoClass} key={info}>
                <Icon iconName={Icons.check} style={iconClass} />
                <TypographyElement
                  variant='body1'
                >
                  {info}
                </TypographyElement>
              </div>
            ))
          }
        </div>
        <Button
          dataTest='start-subscription-button'
          label={ctaText}
          width='100%'
          height='54px'
          onHandleClick={handleCtaClick}
          marginButton='16px 0 0 0'
          marginButtonTablet='24px 0 0 0'
          marginButtonMobile='24px 0 0 0'
          variation='secondary'
        />
      </div>
    </div>
  )
}

FreePricingVeriticalCard.propTypes = {
  /** the big title */
  title: PropTypes.string,
  /** second Title */
  secondTitle: PropTypes.string,
  /** maine price */
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** currency to show next price */
  currency: PropTypes.string,
  /** function that handle the currency change */
  handleChangeCurrency: PropTypes.func,
  /** label of the button start subscription */
  ctaText: PropTypes.string,
  /** function that handle the start subscription */
  handleStartSubscriptionClick: PropTypes.func,
  /** array of string informations */
  infos: PropTypes.array,
  /** width */
  width: PropTypes.number,
  /** shadow color */
  shadowColor: PropTypes.string,
  /** Period text */
  periodText: PropTypes.string,
  /** free price */
  freePrice: PropTypes.string,
  /** subtitle */
  subtitle: PropTypes.string
}

export default FreePricingVeriticalCard
