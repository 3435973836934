const layoutConfig = {
  mobile: 600,
  tablet: 992,
  desktop: 1200
}

export const MediaQueryConifig = {
  desktop: {
    minWidth: layoutConfig.tablet + 1
  },
  tablet: {
    maxWidth: layoutConfig.tablet,
    minWidth: layoutConfig.mobile + 1
  },
  mobile: {
    maxWidth: layoutConfig.mobile
  }
}

export default {
  mobile: `@media (max-width: ${layoutConfig.mobile}px) and (min-width: ${0}px)`,
  tablet: `@media (max-width: ${layoutConfig.tablet}px) and (min-width: ${layoutConfig.mobile + 1}px)`,
  desktop: `@media (max-width: ${layoutConfig.desktop}px) and (min-width: ${layoutConfig.tablet + 1}px)`,
  wide: `@media (min-width: ${layoutConfig.desktop + 1}px)`
}
