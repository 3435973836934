import React from 'react'
import { Icon } from '../../icon/icon'
import { Icons } from '../../../constants'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import styles from './style'

const Controller = ({
  currentView,
  views,
  setCurrentView,
  viewsLength,
  controllerClassName,
  transparent,
  secondaryController,
  scrollNextView,
  scrollPreviousView
}) => {
  const useStyle = createUseStyles(styles)
  const {
    controls,
    icon,
    viewsDots,
    dot,
    activeDot,
    iconWrapper,
    disabled
  } = useStyle({ transparent, secondaryController })
  return (
    <div className={classNames(controls, controllerClassName)}>
      <div
        className={classNames(iconWrapper, currentView === 0 && disabled)}
        onClick={scrollPreviousView}
      >
        <Icon iconName={Icons.roundedLeft} style={icon} />
      </div>
      {
        secondaryController
          ? null
          : (
            <div className={viewsDots}>
              {views.map((view, index) => <div onClick={() => setCurrentView(index)} key={index} className={currentView === index ? classNames(dot, activeDot) : dot} />)}
            </div>
            )
      }
      <div
        className={classNames(iconWrapper, (currentView === viewsLength - 1) && disabled)}
        onClick={scrollNextView}
      >
        <Icon iconName={Icons.roundedRight} style={icon} />
      </div>
    </div>
  )
}

export default Controller
