import {
  Typography
} from 'UI'

export default theme => ({
  container: {
    margin: ({ margin }) => margin
  },
  descriptionText: {
    overflow: 'hidden',
    transition: 'all 0.15s ease',
    position: 'relative'
  },
  icon: {
    paddingLeft: 8,
    fontSize: 16
  },
  showMore: {
    alignItems: 'end'
  },
  shadow: {
    width: '100%',
    height: 60,
    background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%)',
    position: 'absolute',
    bottom: 0,
    display: ({ open }) => open && 'none'
  },
  description: {
    lineHeight: '30px',
    color: theme.neutral[100],
    fontFamily: `${Typography.secondaryFontFamily} !important`,
    '& *': {
      fontFamily: `${Typography.secondaryFontFamily} !important`,
      color: `${theme.neutral[100]} !important`
    },
    '& > p': {
      marginBottom: 24,
      ...Typography.body1
    },
    '& ul': {
      marginBottom: 10,
      paddingLeft: 40
    },
    '& a': {
      ...Typography.link,
      color: `${theme.primary[0]} !important`
    },
    '& a *': {
      color: `${theme.primary[0]} !important`
    },
    '& span a': {
      color: `${theme.primary[0]} !important`
    },
    '& > span > p': {
      marginBottom: 20,
      lineHeight: '20px'
    },
    '& h1': {
      ...Typography.heading5,
      color: Typography.heading5.color + ' !important',
      marginBottom: 24
    },
    '& h2': {
      ...Typography.heading5,
      color: Typography.heading5.color + ' !important',
      marginBottom: 24
    },
    '& h3': {
      ...Typography.heading5,
      color: Typography.heading5.color + ' !important',
      marginBottom: 24
    }
  }
})
