import {
  MediaQueries,
  blue,
  green,
  purple,
  loadingSkeleton,
  loadingSkeletonAnimation
} from '@alphorm/ui'
import { freeCoursesCategoryShape, learningPathShapeCategory } from 'assets'
import { categoriesColor } from 'constants/'

export default {
  container: ({ loading, active, Id, catalog }) => ({
    position: 'relative',
    flex: '1 0 21%',
    height: active ? 130 : 118,
    maxWidth: 'calc(25% - 17px)',
    display: 'flex',
    backgroundColor: !catalog && categoriesColor[Id]?.backgroundColor,
    borderRadius: 4,
    flexDirection: 'column',
    padding: !catalog && '8px 8px 24px 16px',
    justifyContent: 'center',
    alignItems: catalog && 'center',
    transition: 'all 0.2s linear',
    border: loading ? 'none' : catalog && `1px solid ${blue[0]}`,
    marginTop: !active ? 6 : 0,
    cursor: 'pointer',
    ...(loading && loadingSkeleton().core),
    '&:last-child': {
      marginRight: 'auto'
    }
  }),
  freeCategory: {
    backgroundColor: green[5],
    backgroundImage: `url(${freeCoursesCategoryShape})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    padding: '0 16px !important',
    '& > $anchorClass': {
      textTransform: 'uppercase'
    }
  },
  learningPathClass: {
    backgroundColor: purple[0],
    backgroundImage: `url(${learningPathShapeCategory})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    padding: '0 10px !important',
    '& > $anchorClass': {
      textTransform: 'uppercase'
    }
  },
  anchorClass: {
    textDecoration: 'none'
  },
  core: {
    display: 'flex',
    flexDirection: 'column'
  },
  categoryPhotoClass: {
    height: 47,
    width: 51,
    marginTop: 8
  },
  tag: {
    height: 24,
    width: 24,
    borderRadius: '50%',
    backgroundColor: ({ Id, catalog }) => !catalog && categoriesColor[Id]?.primaryColor,
    display: ({ hideNumber }) => hideNumber ? 'none' : 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  photoAndTag: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  [`${MediaQueries.mobile}`]: {
    container: ({ noMobile, active, catalog }) => !noMobile && {
      height: active ? 95 : 83,
      padding: !catalog && '16px 8px 12px 12px',
      maxWidth: 128,
      minWidth: 128,
      marginRight: 16,
      marginTop: 0,
      marginBottom: 0
    },
    categoryPhotoClass: ({ noMobile }) => !noMobile && {
      height: 34,
      width: 36,
      marginTop: 4
    },
    tag: ({ noMobile }) => !noMobile && {
      height: 17,
      width: 17
    }
  },
  ...loadingSkeletonAnimation
}
