import React from 'react'
import { createUseStyles } from 'react-jss'
import { suggestionGroupStyle } from './suggestionGroup.style'

export const SuggestionGroup = ({ children, toggleSuggestionList = false }) => {
  const useStyle = createUseStyles(suggestionGroupStyle)
  const { suggestionGroup } = useStyle({ toggleSuggestionList })
  return (
    <div className={suggestionGroup}>
      {children}
    </div>
  )
}

export default SuggestionGroup
