import google from '../assets/media/google_play_light.png'
import apple from '../assets/media/appstore_light.png'
import desktop from '../assets/media/desktop_light.png'

export const DownloadAppTypes = {
  type: {
    google: 'google',
    apple: 'apple',
    desktop: 'desktop'
  },
  image: {
    google,
    apple,
    desktop
  },
  link: {
    google: '#',
    apple: '#',
    desktop: '#'
  }
}
