import { neutral, MediaQueries } from '@alphorm/ui'

export default {
  modalClass: {
    textAlign: 'unset !important',
    padding: '45px !important',
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  devider: {
    height: 1,
    width: '100%',
    backgroundColor: neutral[3],
    marginBottom: 16
  },
  actionSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  illustrasionContainer: {
    width: '100%',
    padding: '0 40%'
  },
  content: {},
  [`${MediaQueries.mobile}`]: {
    modalClass: {
      textAlign: 'unset !important',
      padding: '90px 20px 20px 20px !important',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%'
    },
    actionSection: {
      flexDirection: 'column'
    },
    content: {
      padding: 24,
      boxShadow: '0 0 36px 0 rgba(194, 212, 230, 0.23)',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 24
    }
  }
}
