import {
  TypographyElement,
  CardSize,
  Container,
  Slider,
  MediaQuery
} from '@alphorm/ui'
import Text from './text.json'
import style from './style'
import { createUseStyles } from 'react-jss'
import WebinarCardRenderer from 'components/common/WebinarCardRenderer'
import { chunk } from 'lodash'
import { useEffect } from 'react'
import { takeAll, skipNone, amazonBucket } from 'constants/'
import moment from 'moment'
import { DetailedDateFormat, formatSecondsDuration } from 'helpers'

const useStyle = createUseStyles(style)

const viewCards = (view, splitedWebinars, WebinarStatus) => splitedWebinars.map((item, key) => (
  <div key={key} className={view}>
    {
      item.map(webinar => (
        <WebinarCardRenderer
          shadow={webinar === undefined}
          key={webinar?.Id}
          image={webinar?.SmallImage}
          status={webinar?.WebinarStatus}
          clockText={webinar?.WebinarStatus !== WebinarStatus.Passed ? moment(webinar?.PlannifiedWebinarDate).format(DetailedDateFormat) : formatSecondsDuration(webinar?.Duration)}
          title={webinar?.Title}
          description={webinar?.SubTitle}
          categoryImage={`${amazonBucket.categories}${webinar?.Categories?.at(0)?.Photo}`}
          categoryName={webinar?.Categories?.at(0)?.Name}
          categoryBackgroundColor={webinar?.Categories?.at(0)?.BackgroundColor}
          size={CardSize.big}
          variant={webinar?.WebinarStatus}
          webinarLabel='Webinar'
          loading={webinar?.loading}
          isUserParticipating={webinar?.IsUserParticipating}
          slug={webinar?.Slug}
        />
      ))
    }
  </div>))

export default ({ FilteredWebinars, WebinarCategory, fetchCategoryWebinarsAction, WebinarStatus }) => {
  useEffect(() => {
    fetchCategoryWebinarsAction({ take: takeAll, skip: skipNone, category: WebinarCategory, hasCategory: true })
  }, [WebinarCategory])
  const { root, view, sliderContainer, reponsiveClass } = useStyle()
  const splitedWebinars = chunk(FilteredWebinars, 3).map(chunkCourses => {
    chunkCourses.push(...Array(3 - chunkCourses.length))
    return chunkCourses
  })
  return (
    <Container className={root}>
      <MediaQuery HideOnDesktop>
        <TypographyElement
          variant='heading2'
          spacing='16px 0 16px 0'
          Component='h2'
        >
          {Text.title}
        </TypographyElement>
        <div className={reponsiveClass}>
          {
            FilteredWebinars.map(webinar => (
              <div className={view} key={webinar?.Id}>
                <WebinarCardRenderer
                  shadow={webinar === undefined}
                  key={webinar?.Id}
                  image={webinar?.SmallImage}
                  status={webinar?.WebinarStatus}
                  clockText={webinar?.WebinarStatus !== WebinarStatus.Passed ? moment(webinar?.PlannifiedWebinarDate).format(DetailedDateFormat) : formatSecondsDuration(webinar?.Duration)}
                  title={webinar?.Title}
                  description={webinar?.SubTitle}
                  categoryImage={`${amazonBucket.categories}${webinar?.Categories?.at(0)?.Photo}`}
                  categoryName={webinar?.Categories?.at(0)?.Name}
                  categoryBackgroundColor={webinar?.Categories?.at(0)?.BackgroundColor}
                  size={CardSize.big}
                  variant={webinar?.WebinarStatus}
                  webinarLabel='Webinar'
                  loading={webinar?.loading}
                  isUserParticipating={webinar?.IsUserParticipating}
                  slug={webinar?.Slug}
                />
              </div>
            ))
          }
        </div>
      </MediaQuery>

      <MediaQuery ShowOnDesktop>
        <div className={sliderContainer}>
          <Slider
            title={Text.title}
            views={viewCards(view, splitedWebinars, WebinarStatus)}
            screen='desktop'
            padding='0 0 16px 0'
          />
        </div>
      </MediaQuery>
    </Container>
  )
}
