import { formatDateYMD, stripHtml } from 'helpers'
import { CourseNeutralPath, TrainerPrimaryPath } from 'navigation/Routes'
import { Helmet } from 'react-helmet'

export default ({ courses = [] }) =>
  <Helmet>
    <script type='application/ld+json'>
      {
        `{
            "@context": "https://schema.org",
            "@type": "ItemList",
            "url": "${window.location.origin}",
            "numberOfItems": "${courses.length}",
            "itemListElement": [
              ${
                courses.map((course, index) => (
                  `
                    {
                      "@type": "ListItem",
                      "position": ${index + 1},
                      "item": {
                          "@type": "Course",
                          "provider": {
                              "@type": "Organization", 
                              "name": "Alphorm", 
                              "url": "${window.location.origin}", 
                              "logo": "https://static.alphorm.com/logos/logosanslecture.png"
                          },
                          "creator": {
                              "@type": "Person",
                              "name": "${course.Author}",
                              "sameAs": "${window.location.origin}${TrainerPrimaryPath}/${course.AuthorId}"
                          },
                          "url": "${window.location.origin}${CourseNeutralPath}/${course.Slug}",
                          "name": "${course.Title}",
                          "description": "${stripHtml(course.Description).replace(/"/g, '\\"')}",
                          "image": "${course.Image}",
                          "datePublished": "${formatDateYMD(course.PublicationDate)}",
                          "inLanguage": "fr" 
                          ${
                            course.Rating
                            ? `,"aggregateRating": {
                                "@type": "AggregateRating",
                                "ratingValue": "${course.Rating}",
                                "reviewCount": "187"
                              },
                              "review": [
                                ${
                                  course.Reviews.map(review => (`
                                    {
                                      "@type": "Review",
                                      "author": "${review.Author}",
                                      "datePublished": "${formatDateYMD(review.ResponseDate)}",
                                      "description": "${stripHtml(review?.Opinion).replace(/"/g, '\\"')}",
                                      "reviewRating": {
                                        "@type": "Rating",
                                        "ratingValue": "4.7"
                                      }
                                    }
                                  `))
                                }
                              ]`
                            : ''
                        }
                      }
                    }
                  `
                ))
              }
            ]
        }`
      }
    </script>
  </Helmet>
