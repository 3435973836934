import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { tagStyle } from './tag.style'
import TypographyElement from '../typographyElement/typographyElement'

const Tag = (props) => {
  const useStyle = createUseStyles(tagStyle)
  const classes = useStyle(props)
  return (
    <div onClick={props.handleClick} className={classes.root}>
      <TypographyElement className={classes.label} variant='body1'>
        {props.label}
      </TypographyElement>
    </div>
  )
}

Tag.propTypes = {
  /**  the control label text to be displayed */
  label: PropTypes.string.isRequired,
  /** element's identifier */
  id: PropTypes.string,
  /** set checkbox disabled by default */
  disabled: PropTypes.bool,
  /** set checkbox checked by default */
  checked: PropTypes.bool,
  /** the method that control the click event */
  handleClick: PropTypes.func,
  /** Replace the tag with a skeleton loader */
  loading: PropTypes.bool
}

Tag.defaultProps = {
  label: 'HTML',
  mode: 'default'
}

export default Tag
