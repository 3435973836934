import { Typography } from '../../../constants/typography'
import { Colors } from '../../../constants/color'

export const emptySuggestionItemStyle = {
  emptySuggestionItem: {
    width: '100%',
    '& label': {
      color: Colors.core.disabled,
      fontFamily: Typography.fontFamily,
      fontSize: Typography.body,
      lineHeight: 1.43,
      display: 'block',
      padding: [10, 14]
    }
  }
}
