import { connect } from 'react-redux'
import UI from './UI'
import { push } from 'connected-react-router'
import { decorateUrl } from 'components/common/UTMscript'

const mapStateToProps = ({ learning }) => ({
  similarCourses: learning.similarCourses,
  loading: learning.loadingSimilarCourses
})

const mapDispatchToProps = dispatch => ({
  goToLink: (link) => dispatch(push(decorateUrl(link)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
