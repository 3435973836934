import { bannerProps } from '../../constants/banner'

export const bannerStyle = theme => ({
  bannerGroup: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: ({ status, secondary }) => bannerProps(theme, status, secondary).borderColor,
    backgroundColor: ({ status, secondary }) => `${bannerProps(theme, status, secondary).backgroundColor}${secondary ? '19' : 'FF'}`,
    padding: ({ padding }) => padding || '12px 24px 12px 19px'
  },
  iconAndMessage: {
    display: 'flex',
    alignItems: 'center'
  },
  iconStyle: {
    color: ({ status, secondary }) => bannerProps(theme, status, secondary).color,
    fontSize: 33,
    marginRight: 8
  },
  closeIcon: {
    color: ({ status, secondary }) => bannerProps(theme, status, secondary).color,
    fontSize: 20,
    cursor: 'pointer'
  }
})
