import React, { useState, useEffect, useRef } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { selectListStyle } from './selectList.style'
import { InputIcon, Icons, TypographyElement } from '../../index'
import SuggestionGroup from './suggestionGroup'
import SuggestionItem from './suggestionItem'

const useStyles = createUseStyles(selectListStyle)

export const SelectList = ({ items = [], onSelectedItem, selectedItem = '', gutterBottom = 0, gutterBottomTablet = 0, gutterBottomMobile = 0, label = '', idField = 'Id', valueField = 'Value', typography, dataTest, selectedLabel }) => {
  const theme = useTheme()
  const node = useRef()
  const [toggleSuggestionList, setToggleSuggestionList] = useState(false)
  const { selectListContainer, selectList } = useStyles({ toggleSuggestionList, gutterBottom, gutterBottomTablet, gutterBottomMobile })
  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])
  const handleClick = e => (!node.current.contains(e.target) && !toggleSuggestionList) && setToggleSuggestionList(false)
  return (
    <div className={selectListContainer} ref={node}>
      {
        label &&
        (
          <TypographyElement
            variant='label'
            spacing='0 0 8px 0'
            {...typography}
          >{label}
          </TypographyElement>
        )
      }
      <div
        data-test={dataTest}
        onClick={() => setToggleSuggestionList(!toggleSuggestionList)}
        className={selectList}
      >
        <TypographyElement variant='body2'>{selectedLabel || selectedItem}</TypographyElement>
        <InputIcon
          height='100%'
          color={theme.primary[0]}
          iconName={Icons.roundedDown}
        />
      </div>
      <SuggestionGroup toggleSuggestionList={toggleSuggestionList}>
        {items.map((option, i) =>
          <SuggestionItem
            selected={selectedItem === option[valueField]}
            dataTest={`${dataTest}-suggestion-item`}
            key={i}
            value={option[valueField]}
            handleClick={(e) => {
              setToggleSuggestionList(!toggleSuggestionList)
              onSelectedItem(option, e)
            }}
          />
        )}
      </SuggestionGroup>
    </div>
  )
}

SelectList.propTypes = {
  /** Array of items [{id, value}] */
  items: PropTypes.array.isRequired,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  /** Selected item */
  selectedItem: PropTypes.string,
  /** function that handle the event when click an item in the list */
  onSelectedItem: PropTypes.func.isRequired,
  /** space to be left on the bottom */
  gutterBottom: PropTypes.number,
  /**  the control label text to be displayed */
  label: PropTypes.string
}

export default SelectList
