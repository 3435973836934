import moment from 'moment'
import Header from '../Header'
import LiveHeader from '../LiveHeader'

export default ({ isUserParticipating, startDate, endDate, plannedDate }) => {
  const isWebinarLive = moment(startDate).isSameOrAfter(moment()) && endDate === null
  const isWebinarPassed = moment(plannedDate).isBefore(moment()) && endDate !== null
  const showLiveHeader = isUserParticipating && (isWebinarLive || isWebinarPassed)
  return (
    showLiveHeader ? <LiveHeader /> : <Header />
  )
}
