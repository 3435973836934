import { createUseStyles, useTheme } from 'react-jss'
import style from './style'
import { FacebookLink, TwitterLink, InstagramLink, LinkedinLink } from 'constants/'
import { Icon, Icons, TypographyElement } from 'UI'

const useStyle = createUseStyles(style)

export default ({ title, shareLinks, textProps }) => {
  const theme = useTheme()
  const { socialMediaContainer, socialMedia, icon, linkClass } = useStyle()
  return (
    <div className={socialMediaContainer}>
      {
        title
          ? (
            <TypographyElement
              variant='body2'
              color={theme.neutral[100]}
              opacity='50%'
              spacing='0 8px 0 0'
              display='inline'
              {...textProps}
            >
              {title}
            </TypographyElement>)
          : null
      }
      <a aria-label='LinkedIn' target='_blank' rel='noopener noreferrer' href={shareLinks ? `${shareLinks?.linkedinShareLink}${window.location}` : LinkedinLink} className={linkClass}>
        <span className={socialMedia}>
          <Icon style={icon} iconName={Icons.linkedin} />
        </span>
      </a>
      <a aria-label='Facebook' target='_blank' rel='noopener noreferrer' href={shareLinks ? `${shareLinks?.facebookShareLink}${window.location}` : FacebookLink} className={linkClass}>
        <span className={socialMedia}>
          <Icon style={icon} iconName={Icons.facebook} />
        </span>
      </a>
      <a aria-label='Twitter' target='_blank' rel='noopener noreferrer' href={shareLinks ? `${shareLinks?.twitterShareLink}${window.location}` : TwitterLink} className={linkClass}>
        <span className={socialMedia}>
          <Icon style={icon} iconName={Icons.twitter} />
        </span>
      </a>
      {!shareLinks
        ? (
          <a aria-label='Instagram' target='_blank' rel='noopener noreferrer' href={InstagramLink} className={linkClass}>
            <span className={socialMedia}>
              <Icon style={icon} iconName={Icons.instagram} />
            </span>
          </a>)
        : null}
    </div>
  )
}
