import { BASE_URL_WEB, ItemTypesEnum } from 'constants/'
import { put, select, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { pageNotFoundPath } from 'navigation/Routes'
import { decorateUrl } from 'components/common/UTMscript'
import { push } from 'connected-react-router'
import { isEmpty } from 'lodash'

const initialState = {
  LearningPath: {
    Title: '',
    Description: '',
    Slug: '',
    Subtitle: '',
    Categories: [],
    PresentationVideo: '',
    PresentationVideoThumbnail: '',
    Tags: [],
    Image: '',
    Duration: 0,
    Price: 0,
    AlternativePrice: 0,
    Code: '',
    Objectives: [],
    Prerequirements: [],
    PublicConcerned: [],
    TotalCourses: 0,
    TagsNames: [],
    TagsIds: []
  },
  Courses: {
    Count: 0,
    FilteredCount: 0,
    Data: []
  },
  CoursePathProjects: [],
  AllCourses: [],
  isLearningPathLoading: false,
  isLearningPathCoursesLoading: false,
  isLearningPathProgressionLoading: false,
  take: 8,
  skip: 0
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_LEARNING_PATH_REQUESTED:
      return {
        ...state,
        isLearningPathLoading: true
      }
    case GET_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        isLearningPathLoading: false,
        LearningPath: data
      }
    case GET_LEARNING_PATH_FAIL:
      return {
        ...state,
        isLearningPathLoading: false
      }
    case RESET_LEARNING_PATH:
      return {
        ...state,
        LearningPath: {
          Title: '',
          Description: '',
          Slug: '',
          Subtitle: '',
          Categories: [],
          PresentationVideo: '',
          PresentationVideoThumbnail: '',
          Tags: [],
          Image: '',
          Duration: 0,
          Price: 0,
          AlternativePrice: 0,
          Code: '',
          Objectives: [],
          Prerequirements: [],
          PublicConcerned: [],
          TotalCourses: 0,
          TagsNames: [],
          TagsIds: []
        },
        Courses: {
          Count: 0,
          Data: []
        },
        CoursePathProjects: [],
        isLearningPathLoading: false,
        isLearningPathCoursesLoading: false,
        isLearningPathProgressionLoading: false,
        take: 8,
        skip: 0
      }
    case GET_LEARNING_PATH_COURSES_SUCCESS:
      return {
        ...state,
        isLearningPathCoursesLoading: false,
        Courses: {
          Count: data.Count,
          Data: [...state.Courses.Data, ...data.Data],
          FilteredCount: data.FilteredCount
        },
        skip: state.skip + state.take
      }
    case GET_LEARNING_PATH__COURSES_FAIL:
      return {
        ...state,
        isLearningPathCoursesLoading: false
      }
    case GET_LEARNING_PATH_ALL_COURSES_SUCCESS:
      return {
        ...state,
        AllCourses: data
      }
    case GET_LEARNING_PATH_ALL_COURSES_FAIL:
      return {
        ...state,
        isLearningPathCoursesLoading: false
      }
    case GET_LEARNING_PATH_PROJECTS_SUCCESS:
      return {
        ...state,
        ...data
      }
    case GET_LEARNING_PATH_COURSE_PROGRESS_SUCCESS:
      return {
        ...state,
        Courses: {
          ...state.Courses,
          Data: data
        }
      }
    case TURN_ON_COURSES_LOADING:
      return {
        ...state,
        isLearningPathCoursesLoading: true,
        isLearningPathProgressionLoading: true
      }
    case TURN_OFF_COURSES_LOADING:
      return {
        ...state,
        isLearningPathCoursesLoading: false,
        isLearningPathProgressionLoading: false
      }
    default:
      return state
  }
}

const getAutoAppliedCoupon = ({ user }) => user?.AutoAppliedCoupons
const getReferential = ({ referential }) => referential
const getShop = ({ support }) => support
const getIsConnected = ({ identity }) => identity?.isConnected

export const fetchLearningPathAction = (slug) => ({ type: GET_LEARNING_PATH_REQUESTED, slug })
export const fetchLearningPathCoursesAction = (pathId, rowsPerPage, skip) => ({ type: GET_LEARNING_PATH_COURSES_REQUESTED, pathId, rowsPerPage, skip })
export const fetchLearningPathAllCoursesAction = (pathId) => ({ type: GET_LEARNING_PATH_ALL_COURSES_REQUESTED, pathId })
export const resetLearningPathAction = _ => ({ type: RESET_LEARNING_PATH })
export const fetchLearningPathProjectsAction = (pathId) => ({ type: GET_LEARNING_PATH_PROJECTS_REQUESTED, pathId })
export const fetchLearningPathCourseProgressAction = (pathId, LearningPath) => ({ type: GET_LEARNING_PATH_COURSE_PROGRESS_REQUESTED, pathId, LearningPath })
export const turnOnLoadingAction = _ => ({ type: TURN_ON_COURSES_LOADING })
export const turnOffLoadingAction = _ => ({ type: TURN_OFF_COURSES_LOADING })

function * fetchLearningPath ({ slug }) {
  yield put(turnOnLoadingAction())
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const referential = yield select(getReferential)
  const shop = yield select(getShop)
  const courseCoupon =
    autoAppliedCoupon?.length > 0
      ? autoAppliedCoupon[0]?.Values?.find(
          (coupon) => coupon.SubjectType === referential.ItemTypes[ItemTypesEnum.Course]
        )
      : null
  const { take, skip } = yield select(({ learningPath }) => learningPath)
  try {
    const response = yield fetch(queries.getLearningPathBySlug(slug))
    if (courseCoupon) {
      response.TotalPrice = courseCoupon?.Value * response.CountCourses
      response.TotalAlternativePrice =
        (courseCoupon?.Value * response.CountCourses) * shop.PriceCoefficient
    }
    yield put({ type: GET_LEARNING_PATH_SUCCESS, data: response })
    yield put(fetchLearningPathCoursesAction(response.Id, take, skip))
    yield put(fetchLearningPathProjectsAction(response.Id))
    yield put(turnOffLoadingAction())
  } catch (err) {
    yield put(push(decorateUrl(pageNotFoundPath)))
    yield put({ type: GET_LEARNING_PATH_FAIL, err })
  }
}

function * fetchLearningPathCourses ({ pathId, rowsPerPage, skip }) {
  try {
    const response = yield fetch(queries.getCoursesByPath(pathId, rowsPerPage, skip))
    yield put({
      type: GET_LEARNING_PATH_COURSES_SUCCESS,
      data: {
        Data: response.Data,
        Count: response.Count,
        FilteredCount: response.FilteredCount
      }
    })
    yield put(fetchLearningPathCourseProgressAction(pathId, response))
  } catch (err) {
    yield put({ type: GET_LEARNING_PATH__COURSES_FAIL, err })
  }
}

function * mapLearningPathWithProgression ({ pathId, LearningPath }) {
  const isConnected = yield select(getIsConnected)
  try {
    if (isConnected) {
      const progressionResponse = yield fetch(queries.getProgressionBySlug(pathId))
      if (!isEmpty(progressionResponse)) {
        LearningPath.Data = LearningPath.Data.map(course => ({
          ...course,
          CompletionPercentage: progressionResponse[course.Id] ?? 0
        }))
        yield put({ type: GET_LEARNING_PATH_COURSE_PROGRESS_SUCCESS, data: LearningPath.Data })
      }
    }
  } catch (err) {
    yield put({ type: GET_LEARNING_PATH_COURSE_PROGRESS_FAIL, err })
  }
}

function * fetchLearningPathAllCourses ({ pathId }) {
  const skipNone = 0
  const takeAll = 0
  try {
    const response = yield fetch(queries.getCoursesByPath(pathId, takeAll, skipNone))
    yield put({
      type: GET_LEARNING_PATH_ALL_COURSES_SUCCESS,
      data: response.Data
    })
  } catch (err) {
    yield put({ type: GET_LEARNING_PATH_ALL_COURSES_FAIL, err })
  }
}

function * fetchLearningPathProjects ({ pathId }) {
  try {
    const response = yield fetch(queries.getProjectsByPath(pathId))
    yield put({ type: GET_LEARNING_PATH_PROJECTS_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_LEARNING_PATH_PROJECTS_FAIL, err })
  }
}

export function * LearningPathRootSaga () {
  yield takeLatest(GET_LEARNING_PATH_REQUESTED, fetchLearningPath)
  yield takeLatest(GET_LEARNING_PATH_COURSES_REQUESTED, fetchLearningPathCourses)
  yield takeLatest(GET_LEARNING_PATH_ALL_COURSES_REQUESTED, fetchLearningPathAllCourses)
  yield takeLatest(GET_LEARNING_PATH_PROJECTS_REQUESTED, fetchLearningPathProjects)
  yield takeLatest(GET_LEARNING_PATH_COURSE_PROGRESS_REQUESTED, mapLearningPathWithProgression)
}

const queries = {
  getLearningPathBySlug: slug => `${BASE_URL_WEB}/CoursePath/${slug}`,
  getCoursesByPath: (pathId, take, skip) => `${BASE_URL_WEB}/CoursePath/courses?PathId=${pathId}&Take=${take}&Skip=${skip}&OrderedByAsc=true`,
  getProjectsByPath: pathId => `${BASE_URL_WEB}/CoursePath/projects/${pathId}`,
  getProgressionBySlug: pathId => `${BASE_URL_WEB}/CoursePath/progression/${pathId}`
}

const GET_LEARNING_PATH_REQUESTED = 'GET_LEARNING_PATH_REQUESTED'
const GET_LEARNING_PATH_SUCCESS = 'GET_LEARNING_PATH_SUCCESS'
const GET_LEARNING_PATH_FAIL = 'GET_LEARNING_PATH_FAIL'

const GET_LEARNING_PATH_COURSES_REQUESTED = 'GET_LEARNING_PATH_COURSES_REQUESTED'
const GET_LEARNING_PATH_COURSES_SUCCESS = 'GET_LEARNING_PATH_COURSES_SUCCESS'
const GET_LEARNING_PATH__COURSES_FAIL = 'GET_LEARNING_PATH__COURSES_FAIL'

const RESET_LEARNING_PATH = 'RESET_LEARNING_PATH'

const GET_LEARNING_PATH_ALL_COURSES_REQUESTED = 'GET_LEARNING_PATH_ALL_COURSES_REQUESTED'
const GET_LEARNING_PATH_ALL_COURSES_SUCCESS = 'GET_LEARNING_PATH_ALL_COURSES_SUCCESS'
const GET_LEARNING_PATH_ALL_COURSES_FAIL = 'GET_LEARNING_PATH_ALL_COURSES_FAIL'

const GET_LEARNING_PATH_PROJECTS_REQUESTED = 'GET_LEARNING_PATH_PROJECTS_REQUESTED'
const GET_LEARNING_PATH_PROJECTS_SUCCESS = 'GET_LEARNING_PATH_PROJECTS_SUCCESS'
const GET_LEARNING_PATH_PROJECTS_FAIL = 'GET_LEARNING_PATH_PROJECTS_FAIL'

const GET_LEARNING_PATH_COURSE_PROGRESS_REQUESTED = 'GET_LEARNING_PATH_COURSE_PROGRESS_REQUESTED'
const GET_LEARNING_PATH_COURSE_PROGRESS_SUCCESS = 'GET_LEARNING_PATH_COURSE_PROGRESS_SUCCESS'
const GET_LEARNING_PATH_COURSE_PROGRESS_FAIL = 'GET_LEARNING_PATH_COURSE_PROGRESS_FAIL'

const TURN_ON_COURSES_LOADING = 'TURN_ON_COURSES_LOADING'
const TURN_OFF_COURSES_LOADING = 'TURN_OFF_COURSES_LOADING'
