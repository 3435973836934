export default {
  root: {
    width: '100%'
  },
  view: {
    gap: 16,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      marginBottom: 8
    }
  },
  reponsiveClass: {
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap !important',
    '& > div': {
      minWidth: 336,
      maxWidth: 336,
      marginRight: 16
    }
  }
}
