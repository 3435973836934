import {
  blue,
  Spacing,
  neutral
} from '@alphorm/ui'

export default {
  videoStyle: {
    width: '100%',
    height: '239px !important',
    marginTop: 24,
    marginBottom: 16,
    borderRadius: 4,
    overflow: 'hidden'
  },
  paperClass: {
    padding: 24,
    borderRadius: 4,
    marginBottom: 12
  },
  tagsPaperClass: {
    marginTop: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: Spacing(5)
  },
  speakerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  speakerInfos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  speakerLinkContainerStyle: {
    backgroundColor: blue[0],
    borderRadius: 4,
    width: 21,
    height: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8
  },
  profileIcon: {
    color: neutral[0]
  },
  speakerLinkStyle: {
    width: 21,
    height: 21,
    cursor: 'pointer'
  }
}
