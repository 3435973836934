import React from 'react'
import { createUseStyles } from 'react-jss'
import { dataTableStyle } from './dataTable.style'
import TableHead from '../tableHead/tableHead'
import { TableRow, Table, TableBody, TypographyElement } from '../..'
import TableCell from '../tableCell/tableCell'
import PropTypes from 'prop-types'

const DataTable = (props) => {
  const useStyle = createUseStyles(dataTableStyle)
  const { tableRow } = useStyle(props)
  const { columns } = props.config
  const { data } = props
  return (
    <Table tableCellPadding={props.cellPadding} headCellPadding={props.headCellPadding}>
      <TableHead>
        <TableRow>
          {
            columns.map((column, i) => <TableCell key={i}><TypographyElement display='inline' variant='heading6' fontSize='1rem'>{column.title}</TypographyElement></TableCell>)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((item, i) => (
            <TableRow
              hover
              key={i}
              className={tableRow}
            >
              {
                columns.map((row, i) => (
                  <TableCell key={i} padding='default'>
                    {
                      row.render
                        ? row.render(item[row.property], props, item)
                        : item[row.property]
                    }
                  </TableCell>
                ))
              }
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

DataTable.propTypes = {
  /** Contains columns informations ( property , title , reder func ) */
  config: PropTypes.object,
  /** An array of data to be maped into rows */
  data: PropTypes.array
}

export default DataTable
