import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { SwitchTopBarStateAction, UserCloseTopBarAction } from 'store/marketing'
import { decorateUrl } from '../UTMscript'
import UI from './UI'

const mapStateToProps = ({ marketing, user }) => ({
  topBarOpen: marketing.topBarOpen,
  topBar: marketing.topBar,
  userTag: user.UserTag,
  userCloseTopBar: marketing.userCloseTopBar
})

const mapDispatchToProps = (dispatch) => ({
  switchTopBarState: (data) => dispatch(SwitchTopBarStateAction(data)),
  goToUrl: link => dispatch(push(decorateUrl(link))),
  userCloseTopBarAction: () => dispatch(UserCloseTopBarAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
