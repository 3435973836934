import { green, red, yellow } from '../constants/color'

export const MiniCardVariation = {
  default: 'default',
  progression: 'progression',
  cart: 'cart',
  promo: 'promo',
  free: 'free'
}

export const MiniCardProgressionColor = {
  todo: red[3],
  inProgress: yellow[3],
  done: green[3]
}
