import { Typography } from '../../constants'

export const switchWithLabelsStyle = (theme) => ({
  inputGroup: {
    width: 102,
    height: 21,
    position: 'relative'
  },
  switchButton: ({ leftLabel, rightLabel }) => ({
    display: 'inline-flex',
    '&::before, &::after': {
      ...Typography.caption,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all .2s linear',
      width: 51,
      height: 21
    },
    '&::before': {
      content: `"${leftLabel}"`,
      color: theme.neutral[400],
      backgroundColor: theme.warning[0],
      border: `1px solid ${theme.warning[0]}`,
      borderRadius: '2px 0 0 2px'
    },
    '&::after': {
      content: `"${rightLabel}"`,
      color: theme.neutral[200],
      backgroundColor: theme.neutral[400],
      border: `1px solid ${theme.neutral[0]}`,
      borderRadius: '0 2px 2px 0'
    }
  }),
  input: {
    width: '100%',
    height: '100%',
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    left: 0,
    margin: 0,
    zIndex: 1,
    '&:checked + label::before': {
      color: theme.neutral[200],
      backgroundColor: theme.neutral[400],
      border: `1px solid ${theme.neutral[0]}`
    },
    '&:checked + label::after': {
      color: theme.neutral[400],
      backgroundColor: theme.warning[0],
      border: `1px solid ${theme.warning[0]}`,
      borderRadius: '0 2px 2px 0'
    }
  }
})
