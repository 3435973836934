import { put, select, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB, defaultCountry, defaultUserType, freeTrialFeature, InvoiceContactInfo, productsNameDataLayer, ProfileTypes as profileTypesEnum } from 'constants/'
import { LOGOUT_SUCCESS, SIGNIN, tryLogout } from 'identity/store'
import { beforeToday, CancelSubscription, ReactivateSubscription } from 'helpers'
import { FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED } from 'components/CheckoutSubscription/store'
import { openFailNotification, openSuccessNotification } from 'components/common/Notification/store'
import Text from './text.json'
import { FINALIZE_COURESES_ORDER_SUCCEEDED } from 'components/Cart/store'

const skipNone = 0

const initialState = {
  error: false,
  Subscription: {},
  address: {},
  invoices: {
    FilteredCount: 0,
    Items: [],
    Total: 0
  },
  loadingBillingAddress: false,
  usersAlreadySignedUp: {},
  loading: false,
  isElligableForTrial: freeTrialFeature,
  showOnboardingTrial: false,
  paymentSchedules: [],
  UserTag: defaultUserType,
  avatarUploading: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SIGNIN:
      return {
        ...state,
        LastName: data?.family_name,
        FirstName: data?.given_name
      }
    case GET_BILLING_ADRESS_REQUESTED:
      return {
        ...state,
        loadingBillingAddress: true
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false
      }
    case GET_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        Subscription: data
      }
    case GET_USER_FAILED:
      return {
        ...state,
        error: data,
        loading: false
      }
    case GET_USER_SUBSCRIPTION_FAILED:
      return {
        ...state,
        error: data
      }
    case GET_BILLING_ADRESS_SUCCESS:
      return {
        ...state,
        address: data,
        loadingBillingAddress: false
      }
    case SET_BILLING_ADRESS_SUCCESS:
      return {
        ...state,
        address: data
      }
    case SET_USER_INFO_SUCCESS:
      return {
        ...state,
        ...data,
        loading: false
      }
    case DELETE_USER_PICTURE_SUCCESS:
      return {
        ...state,
        Photo: null
      }
    case UPDATE_AVATAR_REQUESTED:
      return {
        ...state,
        avatarUploading: true
      }
    case UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        Photo: data,
        avatarUploading: false
      }
    case UPDATE_AVATAR_FAILED:
      return {
        ...state,
        avatarUploading: false
      }
    case CANCEL_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        Subscription: {
          ...state.Subscription,
          AutoRenew: false
        }
      }
    case REACTIVATE_SUBSCRIPTION_SUCCEEDED:
      return {
        ...state,
        Subscription: {
          ...state.Subscription,
          AutoRenew: true
        }
      }
    case GET_INVOICES_RESET_SUCCEEDED:
      return {
        ...state,
        invoices: {
          ...data
        }
      }
    case GET_INVOICES_SUCCEEDED:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          Items: [
            ...state.invoices.Items,
            ...data.Items
          ]
        }
      }
    case UPDATE_PHONE_NUMBER_REQUESTED:
      return {
        ...state,
        ValidatedPhoneNumber: data
      }
    case SAVE_ALREADY_SIGNED_USERS:
      return {
        ...state,
        usersAlreadySignedUp: {
          ...state.usersAlreadySignedUp,
          ...data
        }
      }
    case UPDATE_USER_INFO_REQUESTED: {
      return {
        ...state,
        loading: true
      }
    }
    case SET_USER_INFO_FAILED: {
      return {
        ...state,
        loading: false
      }
    }
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        usersAlreadySignedUp: state.usersAlreadySignedUp
      }
    case SET_TRIAL_STATE:
      return {
        ...state,
        showOnboardingTrial: data
      }
    case GET_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        isElligableForTrial: data
      }
    case SET_USER_ELIGIBILITY_ACTION:
      return {
        ...state,
        isElligableForTrial: data
      }
    case GET_USER_PAYMENT_SCHEDULES_SUCCESS:
      return {
        ...state,
        paymentSchedules: data
      }
    case GET_USER_PAYMENT_SCHEDULES_FAILED:
      return {
        ...state,
        error: data
      }
    default:
      return state
  }
}

const getUser = ({ user }) => user
const getSubscriptionBillingCycles = ({ referential }) => referential.SubscriptionBillingCycles
const getShop = ({ support }) => support
const getReferential = ({ referential }) => referential
const getIsConnected = ({ identity }) => identity.isConnected

const extraDayForPremiumSubscriber = 1

export const fetchUserAction = () => ({ type: USER_FETCH_REQUESTED })
export const fetchUserSubscriptionAction = () => ({ type: USER_SUBSCRIPTION_FETCH_REQUESTED })
export const fetchBillingAddressAction = (data, next) => ({ type: BILLING_ADRESS_REQUESTED, data, next })
export const setTrialState = (data) => ({ type: SET_TRIAL_STATE, data })
export const updateUserPasswordAction = (data) => ({ type: PASSWORD_REQUESTED, data })
export const updateUserInfoAction = (data, next) => ({ type: UPDATE_USER_INFO_REQUESTED, data, next })
export const deleteUserPictureAction = () => ({ type: DELETE_USER_PICTURE_REQUESTED })
export const disableAccountAction = () => ({ type: DISABLE_ACCOUNT_SUCCESS })
export const fetchGetBillingAddressAction = () => ({ type: GET_BILLING_ADRESS_REQUESTED })
export const updateAvatarAction = (avatar) => ({ type: UPDATE_AVATAR_REQUESTED, data: avatar })
export const cancelSubscriptionAction = () => ({ type: CANCEL_SUBSCRIPTION_REQUESTED })
export const reactivateSubscriptionAction = () => ({ type: REACTIVATE_SUBSCRIPTION_REQUESTED })
export const getInvoicesAction = (take, skip) => ({ type: GET_INVOICES_REQUESTED, take, skip })
export const downloadInvoiceAction = (id) => ({ type: DOWNLOAD_INVOICE, id })
export const sendContactMessageAction = (formValues, next, mailEventType) => ({ type: SEND_CONTACT_MESSAGE_REQUESTED, formValues, next, mailEventType })
export const updatePhoneNumberAction = (data) => ({ type: UPDATE_PHONE_NUMBER_REQUESTED, data })
export const saveAlreadySignedUsersAction = userId => ({ type: SAVE_ALREADY_SIGNED_USERS, data: { [`${userId}`]: true } })
export const initUserAddressAction = (firstName, lastName, countryId) => ({ type: INIT_USER_ADDRESS_REQUESTED, firstName, lastName, countryId })
export const fetchUserElligibilityAction = () => ({ type: GET_ELIGIBILITY_REQUESTED })
export const setUserEligibilityAction = (data) => ({ type: SET_USER_ELIGIBILITY_ACTION, data: freeTrialFeature ? data : false })
export const fetchUserPaymentSchedulesAction = () => ({ type: GET_USER_PAYMENT_SCHEDULES_REQUESTED })

function * fetchUser () {
  const { Countries, CountryDetails } = yield select(getReferential)
  try {
    const response = yield fetch(queries.getUserData)
    delete response.Subscription
    const country = response.ValidatedPhoneNumber
      ? Countries.find(country => response.ValidatedPhoneNumber.includes(country.PhoneCode, 0))
      : CountryDetails
    response.detectedCountry = country
    response.initialPhoneCode = country.PhoneCode
    yield put({ type: GET_USER_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_USER_FAILED, err })
  }
}

function * fetchUserSubscription () {
  const subscriptionBillingCycles = yield select(getSubscriptionBillingCycles)
  const { ProfileType } = yield select(getUser)
  const { ProfileTypes } = yield select(getReferential)
  try {
    const response = yield fetch(queries.getUserSubscription)
    if (response.SubscriptionCycleType) {
      const subscription = subscriptionBillingCycles?.find(subscription => subscription.Id === response.SubscriptionCycleType)
      response.subscriptionLabel = subscription?.InvoiceDisplayName
    }
    const { IsSubscriptionActive } = yield import('@alphorm/js')
    response.IsActive = response.EndDate
      ? IsSubscriptionActive(response.EndDate, ProfileTypes[ProfileType] === profileTypesEnum.PremiumSubscriber
          ? extraDayForPremiumSubscriber
          : 0)
      : false
    yield put({ type: GET_USER_SUBSCRIPTION_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_USER_SUBSCRIPTION_FAILED, data: err })
  }
}

function * fetchBillingAdress ({ data, next }) {
  try {
    if (!data.countryId) throw new Error()
    yield fetch(queries.setBillingAdress, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ ...data }), nojson: true })
    const response = {
      Company: data.company || '',
      FirstName: data.firstName || '',
      LastName: data.lastName || '',
      VatNumber: data.vatNumber || '',
      AddressLine1: data.addressLine1 || '',
      AddressLine2: data.addressLine2 || '',
      City: data.city || '',
      PostalCode: data.postalCode || '',
      CountryId: data.countryId || '',
      State: data.state || '',
      AddressTypeId: true,
      AddressId: true
    }
    yield put({ type: SET_BILLING_ADRESS_SUCCESS, data: response })
    yield put(openSuccessNotification(Text.setBillingAddressSuccess))
    next && next()
  } catch (err) {
    yield put({ type: SET_BILLING_ADRESS_FAILED, err })
    yield put(openFailNotification(Text.setBillingAddressFailed))
  }
}

function * updateUserPassword ({ data }) {
  try {
    const response = yield fetch(queries.updateUserPassword, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ password: data }), nojson: true })
    yield put({ type: SET_PASSWORD_SUCCESS, data: response })
    yield put(openSuccessNotification(Text.updatePasswordSuccess))
  } catch (err) {
    yield put({ type: SET_PASSWORD_FAILED, err })
    yield put(openFailNotification(Text.updatePasswordFailed))
  }
}

function * updateUserInfo ({ data, next }) {
  try {
    const body = { ...data }
    delete body.Phone
    yield fetch(queries.updateUserInfo, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body), nojson: true })
    yield put({ type: SET_USER_INFO_SUCCESS, data: data })
    yield put(openSuccessNotification(Text.updateGeneralInfosSuccess))
    next && next()
  } catch (err) {
    yield put({ type: SET_USER_INFO_FAILED, err })
    yield put(openFailNotification(Text.updateGeneralInfosFailed))
  }
}

function * deleteUserPicture () {
  try {
    yield fetch(queries.deleteUserPicture, { method: 'delete', headers: { 'Content-Type': 'application/json' }, nojson: true })
    yield put({ type: DELETE_USER_PICTURE_SUCCESS })
    yield put(openSuccessNotification(Text.deleteAvatarSuccess))
  } catch (err) {
    yield put({ type: DELETE_USER_PICTURE_FAILED, err })
    yield put(openFailNotification(Text.deleteAvatarFailed))
  }
}

function * fetchGetBillingAdress () {
  try {
    const response = yield fetch(queries.getBillingAddress)
    yield put({ type: GET_BILLING_ADRESS_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_BILLING_ADRESS_FAILED, err })
  }
}

function * disableAccount () {
  yield fetchUserSubscription()
  const { Subscription, Id } = yield select(getUser)
  const subscriptionEnded = beforeToday(Subscription.EndDate)
  try {
    yield fetch(queries.disableAccount, { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true })
    const dataLayer = window.dataLayer || []
    yield dataLayer.push({
      event: 'deactivate_account',
      user_id: Id,
      account_type: subscriptionEnded ? 'Gratuit' : 'Abonné'
    })
    yield put(tryLogout())
  } catch (err) {
    yield put({ type: DISABLE_ACCOUNT_FAILED, err })
  }
}

function * updateAvatar ({ data }) {
  try {
    const formData = new window.FormData()
    formData.append('imageFile', data)
    yield fetch(queries.updateAvatar, { method: 'post', body: formData, nojson: true })
    yield put({ type: UPDATE_AVATAR_SUCCESS })
    yield put(openSuccessNotification(Text.updateAvatarSuccess))
  } catch (err) {
    yield put({ type: UPDATE_AVATAR_FAILED, err })
    yield put(openFailNotification(Text.updateAvatarFailed))
  }
}

function * cancelSubscription () {
  const { Subscription, Id } = yield select(getUser)
  try {
    yield fetch(queries.cancelSubscription, { method: 'delete', nojson: true })
    yield put({ type: CANCEL_SUBSCRIPTION_SUCCEEDED })
    CancelSubscription({ subscriptionType: productsNameDataLayer[Subscription.SubscriptionCycleType], userId: Id })
  } catch (err) {
    yield put({ type: CANCEL_SUBSCRIPTION_FAILED, err })
  }
}

function * reactivateSubscription () {
  const { Subscription, Id } = yield select(getUser)
  try {
    yield fetch(queries.reactivateSubscription, { method: 'put', nojson: true })
    yield put({ type: REACTIVATE_SUBSCRIPTION_SUCCEEDED })
    ReactivateSubscription({ subscriptionType: productsNameDataLayer[Subscription.SubscriptionCycleType], userId: Id })
    yield put(openSuccessNotification(Text.reactivateSubscriptionSuccess))
  } catch (err) {
    yield put({ type: REACTIVATE_SUBSCRIPTION_FAILED, err })
    yield put(openFailNotification(Text.reactivateSubscriptionFailed))
  }
}

function * getInvoices ({ take, skip }) {
  try {
    const response = yield fetch(queries.order(take, skip))
    if (skip === skipNone) yield put({ type: GET_INVOICES_RESET_SUCCEEDED, data: response })
    else yield put({ type: GET_INVOICES_SUCCEEDED, data: response })
  } catch (err) {
    yield put({ type: GET_INVOICES_FAILED, err })
  }
}

function * downloadInvoice ({ id }) {
  try {
    const response = yield fetch(queries.getOrderDetails(id))
    const items = response.Items.map(item => ({
      description: item.Description,
      priceExcludingVat: item.PriceExcludingVat
    }))
    const invoiceContent = {
      invoiceNumber: response.OrderNumber,
      date: response.IssueDate,
      paymentMethod: Text.paymentMethodsEnum[response.PaymentMethod],
      user: {
        FirstName: response.BillingAddress.FirstName || '',
        LastName: response.BillingAddress.LastName || '',
        Email: response.User.Email || '',
        Phone: response.User.Phone || ''
      },
      totalPriceHT: Number(response.PriceExcludingVat).toFixed(2),
      totalVAT: Number(response.VatAmount).toFixed(2),
      vatPercentage: response.VatPercentage ? Number(Number(response.VatPercentage).toFixed(2)) : 0,
      totalTTC: Number(response.TotalPrice).toFixed(2),
      currency: response.Currency,
      items,
      shop: {
        Phone: InvoiceContactInfo.ContactPhone,
        Email: InvoiceContactInfo.SupportEmail
      },
      discountAmount: response.DiscountAmountHT ? Number(response.DiscountAmountHT).toFixed(2) : false,
      discountPercentage: response.DiscountPercentage ? Number(Number(response.DiscountPercentage).toFixed(2)) : false,
      billingAdress: {
        AddressLine1: response.BillingAddress.AddressLine1 || '',
        AddressLine2: response.BillingAddress.AddressLine2 || '',
        City: response.BillingAddress.City || '',
        Company: response.BillingAddress.Company || '',
        PostalCode: response.BillingAddress.PostalCode || '',
        State: response.BillingAddress.State || '',
        VatNumber: response.BillingAddress.VatNumber || ''
      },
      country: response.BillingAddress.Country,
      orderType: response.OrderType
    }
    const { InvoiceGenerator } = yield import('@alphorm/js')
    InvoiceGenerator(invoiceContent, response.OrderNumber)
    yield put({ type: DOWNLOAD_INVOICE_SUCCEEDED })
  } catch (err) {
    yield put({ type: DOWNLOAD_INVOICE_FAILED, data: err })
  }
}

function * sendContactMessage ({ formValues, mailEventType, next }) {
  const { CountryId } = yield select(getShop)
  const { Id } = yield select(getUser)
  const IsConnected = yield select(getIsConnected)

  const body = {
    ...formValues,
    mailEventType,
    id: Id
  }
  if (formValues?.country?.Id) body.countryId = formValues.country.Id === defaultCountry.Id ? Number(CountryId) : Number(formValues.country.Id)
  delete body.country
  if (IsConnected) delete body.AcceptConsent
  try {
    yield fetch(queries.sendContactMessage, { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true, useCaptcha: true, body: JSON.stringify(body) })
    yield put({ type: SEND_CONTACT_MESSAGE_SUCCEEDED })
    next && next()
  } catch (err) {
    yield put({ type: SEND_CONTACT_MESSAGE_FAILED, err })
    yield put(openFailNotification(Text.sendContactMessageFailed))
  }
}

function * initUserAddress ({ firstName, lastName, countryId }) {
  try {
    const response = yield fetch(queries.getBillingAddress)
    if (!response.AddressId) {
      yield fetch(queries.setBillingAdress, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ firstName, lastName, countryId }), nojson: true })
      yield put({ type: INIT_USER_ADDRESS_SUCCESS })
    }
  } catch (err) {
    yield put({ type: INIT_USER_ADDRESS_FAILED, err })
  }
}

function * getUserElligibility () {
  const { isElligableForTrial } = yield select(getUser)
  try {
    if (!freeTrialFeature) {
      yield put({ type: GET_ELIGIBILITY_SUCCESS, data: false })
    } else if (isElligableForTrial === null) {
      const response = yield fetch(queries.isEligibleForTrial)
      yield put({ type: GET_ELIGIBILITY_SUCCESS, data: response })
    } else {
      yield put({ type: GET_ELIGIBILITY_SUCCESS, data: isElligableForTrial })
    }
  } catch (err) {
    yield put({ type: GET_ELIGIBILITY_FAILED, err })
  }
}

function * fetchUserPaymentSchedules () {
  try {
    const response = yield fetch(queries.getPaymentSchedules)
    yield put({
      type: GET_USER_PAYMENT_SCHEDULES_SUCCESS,
      data: response
    })
  } catch (err) {
    yield put({ type: GET_USER_PAYMENT_SCHEDULES_FAILED, data: err })
  }
}

export function * userRootSaga () {
  yield takeLatest([USER_FETCH_REQUESTED, UPDATE_AVATAR_SUCCESS, SIGNIN, FINALIZE_COURESES_ORDER_SUCCEEDED, SET_USER_INFO_SUCCESS], fetchUser)
  yield takeLatest([USER_SUBSCRIPTION_FETCH_REQUESTED, SIGNIN, FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED], fetchUserSubscription)
  yield takeLatest(BILLING_ADRESS_REQUESTED, fetchBillingAdress)
  yield takeLatest(GET_BILLING_ADRESS_REQUESTED, fetchGetBillingAdress)
  yield takeLatest(PASSWORD_REQUESTED, updateUserPassword)
  yield takeLatest(UPDATE_USER_INFO_REQUESTED, updateUserInfo)
  yield takeLatest(DELETE_USER_PICTURE_REQUESTED, deleteUserPicture)
  yield takeLatest(DISABLE_ACCOUNT_SUCCESS, disableAccount)
  yield takeLatest(UPDATE_AVATAR_REQUESTED, updateAvatar)
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUESTED, cancelSubscription)
  yield takeLatest(REACTIVATE_SUBSCRIPTION_REQUESTED, reactivateSubscription)
  yield takeLatest(GET_INVOICES_REQUESTED, getInvoices)
  yield takeLatest(DOWNLOAD_INVOICE, downloadInvoice)
  yield takeLatest(SEND_CONTACT_MESSAGE_REQUESTED, sendContactMessage)
  yield takeLatest(INIT_USER_ADDRESS_REQUESTED, initUserAddress)
  yield takeLatest(GET_ELIGIBILITY_REQUESTED, getUserElligibility)
  yield takeLatest(GET_USER_PAYMENT_SCHEDULES_REQUESTED, fetchUserPaymentSchedules)
}

const queries = {
  getUserData: `${BASE_URL_WEB}/User`,
  getUserSubscription: `${BASE_URL_WEB}/User/subscription`,
  setBillingAdress: `${BASE_URL_WEB}/User/setBillingAddress`,
  getBillingAddress: `${BASE_URL_WEB}/User/GetUserBillingAddress`,
  updateNotifications: `${BASE_URL_WEB}/User/updateUserNotifications`,
  updateUserPassword: `${BASE_URL_WEB}/User/updateUserPassword`,
  updateUserInfo: `${BASE_URL_WEB}/User/updateUserProfile`,
  deleteUserPicture: `${BASE_URL_WEB}/User/deleteUserProfilePhoto`,
  disableAccount: `${BASE_URL_WEB}/User/disableAccount`,
  updateAvatar: `${BASE_URL_WEB}/User/updateUserProfilePhoto`,
  cancelSubscription: `${BASE_URL_WEB}/User/cancelSubscription`,
  reactivateSubscription: `${BASE_URL_WEB}/User/reactivateSubscription`,
  order: (take, skip) => `${BASE_URL_WEB}/Order/getInvoices?OrderBy=IssueDate&take=${take}&skip=${skip}&InvoiceType=1`,
  getOrderDetails: (id) => `${BASE_URL_WEB}/Order/getDetails?orderId=${id}`,
  sendContactMessage: `${BASE_URL_WEB}/User/sendContactMessage`,
  isEligibleForTrial: `${BASE_URL_WEB}/User/IsEligibleForTrial`,
  getPaymentSchedules: `${BASE_URL_WEB}/Order/PaymentSchedules?PaymentType=3&OrderedByAsc=true&OrderBy=Date`
}

const SET_TRIAL_STATE = 'SET_TRIAL_STATE'
const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
const GET_USER_FAILED = 'GET_USER_FAILED'
const USER_FETCH_REQUESTED = 'USER_FETCH_REQUESTED'
const USER_SUBSCRIPTION_FETCH_REQUESTED = 'USER_SUBSCRIPTION_FETCH_REQUESTED'
const GET_USER_SUBSCRIPTION_SUCCESS = 'GET_USER_SUBSCRIPTION_SUCCESS'
const GET_USER_SUBSCRIPTION_FAILED = 'GET_USER_SUBSCRIPTION_FAILED'
const SET_BILLING_ADRESS_SUCCESS = 'SET_BILLING_ADRESS_SUCCESS'
const SET_BILLING_ADRESS_FAILED = 'SET_BILLING_ADRESS_FAILED'
const BILLING_ADRESS_REQUESTED = 'BILLING_ADRESS_REQUESTED'
const GET_BILLING_ADRESS_REQUESTED = 'GET_BILLING_ADRESS_REQUESTED'
const GET_BILLING_ADRESS_SUCCESS = 'GET_BILLING_ADRESS_SUCCESS'
const GET_BILLING_ADRESS_FAILED = 'GET_BILLING_ADRESS_FAILED'
const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS'
const SET_PASSWORD_FAILED = 'SET_PASSWORD_FAILED'
const PASSWORD_REQUESTED = 'PASSWORD_REQUESTED'
const UPDATE_USER_INFO_REQUESTED = 'UPDATE_USER_INFO_REQUESTED'
const SET_USER_INFO_SUCCESS = 'SET_USER_INFO_SUCCESS'
const SET_USER_INFO_FAILED = 'SET_USER_INFO_FAILED'
const DISABLE_ACCOUNT_SUCCESS = 'DISABLE_ACCOUNT_SUCCESS'
const DISABLE_ACCOUNT_FAILED = 'DISABLE_ACCOUNT_FAILED'
const DELETE_USER_PICTURE_REQUESTED = 'DELETE_USER_PICTURE_REQUESTED'
const DELETE_USER_PICTURE_SUCCESS = 'DELETE_USER_PICTURE_SUCCESS'
const DELETE_USER_PICTURE_FAILED = 'DELETE_USER_PICTURE_FAILED'
const UPDATE_AVATAR_REQUESTED = 'UPDATE_AVATAR_REQUESTED'
const UPDATE_AVATAR_FAILED = 'UPDATE_AVATAR_FAILED'
const UPDATE_AVATAR_SUCCESS = 'UPDATE_AVATAR_SUCCESS'
const CANCEL_SUBSCRIPTION_REQUESTED = 'CANCEL_SUBSCRIPTION_REQUESTED'
const CANCEL_SUBSCRIPTION_SUCCEEDED = 'CANCEL_SUBSCRIPTION_SUCCEEDED'
const CANCEL_SUBSCRIPTION_FAILED = 'CANCEL_SUBSCRIPTION_FAILED'
const REACTIVATE_SUBSCRIPTION_REQUESTED = 'REACTIVATE_SUBSCRIPTION_REQUESTED'
const REACTIVATE_SUBSCRIPTION_SUCCEEDED = 'REACTIVATE_SUBSCRIPTION_SUCCEEDED'
const REACTIVATE_SUBSCRIPTION_FAILED = 'REACTIVATE_SUBSCRIPTION_FAILED'
const GET_INVOICES_REQUESTED = 'GET_INVOICES_REQUESTED'
const GET_INVOICES_SUCCEEDED = 'GET_INVOICES_SUCCEEDED'
const GET_INVOICES_FAILED = 'GET_INVOICES_FAILED'
const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE'
const DOWNLOAD_INVOICE_SUCCEEDED = 'DOWNLOAD_INVOICE_SUCCEEDED'
const DOWNLOAD_INVOICE_FAILED = 'DOWNLOAD_INVOICE_FAILED'
const SEND_CONTACT_MESSAGE_REQUESTED = 'SEND_CONTACT_MESSAGE_REQUESTED'
const SEND_CONTACT_MESSAGE_SUCCEEDED = 'SEND_CONTACT_MESSAGE_SUCCEEDED'
const SEND_CONTACT_MESSAGE_FAILED = 'SEND_CONTACT_MESSAGE_FAILED'
const GET_INVOICES_RESET_SUCCEEDED = 'GET_INVOICES_RESET_SUCCEEDED'
const UPDATE_PHONE_NUMBER_REQUESTED = 'UPDATE_PHONE_NUMBER_REQUESTED'
const SAVE_ALREADY_SIGNED_USERS = 'SAVE_ALREADY_SIGNED_USERS'
const INIT_USER_ADDRESS_REQUESTED = 'INIT_USER_ADDRESS_REQUESTED'
const INIT_USER_ADDRESS_SUCCESS = 'INIT_USER_ADDRESS_SUCCESS'
const INIT_USER_ADDRESS_FAILED = 'INIT_USER_ADDRESS_FAILED'
const GET_ELIGIBILITY_REQUESTED = 'GET_ELIGIBILITY_REQUESTED'
const GET_ELIGIBILITY_SUCCESS = 'GET_ELIGIBILITYS_SUCCESS'
const GET_ELIGIBILITY_FAILED = 'GET_ELIGIBILITY_FAILED'
const SET_USER_ELIGIBILITY_ACTION = 'SET_USER_ELIGIBILITY_ACTION'
const GET_USER_PAYMENT_SCHEDULES_REQUESTED = 'GET_USER_PAYMENT_SCHEDULES_REQUESTED'
const GET_USER_PAYMENT_SCHEDULES_SUCCESS = 'GET_USER_PAYMENT_SCHEDULES_SUCCESS'
const GET_USER_PAYMENT_SCHEDULES_FAILED = 'GET_USER_PAYMENT_SCHEDULES_FAILED'
