export const suggestionGroupStyle = theme => ({
  suggestionGroup: {
    width: 'calc(100% - 2px)',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.neutral[400],
    position: 'absolute',
    zIndex: 5,
    overflow: 'auto',
    borderRadius: 8,
    border: `1px solid ${theme.neutral[0]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.primary[1300],
      borderRadius: 4
    }
  }
})
