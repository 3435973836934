import { useRef, useState, useEffect } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import style from './style'
import {
  TypographyElement,
  Icon,
  Icons,
  Skeleton
} from 'UI'
import Text from './text.json'

const useStyle = createUseStyles(style)

const DescriptionSkeleton = ({ isMobile, desktopSkeletons }) => {
  return (
    isMobile
      ? <Skeleton lines={5} width={['40%', '100%']} height={20} margin='0 0 16px 0' />
      : (
        <>
          <Skeleton lines={1} width='40%' height={50} margin='0 0 16px 0' />
          <Skeleton lines={20} width='100%' height={24} margin='0 0 12px 0' />
          <Skeleton lines={1} width='70%' height={24} margin='0 0 12px 0' />
        </>)
  )
}

export default ({ Description, title, loading, noTitle, noExpand, collapsable, descriptionTextHeight = 130, isMobile, showMoreTextProps, margin }) => {
  const theme = useTheme()
  const [open, setOpen] = useState(!!noExpand)
  const ref = useRef()
  const { descriptionText, icon, shadow, description, container, showMore } = useStyle({ open, margin })
  const titleText = title || Text.title
  useEffect(() => {
    setOpen(!!noExpand)
  }, [Description, noExpand])
  return (
    loading
      ? <DescriptionSkeleton isMobile={isMobile} />
      : (
        <div className={container}>
          {!noTitle
            ? (
              <TypographyElement
                variant='heading2'
                spacing='0 0 24px 0'
                Component='h2'
              >
                {titleText}
              </TypographyElement>
              )
            : null}
          <div>
            <div className={descriptionText} style={{ height: open ? ref?.current?.clientHeight : descriptionTextHeight }}>
              <div ref={ref}>
                <div className={description} dangerouslySetInnerHTML={{ __html: Description }} />
              </div>
              <div className={shadow} />
            </div>
            {
              (!noExpand || collapsable) &&
                <TypographyElement
                  variant='button'
                  display='flex'
                  color={theme.primary[0]}
                  cursor='pointer'
                  handleClick={() => setOpen(!open)}
                  spacing='12px 0 0 0'
                  className={showMore}
                  {...showMoreTextProps}
                >
                  {open ? Text.showLess : Text.showMore}<Icon iconName={open ? Icons.roundedUp : Icons.roundedDown} style={icon} />
                </TypographyElement>
            }
          </div>
        </div>
        )
  )
}
