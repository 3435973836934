import { Helmet } from 'react-helmet'

export default ({ id }) =>
  id
    ? (
      <Helmet>
        <script>
          {
            `
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'user_id': '${id}'
              })
            `
          }
        </script>
        <script>
          {
            `
              window.dataLayer = window.dataLayer || [];
              dataLayer.push({
                'userId': '${id}'
              })
            `
          }
        </script>
      </Helmet>
      )
    : null
