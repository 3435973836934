import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import styles from './adCard.styles'
import classNames from 'classnames'
import TypographyElement from '../typographyElement/typographyElement'
import Skeleton from '../skeleton/skeleton'

const LoadingSkeleton = ({
  className,
  width,
  secondary,
  margin,
  tabletMargin,
  mobileMargin,
  noFlex
}) => {
  const theme = useTheme()
  const useStyle = createUseStyles(styles)
  const { root } = useStyle({
    width,
    shadowColor: theme.neutral[0],
    secondary,
    margin,
    tabletMargin,
    mobileMargin,
    noFlex
  })
  return (
    <div className={classNames(root, className)}>
      <Skeleton lines={1} width='100%' height={41} margin='0 0 12px 0' />
      <Skeleton lines={4} width='100%' height={31} gap={4} margin='0 0 16px 0' />
      <Skeleton lines={1} width='100%' height={54} />
    </div>
  )
}

const AdCard = ({ title, core, buttonLabel, onButtonClick, width, shadowColor, className, secondary, margin, tabletMargin, mobileMargin, noFlex, loading, href }) => {
  const theme = useTheme()
  const useStyle = createUseStyles(styles)
  const { root, button } = useStyle({ width, shadowColor, secondary, margin, tabletMargin, mobileMargin, noFlex })
  const Component = href ? 'a' : 'div'
  if (loading) {
    return (
      <LoadingSkeleton
        className={className}
        width={width}
        shadowColor={shadowColor}
        secondary={secondary}
        margin={margin}
        tabletMargin={tabletMargin}
        mobileMargin={mobileMargin}
        noFlex={noFlex}
      />
    )
  }

  return (
    <div className={classNames(root, className)}>
      <TypographyElement
        variant='heading5'
        spacing='0 0 12px 0'
        spacingTablet='0 0 16px 0'
        spacingMobile='0 0 16px 0'
        margin='0 -4px 0 0 '
        Component='h3'
      >
        {title}
      </TypographyElement>
      <TypographyElement variant='body1' spacing='0 0 16px 0'>{core}</TypographyElement>
      <Component
        onClick={(e) => {
          e.preventDefault()
          onButtonClick()
        }}
        className={button}
        {...href && { href, target: '_blank', rel: 'noopener noreferrer' }}
      >
        <TypographyElement fontWeight='bold' variant='button' spacing='16px 0' color={secondary ? theme.primary[800] : theme.primary[0]}>{buttonLabel}</TypographyElement>
      </Component>
    </div>
  )
}

AdCard.propTypes = {
  /** Title of the card */
  title: PropTypes.string,
  /** Core of the card */
  core: PropTypes.string,
  /** Label of the button */
  buttonLabel: PropTypes.string,
  /** Function to execute when button is clicked */
  onButtonClick: PropTypes.func,
  /** Width of the card */
  width: PropTypes.string,
  /** Color of the shadow */
  shadowColor: PropTypes.string,
  /** Classname of the card */
  className: PropTypes.string,
  /** Secondary color of the card */
  secondary: PropTypes.bool,
  /** Margin of the card */
  margin: PropTypes.string,
  /** Margin of the card on tablet */
  tabletMargin: PropTypes.string,
  /** Margin of the card on mobile */
  mobileMargin: PropTypes.string
}

export default AdCard
