import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import style from './teamSubscriptionHorizontalCard.style'
import { Paper, TypographyElement, Button } from '../..'
import { neutral, Icons, blue } from '../../constants'
import Icon from '../icon/icon'

const useStyle = createUseStyles(style)

const TeamSubscriptionHorizontalCard = ({
  title,
  firstParagraph,
  secondParagraph,
  infos,
  infos2,
  firstSubTitle,
  secendSubTitle,
  handleStartSubscriptionClick,
  startSubscriptionLabel,
  hideSwitchCurrency,
  phoneAction,
  phoneNumber
}) => {
  const { paper, header, body, infoClass, iconClass, titleClassName, infosClass } = useStyle({ hideSwitchCurrency })
  return (
    <Paper className={paper}>
      <div className={header}>
        <TypographyElement
          variant='heading3'
          fontSize='22px'
          fontWeight={900}
          color={blue[0]}
          spacing='25px 0px 16px 8px'
          margin='20px 0 0 -8px'
          spacingTablet='8px 0 0 8px'
          marginTablet='0 0 0 -8px'
          spacingMobile='8px 0 0 8px'
          marginMobile='0 0 10px -8px'
          className={titleClassName}
          Component='h3'
        >
          {title}
        </TypographyElement>
        <TypographyElement
          variant='body1'
          color={neutral[6]}
          fontWeight={500}
          lineHeight='22px'
        >
          {firstParagraph}
        </TypographyElement>
        <TypographyElement
          variant='body1'
          color={neutral[6]}
          fontWeight={500}
          lineHeight='22px'
        >
          {secondParagraph}
          <TypographyElement
            variant='body1'
            color={blue[0]}
            fontWeight={500}
            cursor='pointer'
            handleClick={phoneAction}
            display='inline'
            href={`tel:${phoneNumber}`}
            Component='a'
          >
            {` ${phoneNumber}`}
          </TypographyElement>
        </TypographyElement>
      </div>
      <div className={body}>
        <div className={infosClass}>
          <div>
            <TypographyElement
              variant='smallText'
              color={neutral[6]}
              fontWeight={800}
              spacing='0 0 8px 0'
            >
              {firstSubTitle}
            </TypographyElement>
            <div>
              {
                infos.map(info => (
                  <div className={infoClass} key={info.label}>
                    <Icon iconName={Icons.check} style={iconClass} />
                    <TypographyElement
                      variant='smallText'
                      color={neutral[4]}
                      fontWeight={500}
                      spacing='0px 8px 0px 0px'
                    >
                      {info.label}
                    </TypographyElement>
                    <TypographyElement
                      variant='smallText'
                      color={blue[0]}
                      fontWeight={500}
                      cursor='pointer'
                      handleClick={info.action}
                    >
                      {info.actionLabel}
                    </TypographyElement>
                  </div>
                ))
              }
            </div>
          </div>
          <div>
            <TypographyElement
              variant='smallText'
              color={neutral[6]}
              fontWeight={800}
              spacing='0 0 8px 0'
              spacingTablet='24px 0 12px 0'
              spacingMobile='24px 0 12px 0'
            >
              {secendSubTitle}
            </TypographyElement>
            <div>
              {
                infos2.map(info => (
                  <div className={infoClass} key={info.label}>
                    <Icon iconName={Icons.check} style={iconClass} />
                    <TypographyElement
                      variant='smallText'
                      color={neutral[4]}
                      fontWeight={500}
                      display='inline'
                    >
                      {info.label}
                      {
                        info.actionLabel &&
                          <TypographyElement
                            variant='smallText'
                            color={blue[0]}
                            fontWeight={500}
                            cursor='pointer'
                            handleClick={info.action}
                            display='inline'
                            Component='span'
                          >
                            {` ${info.actionLabel}`}
                          </TypographyElement>
                      }
                    </TypographyElement>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <Button
          label={startSubscriptionLabel}
          width='175px'
          height='54px'
          handleClick={handleStartSubscriptionClick}
          widthTablet='100%'
          widthMobile='100%'
          marginButtonTablet='16px 0 0 0'
          marginButtonMobile='16px 0 0 0'
        />
      </div>
    </Paper>
  )
}

TeamSubscriptionHorizontalCard.propTypes = {
  /** the big title */
  title: PropTypes.string,
  /** second Title */
  secondTitle: PropTypes.string,
  /** phone number */
  phoneNumber: PropTypes.string,
  /** its either currency1 or currency2 to change switch state */
  selectedCurrency: PropTypes.string,
  /** function that handle the currency change */
  handleChangeCurrency: PropTypes.func,
  /** label of the button start subscription */
  startSubscriptionLabel: PropTypes.string,
  /** function that handle the start subscription */
  handleStartSubscriptionClick: PropTypes.func,
  /** array of string informations */
  infos: PropTypes.array
}

export default TeamSubscriptionHorizontalCard
