import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { linkStyle } from './link.style'
import Icon from '../icon/icon'

const Link = (props) => {
  const useStyle = createUseStyles(linkStyle)
  const { link, icon } = useStyle(props)
  return (
    <a
      {...(props.url && props.url !== '' ? { href: props.url } : null)}
      onClick={props.handleClick}
      target={props.openOnNewTab ? '_blank' : '_self'}
      className={link}
    >
      {props.iconName && <Icon iconName={props.iconName} style={icon} />}
      {props.children}
    </a>
  )
}

Link.propTypes = {
  /** the color of the Link */
  color: PropTypes.string,
  /** the name of the icon from te library */
  iconName: PropTypes.string,
  /** the url */
  url: PropTypes.string,
  /** if set to true it opens the link on a new tab */
  openOnNewTab: PropTypes.bool,
  handleClick: PropTypes.func,
  /** display css */
  display: PropTypes.string
}

export default Link
