import { MediaQueries } from 'UI/'

export default (theme) => ({
  containerClass: {
    marginBottom: 128
  },
  desktopHeaderRow: {
    marginBottom: 64
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: '16px 20px',
    marginBottom: 16
  },
  paper2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 20px',
    marginTop: 16
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    marginRight: 8
  },
  socialIconsContainer: {
    height: 26,
    width: 26,
    backgroundColor: theme.secondary[0],
    borderRadius: 13,
    marginRight: 7,
    cursor: 'pointer',
    '& :first-child': {
      color: theme.primary[800]
    }
  },
  centerIconClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: 12
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    marginBottom: 64
  },
  certificateStyle: {
    '& > div': {
      height: '100%',
      width: '100%'
    },
    display: 'flex'
  },
  image: {
    height: 421,
    width: '100%',
    zIndex: '-1'
  },
  name: {
    color: theme.primary[0],
    position: 'absolute',
    top: 260
  },
  certificateContainerStyle: {
    width: '80%'
  },
  title: {
    position: 'absolute',
    top: 308
  },
  date: {
    position: 'absolute',
    top: 360
  },
  link: {
    position: 'absolute',
    top: 480,
    left: '83%',
    width: 220,
    cursor: 'pointer'
  },
  [`${MediaQueries.tablet}`]: {
    containerClass: {
      marginBottom: 64
    },
    certificateContainerStyle: {
      width: '100%'
    },
    link: {
      position: 'absolute',
      top: '16.3%',
      left: '69%',
      width: 220,
      cursor: 'pointer'
    },
    certificateStyle: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      marginLeft: 0
    },
    image: {
      height: '100%',
      width: '100%',
      zIndex: '-1'
    },
    name: {
      color: theme.primary[0],
      position: 'absolute',
      top: '9%'
    },
    title: {
      position: 'absolute',
      top: '10.7%'
    },
    date: {
      position: 'absolute',
      top: '12.5%'
    },
    subContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 0
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      padding: '16px 20px',
      marginTop: 16
    },
    paper2: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      padding: '16px 16px',
      marginTop: 16,
      marginBottom: 64
    }
  },
  [`${MediaQueries.mobile}`]: {
    containerClass: {
      marginBottom: 64
    },
    certificateContainerStyle: {
      width: '100%'
    },
    link: {
      position: 'absolute',
      top: '12.7%',
      left: '74%',
      width: 90,
      cursor: 'pointer'
    },
    certificateStyle: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      marginLeft: 0
    },
    image: {
      height: '100%',
      width: '100%',
      zIndex: '-1'
    },
    name: {
      color: theme.primary[0],
      position: 'absolute',
      top: '7.5%'
    },
    title: {
      position: 'absolute',
      top: '9%'
    },
    date: {
      position: 'absolute',
      top: '10%'
    },
    subContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 0
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      padding: '16px 20px',
      marginTop: 16
    },
    paper2: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      padding: '16px 20px',
      marginTop: 16,
      marginBottom: 64
    }
  }
})
