import star from '../../assets/media/star.svg'
import secondaryStar from '../../assets/media/secondaryStar.svg'
import starBlank from '../../assets/media/starBlank.svg'

export const RateStarsStyle = {
  rateStarsGroup: {
    margin: ({ margin }) => margin,
    height: ({ height }) => height,
    position: 'relative',
    width: ({ width }) => width,
    '&::before': {
      backgroundImage: `url("${starBlank}")`,
      content: '""',
      backgroundRepeat: 'repeat-x',
      backgroundSize: ({ height }) => height,
      bottom: 0,
      display: 'block',
      height: ({ height }) => height,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: ({ width }) => width
    }
  },
  starFill: {
    width: ({ pourcentage = 0 }) => pourcentage,
    display: 'block',
    height: ({ height }) => height,
    position: 'absolute',
    overflow: 'hidden',
    '&::before': {
      backgroundImage: ({ secondary }) => `url("${secondary ? secondaryStar : star}")`,
      content: '""',
      backgroundRepeat: 'repeat-x',
      backgroundSize: ({ height }) => height,
      bottom: 0,
      display: 'block',
      height: ({ height }) => height,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: ({ width }) => width
    }
  }
}
