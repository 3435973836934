import { Colors, Typography } from '../../constants'

export const breadcrumbStyle = {
  breadcrumbGroup: {
    display: 'flex',
    '& div:last-child div': {
      display: 'none'
    },
    '& li:last-child > a > span': {
      cursor: 'default'
    }
  },
  pathAndIcon: {
    display: 'flex',
    flexDirection: 'row'
  },
  breadcrumbLink: {
    textDecoration: 'none',
    cursor: 'pointer'
  },
  icon: {
    marginTop: 2,
    width: 18,
    height: 18,
    backgroundColor: Colors.core.roundedBorder,
    color: Colors.core.icon,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: Typography.minimizedText,
    margin: [2, 8, 0, 8]
  },
  path: {
    fontFamily: Typography.fontFamily,
    fontSize: Typography.minimizedText,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: Colors.text.medium,
    cursor: 'pointer'
  }
}
