import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { horizontalCardStyle } from './horizontalCard.style'
import Icon from '../icon/icon'
import RateStars from '../rateStars/rateStars'
import ProgressionRing from '../progressionRing/progressionRing'
import TypographyElement from '../typographyElement/typographyElement'
import classNames from 'classnames'
import {
  Icons,
  RateStarSize,
  green,
  Spacing,
  typographyTypes,
  neutral
} from '../../constants'
import { formatMinutesDuration } from '../../constants/formatters'
import CheckMark from '../checkMark'
import Skeleton from '../skeleton/skeleton'

const HorizontalCard = ({ children, screen, ContentComponent, loading, dataTest, ...props }) => {
  const useStyle = createUseStyles(horizontalCardStyle)
  const { cardGroup, CardImage, progressionRing, infosAndActions, CardInfo, titleAndCartLabel, CardTitle, title, infos, ratingText, users, userIcon, userText, time, clockIcon, clockText, rate } = useStyle({ loading, ...props })
  const titleText = `${props.title.substr(0, 90)}${props.title.length > 90 ? '...' : ''}`
  return (
    <div className={classNames(cardGroup, props.className)} onClick={props.onCardClick}>
      <div className={CardImage}>
        {
          props.progress &&
          (
            <div className={progressionRing}>
              <ProgressionRing
                radius={55 / 2}
                stroke={2}
                color='white'
                backgroundColor='rgba(255,255,255,0.25)'
                progress={props.progress || 0}
              >
                <TypographyElement
                  variant='heading4'
                  weight='semibold'
                  align='center'
                  color='white'
                >{props.progress}%
                </TypographyElement>
              </ProgressionRing>
            </div>
          )
        }
      </div>
      <div className={infosAndActions}>
        <div className={CardInfo}>
          <div className={titleAndCartLabel}>
            {
              loading
                ? <Skeleton lines={1} wrapperWidth='100%' width='100%' height={20} />
                : (
                  <>
                    <TypographyElement
                      dataTest={dataTest && `${dataTest}-title`}
                      fontSize='16px'
                      fontWeight='700'
                      lineHeight='20px'
                      variant={typographyTypes.heading4}
                      fontSizeTablet='10px'
                      margin={Spacing(0, 3, 0, 0)}
                      fontWeightTablet={700}
                      lineHeightTablet='13px'
                      variantTablet={typographyTypes.extraSmallText}
                      marginTablet={Spacing(0, 3, 0, 0)}
                      fontSizeMobile='10px'
                      fontWeightMobile={700}
                      lineHeightMobile='13px'
                      variantMobile={typographyTypes.extraSmallText}
                      align='left'
                      display='-webkit-box'
                      className={CardTitle}
                      Component={props.titleAs || 'p'}
                    >{titleText}
                    </TypographyElement>
                    {
                      props.cartLabel &&
                      (
                        <div className={title}>
                          <CheckMark color={green[4]} />
                          <TypographyElement
                            margin={Spacing(0, 0, 0, 2)}
                            fontSize='11px'
                            fontWeight={600}
                            lineHeight='14px'
                            variant={typographyTypes.smallText}
                            color={neutral[7]}
                            marginTablet={() => Spacing(0, 0, 0, 2)}
                            fontSizeTablet='8px'
                            fontWeightTablet={400}
                            lineHeightTablet='10px'
                            variantTablet={typographyTypes.smallText}
                            marginMobile={() => Spacing(0, 0, 0, 2)}
                            fontSizeMobile='11px'
                            fontWeightMobile={600}
                            lineHeightMobile='14px'
                            variantMobile={typographyTypes.smallText}
                            display='inline'
                          >
                            {props.cartLabel}
                          </TypographyElement>
                        </div>
                      )
                    }
                  </>
                  )
            }
          </div>
          {
            loading
              ? <Skeleton lines={1} wrapperWidth='100%' width='40%' height={20} />
              : props.subtitle
                ? (
                  <div className={infos}>
                    <TypographyElement
                      color={neutral[5]}
                      fontSize='14px'
                      fontWeight={600}
                      lineHeight='20px'
                      variant={typographyTypes.smallText}
                      className={ratingText}
                    >{props.subtitle}
                    </TypographyElement>
                  </div>
                  )
                : ContentComponent
                  ? <ContentComponent />
                  : (
                    <div className={infos}>
                      <div className={users}>
                        <Icon iconName={Icons.user} style={userIcon} />
                        <TypographyElement
                          fontSize='12px'
                          fontWeight={400}
                          lineHeight='15px'
                          variant={typographyTypes.smallText}
                          color={neutral[5]}
                          fontSizeTablet='8px'
                          lineHeightTablet='10px'
                          variantTablet={typographyTypes.extraSmallText}
                          fontSizeMobile='8px'
                          lineHeightMobile='10px'
                          variantMobile={typographyTypes.extraSmallText}
                          className={userText}
                        >{props.usersEnrolled}
                        </TypographyElement>
                      </div>
                      <div className={time}>
                        <Icon iconName={Icons.clock} style={clockIcon} />
                        <TypographyElement
                          fontSize='12px'
                          fontWeight={400}
                          lineHeight='15px'
                          variant={typographyTypes.smallText}
                          color={neutral[5]}
                          fontSizeTablet='8px'
                          lineHeightTablet='10px'
                          variantTablet={typographyTypes.extraSmallText}
                          fontSizeMobile='8px'
                          lineHeightMobile='10px'
                          variantMobile={typographyTypes.extraSmallText}
                          className={clockText}
                        >{formatMinutesDuration(props.duration)}
                        </TypographyElement>
                      </div>
                      <div className={rate}>
                        <RateStars
                          value={props.rate}
                          size={RateStarSize.small}
                        />
                        <TypographyElement
                          fontSize='12px'
                          fontWeight={400}
                          lineHeight='15px'
                          variant={typographyTypes.smallText}
                          color={neutral[5]}
                          fontSizeTablet='8px'
                          lineHeightTablet='10px'
                          variantTablet={typographyTypes.extraSmallText}
                          fontSizeMobile='8px'
                          lineHeightMobile='10px'
                          variantMobile={typographyTypes.extraSmallText}
                          className={ratingText}
                        >({props.userRated})
                        </TypographyElement>
                      </div>
                    </div>
                    )
          }
        </div>
        <div className={props.childrenClassName}>
          {
            loading
              ? null
              : children
          }
        </div>
      </div>
    </div>
  )
}

HorizontalCard.propTypes = {
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  /** when user click on the card the method fired up */
  onCardClick: PropTypes.func,
  /** the cover image of the card */
  image: PropTypes.string,
  /** product title */
  title: PropTypes.string,
  /** percentage of the progress made */
  progress: PropTypes.number,
  /** how many people did buy the product */
  usersEnrolled: PropTypes.number,
  /** duration of the product */
  duration: PropTypes.number,
  /** number of stars given to the product as rate */
  rate: PropTypes.number,
  /** how many people rated the product */
  userRated: PropTypes.number,
  /** the title of the card as a component */
  titleAs: PropTypes.string
}

HorizontalCard.defaultProps = {
  title: ''
}

export default HorizontalCard
