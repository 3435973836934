import React from 'react'

const TableHead = (props) => {
  return (
    <thead className={props.className}>
      {props.children}
    </thead>
  )
}

export default TableHead
