import { MediaQueries } from '../..'

export default (theme) => ({
  root: {
    width: ({ width }) => width || '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 24px 24px 22px',
    flex: ({ noFlex }) => noFlex ? null : 1,
    borderRadius: 8,
    borderLeft: `solid 1px ${theme.neutral[0]}`,
    borderRight: `solid 1px ${theme.neutral[0]}`,
    borderBottom: `solid 1px ${theme.neutral[0]}`,
    boxShadow: ({ shadowColor }) => `0 -2px 0 0 ${shadowColor || theme.extra.pink}`,
    margin: ({ margin }) => margin || null
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: ({ secondary }) => secondary ? theme.secondary[0] : theme.neutral[0],
    borderRadius: 8,
    textDecoration: 'none',
    marginTop: 'auto'
  },
  [`${MediaQueries.tablet}`]: {
    root: {
      padding: '24px 24px 26px 22px',
      margin: ({ tabletMargin }) => tabletMargin || null
    }
  },
  [`${MediaQueries.mobile}`]: {
    root: {
      padding: '24px 24px 26px 22px',
      margin: ({ mobileMargin }) => mobileMargin || null
    }
  }
})
