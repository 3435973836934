import { Col, Container, Paper, Row, Steps } from 'UI'
import { smallLogo } from 'assets'
import { createUseStyles } from 'react-jss'
import style from './style'
import { HomePath, OnBoardingFirstPath, OnBoardingSecondPath, PhoneValidationPath, InvitedPasswordSetPath } from 'navigation/Routes'
import { Switch, Route, useLocation } from 'react-router-dom'
import Step1 from './Step1'
import Step2 from './Step2'
import InvitedPassword from './InvitedPassword'
import PhoneValidation from 'components/common/PhoneValidation'
import { useState } from 'react'
import { OnboardingVideos } from 'constants/'
import Text from './text.json'

const useStyle = createUseStyles(style)

const getStep = (pathname) => {
  switch (true) {
    case pathname.includes(OnBoardingFirstPath):
      return 1
    case pathname.includes(OnBoardingSecondPath):
      return 2
    default:
      return 1
  }
}

export default ({ b2bNewUser, goTo }) => {
  const { container, paperStyle, logo, stepsClass } = useStyle()
  const location = useLocation()
  const { pathname } = location
  const step = getStep(pathname)
  const [phone, setPhone] = useState('')
  return (
    <Container className={container}>
      <Row>
        <Col direction='row' grid={12} pos='center' posTablet='center' posMobile='center'>
          <img src={smallLogo} alt='logo' className={logo} />
        </Col>
      </Row>
      <Row className={stepsClass}>
        <Col grid={6} start={4} mgrid={12} sgrid={12} startTablet={1} startMobile={1}>
          <Steps
            stepWidth={130}
            step={step}
            labels={Text.steps}
            handleStepClick={() => {}}
          />
        </Col>
      </Row>
      <Row>
        <Col pos='center' grid={8} start={3} mgrid={12} sgrid={12} takeGapLeft takeGapRight>
          <Paper noHover className={paperStyle}>
            <Switch>
              <Route exact path={OnBoardingFirstPath}>
                <Step1
                  b2bNewUser={b2bNewUser}
                  nextStep={() => !b2bNewUser ? goTo(OnBoardingSecondPath) : goTo(InvitedPasswordSetPath, { validateInfos: true })}
                  goToValidationPhone={phoneNumber => {
                    goTo(PhoneValidationPath)
                    setPhone(phoneNumber)
                  }}
                />
              </Route>
              <Route exact path={PhoneValidationPath}>
                <PhoneValidation
                  hideAlphormLogo
                  phone={phone}
                  cancel={() => goTo(OnBoardingFirstPath)}
                  submit={() => goTo(OnBoardingFirstPath)}
                />
              </Route>
              <Route exact path={InvitedPasswordSetPath}>
                <InvitedPassword
                  nextStep={() => goTo(OnBoardingSecondPath)}
                />
              </Route>
              <Route exact path={OnBoardingSecondPath}>
                <Step2
                  b2bNewUser={b2bNewUser}
                  nextStep={() => goTo(HomePath)}
                  videoLink={OnboardingVideos.BasicSubscriber}
                />
              </Route>
            </Switch>
          </Paper>
        </Col>
      </Row>
    </Container>
  )
}
