import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { textInputStyle } from './textInput.style'
import IconAndMessage from '../iconAndMessage/iconAndMessage'
import { Icons, textColorMap } from '../../constants'
import { InputIcon } from '../..'
import classNames from 'classnames'
import TypographyElement from '../typographyElement/typographyElement'

const iconMap = {
  error: Icons.error,
  success: Icons.success
}

const TextInput = (props) => {
  const theme = useTheme()
  const useStyle = createUseStyles(textInputStyle)
  const classes = useStyle(props)
  return (
    <div className={classNames(classes.inputGroup, props.className)}>
      {props.label &&
      (
        <TypographyElement
          color={textColorMap(theme, props.status)}
          spacing='0 0 9px 0'
          variant='label'
          {...props.typography}
        >{props.label}
        </TypographyElement>
      )}
      <input
        readOnly={props.readOnly}
        disabled={props.disabled}
        value={props.value}
        id={props.id}
        name={props.name}
        className={classNames(classes.input, props.textInputClassName)}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        onBlur={props.onHandleBlur}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
        onKeyUp={props.onKeyUp}
        onClick={props.onClick}
        data-test={props.dataTest}
      />
      {
        props.status
          ? props.message
              ? (
                <IconAndMessage
                  dataTest={props.ErrorMessageDataTest}
                  background
                  message={props.message}
                  iconName={Icons[props.status]}
                  status={props.status}
                />
                )
              : !props.noIcon
                  ? <InputIcon iconName={iconMap[props.status]} color={textColorMap(theme, props.status)} />
                  : null
          : null
      }
    </div>
  )
}

TextInput.propTypes = {
  readOnly: PropTypes.bool,
  /** the state of the input */
  status: PropTypes.oneOf(['info', 'success', 'error', 'text']),
  /** the hint message that appears under the input */
  message: PropTypes.string,
  /** the placeholder explanation text to be displayed when input not filled yet */
  placeholder: PropTypes.string.isRequired,
  /**  value by default shiiped with the input */
  value: PropTypes.string,
  /**  input unique identifier */
  id: PropTypes.string,
  /**  the control label text to be displayed */
  label: PropTypes.string,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** the method that controls the blur of the input */
  handleBlur: PropTypes.func,
  /** space to be left on the bottom */
  gutterBottom: PropTypes.number,
  /** the test Id of the input for end to end testing */
  dataTest: PropTypes.string,
  /** the test Id of the error message for end to end testing */
  ErrorMessageDataTest: PropTypes.string
}

TextInput.defaultProps = {
  message: '',
  placeholder: 'input placeholder',
  disabled: false
}

export default TextInput
