const appStore = 'https://static.alphorm.com/prod/assets/images/site/appstore.svg'
const certificateImg = 'https://static.alphorm.com/prod/assets/images/site/certificate.png'
const appDesktop = 'https://static.alphorm.com/prod/assets/images/site/desktop.svg'
const googlePlay = 'https://static.alphorm.com/prod/assets/images/site/google_play.svg'
const backgroundFooterLeft = 'https://static.alphorm.com/prod/assets/images/site/bg-footer-left.svg'
const backgroundFooterRight = 'https://static.alphorm.com/prod/assets/images/site/bg-footer-right.svg'
const capgemini = 'https://static.alphorm.com/prod/assets/images/site/capgemini.png'
const exampleCategory = 'https://static.alphorm.com/prod/assets/images/site/exampleCategoryImage.svg'
const heroIllustrationHomePage = 'https://static.alphorm.com/prod/assets/images/site/hero-illustration-homePage.svg'
const smallLogo = 'https://static.alphorm.com/prod/assets/images/site/logo-header-small.svg'
const largeLogo = 'https://static.alphorm.com/prod/assets/images/site/logo-large.svg'
const masterCardLogo = 'https://static.alphorm.com/prod/assets/images/site/mastercard-logo.svg'
const paypalLogo = 'https://static.alphorm.com/prod/assets/images/site/paypal-logo.svg'
const visaLogo = 'https://static.alphorm.com/prod/assets/images/site/visa-logo.svg'
const neuronesIt = 'https://static.alphorm.com/prod/assets/images/site/neurones-it.png'
const paris = 'https://static.alphorm.com/prod/assets/images/site/paris.png'
const safran = 'https://static.alphorm.com/prod/assets/images/site/safran.png'
const sncf = 'https://static.alphorm.com/prod/assets/images/site/sncf.png'
const supinfo = 'https://static.alphorm.com/prod/assets/images/site/supinfo.png'
const volkswagen = 'https://static.alphorm.com/prod/assets/images/site/volkswagen.png'
const mobileApp = 'https://static.alphorm.com/prod/assets/images/site/img-homepage-app-pro.svg'
const pageNotFound = 'https://static.alphorm.com/prod/assets/images/site/web-404-page-illustration.svg'
const thankYouForContact = 'https://static.alphorm.com/prod/assets/images/site/web-contact-us-thank-you-page-illustration.png'
const backgroundHeaderTrainer = 'https://static.alphorm.com/prod/assets/images/site/img-banner-devenir-formateur.png'
const cancellatonStep1 = 'https://static.alphorm.com/prod/assets/images/site/web-cancellation-1.svg'
const cancellatonStep2 = 'https://static.alphorm.com/prod/assets/images/site/web-cancellation-2.svg'
const entrepriseHeroIllustration = 'https://static.alphorm.com/prod/assets/images/site/web-entreprise-page-hero-illustration.svg'
const entrepriseSectionBackground = 'https://static.alphorm.com/prod/assets/images/site/img-section-entreprise.png'
const backgroundPricingCards = 'https://static.alphorm.com/prod/assets/images/site/backgroundWhitePricingCards.png'
const solutionsClient = 'https://static.alphorm.com/prod/assets/images/site/img-solutionsClient.jpg'
const anonymeClient = 'https://static.alphorm.com/prod/assets/images/site/img-anonymClient.png'
const bergeracClient = 'https://static.alphorm.com/prod/assets/images/site/img-bergeracClient.jpg'
const dataDockeEntreprise = 'https://static.alphorm.com/prod/assets/images/site/dataDocke.png'
const backgroundEntrepriseShape1 = 'https://static.alphorm.com/prod/assets/images/site/bg-entreprise-shape-1.svg'
const backgroundEntrepriseShape2 = 'https://static.alphorm.com/prod/assets/images/site/bg-entreprise-shape-2.svg'
const happyLearnerEntreprise = 'https://static.alphorm.com/prod/assets/images/site/web-entreprise-learner-illustration.svg'
const aktoLogo = 'https://static.alphorm.com/prod/assets/images/site/entrepriseFinance/aktoLogo.png'
const atlasLogo = 'https://static.alphorm.com/prod/assets/images/site/entrepriseFinance/atlasLogo.png'
const opco2iLogo = 'https://static.alphorm.com/prod/assets/images/site/entrepriseFinance/opco2iLogo.png'
const opcoepLogo = 'https://static.alphorm.com/prod/assets/images/site/entrepriseFinance/opcoepLogo.png'
const opcocommerceLogo = 'https://static.alphorm.com/prod/assets/images/site/entrepriseFinance/opcocommerceLogo.png'
const opcoSanteLogo = 'https://static.alphorm.com/prod/assets/images/site/entrepriseFinance/opcoSanteLogo.png'
const currency = 'https://static.alphorm.com/prod/assets/images/site/currency.svg'
const megaphone = 'https://static.alphorm.com/prod/assets/images/site/megaphone.svg'
const people = 'https://static.alphorm.com/prod/assets/images/site/people.svg'
const chatBubles = 'https://static.alphorm.com/prod/assets/images/site/chatBubles.svg'
const checkmark = 'https://static.alphorm.com/prod/assets/images/site/checkmark.svg'
const noteBook = 'https://static.alphorm.com/prod/assets/images/site/noteBook.svg'
const videoPlayer = 'https://static.alphorm.com/prod/assets/images/site/videoPlayer.svg'
const underMaintenance = 'https://static.alphorm.com/prod/assets/images/site/web-under-maintenance-page-illustration.png'
const cpfHeaderBackground = 'https://static.alphorm.com/prod/assets/images/site/cpfBackgroundHeader.png'
const emptyCart = 'https://static.alphorm.com/prod/assets/images/site/empty-cart-illustration.svg'
const thankYouForBuying = 'https://static.alphorm.com/prod/assets/images/site/thank-you-for-buying-illustration.svg'
const fundingIllustration = 'https://static.alphorm.com/prod/assets/images/site/web-financement-illustration.svg'
const fundingEntreprises = 'https://static.alphorm.com/prod/assets/images/site/icon-entreprises.svg'
const fundingParticulier = 'https://static.alphorm.com/prod/assets/images/site/icon-particuliers.svg'
const fundingEntreprisesAndParticulier = 'https://static.alphorm.com/prod/assets/images/site/icon-entreprises-particuliers.svg'
const homeBackgroundMobile2 = 'https://static.alphorm.com/prod/assets/images/site/home-web-mobile-2.svg'
const becomeTrainerBackgroundMobile1 = 'https://static.alphorm.com/prod/assets/images/site/static-page-background-1.svg'
const becomeTrainerBackgroundMobile2 = 'https://static.alphorm.com/prod/assets/images/site/static-page-background-2.svg'
const becomeTrainerBackgroundMobile3 = 'https://static.alphorm.com/prod/assets/images/site/static-page-background-3.svg'
const pricingBackgroundMobile1 = 'https://static.alphorm.com/prod/assets/images/site/mobile_pricing_shape_01.svg'
const pricingBackgroundMobile2 = 'https://static.alphorm.com/prod/assets/images/site/mobile_pricing_shape_02.svg'
const learningCertificats = 'https://static.alphorm.com/prod/assets/images/site/learningCertificats.svg'
const learningFavorits = 'https://static.alphorm.com/prod/assets/images/site/learningFavorits.svg'
const learningHistory = 'https://static.alphorm.com/prod/assets/images/site/learningHistory.svg'
const learningMyCourses = 'https://static.alphorm.com/prod/assets/images/site/learningMyCourses.svg'
const learningRecommended = 'https://static.alphorm.com/prod/assets/images/site/learningRecommended.svg'
const learningCertificatsEmpty = 'https://static.alphorm.com/prod/assets/images/site/learningCertificatsEmpty.svg'
const learningMyCoursesEmpty = 'https://static.alphorm.com/prod/assets/images/site/learningMyCoursesEmpty.svg'
const learningFavoritsEmpty = 'https://static.alphorm.com/prod/assets/images/site/learningFavoritsEmpty.svg'
const learningHistoryEmpty = 'https://static.alphorm.com/prod/assets/images/site/learningHistoryEmpty.svg'
const fundingMobileBackground = 'https://static.alphorm.com/prod/assets/images/site/fundingMobileBackground.svg'
const fundingMobileBackground2 = 'https://static.alphorm.com/prod/assets/images/site/fundingMobileBackground2.svg'
const fundingMobileBackground3 = 'https://static.alphorm.com/prod/assets/images/site/fundingMobileBackground3.svg'
const OpcoMobileBackground = 'https://static.alphorm.com/prod/assets/images/site/OpcoMobileBackground.svg'
const OpcoMobileBackground2 = 'https://static.alphorm.com/prod/assets/images/site/OpcoMobileBackground2.svg'
const poleMobileBackground = 'https://static.alphorm.com/prod/assets/images/site/poleMobileBackground.svg'
const poleMobileBackground2 = 'https://static.alphorm.com/prod/assets/images/site/poleMobileBackground2.svg'
const poleMobileBackground3 = 'https://static.alphorm.com/prod/assets/images/site/poleMobileBackground3.svg'
const checkoutSubscriptionShape = 'https://static.alphorm.com/prod/assets/images/site/checkoutSubscriptionShape.png'
const maintenanceRightShape = 'https://static.alphorm.com/prod/assets/images/site/maintenanceRightShape.svg'
const maintenanceLeftShape = 'https://static.alphorm.com/prod/assets/images/site/maintenanceLeftShape.svg'
const maintenanceShapeBackground = 'https://static.alphorm.com/prod/assets/images/site/maintenanceShapeBackground.svg'
const onboardingBackground = 'https://static.alphorm.com/prod/assets/images/site/bg-onboarding-full-page.svg'
const alphormLogoWhite = 'https://static.alphorm.com/prod/assets/images/site/alphorm-logo-white.svg'
const onboardingIntrest = 'https://static.alphorm.com/prod/assets/images/site/onboarding-centre-interet.svg'
const onboardingFinance = 'https://static.alphorm.com/prod/assets/images/site/onboarding-financement.svg'
const onboardingPassword = 'https://static.alphorm.com/prod/assets/images/site/onboarding-mot-de-passe.svg'
const onboardingOffres = 'https://static.alphorm.com/prod/assets/images/site/onboarding-offres.svg'
const onboardingInfo = 'https://static.alphorm.com/prod/assets/images/site/onboarding-profil-informations.svg'
const onboardingFreePeriod = 'https://static.alphorm.com/prod/assets/images/site/onboarding-alphorm-gratuite-step-illustration.svg'
const onboardingMyAccount = 'https://static.alphorm.com/prod/assets/images/site/onboarding-mon-compte-formation.png'
const unfollowCourse = 'https://static.alphorm.com/prod/assets/images/site/unfollowCourse.svg'
const paymentMethod = 'https://static.alphorm.com/prod/assets/images/site/paymentMethod.svg'
const paymentMethodVerified = 'https://static.alphorm.com/prod/assets/images/site/paymentMethodVerified.svg'
const confirmReactivation = 'https://static.alphorm.com/prod/assets/images/site/confirmReactivation.svg'
const mastercardIcon = 'https://static.alphorm.com/prod/assets/images/site/mastercardIcon.png'
const paypalIcon = 'https://static.alphorm.com/prod/assets/images/site/paypalIcon.svg'
const visaIcon = 'https://static.alphorm.com/prod/assets/images/site/visaIcon.png'
const loader = 'https://static.alphorm.com/prod/assets/images/site/loader-all-projects.svg'
const bgPricingCard = 'https://static.alphorm.com/prod/assets/images/site/bgPricingCard.svg'
const bgPricingCardWhite = 'https://static.alphorm.com/prod/assets/images/site/bgPricingCardWhite.svg'
const bgSubscriptionB2BCard = 'https://static.alphorm.com/prod/assets/images/site/bgSubscriptionB2BCard.svg'
const onboardingLicence = 'https://static.alphorm.com/prod/assets/images/site/onboardingLicence.svg'
const boostMySkillsIllustration = 'https://static.alphorm.com/prod/assets/images/site/boost-my-skills-illustration.svg'
const certifyMySkillsIllustration = 'https://static.alphorm.com/prod/assets/images/site/certify-my-skills-illustration.svg'
const motivateTeamIllustration = 'https://static.alphorm.com/prod/assets/images/site/motivate-teams-illustration.svg'
const validationPhoneNumber = 'https://static.alphorm.com/prod/assets/images/site/validationPhoneNumber.svg'
const satisfiedOrRefundedBadge = 'https://static.alphorm.com/prod/assets/images/site/satisfied-or-refunded-badge.svg'
const screenFeaturesOffline1 = 'https://static.alphorm.com/prod/assets/images/site/screen-features-offline-1.png'
const screenFeaturesOffline2 = 'https://static.alphorm.com/prod/assets/images/site/screen-features-offline-2.png'
const screenFeaturesOffline3 = 'https://static.alphorm.com/prod/assets/images/site/screen-features-offline-3.png'
const laptopHeroOffline = 'https://static.alphorm.com/prod/assets/images/site/laptop-hero-offline.png'
const laptopHeroOfflineMobile = 'https://static.alphorm.com/prod/assets/images/site/laptop-hero-offline-mobile.png'
const downloadButtonLinux = 'https://static.alphorm.com/prod/assets/images/site/download-button-linux.png'
const downloadButtonMacOS = 'https://static.alphorm.com/prod/assets/images/site/download-button-macos.png'
const downloadButtonWindows = 'https://static.alphorm.com/prod/assets/images/site/download-button-windows.png'
const downloadMobileButtonLinux = 'https://static.alphorm.com/prod/assets/images/site/download-mobile-button-linux.png'
const downloadMobileButtonMacOS = 'https://static.alphorm.com/prod/assets/images/site/download-mobile-button-macos.png'
const downloadMobileButtonWindows = 'https://static.alphorm.com/prod/assets/images/site/download-mobile-button-windows.png'
const bulletPointIcon = 'https://static.alphorm.com/prod/assets/images/site/bullet-point-icon.svg'
const offlineTopbarBlueShape = 'https://static.alphorm.com/prod/assets/images/site/topbar-blue-shape-offline.svg'
const bgLaptopShapeOfflineMobile = 'https://static.alphorm.com/prod/assets/images/site/bg-laptop-shape-offline-mobile.svg'
const smallBGLaptopOvalLPOffline = 'https://static.alphorm.com/prod/assets/images/site/small-bg-laptop-oval-LP-offline.svg'
const bgScreensOvalLPOffline = 'https://static.alphorm.com/prod/assets/images/site/bg-screens-oval-LP-offline.svg'
const logoApps = 'https://static.alphorm.com/prod/assets/images/site/logoApps.png'
const freeCoursesCategoryShape = 'https://static.alphorm.com/prod/assets/images/site/free-courses-category-shape.svg'
const greenCheckMark = 'https://static.alphorm.com/prod/assets/images/site/free-courses-LP-checkmark.svg'
const freeCoursesCategoryLPBackground = 'https://static.alphorm.com/prod/assets/images/site/free-courses-category-LP-bg.png'
const onboardingAlphorm = 'https://static.alphorm.com/prod/assets/images/site/onboardingAlphorm.svg'
const happyFaceIcon = 'https://static.alphorm.com/prod/assets/images/site/happy-face-icon.svg'
const happyFaceColoredIcon = 'https://static.alphorm.com/prod/assets/images/site/happy-face-colored-icon.svg'
const neutralFaceIcon = 'https://static.alphorm.com/prod/assets/images/site/neutral-face-icon.svg'
const neutralFaceColoredIcon = 'https://static.alphorm.com/prod/assets/images/site/neutral-face-colored-icon.svg'
const sadFaceIcon = 'https://static.alphorm.com/prod/assets/images/site/sad-face-icon.svg'
const sadFaceColoredIcon = 'https://static.alphorm.com/prod/assets/images/site/sad-face-colored-icon.svg'
const trialPaymentIllustration = 'https://static.alphorm.com/prod/assets/images/site/trialPaymentIllustration.svg'
const trialConfirmationIllustration = 'https://static.alphorm.com/prod/assets/images/site/trialConfirmationIllustration.svg'
const trialSubscriptionIllustration = 'https://static.alphorm.com/prod/assets/images/site/trialSubscriptionIllustration.svg'
const learningPathShapeCategory = 'https://static.alphorm.com/prod/assets/images/site/LP-shape-categorie.svg'
const creditCard = 'https://static.alphorm.com/prod/assets/images/site/creditCard.svg'
const creditCardBlue = 'https://static.alphorm.com/prod/assets/images/site/iconx3.svg'
const yellowCheck = 'https://static.alphorm.com/prod/assets/images/site/yellowCheck.svg'
const popUpMock = 'https://static.alphorm.com/prod/assets/images/site/popUpMock.png'
const carteInternational = 'https://static.alphorm.com/prod/assets/images/site/carteInternational.svg'
const carteNationale = 'https://static.alphorm.com/prod/assets/images/site/carteNationale.svg'
const virementBancaire = 'https://static.alphorm.com/prod/assets/images/site/virementBancaire.svg'
const otherWebinarCategory = 'https://static.alphorm.com/prod/assets/images/site/webinar-categorie-autres.svg'
const whiteLogo = 'https://static.alphorm.com/prod/assets/images/site/logo-white.svg'
const pageNotFoundImage = 'https://static.alphorm.com/prod/assets/images/site/illustration-404-page.svg'
const dashboardHistoryIllustration = 'https://static.alphorm.com/prod/assets/images/site/illustration-dashboard-history.svg'
const illustrationCPF = 'https://static.alphorm.com/prod/assets/images/site/illustration-CPF.svg'
const checkoutSuccessIllustration = 'https://static.alphorm.com/prod/assets/images/site/illustration-checkout-success.svg'
const becomeTrainerImage1 = 'https://static.alphorm.com/prod/assets/images/site/section-devenir-formateur-1.png'
const becomeTrainerImage2 = 'https://static.alphorm.com/prod/assets/images/site/section-devenir-formateur-2.png'
const b2bImage = 'https://static.alphorm.com/prod/assets/images/site/section-logo-b2b.png'

export {
  appStore,
  appDesktop,
  googlePlay,
  backgroundFooterLeft,
  backgroundFooterRight,
  capgemini,
  exampleCategory,
  heroIllustrationHomePage,
  smallLogo,
  largeLogo,
  masterCardLogo,
  paypalLogo,
  visaLogo,
  neuronesIt,
  paris,
  safran,
  sncf,
  supinfo,
  volkswagen,
  mobileApp,
  pageNotFound,
  thankYouForContact,
  backgroundHeaderTrainer,
  cancellatonStep1,
  cancellatonStep2,
  entrepriseHeroIllustration,
  entrepriseSectionBackground,
  backgroundPricingCards,
  solutionsClient,
  anonymeClient,
  bergeracClient,
  dataDockeEntreprise,
  backgroundEntrepriseShape1,
  backgroundEntrepriseShape2,
  happyLearnerEntreprise,
  certificateImg,
  aktoLogo,
  atlasLogo,
  opco2iLogo,
  opcoepLogo,
  opcocommerceLogo,
  opcoSanteLogo,
  currency,
  megaphone,
  people,
  checkmark,
  chatBubles,
  noteBook,
  videoPlayer,
  underMaintenance,
  emptyCart,
  cpfHeaderBackground,
  thankYouForBuying,
  fundingIllustration,
  fundingEntreprisesAndParticulier,
  fundingParticulier,
  fundingEntreprises,
  homeBackgroundMobile2,
  becomeTrainerBackgroundMobile1,
  becomeTrainerBackgroundMobile2,
  becomeTrainerBackgroundMobile3,
  pricingBackgroundMobile1,
  pricingBackgroundMobile2,
  learningCertificats,
  learningFavorits,
  learningHistory,
  learningMyCourses,
  learningRecommended,
  learningCertificatsEmpty,
  learningMyCoursesEmpty,
  learningFavoritsEmpty,
  learningHistoryEmpty,
  fundingMobileBackground,
  fundingMobileBackground2,
  fundingMobileBackground3,
  OpcoMobileBackground,
  OpcoMobileBackground2,
  poleMobileBackground,
  poleMobileBackground2,
  poleMobileBackground3,
  checkoutSubscriptionShape,
  maintenanceRightShape,
  maintenanceLeftShape,
  maintenanceShapeBackground,
  onboardingBackground,
  alphormLogoWhite,
  onboardingIntrest,
  onboardingFinance,
  onboardingPassword,
  onboardingOffres,
  onboardingInfo,
  onboardingMyAccount,
  unfollowCourse,
  paymentMethod,
  paymentMethodVerified,
  confirmReactivation,
  mastercardIcon,
  paypalIcon,
  visaIcon,
  loader,
  bgPricingCard,
  bgPricingCardWhite,
  bgSubscriptionB2BCard,
  onboardingLicence,
  boostMySkillsIllustration,
  certifyMySkillsIllustration,
  motivateTeamIllustration,
  validationPhoneNumber,
  satisfiedOrRefundedBadge,
  screenFeaturesOffline1,
  screenFeaturesOffline2,
  screenFeaturesOffline3,
  laptopHeroOffline,
  laptopHeroOfflineMobile,
  downloadButtonLinux,
  downloadButtonMacOS,
  downloadButtonWindows,
  downloadMobileButtonLinux,
  downloadMobileButtonMacOS,
  downloadMobileButtonWindows,
  bulletPointIcon,
  offlineTopbarBlueShape,
  bgLaptopShapeOfflineMobile,
  smallBGLaptopOvalLPOffline,
  bgScreensOvalLPOffline,
  logoApps,
  freeCoursesCategoryShape,
  greenCheckMark,
  freeCoursesCategoryLPBackground,
  onboardingAlphorm,
  happyFaceIcon,
  happyFaceColoredIcon,
  neutralFaceIcon,
  neutralFaceColoredIcon,
  sadFaceIcon,
  sadFaceColoredIcon,
  trialPaymentIllustration,
  trialConfirmationIllustration,
  onboardingFreePeriod,
  learningPathShapeCategory,
  trialSubscriptionIllustration,
  creditCard,
  creditCardBlue,
  yellowCheck,
  popUpMock,
  carteInternational,
  carteNationale,
  virementBancaire,
  otherWebinarCategory,
  whiteLogo,
  pageNotFoundImage,
  dashboardHistoryIllustration,
  illustrationCPF,
  checkoutSuccessIllustration,
  becomeTrainerImage1,
  becomeTrainerImage2,
  b2bImage
}
