import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { checkBoxStyle } from './checkBox.style'
import TypographyElement from '../typographyElement/typographyElement'

const CheckBox = (props) => {
  const useStyle = createUseStyles(checkBoxStyle)
  const { inputGroup, input, checkbox } = useStyle(props)

  return (
    <div className={inputGroup}>
      <input
        data-test={props.dataTest}
        readOnly={props.readOnly}
        onChange={props.handleChange}
        checked={props.checked}
        value={props.value}
        id={props.id}
        type='checkbox'
        className={input}
        name={props.name}
        disabled={props.disabled}
      />
      <label className={classNames(checkbox, 'fa fa-check')} />
      <TypographyElement
        spacing='0 0 0 10px'
        lineHeight='20px'
        Component='label'
        variant='body2'
        htmlFor={props.id}
        className={props.labelClassName}
      >
        {props.label}
      </TypographyElement>
    </div>
  )
}

CheckBox.propTypes = {
  readOnly: PropTypes.bool,
  /**  the control label text to be displayed */
  label: PropTypes.string.isRequired,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** element's identifier */
  id: PropTypes.string,
  /** the value shipped with the input when submitted */
  value: PropTypes.string,
  /** the method that control the state of the checkbox */
  checked: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** indeterminate state */
  indeterminate: PropTypes.bool
}

CheckBox.defaultProps = {
  label: 'checkbox label',
  disabled: false
}

export default CheckBox
