import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ webinar }) => ({
  plannedDate: webinar.data.PlannifiedWebinarDate,
  startDate: webinar.data.StartDate,
  endDate: webinar.data.EndDate
})

export default connect(mapStateToProps, null)(UI)
