import { blue, neutral, Spacing } from '../../constants'
import backgroundPricingCards from '../../assets/media/bg-pricing-card-component.svg'
import backgroundPricingCardsTitle from '../../assets/media/bg-pricing-card-title.svg'
import mediaQueries from '../../constants/mediaQueries'

export default {
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    boxShadow: '0 0 5px 0 rgba(194, 212, 230, 0.23) !important',
    '&:hover': {
      boxShadow: '0 0 36px 0 rgba(194, 212, 230, 0.23) !important',
      transform: 'scale(1.02)',
      '& $header': {
        backgroundSize: '70% 60%, auto'
      }
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: () => Spacing(5),
    borderBottom: `1px solid ${neutral[1]}`,
    backgroundSize: '60% 50%, auto',
    backgroundImage: `url('${backgroundPricingCards}')`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: neutral[0],
    backgroundPosition: 'right top',
    transition: 'all 0.3s ease-in-out'
  },
  titleClassName: {
    backgroundSize: '34px 28px, auto',
    backgroundImage: `url('${backgroundPricingCardsTitle}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '2px 20px'
  },
  body: {
    width: '100%',
    height: '100%',
    padding: () => Spacing(5, 5, 5, 5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  infoClass: {
    display: 'flex',
    alignItems: 'baseline',
    margin: () => Spacing(0, 0, 2, 0)
  },
  iconClass: {
    color: blue[0],
    fontSize: 12,
    lineHright: 0,
    padding: () => Spacing(0, 3, 0, 0)
  },
  infosClass: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  [`${mediaQueries.tablet}`]: {
    infosClass: {
      flexDirection: 'column'
    },
    header: {
      padding: () => Spacing(5),
      backgroundImage: 'none'
    },
    titleClassName: {
      backgroundPosition: '0px 3px'
    }
  },
  [`${mediaQueries.mobile}`]: {
    infosClass: {
      flexDirection: 'column'
    },
    header: {
      padding: () => Spacing(5),
      backgroundImage: 'none'
    },
    titleClassName: {
      backgroundPosition: '0px 3px'
    }
  }
}
