import { useEffect, useState } from 'react'
import {
  Container,
  TypographyElement,
  CodeVerificationInput,
  Button,
  Status,
  MediaQuery,
  ButtonSize
} from 'UI'
import style from './style'
import { createUseStyles, useTheme } from 'react-jss'
import Text from './text.json'
import { verifyCode, verifyPhoneNumber } from 'helpers'
import { smallLogo, validationPhoneNumber } from 'assets'
import classNames from 'classnames'
import { errors, codeLength, digitsToShow } from 'constants/'

const useStyle = createUseStyles(style)

let timer

export default ({
  cancel,
  submit,
  phone = '',
  titleProps = {},
  subtitleProps = {},
  imageClass,
  showTopIllustration,
  hideAlphormLogo,
  updatePhoneNumber
}) => {
  const {
    root,
    codeVerifierClass,
    buttonsContainer,
    image,
    textInputClassName,
    validationPhoneNumberClass,
    validationPhoneNumberImage
  } = useStyle()
  const [code, setCode] = useState([])
  const [status, setStatus] = useState(errors.notSet)
  const [error, setError] = useState(false)
  const theme = useTheme()
  useEffect(async () => {
    if (code.join('').length === codeLength) {
      try {
        const response = await verifyCode(phone, code.join(''))
        if (response) {
          setStatus(errors.valid)
          setError(false)
          updatePhoneNumber(phone)
        } else {
          setStatus(errors.notValid)
          setError(true)
          timer = setTimeout(() => setCode([]), 500)
        }
      } catch (e) {
        setStatus(errors.notValid)
        setError(true)
        timer = setTimeout(() => setCode([]), 500)
      }
    } else setStatus(errors.notSet)
    return () => clearTimeout(timer)
  }, [code])

  const sendVerificationCodeAgain = async () => await verifyPhoneNumber(phone)
  return (
    <Container className={root}>
      <div>
        {!hideAlphormLogo &&
          <MediaQuery HideOnDesktop>
            <object type='image/svg+xml' data={smallLogo} className={image}>svg-animation</object>
          </MediaQuery>}
        {
          showTopIllustration &&
            <div className={classNames(imageClass, validationPhoneNumberClass)}>
              <object className={validationPhoneNumberImage} type='image/svg+xml' data={validationPhoneNumber}>svg-animation</object>
            </div>
        }
        <TypographyElement
          alignTablet='center'
          alignMobile='center'
          component='h3'
          spacing='0 0 16px 0'
          variant='heading1'
          marginTablet='44px 0 0 0'
          marginMobile='44px 0 0 0'
          {...titleProps}
        >
          {Text.header}
        </TypographyElement>
        <MediaQuery HideOnDesktop>
          <div className={validationPhoneNumberClass}>
            <object className={validationPhoneNumberImage} type='image/svg+xml' data={validationPhoneNumber}>svg-animation</object>
          </div>
        </MediaQuery>
        <TypographyElement
          alignTablet='center'
          alignMobile='center'
          component='h3'
          spacing='0 0 24px 0'
          variant='body1'
          {...subtitleProps}
        >
          {`${Text.subHeader}${phone.substring(phone.length - digitsToShow)}`}
        </TypographyElement>
        <CodeVerificationInput
          status={status === errors.valid ? Status.success : status === errors.notValid ? Status.error : null}
          className={codeVerifierClass}
          value={code.join('').slice(0, codeLength)}
          handleChange={setCode}
          textInputClassName={textInputClassName}
          inputHeightTablet={68}
          inputHeightMobile={52}
        />
        {
        error
          ? <TypographyElement variant='body2' spacing='0 0 16px 0' color={theme.error[0]}>{Text.invalidCode}</TypographyElement>
          : null
      }
        <TypographyElement
          alignTablet='center'
          alignMobile='center'
          variant='body2'
          spacing='0 0 16px 0'
          spacingTablet='12px 0'
          spacingMobile='12px 0'
        >
          {Text.noSmsYet}
          <TypographyElement
            display='inline'
            Component='span'
            variant='body2'
            color={theme.primary[0]}
            cursor='pointer'
            handleClick={sendVerificationCodeAgain}
          >
            {` ${Text.retry}`}
          </TypographyElement>
        </TypographyElement>
      </div>
      <div className={buttonsContainer}>
        <Button
          onHandleClick={cancel}
          variation='text'
          label={Text.cancel}
          size={ButtonSize.small}
        />
        <Button
          onHandleClick={submit}
          disabled={status !== errors.valid}
          label={Text.submit}
          size={ButtonSize.small}
          width={147}
          widthTablet='100%'
          widthMobile='100%'
        />
      </div>
    </Container>
  )
}
