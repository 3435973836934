import { Colors } from './color'

export const CheckoutButtonSize = {
  small: 'small',
  big: 'big'
}

export const CheckoutButtonVariation = {
  primary: 'primary',
  important: 'important'
}

export const CheckoutPriceSizeMap = {
  small: {
    width: 44,
    height: 32,
    padding: 5,
    iconFontSize: 15,
    iconMarginRight: 5
  },
  big: {
    width: 55,
    height: 40,
    padding: 16,
    iconFontSize: 20,
    iconMarginRight: 5
  }
}

export const CheckoutButtonVariationMap = {
  primary: {
    priceBackgroundColor: Colors.cardColor.primary,
    hoverBackground: Colors.hoverCheckoutButton
  },
  important: {
    priceBackgroundColor: Colors.cardColor.important,
    hoverBackground: Colors.hoverCheckoutButtonPromo
  }
}
