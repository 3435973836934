import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Styles from './layout.style'
import classNames from 'classnames'

const Row = ({
  children,
  Component = 'div',
  className,
  repeatCols = 12,
  mrepeatCols = 12,
  srepeatCols = 12,
  gap = 0,
  tabletGap = 0,
  mobileGap = 0
}) => {
  const useStyle = createUseStyles(Styles)
  const { row } = useStyle({ repeatCols, mrepeatCols, srepeatCols, gap, tabletGap, mobileGap })
  return (
    <Component className={classNames(className, row)}>
      {children}
    </Component>
  )
}

Row.proptypes = {
  /** justify content with the flex display */
  justify: PropTypes.oneOf(['center', 'left', 'right']),
  /** alignItems content with the flex display */
  alignItems: PropTypes.string
}

export default Row
