import { ButtonSizeMap, ButtonVariationMap, Typography } from '../../constants'
import mediaQueries from '../../constants/mediaQueries'
import { loadingSkeleton, loadingSkeletonAnimation } from '../../helpers'

export const ButtonStyle = (theme) => ({
  button: props => ({
    width: props.width || null,
    fontFamily: Typography.tertiaryFontFamily,
    fontSize: ButtonSizeMap[props.size].fontSize,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: ButtonSizeMap[props.size].lineHeight,
    letterSpacing: 'normal',
    textAlign: 'center',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: props.height || ButtonSizeMap[props.size].height,
    borderTopLeftRadius: props.topLeft,
    borderTopRightRadius: props.topRight,
    borderBottomLeftRadius: props.bottomLeft,
    borderBottomRightRadius: props.bottomRight,
    backgroundColor: props.loading ? 'transparent' : props.backgroundColor || ButtonVariationMap(theme, props.variation).backgroundColor,
    border: 0,
    color: props.color ? props.color : ButtonVariationMap(theme, props.variation).color,
    paddingLeft: props.paddingLeft || ButtonSizeMap[props.size].paddingLeft,
    paddingRight: props.paddingRight || ButtonSizeMap[props.size].paddingRight,
    textDecoration: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    transition: 'all 0.2s, margin 0s',
    margin: props.marginButton || 0,
    boxShadow: props.loading ? 'none !important' : 'none',
    '&:hover': {
      backgroundColor: props.loading ? 'transparent' : props.backgroundColorHover || ButtonVariationMap(theme, props.variation).backgroundColorHovered
    },
    '&:active': {
      backgroundColor: props.loading ? 'transparent' : ButtonVariationMap(theme, props.variation).backgroundColorPressed,
      transform: !props.loading && !props.disabled && !props.disableTransform ? `scale(${ButtonVariationMap(theme, props.variation).activeScale})` : '',
      color: ButtonVariationMap(theme, props.variation).colorPressed || ButtonVariationMap(theme, props.variation).color
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: `rgba(0,0,0,${ButtonVariationMap(theme, props.variation).backgroundColorHoveredEffect})`,
      opacity: 0,
      transition: 'all 0.2s'
    },
    '&:hover::after': {
      opacity: 1
    },
    '&:focus': {
      outline: 'none'
    },
    '&:disabled': {
      cursor: 'default',
      '&:hover': {
        backgroundColor: ButtonVariationMap(theme, props.variation).backgroundColorDisabled
      },
      backgroundColor: props.loading ? 'transparent' : ButtonVariationMap(theme, props.variation).backgroundColorDisabled,
      borderWidth: props.loading ? 0 : ButtonVariationMap(theme, props.variation).borderWidth,
      color: ButtonVariationMap(theme, props.variation).colorDisabled,
      borderColor: ButtonVariationMap(theme, props.variation).colorDisabled
    },
    ...(props.loading && loadingSkeleton({ borderRadius: `${props.topRight} ${props.topLeft} 4px 4px` }).core)
  }),
  text: props => ({
    margin: props.margin || '0px 20px',
    fontSize: ButtonSizeMap[props.size].fontSize
  }),
  [`${mediaQueries.tablet}`]: {
    button: props => ({
      width: props.widthTablet || null,
      height: props.heightTablet || null,
      margin: [props.marginButtonTablet, '!important'] || 0
    })
  },
  [`${mediaQueries.mobile}`]: {
    button: props => ({
      width: props.widthMobile || null,
      height: props.heightMobile || null,
      margin: [props.marginButtonMobile, '!important'] || 0
    })
  },
  ...loadingSkeletonAnimation
})
