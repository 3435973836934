import { MediaQueries } from 'UI'

export default (theme) => ({
  subContainerClass: {
    display: 'flex'
  },
  rootClass: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 32
  },
  avatarGroupClass: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: 16
  },
  socialGroupClass: {
    width: 232,
    display: 'flex',
    alignItems: 'center'
  },
  centerIconClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    fontSize: 18
  },
  iconClass: {
    height: 40,
    width: 40,
    border: '1px solid',
    borderColor: theme.neutral[0],
    borderRadius: 8,
    marginRight: 8,
    cursor: 'pointer',
    '& :first-child': {
      color: theme.primary[100]
    }
  },
  formRootClass: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 32,
    width: '100%',
    gap: 32
  },
  inputClass: {
    display: 'none'
  },
  pointerClass: {
    cursor: 'pointer'
  },
  rowClass: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  submitClass: {},
  [`${MediaQueries.tablet}`]: {
    submitClass: {
      width: '100%'
    },
    formRootClass: {
      gap: 16,
      marginTop: 24
    },
    rowClass: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32
    }
  },
  [`${MediaQueries.mobile}`]: {
    submitClass: {
      width: '100%'
    },
    formRootClass: {
      gap: 16,
      marginTop: 24
    },
    rowClass: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 32
    }
  }
})
