import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { iconButtonStyle } from './iconButton.style'
import { ButtonSize, ButtonVariation, ButtonType } from '../../constants'
import Icon from '../icon/icon'
import Button from '../button/button'
import classNames from 'classnames'
const IconButton = (props) => {
  const useStyle = createUseStyles(iconButtonStyle)
  const { icon } = useStyle(props)
  return (
    <Button {...props}>
      <Icon iconName={props.iconName} style={classNames(icon, props.iconStyle)} />
    </Button>
  )
}

IconButton.propTypes = {
  /**  the text that gonna be displayed on the button */
  label: PropTypes.string,
  /** the margin of the icon */
  margin: PropTypes.string,
  /**  the type of the button */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /**  the variation of the button, (primary,secondary) */
  variation: PropTypes.oneOf([
    'primary',
    'secondary',
    'important',
    'confirmation'
  ]),
  /** the name of the icon from te library */
  // iconName: PropTypes.string.isRequired,
  /** the size of the Button */
  size: PropTypes.oneOf(['big', 'small']),
  /** the method that handle the click event */
  handleClick: PropTypes.func
}

IconButton.defaultProps = {
  size: ButtonSize.big,
  variation: ButtonVariation.primary,
  type: ButtonType.button
}

export default IconButton
