import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { MediaQueryConifig } from '../../constants/mediaQueries'

const MediaQuery = ({ children, HideOnDesktop, HideOnTablet, HideOnMobile, ShowOnDesktop, ShowOnTablet, ShowOnMobile }) => {
  const isDesktop = useMediaQuery(MediaQueryConifig.desktop)
  const isTablet = useMediaQuery(MediaQueryConifig.tablet)
  const isMobile = useMediaQuery(MediaQueryConifig.mobile)
  return (ShowOnDesktop || ShowOnTablet || ShowOnMobile)
    ? (
        ShowOnMobile && isMobile
          ? children
          : ShowOnTablet && isTablet
            ? children
            : ShowOnDesktop && isDesktop
              ? children
              : null
      )
    : (
        HideOnMobile && isMobile
          ? null
          : HideOnTablet && isTablet
            ? null
            : HideOnDesktop && isDesktop
              ? null
              : (children || null)
      )
}

MediaQuery.propTypes = {
  /** set true will hide the children in Desktop mode */
  HideOnDesktop: PropTypes.bool,
  /** set true will hide the children in Tablet mode */
  HideOnTablet: PropTypes.bool,
  /** set true will hide the children in Mobile mode */
  HideOnMobile: PropTypes.bool,
  /** set true will Show the children in Desktop mode */
  ShowOnDesktop: PropTypes.bool,
  /** set true will Show the children in Tablet mode */
  ShowOnTablet: PropTypes.bool,
  /** set true will Show the children in Mobile mode */
  ShowOnMobile: PropTypes.bool
}

export default MediaQuery
