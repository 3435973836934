export default (theme) => ({
  stars: {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    '&:hover > $activeStar svg path': {
      fill: 'none'
    }
  },
  activeStar: {
    '&$starIcon svg path': {
      fill: theme.secondary[0]
    }
  },
  starIcon: {
    padding: '0 5px',
    cursor: 'pointer',
    '&:first-child': {
      paddingRight: 0
    },
    '&:last-child': {
      paddingLeft: 0
    },
    '& svg path': {
      fill: theme.secondary[500],
      strokeWidth: 0
    },
    '&:hover ~ *, &:hover': {
      '& svg path': {
        fill: `${theme.secondary[0]} !important`
      }
    }
  }
})
