import fetch from 'core/fetch'
import { put, select, takeLatest } from 'redux-saga/effects'
import { BASE_URL_WEB, ItemTypesEnum } from 'constants/'
import { setAlternativeCurrency, switchToAlternativeCurrencyAction } from 'store/currency'
import { SIGNIN } from 'identity/store'

const initialState = {
  Id: '44904365-94f3-4c3a-9b96-4418e5791353',
  Name: 'France',
  Email: 'contact@alphorm.com',
  ContactPhone: 'contact@alphorm.com',
  ContactAddress: '66 avenue des Champs Elysées, 75008 Paris, France',
  ContactEmail: '+33 1 77 62 45 80',
  ContactFirstName: 'Hamid',
  ContactLastName: 'HARABAZAN',
  CurrencyId: 3,
  PriceCoefficient: 1,
  IsTrialActive: true,
  CountryId: 65,
  Country: null,
  Phone: '+33 1 77 62 45 80',
  Vat: 20,
  IsDefault: true,
  SubscriptionTypePrices: []
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_CURRENT_SHOP_SUCCESS:
      return {
        ...state,
        ...data
      }
    case GET_CURRENT_SHOP_FAIL:
      return {
        ...state,
        err: data
      }
    default:
      return state
  }
}

export const fetchDetectedShopAction = () => ({ type: CURRENT_SHOP_FETCH_REQUESTED })

const getAutoAppliedCoupon = ({ user }) => user?.AutoAppliedCoupons
const getItemTypes = ({ referential }) => referential?.ItemTypes

const getCachedCurrencyChoice = ({ currency }) => currency.alternativeCurrency
const getUser = ({ identity }) => identity?.user?.Id

function * fetchDetectedShop () {
  const Id = yield select(getUser)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const ItemTypes = yield select(getItemTypes)
  const alreadyInitialized = select(getCachedCurrencyChoice)
  try {
    const referential = yield fetch(queries.getReferential)
    const detectedShop = yield fetch(queries.getDetectedShop)
    yield put(setAlternativeCurrency(referential.Currencies[detectedShop.CurrencyId]))
    if (!detectedShop.IsDefault && !alreadyInitialized) yield put(switchToAlternativeCurrencyAction())
    if (detectedShop.IsDefault) {
      detectedShop?.SubscriptionTypePrices.map(subscription => {
        subscription.AlternativePrice = subscription.Price
        if (subscription.Promotion) {
          subscription.Promotion.AlternativePromotionPrice = subscription.Promotion.PromotionPrice
        }
        return subscription
      })
    }
    if (autoAppliedCoupon && autoAppliedCoupon.length > 0) {
      detectedShop?.SubscriptionTypePrices.map(subscription => {
        const autoAppliedCouponForSubscription = autoAppliedCoupon[0]?.Values?.find(coupon => coupon.BillingCycle === subscription.BillingCycle && coupon.SubjectType === ItemTypes[ItemTypesEnum.Subscription])
        if (subscription.Promotion === null && autoAppliedCouponForSubscription) {
          subscription.Promotion = {
            AlternativePromotionPrice: autoAppliedCouponForSubscription.Value,
            PromotionPrice: autoAppliedCouponForSubscription.Value * detectedShop.PriceCoefficient,
            BillingCycle: subscription.BillingCycle,
            AutoAppliedCouponId: autoAppliedCoupon[0].Id
          }
        }
        return subscription
      })
    }
    if (detectedShop?.PromotionCourse?.Target !== null && detectedShop?.PromotionCourse?.Target !== Id) detectedShop.PromotionCourse = null
    yield put({ type: GET_CURRENT_SHOP_SUCCESS, data: detectedShop })
  } catch (err) {
    yield put({ type: GET_CURRENT_SHOP_FAIL, err })
  }
}

export function * shopRootSaga () {
  yield takeLatest([CURRENT_SHOP_FETCH_REQUESTED, SIGNIN], fetchDetectedShop)
}

const queries = {
  getDetectedShop: `${BASE_URL_WEB}/Shop/getDetected`,
  getReferential: `${BASE_URL_WEB}/Referential/getReferential`
}
export const GET_CURRENT_SHOP_SUCCESS = 'GET_CURRENT_SHOP_SUCCESS'
export const GET_CURRENT_SHOP_FAIL = 'GET_CURRENT_SHOP_FAIL'
const CURRENT_SHOP_FETCH_REQUESTED = 'CURRENT_SHOP_FETCH_REQUESTED'
