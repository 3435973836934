
import {
  Button,
  Row,
  Paper,
  Col,
  TypographyElement,
  Avatar,
  AvatarSize,
  Icon,
  Icons,
  ButtonVariation,
  Container,
  blue,
  neutral,
  MediaQuery
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import Description from 'components/common/Description'
import VideoPlayer from 'components/common/VideoPlayer'
import { amazonBucket } from 'constants/'
import { nameInitials } from 'helpers'
import { smallLogo } from 'assets'
import { SearchPath, TrainerPrimaryPath } from 'navigation/Routes'
import Tag from 'components/Catalog/Tag'
import Text from './text.json'
import { useState } from 'react'
import UnfollowWebinarPopUp from '../UnfollowWebinarPopUp'

const useStyle = createUseStyles(style)

export default ({ description, speakers, tags, handleTagClick, resourcesLink, goto, presentationVideoLink, isUserParticipating }) => {
  const { paperClass, speakerContainer, speakerInfos, profileIcon, videoStyle, speakerLinkContainerStyle, speakerLinkStyle, tagsPaperClass } = useStyle()
  const [unfollowWbinarPopUp, setUnfollowWbinarPopUp] = useState(false)
  const avatarRenderer = (photo) => photo ? `${amazonBucket.bucketBaseUrl}${photo}` : null
  const trainerLinkRenderer = (slug) => `${TrainerPrimaryPath}/${slug}`
  return (
    <Container>
      <UnfollowWebinarPopUp changeUnfollowModalState={setUnfollowWbinarPopUp} modalState={unfollowWbinarPopUp} />
      <MediaQuery HideOnTablet HideOnMobile>
        <Row justify='between'>
          <Col direction='column' grid={6}>
            <Description
              title={Text.descriptionTitle}
              descriptionTextHeight={300}
              Description={description}
            />
          </Col>
          <Col direction='column' grid={5}>
            <a target='_blank' rel='noopener noreferrer' href={resourcesLink}>
              <Button variation={ButtonVariation.primary} label={Text.buttonText} width='100%' marginButton='0 0 16px 0' disabled={!resourcesLink}> </Button>
            </a>
            {presentationVideoLink ? <VideoPlayer videoLink={presentationVideoLink} mp4 externalClass={videoStyle} /> : null}
            {
              speakers.length && speakers?.map((speaker, key) => (
                <Paper key={key} className={paperClass}>
                  <div className={speakerContainer}>
                    <div className={speakerInfos}>
                      <Avatar
                        img={avatarRenderer(speaker.Photo)}
                        name={nameInitials({ firstName: speaker.FirstName, lastName: speaker.LastName })}
                        size={AvatarSize.size_0}
                        dataTest='profile-avatar'
                      />
                      <TypographyElement
                        component='p'
                        color={blue[0]}
                        align='left'
                        margin='0 15px'
                        fontSize={16}
                      >
                        {speaker.FirstName} {speaker.LastName}
                      </TypographyElement>
                    </div>
                    <div className={speakerInfos}>
                      {speaker.TrainerSlug
                        ? <img onClick={() => goto(trainerLinkRenderer(speaker.TrainerSlug))} src={smallLogo} className={speakerLinkStyle} />
                        : null}

                      <a target='_blank' rel='noopener noreferrer' href={speaker.LinkedInLink}>
                        <div className={speakerLinkContainerStyle}>
                          <Icon style={profileIcon} iconName={Icons.linkedin} />
                        </div>
                      </a>
                    </div>
                  </div>
                </Paper>
              ))
            }
            <Paper className={tagsPaperClass}>
              <TypographyElement
                variant='body1'
                align='left'
                spacing='0 0 16px 0'
                fontWeight={700}
                color={neutral[6]}
                Component='h3'
              >
                {Text.tagTitle}
              </TypographyElement>
              <div>
                {tags.map((tag, key) =>
                  <Tag
                    key={key}
                    path={`${SearchPath}?query=${encodeURIComponent(tag.Name.trim())}`}
                    handleTagClick={() => handleTagClick(`${SearchPath}?query=${encodeURIComponent(tag.Name.trim())}`)}
                    Name={tag.Name}
                  />)}
              </div>
            </Paper>
            {isUserParticipating ? <TypographyElement variant='button' align='left' spacing='16px 0' textDecoration='underline' cursor='pointer' Component='p' color={neutral[6]} handleClick={() => setUnfollowWbinarPopUp(true)}>{Text.unfollowWebinar}</TypographyElement> : null}
          </Col>
        </Row>
      </MediaQuery>
      <MediaQuery HideOnDesktop>
        <a target='_blank' rel='noopener noreferrer' href={resourcesLink}>
          <Button variation={ButtonVariation.primary} label={Text.buttonText} marginButton='0 0 16px 0' width='100%' disabled={!resourcesLink}> </Button>
        </a>
        {presentationVideoLink ? <VideoPlayer videoLink={presentationVideoLink} mp4 externalClass={videoStyle} /> : null}
        {
          speakers?.map((speaker, key) => (
            <Paper key={key} className={paperClass}>
              <div className={speakerContainer}>
                <div className={speakerInfos}>
                  <Avatar
                    img={avatarRenderer(speaker.Photo)}
                    name={nameInitials({ firstName: speaker.FirstName, lastName: speaker.LastName })}
                    size={AvatarSize.size_0}
                    dataTest='profile-avatar'
                  />
                  <TypographyElement
                    component='p'
                    color={blue[0]}
                    align='left'
                    margin='0 15px'
                    fontSize={16}
                  >
                    {speaker.FirstName} {speaker.LastName}
                  </TypographyElement>
                </div>
                <div className={speakerInfos}>
                  {speaker.TrainerSlug
                    ? <img onClick={() => goto(trainerLinkRenderer(speaker.TrainerSlug))} src={smallLogo} className={speakerLinkStyle} />
                    : null}

                  <a target='_blank' rel='noopener noreferrer' href={speaker.LinkedInLink}>
                    <div className={speakerLinkContainerStyle}>
                      <Icon style={profileIcon} iconName={Icons.linkedin} />
                    </div>
                  </a>
                </div>
              </div>
            </Paper>
          ))
        }
        <Description
          title={Text.descriptionTitle}
          descriptionTextHeight={300}
          Description={description}
        />
        <Paper className={tagsPaperClass}>
          <TypographyElement
            variant='body1'
            align='left'
            spacing='0 0 16px 0'
            fontWeight={700}
            color={neutral[6]}
            Component='h3'
          >
            {Text.tagTitle}
          </TypographyElement>
          <div>
            {tags.map((tag, key) =>
              <Tag
                key={key}
                path={`${SearchPath}?query=${encodeURIComponent(tag.Name.trim())}`}
                handleTagClick={() => handleTagClick(`${SearchPath}?query=${encodeURIComponent(tag.Name.trim())}`)}
                Name={tag.Name}
              />)}
          </div>
        </Paper>
        {isUserParticipating ? <TypographyElement variant='button' align='left' spacing='16px 0' textDecoration='underline' cursor='pointer' Component='p' color={neutral[6]} handleClick={() => setUnfollowWbinarPopUp(true)}>{Text.unfollowWebinar}</TypographyElement> : null}
      </MediaQuery>
    </Container>
  )
}
