import Text from 'components/Profile/text.json'
import { BASE_URL_WEB } from 'constants/'
import fetch from 'core/fetch'
import { isEmpty } from 'lodash'

const queries = {
  userExist: (email) => `${BASE_URL_WEB}/User/isEmailRegistered/${email}`
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i

const emailExist = async (email, originalEmail) => {
  if (originalEmail && email === originalEmail) return false
  const response = await fetch(queries.userExist(email))
  return response
}

export const validateGenral = async (values, originalEmail) => {
  const errors = {}
  if (!values.FirstName) {
    errors.FirstName = Text.required
  }
  if (!values.LastName) {
    errors.LastName = Text.required
  }
  if (!values.Email) {
    errors.Email = Text.required
  }
  if (!emailRegex.test(values.Email)) {
    errors.Email = Text.emailIncorrect
  }
  if (isEmpty(errors)) {
    const response = await emailExist(values.Email, originalEmail)
    if (response) errors.Email = Text.emailAlreadyExist
  }
  return errors
}

export const validateAdress = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = Text.required
  }
  if (!values.lastName) {
    errors.lastName = Text.required
  }
  if (!values.addressLine1) {
    errors.addressLine1 = Text.required
  }
  if (!values.city) {
    errors.city = Text.required
  }
  if (!values.postalCode) {
    errors.postalCode = Text.required
  }
  if (!values.country.country || !values.countryId) {
    errors.country = Text.required
  }

  return errors
}

export const validateNewB2bAccountInfos = values => {
  const errors = {}
  if (!values.FirstName) {
    errors.FirstName = Text.required
  }
  if (!values.LastName) {
    errors.LastName = Text.required
  }
  return errors
}

export const validateNewB2bAccountPassword = values => {
  const errors = {}
  if (!values.password) {
    errors.password = Text.required
  } else if (values.password.length < 5) {
    errors.password = Text.weakPassword
  }
  return errors
}

export const validateContact = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = Text.required
  }
  if (!values.lastName) {
    errors.lastName = Text.required
  }
  if (!values.email) {
    errors.email = Text.required
  }
  if (!emailRegex.test(values.email)) {
    errors.email = Text.emailIncorrect
  }
  if (!values.message) {
    errors.message = Text.required
  }
  return errors
}

export const validateFinancing = values => {
  const errors = {}
  if (!values.firstName) {
    errors.firstName = Text.required
  }
  if (!values.lastName) {
    errors.lastName = Text.required
  }
  if (!values.email) {
    errors.email = Text.required
  }
  if (!emailRegex.test(values.email)) {
    errors.email = Text.emailIncorrect
  }
  if (!values.functionName) {
    errors.functionName = Text.required
  }
  if (!values.phone) {
    errors.phone = Text.required
  }
  return errors
}
