import { Helmet } from 'react-helmet'

export default ({ items = [] }) =>
  <Helmet>
    <script type='application/ld+json'>
      {
        `
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                ${
                    items.map((item, index) => (`{
                      "@type": "ListItem",
                      "position": ${index + 1},
                      "item": {
                        "@id": "${item.link}",
                        "name": "${item.name}"
                      }
                    }`))
                }
            ]
          }
        `
      }
    </script>
  </Helmet>
