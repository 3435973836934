import React, { useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { SubscriptionTypes, SwitchCurrency, TypographyElement, Button } from '../..'
import { Icons } from '../../constants'
import Icon from '../icon/icon'
import SwitchWithLabels from '../SwitchWithLabels/switchWithLabels'
import MediaQuery from '../mediaQuery'

import style from './subscriptionOfferVerticalCard.style'

const showFreeText = false

const useStyle = createUseStyles(style)

const SubscriptionOfferVerticalCard = ({
  shadowColor,
  title,
  secondTitle,
  freeText,
  billedYearlyText1,
  billedYearlyText2,
  yearlyPrice,
  infos,
  handleStartSubscriptionClick,
  startSubscriptionLabel,
  handleChangeCurrency,
  currency1,
  currency2,
  selectedCurrency,
  hideSwitchCurrency,
  price,
  currency,
  expenderMessage,
  monthly,
  type,
  promo,
  pricePromo,
  promoText,
  monthlySwitchLabel,
  yearlySwitchLabel,
  handleChangeSubscriptionType,
  threeTimePayment,
  width
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const {
    paper,
    header,
    titleAndPromo,
    expenderIcon,
    priceAndBanner,
    freeMonthsBanner,
    freeMonthsBannerPromo,
    priceAndPromo,
    body,
    infoClass,
    iconClass,
    switchSection,
    promoTag,
    promoTagContainer,
    priceClass,
    infoStyle,
    infoClassContainer
  } = useStyle({
    promo,
    shadowColor,
    threeTimePayment,
    width,
    open,
    priceAndBannerMarginExtra: type !== SubscriptionTypes.Yearly
  })
  return (
    <div className={paper}>
      <div className={header}>
        <div className={switchSection}>
          <SwitchWithLabels
            dataTest='subscription-offer-switch'
            handleChange={handleChangeSubscriptionType}
            checked={type === SubscriptionTypes.Monthly}
            leftLabel={yearlySwitchLabel}
            rightLabel={monthlySwitchLabel}
          />
          {
            !hideSwitchCurrency && (
              <SwitchCurrency
                handleChange={handleChangeCurrency}
                devis={currency1}
                alterDevis={currency2}
                checked={currency1 !== selectedCurrency}
              />
            )
          }
        </div>
        <div className={titleAndPromo}>
          <TypographyElement
            variant='heading4'
            color={theme.warning[0]}
          >
            {title}
          </TypographyElement>
          {
          promo &&
          (
            <div className={promoTagContainer}>
              <TypographyElement
                display='inline'
                variant='caption'
                color={theme.warning[100]}
                spacing='4px 6px'
                className={promoTag}
              >
                {promoText.toUpperCase()}
              </TypographyElement>
            </div>)
        }
        </div>
        <TypographyElement
          variant='body1'
          color={theme.neutral[100]}
          spacing='16px 0px 19px 0'
        >
          {secondTitle}
        </TypographyElement>
        {!promo
          ? (
            <div className={priceAndBanner}>
              <TypographyElement
                variant='heading4'
                color={theme.primary[100]}
                className={priceClass}
                key={`${price}-${currency}`}
                spacing='0px 8px 0px 0px'
              >
                {price}
                <TypographyElement
                  variant='heading6'
                  color={theme.primary[100]}
                  display='inline-block'
                  spacing='0 0 0 8px'
                >
                  {`${currency}/${monthly}`}
                </TypographyElement>
              </TypographyElement>
              {showFreeText && type === SubscriptionTypes.Yearly && (
                <div className={freeMonthsBanner}>
                  <TypographyElement
                    variant='caption'
                    color={theme.neutral[400]}
                  >
                    {freeText}
                  </TypographyElement>
                </div>)}
            </div>
            )
          : (
            <div className={priceAndBanner}>
              <div className={priceAndPromo}>
                <TypographyElement
                  variant='heading4'
                  color={theme.error[0]}
                  className={priceClass}
                  key={`${pricePromo}-${currency}`}
                >
                  {pricePromo}
                  <TypographyElement
                    variant='body1'
                    color={theme.error[0]}
                    display='inline-block'
                    spacing='0 0 0 6px'
                  >
                    {`${currency}/${monthly}`}
                  </TypographyElement>
                </TypographyElement>
                <TypographyElement
                  variant='caption'
                  color={theme.neutral[200]}
                  textDecoration='line-through'
                >
                  {`${price} ${currency}/${monthly}`}
                </TypographyElement>
              </div>
              {showFreeText && type === SubscriptionTypes.Yearly
                ? (
                  <div className={freeMonthsBannerPromo}>
                    <TypographyElement
                      variant='caption'
                      color={theme.neutral[400]}
                    >
                      {freeText}
                    </TypographyElement>
                  </div>)
                : null}
            </div>
            )}
        {type === SubscriptionTypes.Yearly
          ? (
            <>
              <TypographyElement
                variant='body2'
                color={theme.neutral[200]}
                lineHeight='22px'
              >
                {`${billedYearlyText1}`}
              </TypographyElement>
              <TypographyElement
                variant='body2'
                color={theme.neutral[200]}
                lineHeight='22px'
              >
                {billedYearlyText2}
              </TypographyElement>
            </>)
          : null}
      </div>
      <div className={body}>
        <MediaQuery HideOnDesktop>
          <div className={infoStyle}>
            <TypographyElement
              variant='link'
              fontWeight={500}
              cursor='pointer'
              spacing={open ? '0 0 12px 0' : '0 0 0 0'}
              handleClick={() => setOpen(!open)}
            >
              {expenderMessage}
              <Icon iconName={Icons.roundedUp} style={expenderIcon} />
            </TypographyElement>
          </div>
        </MediaQuery>
        <div className={infoClassContainer}>
          {
            infos.map(info => (
              <div className={infoClass} key={info}>
                <Icon iconName={Icons.check} style={iconClass} />
                <TypographyElement
                  variant='body1'
                >
                  {info}
                </TypographyElement>
              </div>
            ))
          }
        </div>
        <Button
          dataTest='start-subscription-button'
          label={startSubscriptionLabel}
          width='100%'
          height='54px'
          onHandleClick={handleStartSubscriptionClick}
          Component='a'
          href='/price'
          marginButton='16px 0 0 0'
        />
      </div>
    </div>
  )
}

SubscriptionOfferVerticalCard.propTypes = {
  /** the big title */
  title: PropTypes.string,
  /** second Title */
  secondTitle: PropTypes.string,
  /** maine price */
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** currency to show next price */
  currency: PropTypes.string,
  /** first currency to show in the switch currency */
  currency1: PropTypes.string,
  /** second currency to show in the switch currency */
  currency2: PropTypes.string,
  /** its either currency1 or currency2 to change switch state */
  selectedCurrency: PropTypes.string,
  /** function that handle the currency change */
  handleChangeCurrency: PropTypes.func,
  /** monthly label */
  monthly: PropTypes.string,
  /** selected type of subscription */
  type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** label of the button start subscription */
  startSubscriptionLabel: PropTypes.string,
  /** function that handle the start subscription */
  handleStartSubscriptionClick: PropTypes.func,
  /** array of string informations */
  infos: PropTypes.array,
  /** information about engagement */
  engagement: PropTypes.string,
  /** monthly label of the billing cycle switch */
  monthlySwitchLabel: PropTypes.string,
  /** yearly label of the billing cycle switch */
  yearlySwitchLabel: PropTypes.string,
  /** function that handle the subscription type */
  handleChangeSubscriptionType: PropTypes.func,
  /** billed yearly text */
  billedYearlyText: PropTypes.string,
  /** width */
  width: PropTypes.number
}

export default SubscriptionOfferVerticalCard
