import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Styles from './layout.style'
import classNames from 'classnames'

const Container = ({ children, className, Component = 'div', nopadding, fill }) => {
  const useStyle = createUseStyles(Styles)
  const { container } = useStyle({ nopadding, fill })
  return (
    <Component className={classNames(container, className)}>
      {children}
    </Component>
  )
}

Container.proptypes = {
  /** eliminate the padding (left and right) already set by default */
  nopadding: PropTypes.bool,
  /** fill height */
  fill: PropTypes.bool,
  /** 100% width */
  fluid: PropTypes.bool
}

export default Container
