import { createUseStyles } from 'react-jss'
import style from './style'
import SocialMedia from '../../SocialMedia'
import { whiteLogo } from 'assets'
import { HomePath } from 'navigation/Routes'
import { Col } from 'UI'

const useStyle = createUseStyles(style)

export default ({ goToUrl }) => {
  const { root, logoCol, logoClass } = useStyle()
  return (
    <div className={root}>
      <Col className={logoCol} grid={6} mgrid={6} sgrid={1} pos='left' align='mid'>
        <a href={HomePath} onClick={e => e.preventDefault()}><img src={whiteLogo} className={logoClass} onClick={() => goToUrl(HomePath)} width={117} height={34} alt='logo' /></a>
      </Col>
      <Col grid={6} mgrid={6} sgrid={6} pos='right' align='mid'>
        <SocialMedia />
      </Col>
    </div>
  )
}
