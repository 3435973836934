import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { cardStyle } from './LearningPathCard.style'
import { formatMinutesDuration } from '../../constants/formatters'
import TypographyElement from '../typographyElement/typographyElement'
import classNames from 'classnames'
import Skeleton from '../skeleton/skeleton'
import Paper from '../paper/paper'
import { Icons } from 'UI/constants'
import Icon from '../icon/icon'

const CardSkeleton = ({
  className,
  height
}) => {
  const useStyles = createUseStyles(cardStyle)
  const classes = useStyles({ height })
  return (
    <Paper className={classNames(classes.cardGroup, className)}>
      <div className={classes.header}>
        <Skeleton width={24} height={24} wrapperWidth={50} />
        <Skeleton width='50%' height={20} />
      </div>
      <Skeleton width={['80%', '60%', '30%']} height={[20, 20, 15]} lines={3} />
      <div className={classes.coursesText}>
        <Skeleton width='50%' height={20} />
      </div>
    </Paper>
  )
}

const Card = ({
  loading,
  shadow,
  className,
  handleCardClick,
  title,
  label = 'Parcours',
  dataTest,
  duration,
  coursesCount,
  height,
  children,
  coursesLabel = 'Formations',
  isNew
}) => {
  const theme = useTheme()
  const useStyles = createUseStyles(cardStyle)
  const { cardGroup, header, headerLeft, pathIcon, coursesText, shadowCard, newBadge } = useStyles({ loading, height })

  if (loading) return (<CardSkeleton className={className} height={height} />)
  else if (shadow) return (<div className={classNames(shadowCard, className)} />)
  else {
    return (
      <Paper
        onClick={handleCardClick}
        className={classNames(cardGroup, className)}
        title={title}
        data-test={dataTest && `${dataTest}-card`}
      >
        <div className={header}>
          <div className={headerLeft}>
            <Icon iconName={Icons.path} style={pathIcon} />
            <TypographyElement variant='body2'>{label}</TypographyElement>
          </div>
          {
          isNew
            ? <div className={newBadge}><TypographyElement variant='caption' color={theme.error[0]} spacing='3px 8px'>NOUVEAU</TypographyElement></div>
            : null
          }
        </div>
        <TypographyElement variant='heading6' maxLines={2}>{title}</TypographyElement>
        <TypographyElement variant='caption' spacing='8px 0 0 0'>{formatMinutesDuration(duration)}</TypographyElement>
        <div className={coursesText}>
          <TypographyElement variant='body2' fontWeight={500} color={theme.primary[0]}>{coursesCount} {coursesLabel}</TypographyElement>
        </div>
        {children}
      </Paper>
    )
  }
}

Card.propTypes = {
  /** provide a className to put your style */
  className: PropTypes.string,
  /** provide a title to the card */
  title: PropTypes.string,
  /** provide a label to the card */
  label: PropTypes.string,
  /** provide a duration to the card */
  duration: PropTypes.number,
  /** provide a trainerName to the card */
  coursesCount: PropTypes.number,
  /** provide a loading to the card */
  loading: PropTypes.bool,
  /** provide a handleCardClick to the card */
  handleCardClick: PropTypes.func,
  /** provide a dataTest to the card */
  dataTest: PropTypes.string,
  /** provide a children to the card */
  children: PropTypes.node
}

export default Card
