import { InputColors } from '../../constants'

export const inputIconStyle = {
  icon: {
    position: 'absolute',
    right: 20,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    color: props => props.color,
    cursor: props => props.cursor,
    height: props => props.height || 54,
    '&:hover': {
      color: InputColors.inputshown
    }
  },
  iconStyle: {
    fontSize: props => props.size || '15px',
    lineHeight: 0
  }
}
