import { Spacing } from '../../constants'

export const searchInputStyle = (theme) => ({
  root: {
    position: 'relative',
    width: props => props.width || '100%'
  },
  inputGroup: {
    width: '100%',
    height: 'auto',
    position: 'relative'
  },
  results: {
    width: '100%',
    backgroundColor: theme.neutral[400],
    position: 'absolute',
    top: '100%',
    zIndex: 1,
    padding: () => Spacing(5, 4),
    borderBottom: `1px solid ${theme.neutral[200]}`,
    borderLeft: `1px solid ${theme.neutral[200]}`,
    borderRight: `1px solid ${theme.neutral[200]}`,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    marginTop: -1
  },
  devider: {
    position: 'absolute',
    top: 0,
    width: '95%',
    height: 1,
    backgroundColor: theme.neutral[200],
    margin: '0 auto'
  }
})
