import {
  MediaQueries
} from 'UI'

export default {
  anchorClass: {
    width: ({ width }) => width || '100%',
    textDecoration: 'none',
    color: 'unset',
    margin: ({ margin }) => margin || null
  },
  [`${MediaQueries.desktop}`]: {
    anchorClass: {
      margin: ({ marginDesktop }) => marginDesktop || null,
      width: ({ desktopWidth }) => desktopWidth || null
    }
  },
  [`${MediaQueries.tablet}`]: {
    anchorClass: {
      margin: ({ marginTablet }) => marginTablet || null,
      width: ({ tabletWidth }) => tabletWidth || null
    }
  },
  [`${MediaQueries.mobile}`]: {
    anchorClass: {
      margin: ({ marginMobile }) => marginMobile || null,
      width: ({ mobileWidth }) => mobileWidth || null
    }
  }
}
