import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ webinar }) => ({
  categories: webinar.data.Categories,
  title: webinar.data.Title,
  webinarLink: webinar.data.WebinarLink
})

export default connect(mapStateToProps, null)(UI)
