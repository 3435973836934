export const badgeStyles = {
  badgeGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    padding: '4px 8px',
    minWidth: 60,
    backgroundColor: ({ backgroundColor }) => backgroundColor
  }
}
