import {
  Spacing
} from '@alphorm/ui'

export default {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: () => Spacing(0, 0, 10, 0)
  },
  alphorm: {
    padding: () => Spacing(0, 0, 3, 0)
  },
  conditionsAndPayment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  paymentContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  payment: {
    display: 'flex',
    alignItems: 'center'
  },
  visaClass: {
    padding: () => Spacing(0, 4)
  }
}
