import { put, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB } from 'constants/'
import { push } from 'connected-react-router'
import { TurnOffLoaderAction, TurnOnLoaderAction } from 'store/config'
import { pageNotFoundPath } from 'navigation/Routes'
import { decorateUrl } from 'components/common/UTMscript'

const initialState = {
  error: false,
  isLoading: true
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case TRAINER_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case GET_TRAINER_BY_ID_SUCESS:
      return {
        ...state,
        ...data,
        isLoading: false
      }
    case GET_TRAINER_BY_ID_FAIL:
      return {
        ...state,
        error: data,
        isLoading: false
      }
    case CLEAR_TRAINER:
      return { ...initialState }
    default:
      return state
  }
}
export const fetchTrainerAction = (trainerId) => ({ type: TRAINER_FETCH_REQUESTED, trainerId })
export const clearTrainerAction = () => ({ type: CLEAR_TRAINER })

function * fetchTrainer (data) {
  const { trainerId } = data
  yield put(TurnOnLoaderAction(true))
  try {
    const response = yield fetch(queries.getTrainerById(trainerId))
    yield put({ type: GET_TRAINER_BY_ID_SUCESS, data: response })
    yield put(TurnOffLoaderAction(false))
  } catch (err) {
    yield put(push(decorateUrl(pageNotFoundPath)))
    yield put({ type: GET_TRAINER_BY_ID_FAIL, err })
  }
}

export function * trainerRootSaga () {
  yield takeLatest(TRAINER_FETCH_REQUESTED, fetchTrainer)
}

const queries = {
  getTrainerById: id => `${BASE_URL_WEB}/Trainer/getTrainerDetail/${id}`
}
const GET_TRAINER_BY_ID_SUCESS = 'GET_TRAINER_BY_ID_SUCESS'
const GET_TRAINER_BY_ID_FAIL = 'GET_TRAINER_BY_ID_FAIL'
const TRAINER_FETCH_REQUESTED = 'TRAINER_FETCH_REQUESTED'
const CLEAR_TRAINER = 'CLEAR_TRAINER'
