import { connect } from 'react-redux'
import UI from './UI'
import { decorateUrl } from 'components/common/UTMscript'
import { push } from 'connected-react-router'

const mapStateToProps = ({ webinars, catalog, referential }) => ({
  webinars: webinars.items,
  webinarsTotal: webinars.webinarsTotal,
  webinarsCount: webinars.webinarsCount,
  error: webinars.error,
  isLoading: webinars.isLoading,
  categories: catalog.categories,
  loadingCategories: catalog.loadingCategories,
  WebinarStatus: referential.WebinarStatus
})

const mapDispatchToProps = dispatch => ({
  goToLink: (url) => dispatch(push(decorateUrl(url)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
