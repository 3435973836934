import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const Icon = ({
  onHandleIconClick,
  iconName,
  style,
  dataTest
}) =>
  <i
    onClick={onHandleIconClick}
    className={classNames(iconName, style)}
    data-test={dataTest}
  />

Icon.propTypes = {
  /** its a classe of style to apply on the icon */
  style: PropTypes.string,
  /** the name of the icon from te library */
  // iconName: PropTypes.oneOf([]),
  /** Handles the onClick  */
  onHandleIconClick: PropTypes.func,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string
}

export default Icon
