import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { optionItemStyle } from './option.style'
import { TypographyElement } from '../../..'
import { Spacing } from '../../../constants'
import classNames from 'classnames'

export const OptionItem = (props) => {
  const theme = useTheme()
  const useStyle = createUseStyles(optionItemStyle)
  const { optionItem, optionItemLabel, flag } = useStyle(props)
  return (
    <div
      className={optionItem}
      onClick={props.handleClick}
      data-test='suggestion-item'
    >
      <TypographyElement
        variant='body2'
        cursor='pointer'
        spacing={Spacing(4)}
        className={optionItemLabel}
        color={props.active ? theme.neutral[400] : theme.neutral[200]}
      >
        <span className={classNames(`flag-icon flag-icon-${props.countryCode?.toLowerCase()}`, flag)} />
        {`${props.countryName} (${props.value})`}
      </TypographyElement>
    </div>
  )
}

OptionItem.propTypes = {
  /** the click handler to fire when a user click on the item */
  handleClick: PropTypes.func.isRequired,
  /** its htmlFor to toggle a checkbox */
  for: PropTypes.string.isRequired,
  /** the text to show in the item */
  value: PropTypes.string.isRequired,
  /** a unique id */
  id: PropTypes.number.isRequired
}

export default OptionItem
