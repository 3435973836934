import { BASE_URL_WEB } from 'constants/'
import fetch from 'core/fetch'
import { put, takeLatest } from 'redux-saga/effects'

const initialState = {
  isQuizOpen: false,
  attemptId: 0,
  attempts: 0,
  showResult: false,
  showAnswers: false,
  questionPosition: 0,
  selectedChoices: [],
  ResultsHistory: [],
  QuizStatus: '',
  Report: {}
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case OPEN_QUIZ:
      return {
        ...state,
        isQuizOpen: data
      }
    case SET_QUESTION_POSITION:
      return {
        ...state,
        questionPosition: data
      }
    case SET_SHOW_ANSWERS:
      return {
        ...state,
        showAnswers: data
      }
    case SET_SHOW_RESULTS:
      return {
        ...state,
        showResult: data
      }
    case SET_SELECTED_CHOICES:
      return {
        ...state,
        selectedChoices: data
      }
    case SET_QUIZ_STATUS:
      return {
        ...state,
        QuizStatus: data
      }
    case GET_QUIZ_CONTENT_SUCCESS:
      return {
        ...state,
        ...data
      }
    case GET_QUIZ_CONTENT_FAIL:
      return {
        ...state,
        data
      }
    case CREATE_QUIZ_ATTEMPT_SUCCESS:
      return {
        ...state,
        attemptId: data
      }
    case CREATE_QUIZ_ATTEMPT_FAIL:
      return {
        ...state,
        data
      }
    case COMPLETE_QUIZ_ATTEMPT_SUCCESS:
      return {
        ...state,
        data
      }
    case COMPLETE_QUIZ_ATTEMPT_FAIL:
      return {
        ...state,
        data
      }
    case MARK_QUESTION_ANSWERED_SUCCESS:
      return {
        ...state,
        data
      }
    case MARK_QUESTION_ANSWERED_FAIL:
      return {
        ...state,
        data
      }
    case GET_QUIZ_HISTORY_SUCCESS:
      return {
        ...state,
        ResultsHistory: data,
        attempts: data.length
      }
    case GET_QUIZ_HISTORY_FAIL:
      return {
        ...state,
        data
      }
    case GET_QUIZ_REPORT_SUCCESS:
      return {
        ...state,
        Report: data
      }
    case GET_QUIZ_REPORT_FAIL:
      return {
        ...state,
        data
      }
    case CLEAR_QUIZ:
      return {
        ...initialState
      }
    default:
      return state
  }
}

export const openQuiz = isOpen => ({ type: OPEN_QUIZ, data: isOpen })
export const fetchQuizContentAction = slug => ({ type: GET_QUIZ_CONTENT_REQUEST, slug })
export const createQuizAttemptAction = courseId => ({ type: CREATE_QUIZ_ATTEMPT_REQUEST, courseId })
export const completeQuizAttemptAction = (attemptId, courseId) => ({ type: COMPLETE_QUIZ_ATTEMPT_REQUEST, attemptId, courseId })
export const markQuestionAnsweredAction = (attemptId, courseId, questionId, userAnswers) => ({ type: MARK_QUESTION_ANSWERED_REQUEST, attemptId, courseId, questionId, userAnswers })
export const getQuizHistoryAction = courseId => ({ type: GET_QUIZ_HISTORY_REQUEST, courseId })
export const getQuizReportAction = courseId => ({ type: GET_QUIZ_REPORT_REQUEST, courseId })
export const setQuestionPosition = position => ({ type: SET_QUESTION_POSITION, data: position })
export const setShowAnswers = position => ({ type: SET_SHOW_ANSWERS, data: position })
export const setShowResults = position => ({ type: SET_SHOW_RESULTS, data: position })
export const setSelectedChoices = choices => ({ type: SET_SELECTED_CHOICES, data: choices })
export const setQuizStatus = status => ({ type: SET_QUIZ_STATUS, data: status })
export const clearQuizAction = () => ({ type: CLEAR_QUIZ })

export function * fetchQuizContent ({ slug }) {
  try {
    const response = yield fetch(queries.getQuizContent(slug))
    yield put({ type: GET_QUIZ_CONTENT_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_QUIZ_CONTENT_FAIL })
  }
}

function * createQuizAttempt ({ courseId }) {
  try {
    const body = {
      courseId
    }
    const response = yield fetch(queries.createQuizAttempt, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    yield put({ type: CREATE_QUIZ_ATTEMPT_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: CREATE_QUIZ_ATTEMPT_FAIL })
  }
}

function * completeQuizAttempt ({ attemptId, courseId }) {
  try {
    const body = {
      attemptId,
      courseId
    }
    yield fetch(queries.completeQuizAttempt, { method: 'put', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body), nojson: true })
    yield put({ type: COMPLETE_QUIZ_ATTEMPT_SUCCESS })
    yield put({ type: GET_QUIZ_HISTORY_REQUEST, courseId })
  } catch (err) {
    yield put({ type: COMPLETE_QUIZ_ATTEMPT_FAIL })
  }
}

function * markQuestionAnswered ({ attemptId, courseId, questionId, userAnswers }) {
  try {
    const body = {
      attemptId,
      courseId,
      questionId,
      userAnswers
    }
    const response = yield fetch(queries.markQuestionAnswered, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body), nojson: true })
    yield put({ type: MARK_QUESTION_ANSWERED_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: MARK_QUESTION_ANSWERED_FAIL })
  }
}

export function * getQuizHistory ({ courseId }) {
  try {
    const response = yield fetch(queries.getQuizHistory(courseId))
    yield put({ type: GET_QUIZ_HISTORY_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_QUIZ_HISTORY_FAIL })
  }
}

export function * getQuizReport ({ courseId }) {
  try {
    const response = yield fetch(queries.getQuizReport(courseId))
    yield put({ type: GET_QUIZ_REPORT_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_QUIZ_REPORT_FAIL })
  }
}

export function * QuizRootSaga () {
  yield takeLatest(GET_QUIZ_CONTENT_REQUEST, fetchQuizContent)
  yield takeLatest(CREATE_QUIZ_ATTEMPT_REQUEST, createQuizAttempt)
  yield takeLatest(COMPLETE_QUIZ_ATTEMPT_REQUEST, completeQuizAttempt)
  yield takeLatest(MARK_QUESTION_ANSWERED_REQUEST, markQuestionAnswered)
  yield takeLatest(GET_QUIZ_HISTORY_REQUEST, getQuizHistory)
  yield takeLatest(GET_QUIZ_REPORT_REQUEST, getQuizReport)
}

const queries = {
  getQuizContent: slug => `${BASE_URL_WEB}/Course/quiz/${slug}`,
  createQuizAttempt: `${BASE_URL_WEB}/Quiz/CreateQuizAttempt`,
  completeQuizAttempt: `${BASE_URL_WEB}/Quiz/MarkQuizAttemptComplete`,
  markQuestionAnswered: `${BASE_URL_WEB}/Quiz/MarkQuestionAnswered`,
  getQuizHistory: courseId => `${BASE_URL_WEB}/Quiz/${courseId}`,
  getQuizReport: courseId => `${BASE_URL_WEB}/Quiz/GetQuizReport/${courseId}`
}

const OPEN_QUIZ = 'OPEN_QUIZ'
const SET_QUESTION_POSITION = 'SET_QUESTION_POSITION'
const SET_SHOW_ANSWERS = 'SET_SHOW_ANSWERS'
const SET_SHOW_RESULTS = 'SET_SHOW_RESULTS'
const SET_QUIZ_STATUS = 'SET_QUIZ_STATUS'
const SET_SELECTED_CHOICES = 'SET_SELECTED_CHOICES'
const GET_QUIZ_CONTENT_SUCCESS = 'GET_QUIZ_CONTENT_SUCCESS'
const GET_QUIZ_CONTENT_FAIL = 'GET_QUIZ_CONTENT_FAIL'
const GET_QUIZ_CONTENT_REQUEST = 'GET_QUIZ_CONTENT_REQUEST'
const CREATE_QUIZ_ATTEMPT_SUCCESS = 'CREATE_QUIZ_ATTEMPT_SUCCESS'
const CREATE_QUIZ_ATTEMPT_FAIL = 'CREATE_QUIZ_ATTEMPT_FAIL'
const CREATE_QUIZ_ATTEMPT_REQUEST = 'CREATE_QUIZ_ATTEMPT_REQUEST'
const COMPLETE_QUIZ_ATTEMPT_SUCCESS = 'COMPLETE_QUIZ_ATTEMPT_SUCCESS'
const COMPLETE_QUIZ_ATTEMPT_FAIL = 'COMPLETE_QUIZ_ATTEMPT_FAIL'
const COMPLETE_QUIZ_ATTEMPT_REQUEST = 'COMPLETE_QUIZ_ATTEMPT_REQUEST'
const MARK_QUESTION_ANSWERED_SUCCESS = 'MARK_QUESTION_ANSWERED_SUCCESS'
const MARK_QUESTION_ANSWERED_FAIL = 'MARK_QUESTION_ANSWERED_FAIL'
const MARK_QUESTION_ANSWERED_REQUEST = 'MARK_QUESTION_ANSWERED_REQUEST'
const GET_QUIZ_HISTORY_SUCCESS = 'GET_QUIZ_HISTORY_SUCCESS'
const GET_QUIZ_HISTORY_FAIL = 'GET_QUIZ_HISTORY_FAIL'
const GET_QUIZ_HISTORY_REQUEST = 'GET_QUIZ_HISTORY_REQUEST'
const GET_QUIZ_REPORT_SUCCESS = 'GET_QUIZ_REPORT_SUCCESS'
const GET_QUIZ_REPORT_FAIL = 'GET_QUIZ_REPORT_FAIL'
const GET_QUIZ_REPORT_REQUEST = 'GET_QUIZ_REPORT_REQUEST'
const CLEAR_QUIZ = 'CLEAR_QUIZ'
