import { createTheAllCoursesCategory, fetchCategoriesAction } from 'components/Catalog/store'
import { fetchDetectedShopAction } from 'components/common/Support/store'
import { fetchUserElligibilityAction } from 'components/Profile/store'
import { fetchWidgetsAction } from './marketing'
import { fetchReferentialAction } from './referential'
import { SetSessionId } from './config'
import { v4 as uuidv4 } from 'uuid'
import { cleanStoreAction } from 'pages/Course/store'
const sessionId = uuidv4()

export const initDataStore = dispatch => {
  dispatch(cleanStoreAction())
  dispatch(SetSessionId(sessionId))
  dispatch(fetchReferentialAction())
  dispatch(fetchDetectedShopAction())
  dispatch(createTheAllCoursesCategory())
  dispatch(fetchCategoriesAction())
  dispatch(fetchUserElligibilityAction())
  dispatch(fetchWidgetsAction())
}
