export const checkBoxStyle = (theme) => ({
  inputGroup: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'flex-start',
    width: '100%'
  },
  checkbox: ({ indeterminate }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 16,
      height: 16,
      borderRadius: 3,
      borderWidth: 2,
      borderColor: theme.neutral[0],
      borderStyle: 'solid',
      position: 'relative',
      transition: 'border-color .1s linear'
    },
    '&::after': {
      content: indeterminate ? '"-"' : '"\u2713"',
      display: 'block',
      position: 'absolute',
      lineHeight: 0,
      color: theme.neutral[400],
      transition: 'color .1s linear'
    }
  }),
  input: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    cursor: 'pointer',
    width: 18,
    height: 18,
    margin: 0,
    zIndex: 999,
    '&:checked + label::before': {
      borderColor: theme.secondary[100],
      backgroundColor: theme.secondary[100]
    },
    '&:checked + label::after': {
      color: theme.neutral[0]
    },
    '&:hover + label::before': {
      borderColor: theme.secondary[100]
    },
    '&:disabled + label::before': {
      borderColor: theme.neutral[0],
      backgroundColor: theme.neutral[0]
    },
    '&:disabled + label::after': {
      color: theme.neutral[100],
      content: '"-"'
    },
    '&:checked:disabled + label::after': {
      content: '"\u2713"'
    }
  }
})
