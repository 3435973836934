import theme from './theme'

export const bannerStatus = {
  success: 'success',
  error: 'error',
  alert: 'alert',
  info: 'info'
}

export const bannerProps = (theme, status, secondary) => ({
  success: {
    backgroundColor: theme.success[0],
    borderColor: theme.success[0],
    color: secondary ? theme.success[0] : theme.neutral[0]
  },
  error: {
    backgroundColor: theme.error[0],
    borderColor: theme.error[0],
    color: secondary ? theme.error[0] : theme.neutral[0]
  },
  alert: {
    backgroundColor: theme.warning[0],
    borderColor: theme.warning[0],
    color: secondary ? theme.warning[0] : theme.neutral[0]
  },
  info: {
    backgroundColor: theme.info[0],
    borderColor: theme.info[0],
    color: secondary ? theme.info[0] : theme.neutral[0]
  }
}[status])

export const bannerTextColors = (status, secondary) => ({
  success: secondary ? theme.success[0] : theme.neutral[0],
  error: secondary ? theme.error[0] : theme.neutral[0],
  alert: secondary ? theme.warning[0] : theme.neutral[0],
  info: secondary ? theme.info[0] : theme.neutral[0]
}[status])
