import { createUseStyles } from 'react-jss'
import style from './style'
import {
  Icon,
  Icons,
  TypographyElement
} from '@alphorm/ui'
import { FacebookShareLink, TwitterShareLink, LinkdinShareLink } from 'constants/'

const useStyle = createUseStyles(style)

export default ({ title, shareLinks, iconSize }) => {
  const { socialMediaContainer, iconsContainer, socialMedia, icon, linkClass } = useStyle({ iconSize })
  return (
    <div className={socialMediaContainer}>
      <TypographyElement
        variant='heading4'
        spacing='0 8px 0 0'
      >
        {title}
      </TypographyElement>
      <div className={iconsContainer}>
        <a aria-label='LinkedIn' target='_blank' rel='noopener noreferrer' href={`${LinkdinShareLink}${window.location}`} className={linkClass}>
          <span className={socialMedia}>
            <Icon style={icon} iconName={Icons.linkedin} />
          </span>
        </a>
        <a aria-label='Twitter' target='_blank' rel='noopener noreferrer' href={`${TwitterShareLink}${window.location}`} className={linkClass}>
          <span className={socialMedia}>
            <Icon style={icon} iconName={Icons.twitter} />
          </span>
        </a>
        <a aria-label='Facebook' target='_blank' rel='noopener noreferrer' href={`${FacebookShareLink}${window.location}`} className={linkClass}>
          <span className={socialMedia}>
            <Icon style={icon} iconName={Icons.facebook} />
          </span>
        </a>
        <a
          className={linkClass}
          onClick={(e) => {
            e.preventDefault()
            navigator.clipboard.writeText(shareLinks?.externalLink || window.location)
          }}
        >
          <span className={socialMedia}>
            <Icon style={icon} iconName={Icons.info} />
          </span>
        </a>
      </div>
    </div>
  )
}
