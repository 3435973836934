import { Typography } from '../../constants'
import mediaQueries from '../../constants/mediaQueries'

export const TypographyElementStyle = {
  text: props => ({
    fontFamily: Typography[props.variant].fontFamily || Typography.fontFamily,
    color: props.color || Typography[props.variant].color,
    fontSize: props.fontSize || Typography[props.variant].fontSize,
    fontWeight: props.fontWeight || Typography[props.variant].fontWeight,
    lineHeight: props.lineHeight || Typography[props.variant].lineHeight,
    fontStyle: props.fontStyle || 'normal',
    textAlign: props.align,
    padding: props.spacing ? props.spacing : 0,
    margin: props.margin ? props.margin : 0,
    display: props.maxLines ? '-webkit-box' : props.display || 'block',
    cursor: props.cursor || null,
    opacity: props.opacity || null,
    textDecoration: props.textDecoration || 'none',
    fontStretch: 'normal',
    '-webkit-line-clamp': props.maxLines,
    'line-clamp': props.maxLines,
    '-webkit-box-orient': 'vertical',
    overflow: props.maxLines ? 'hidden' : null
  }),
  [`${mediaQueries.tablet}`]: {
    text: props => ({
      color: props.colorTablet || Typography[props.variantTablet].color,
      fontSize: props.fontSizeTablet || props.variantTablet !== props.variant ? Typography[props.variantTablet].fontSize : Typography[props.variant].tabletFontSize,
      fontWeight: props.fontWeightTablet || Typography[props.variantTablet].fontWeight,
      lineHeight: props.lineHeightTablet || props.variantTablet !== props.variant ? Typography[props.variantTablet].lineHeight : Typography[props.variant].lineHeightTablet,
      textAlign: props.alignTablet,
      padding: props.spacingTablet ? props.spacingTablet : 0,
      margin: props.marginTablet ? props.marginTablet : 0,
      display: props.displayTablet || 'block'
    })
  },
  [`${mediaQueries.mobile}`]: {
    text: props => ({
      color: props.colorMobile || Typography[props.variantMobile].color,
      fontSize: props.fontSizeMobile || props.variantMobile !== props.variant ? Typography[props.variantMobile].fontSize : Typography[props.variant].mobileFontSize,
      fontWeight: props.fontWeightMobile || Typography[props.variantMobile].fontWeight,
      lineHeight: props.lineHeightMobile || props.variantMobile !== props.variant ? Typography[props.variantMobile].lineHeight : Typography[props.variant].lineHeightMobile,
      textAlign: props.alignMobile,
      padding: props.spacingMobile ? props.spacingMobile : 0,
      margin: props.marginMobile ? props.marginMobile : 0,
      display: props.displayMobile || 'block'
    })
  }
}
