import { textColorMap } from '../../constants'

export const inputMessageStyle = (theme) => ({
  box: props => ({
    width: '100%',
    minHeight: '26px',
    padding: [0, 16],
    display: 'flex',
    alignItems: 'center',
    lineHeight: '26px',
    backgroundColor: props.background ? theme.neutral[400] : '',
    color: props.color ? props.color : props.status ? textColorMap(theme, props.status) : ''
  }),
  icon: {
    lineHeight: '10px'
  }
})
