import { Typography, blue, neutral, Spacing } from '../../constants'

export const menuItemStyle = {
  '@keyframes hover-translate': {
    '0%': {
      borderLeft: `2px solid ${blue[0]}`
    },
    '20%': {
      borderLeft: `2px solid ${blue[0]}`
    },
    '80%': {
      borderLeft: `2px solid ${blue[0]}`
    },
    '100%': {
      borderLeft: 'none'
    }
  },
  menuItemGroup: {
    height: 38,
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&:hover $menuItem': {
      animation: '$hover-translate 1500ms ease forwards'
    },
    '&:hover > $menuItem': {
      transform: 'translateX(5px)'
    }
  },
  menuItem: {
    position: 'relative',
    padding: () => Spacing(0, 4, 0, 4),
    margin: () => Spacing(3, 0, 3, 0),
    width: '100%',
    fontFamily: Typography.fontFamily,
    fontSize: Typography.menuItem,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    lineHeight: 1.71,
    color: neutral[6],
    '& span': {
      fontWeight: 'bold',
      marginLeft: 5
    },
    transition: 'transform 200ms linear'
  },
  input: props => ({
    opacity: 0,
    position: 'absolute',
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    margin: 0,
    zIndex: 99,
    '&:checked + div': {
      width: '100%'
    },
    '&:hover + div + div': {
      width: '100%'
    },
    '&:checked + div + div': {
      color: blue[0],
      borderLeft: `2px solid ${blue[0]}`,
      '& a': {
        borderBottom: props.all ? '1px solid ' + blue[0] : 'none',
        lineHeight: props.all ? '12px !important' : 1.71,
        display: props.all ? 'inline-flex' : ''
      }
    },
    '& + div + div a': {
      borderBottom: props.all ? '1px solid ' + neutral[6] : 'none',
      lineHeight: props.all ? '12px !important' : 1.71,
      display: props.all ? 'inline-flex' : ''
    }
  }),
  selector: {
    position: 'absolute',
    width: '0',
    height: '100%',
    transition: 'all 0.3s linear'
  }
}
