export default (theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    gap: 16,
    scrollBehavior: 'smooth'
  },
  icon: {
    minWidth: 27,
    minHeight: 27,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.secondary[0],
    backgroundColor: theme.neutral[400],
    fontSize: 'x-small',
    border: `1px solid ${theme.secondary[0]}`,
    borderRadius: '50%',
    boxShadow: `0px 0px 80px 50px ${theme.neutral[400]}`,
    position: 'sticky',
    cursor: 'pointer',
    zIndex: 1
  },
  left: {
    left: 4,
    display: ({ hideLeft }) => hideLeft ? 'none' : 'flex'
  },
  right: {
    right: 4,
    display: ({ hideRight }) => hideRight ? 'none' : 'flex'
  }
})
