import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import { emptySuggestionItemStyle } from './emptySuggestionItem.style'

export const EmptySuggestionItem = (props) => {
  const useStyle = createUseStyles(emptySuggestionItemStyle)
  const { emptySuggestionItem } = useStyle(props)
  return (
    <div className={emptySuggestionItem}>
      <label>
        {props.value}
      </label>
    </div>
  )
}

EmptySuggestionItem.propTypes = {
  /** value its the text to show  */
  value: PropTypes.string.isRequired
}

export default EmptySuggestionItem
