import {
  Container,
  TypographyElement,
  neutral,
  Row,
  Col,
  Button,
  ButtonVariation,
  Icon,
  Icons,
  MediaQuery,
  Skeleton
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import CategoryTag from 'components/Webinar/CategoryTag'
import { TimeZoneDateFormat } from 'helpers'
import moment from 'moment'
import TimeCountdown from 'components/Webinar/TimeCountdown'
import ShareOnSocials from 'components/Webinar/ShareOnSocials'
import Text from './text.json'
import StatusTag from '../StatusTag'
import { signin } from 'identity/userManager'

const useStyle = createUseStyles(style)

export default ({
  categories,
  title,
  subtitle,
  plannedDate,
  endDate,
  isUserParticipating,
  webinarId,
  isConnected,
  registerToWebinar,
  isLoading
}) => {
  const {
    container,
    categoriesContainer,
    icon,
    dateContainer,
    buttonIconClass,
    socialsContainer,
    skeletonContainer
  } = useStyle()

  const handleRegisterToWebinarClick = () => {
    if (!isConnected) signin()
    else if (!isUserParticipating) registerToWebinar(webinarId)
  }

  return (
    <Container>
      {
        isLoading
          ? (
            <div className={container}>
              <Row>
                <Col direction='column' grid={12} mgrid={12} sgrid={12} align='mid'>
                  <div className={categoriesContainer}>
                    <Skeleton lines={1} height={23} wrapperWidth={100} />
                    <Skeleton lines={1} height={23} wrapperWidth={100} />
                    <Skeleton lines={1} height={23} wrapperWidth={100} />
                  </div>
                  <div className={dateContainer}>
                    <Skeleton lines={1} height={23} wrapperWidth={200} />
                  </div>
                  <div className={skeletonContainer}>
                    <Skeleton lines={1} height={48} wrapperWidth={1100} />
                    <Skeleton lines={1} height={48} wrapperWidth={800} />
                  </div>
                  <MediaQuery HideOnTablet HideOnMobile>
                    <div className={skeletonContainer}>
                      <Skeleton lines={1} height={20} wrapperWidth={1000} />
                      <Skeleton lines={1} height={20} wrapperWidth={900} />
                    </div>
                  </MediaQuery>
                  <Skeleton lines={1} height={52} wrapperWidth={300} margin='0 0 16px 0' />
                  <Skeleton lines={1} height={48} wrapperWidth={365} />
                  <div className={socialsContainer}>
                    <Skeleton lines={1} height={48} wrapperWidth={365} />
                  </div>
                </Col>
              </Row>
            </div>
            )
          : (
            <div className={container}>
              <Row>
                <Col direction='column' grid={12} mgrid={12} sgrid={12} align='mid'>
                  <div className={categoriesContainer}>
                    <StatusTag />
                    {categories?.map((category, key) => (
                      <CategoryTag
                        key={key}
                        name={category.Name}
                        categoryId={category.Id}
                        photo={category.ImageUrl}
                      />
                    ))}
                  </div>
                  <div className={dateContainer}>
                    <Icon iconName={Icons.clock} style={icon} />
                    <TypographyElement
                      variant='body1'
                      align='left'
                      fontWeight={400}
                      display='inline-flex'
                      fontSize='12px'
                      color={neutral[4]}
                    >
                      {moment(plannedDate).format(TimeZoneDateFormat)}
                    </TypographyElement>
                  </div>
                  <TypographyElement
                    component='h2'
                    variant='heading2'
                    lineHeight='55px'
                    align='center'
                    spacing='0 0 12px 0'
                    lineHeightTablet='40px'
                    lineHeightMobile='40px'
                    spacingMobile='0 0 33px 0'
                    spacingTablet='0 0 33px 0'
                    fontSize='48px'
                    fontWeight={600}
                  >
                    {title}
                  </TypographyElement>
                  <MediaQuery HideOnTablet HideOnMobile>
                    <TypographyElement
                      variant='body1'
                      align='center'
                      lineHeight='30px'
                      fontWeight={400}
                      display='inline-flex'
                      color={neutral[5]}
                      spacing='0 0 42px 0'
                      fontSize='20px'
                    >
                      {subtitle}
                    </TypographyElement>
                  </MediaQuery>
                  <TimeCountdown plannedDate={plannedDate} endDate={endDate} />
                  <Button
                    width={365}
                    widthTablet='100%'
                    widthMobile='100%'
                    variation={ButtonVariation.confirmation}
                    label={Text.subscribe}
                    handleClick={handleRegisterToWebinarClick}
                    textProps={{
                      spacing: isUserParticipating ? '0 0 0 4px' : null
                    }}
                  >
                    {isUserParticipating ? <Icon iconName={Icons.success} style={buttonIconClass} /> : null}
                  </Button>
                  <div className={socialsContainer}>
                    <ShareOnSocials title={Text.shareWebinar} iconSize={45} />
                  </div>
                </Col>
              </Row>
            </div>)
      }
    </Container>
  )
}
