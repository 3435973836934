import { MediaQueries } from 'UI/constants'

export const selectListStyle = (theme) => ({
  selectListContainer: {
    position: 'relative',
    width: '100%',
    marginBottom: ({ gutterBottom }) => `${gutterBottom || 0}px`
  },
  selectList: ({ toggleSuggestionList }) => ({
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 54,
    border: `1px solid ${theme.neutral[0]}`,
    borderBottomLeftRadius: toggleSuggestionList ? 0 : 8,
    borderBottomRightRadius: toggleSuggestionList ? 0 : 8,
    borderRadius: 8,
    backgroundColor: theme.neutral[400],
    cursor: 'pointer',
    display: 'flex',
    padding: '15px 42px 15px 16px',
    alignItems: 'center',
    '& > div:last-child': {
      transition: 'all 0.15s linear',
      transform: toggleSuggestionList ? 'rotate(180deg)' : 'rotate(0deg)',
      cursor: 'pointer'
    },
    '&::before': {
      content: '""',
      display: 'block',
      height: '100%',
      width: '42px',
      position: 'absolute',
      right: 0,
      backgroundColor: theme.neutral[400]
    }
  }),
  [`${MediaQueries.tablet}`]: {
    selectListContainer: {
      marginBottom: ({ gutterBottomTablet }) => gutterBottomTablet && `${gutterBottomTablet}px`
    }
  },
  [`${MediaQueries.mobile}`]: {
    selectListContainer: {
      marginBottom: ({ gutterBottomMobile }) => gutterBottomMobile && `${gutterBottomMobile}px`
    }
  }
})
