import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import style from './codeVerificationInput.style'
import classNames from 'classnames'

const CodeVerificationInput = ({
  className,
  textInputClassName,
  handleChange,
  status,
  codeLength,
  inputHeight,
  inputHeightTablet,
  inputHeightMobile,
  inputWidth,
  value,
  sectionGap,
  numberOfSections,
  inputGap,
  justifyContent,
  margin
}) => {
  const useStyle = createUseStyles(style)
  const inputRefs = useRef([])
  const classes = useStyle({ status, inputHeight, inputHeightTablet, inputHeightMobile, inputWidth, sectionGap, inputGap, justifyContent, margin })
  const inputs = Array.from(new Array(codeLength).keys())
  const sections = new Array(numberOfSections).fill(0)
  const [values, setValues] = useState([...value, ...(new Array(codeLength - value.length).fill(''))])
  const numberOfInputsInSection = Math.floor(codeLength / numberOfSections)

  const onChange = (value, index) => {
    const newValues = [...values]
    newValues[index] = value
    value !== '' && index < codeLength - 1 && inputRefs.current[index + 1].focus()
    setValues([...newValues])
    handleChange && handleChange(newValues)
  }

  const onPast = (event) => {
    const pasteValue = (event.clipboardData || window.clipboardData).getData('text').slice(0, codeLength)
    setValues([...pasteValue, ...(new Array(codeLength - pasteValue.length).fill(''))])
  }

  useEffect(() => {
    window.addEventListener('paste', onPast)
    return () => {
      window.removeEventListener('paste', onPast)
    }
  }, [])

  useEffect(() => {
    setValues([...value, ...(new Array(codeLength - value.length).fill(''))])
    if (value.length === 0) {
      inputRefs?.current[0]?.focus()
    }
  }, [value])

  return (
    <div className={classNames(className, classes.inputGroup)}>
      {
        sections.map((section, indexSection) =>
          <div key={indexSection} className={classes.sectionClass}>
            {
              inputs.slice(indexSection * numberOfInputsInSection, indexSection * numberOfInputsInSection + numberOfInputsInSection).map((inputIndex, index) =>
                <input
                  key={inputIndex}
                  value={values[inputIndex]}
                  className={classNames(textInputClassName, classes.input)}
                  type='text'
                  maxLength='1'
                  onChange={(e) => onChange(e.target.value, inputIndex)}
                  ref={el => { inputRefs.current[inputIndex] = el }}
                />
              )
            }
          </div>
        )
      }
    </div>
  )
}

CodeVerificationInput.propTypes = {
  /** the state of the input */
  status: PropTypes.oneOf(['info', 'success', 'error', 'text']),
  /**  value by default shiped with the input */
  value: PropTypes.string,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** the code length */
  codeLength: PropTypes.number,
  /** height of inputs */
  inputHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** width of inputs */
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** className of the input */
  textInputClassName: PropTypes.string,
  /** className of the component */
  className: PropTypes.string,
  /** sectionGap between inputs */
  sectionGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** input gap */
  inputGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** number of sections */
  numberOfSections: PropTypes.number
}

CodeVerificationInput.defaultProps = {
  codeLength: 6,
  inputHeight: 94,
  inputWidth: 80,
  value: '',
  sectionGap: '1.5rem',
  inputGap: '0.5rem',
  numberOfSections: 2
}

export default CodeVerificationInput
