import { addToCartWebhookAction, ADD_ITEM_TO_CART, RESET_SHOPPING_CART_SUCCEEDED, RESET_SUBSCRIPTION_CART } from 'components/Cart/store'
import { openFailNotification } from 'components/common/Notification/store'
import { fetchUserSubscriptionAction } from 'components/Profile/store'
import { BASE_URL_WEB, ItemTypesDataLayer, ItemTypesEnum, productsIdDataLayer, productsNameDataLayer, ProfileTypes } from 'constants/'
import fetch from 'core/fetch'
import { AddToCartDataLayer } from 'helpers'
import { LOGOUT_SUCCESS } from 'identity/store'
import { put, select, takeLatest } from 'redux-saga/effects'
import { TurnOffLoaderAction, TurnOnLoaderAction } from 'store/config'
import { defaultCurrency } from 'store/currency'
import Text from './text.json'
import { push } from 'connected-react-router'
import { SubscriptionCartPaymentSuccessPath, SubscriptionCheckoutPath } from 'navigation/Routes'

const initialState = {
  step: 1,
  initialSubscriptionChoice: 0,
  subscriptionChoice: 0,
  CouponStillValid: null,
  coupon: '',
  couponId: '',
  Total: 0,
  inCart: false,
  finaleSubscriptionChoice: null,
  computing: false,
  loading: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case COMPUTE_SUBSCRIPTION_CART_REQUESTED:
      return {
        ...state,
        computing: true
      }
    case GET_SUBSCRIPTION_CART_SUCCEEDED:
      return {
        ...state,
        ...data
      }
    case NEXT_STEP:
      return {
        ...state,
        step: state.step + 1
      }
    case PREVIOUS_STEP:
      return {
        ...state,
        step: state.step - 1
      }
    case RESET_STEPS:
      return {
        ...state,
        step: 1
      }
    case SELECT_INITIAL_CHOICE:
      return {
        ...state,
        initialSubscriptionChoice: data,
        subscriptionChoice: data,
        inCart: true
      }
    case SELECT_CHOICE:
      return {
        ...state,
        subscriptionChoice: data,
        inCart: true,
        computing: true
      }
    case HANDLE_SUBSCRIPTION_COUPON_CHANGE:
      return {
        ...state,
        ...data
      }
    case VERIFY_SUBSCRIPTION_COUPON_SUCCEEDED:
      return {
        ...state,
        ...data
      }
    case VERIFY_SUBSCRIPTION_COUPON_FAILED:
      return {
        ...state,
        ...data
      }
    case COMPUTE_SUBSCRIPTION_CART_SUCCEEDED:
      return {
        ...state,
        Total: data.Total,
        computing: false
      }
    case FINALIZE_SUBSCRIPTION_ORDER:
      return {
        ...state,
        loading: true
      }
    case FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED:
      return {
        ...state,
        finaleSubscriptionChoice: state.subscriptionChoice,
        inCart: false,
        loading: false,
        fromOnboarding: data.fromOnboarding
      }
    case FINALIZE_SUBSCRIPTION_ORDER_FAILED:
      return {
        ...state,
        loading: false
      }
    case RESET_COUPON_SUBSCRIPTION_CART_SUCCEEDED:
      return {
        ...state,
        CouponStillValid: null,
        coupon: '',
        couponId: ''
      }
    case RESET_SUBSCRIPTION_CART:
    case LOGOUT_SUCCESS:
    case RESET_SHOPPING_CART_SUCCEEDED:
    case ADD_ITEM_TO_CART:
      return initialState
    default:
      return state
  }
}

export const lastStep = 1

export const paymentStep = 1

const getCartState = ({ subscriptionCart }) => subscriptionCart

const getSupportState = ({ support }) => support

const getReferential = ({ referential }) => referential

const getCreditCards = ({ payment }) => payment.creditCards

const getIdentity = ({ identity }) => identity

const getCurrencyState = ({ currency }) => currency

export const GoToNextStep = _ => ({ type: NEXT_STEP })

export const GoToPreviousStep = _ => ({ type: PREVIOUS_STEP })

export const ResetStep = _ => ({ type: RESET_STEPS })

export const SelectInitialChoice = data => ({ type: SELECT_INITIAL_CHOICE, data })

export const SelectChoice = (data, noCouponReset) => ({ type: SELECT_CHOICE, data, noCouponReset })

export const HandleCouponChangeAction = id => id ? ({ type: HANDLE_SUBSCRIPTION_COUPON_CHANGE, data: { coupon: id } }) : ({ type: RESET_COUPON_SUBSCRIPTION_CART_REQUESTED })

export const VerifyCouponAction = _ => ({ type: VERIFY_SUBSCRIPTION_COUPON })

export const FinalizeSubscriptionOrderAction = (next, noLoader, fromOnboarding, isThreeTimes) => ({ type: FINALIZE_SUBSCRIPTION_ORDER, next, noLoader, fromOnboarding, isThreeTimes })

export const ComputeSubscriptionCartAction = _ => ({ type: COMPUTE_SUBSCRIPTION_CART_REQUESTED })

export const AddSubscriptionToCartAction = _ => ({ type: ADD_SUBSCRIPTION_TO_CART_REQUESTED })

export const ResetSubscriptionCartAction = _ => ({ type: RESET_SUBSCRIPTION_CART })

export const ResetCouponSubscriptionCartAction = _ => ({ type: RESET_COUPON_SUBSCRIPTION_CART_REQUESTED })

function * AddSubscriptionToCart ({ noCouponReset }) {
  const { SubscriptionTypePrices } = yield select(getSupportState)
  const { subscriptionChoice } = yield select(getCartState)
  const { user } = yield select(getIdentity)
  const subscription = SubscriptionTypePrices.find(subscriptionType => subscriptionType.BillingCycle === subscriptionChoice)
  try {
    const response = yield fetch(queries.addSubscriptionToCart, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(subscription.Id) })
    if (response.status === 404) throw new Error()
    yield put(addToCartWebhookAction(user.email))
    AddToCartDataLayer({
      item: {
        item_name: productsNameDataLayer[subscriptionChoice],
        item_id: productsIdDataLayer[subscriptionChoice],
        item_category: ItemTypesDataLayer.subscription,
        price: subscription.AlternativePrice,
        quantity: 1
      }
    })
    yield put({ type: ADD_SUBSCRIPTION_TO_CART_SUCCEEDED })
    if (!noCouponReset) yield put(ResetCouponSubscriptionCartAction())
  } catch (error) {
    yield put({ type: ADD_SUBSCRIPTION_TO_CART_FAILED })
  }
}

function * VerifyCoupon () {
  const { coupon } = yield select(getCartState)
  const { ItemTypes } = yield select(getReferential)
  if (coupon) {
    try {
      const response = yield fetch(queries.verifyCoupon(coupon, ItemTypes[ItemTypesEnum.Subscription]))
      if (response.status === 404) throw new Error()
      yield put({ type: VERIFY_SUBSCRIPTION_COUPON_SUCCEEDED, data: { CouponStillValid: true, couponId: response.Id } })
    } catch (error) {
      yield put({ type: VERIFY_SUBSCRIPTION_COUPON_FAILED, data: { CouponStillValid: coupon.length === 0 ? null : false, couponId: '', coupon: coupon } })
    }
  }
}

function * ComputeSubscriptionCart () {
  const cart = yield select(getCartState)
  const { ItemTypes } = yield select(getReferential)
  const { SubscriptionTypePrices } = yield select(getSupportState)
  const { subscriptionChoice } = yield select(getCartState)
  const subscription = SubscriptionTypePrices.find(subscriptionType => subscriptionType.BillingCycle === subscriptionChoice)
  const currency = yield select(getCurrencyState)
  const useEuro = currency.initialCurrency === defaultCurrency
  const autoAppliedCouponId = subscription?.Promotion?.AutoAppliedCouponId
  const body = {
    couponId: useEuro ? (cart.couponId || autoAppliedCouponId || null) : null,
    autoAppliedCoupon: !!autoAppliedCouponId,
    cartItems: [{
      ItemId: subscription?.Id,
      ItemType: ItemTypes[ItemTypesEnum.Subscription]
    }],
    itemType: ItemTypes[ItemTypesEnum.Subscription],
    subscriptionCycleType: cart.subscriptionChoice,
    useEuro
  }
  try {
    const response = yield fetch(queries.compute, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    yield put({ type: COMPUTE_SUBSCRIPTION_CART_SUCCEEDED, data: { CouponStillValid: response.CouponStillValid, Total: response.Total } })
  } catch (err) {
    yield put({ type: COMPUTE_SUBSCRIPTION_CART_FAILED })
  }
}

function * FinalizeSubscriptionOrder ({ next, noLoader, fromOnboarding, isThreeTimes }) {
  if (!noLoader) yield put(TurnOnLoaderAction())
  const { couponId, subscriptionChoice } = yield select(getCartState)
  const { ItemTypes, PaymentScheduleTypes } = yield select(getReferential)
  const { SubscriptionTypePrices } = yield select(getSupportState)
  const ItemType = ItemTypes[ItemTypesEnum.Subscription]
  const creditCards = yield select(getCreditCards)
  const defaultCreditCard = creditCards.find(card => card.IsDefault)
  const currency = yield select(getCurrencyState)
  const useEuro = currency.initialCurrency === defaultCurrency
  const subscription = SubscriptionTypePrices.find(subscriptionType => subscriptionType.BillingCycle === subscriptionChoice)
  const autoAppliedCouponId = subscription?.Promotion?.AutoAppliedCouponId
  try {
    const body = {
      PaymentMethodId: defaultCreditCard.Id,
      paymentMethodType: defaultCreditCard.PaymentMethodType,
      paymentScheduleType: isThreeTimes ? PaymentScheduleTypes.ThreeTimes : PaymentScheduleTypes.OneTime,
      ItemType,
      SubscriptionCycleType: subscriptionChoice,
      autoAppliedCoupon: !!autoAppliedCouponId
    }
    if (couponId || autoAppliedCouponId) {
      body.couponId = couponId || autoAppliedCouponId
      yield fetch(queries.applyCoupon(body.couponId), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    }
    yield fetch(queries.changeEuroAsDefault(useEuro), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    const response = yield fetch(queries.finalizeSubscriptionOrder, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    if (!response.IsOrderProcessedSuccesfull) throw new Error('Order not processed')
    yield put({ type: FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED, data: { ProfileType: ProfileTypes.PremiumSubscriber, fromOnboarding: fromOnboarding } })
    yield put(fetchUserSubscriptionAction())
    yield next ? next() : put(push(`${SubscriptionCheckoutPath}${SubscriptionCartPaymentSuccessPath}`, { fromCart: true }))
    yield put(TurnOffLoaderAction())
  } catch (err) {
    yield put({ type: FINALIZE_SUBSCRIPTION_ORDER_FAILED, err })
    yield put(TurnOffLoaderAction())
    yield put(openFailNotification(Text.finalizeSubscriptionOrderFailed))
  }
}

function * ApplySubscriptionCoupon ({ data }) {
  const { isConnected } = yield select(getIdentity)
  if (isConnected) {
    try {
      yield fetch(queries.applyCoupon(data.couponId), { method: 'post', headers: { 'Content-Type': 'application/json' } })
      yield put({ type: APPLY_SUBSCRIPTION_COUPON_SUCCEEDED })
    } catch (error) {
      yield put({ type: APPLY_SUBSCRIPTION_COUPON_FAILED })
    }
  }
}

function * RemoveSubscriptionCoupon () {
  const { couponId } = yield select(getCartState)
  try {
    if (couponId) yield fetch(queries.removeCoupon(couponId), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    yield put({ type: RESET_COUPON_SUBSCRIPTION_CART_SUCCEEDED })
  } catch (error) {
    yield put({ type: RESET_COUPON_SUBSCRIPTION_CART_FAILED })
  }
}

export function * subscriptionCartRootSaga () {
  yield takeLatest(VERIFY_SUBSCRIPTION_COUPON, VerifyCoupon)
  yield takeLatest(FINALIZE_SUBSCRIPTION_ORDER, FinalizeSubscriptionOrder)
  yield takeLatest([
    COMPUTE_SUBSCRIPTION_CART_REQUESTED,
    VERIFY_SUBSCRIPTION_COUPON_SUCCEEDED,
    VERIFY_SUBSCRIPTION_COUPON_FAILED,
    GET_SUBSCRIPTION_CART_SUCCEEDED,
    ADD_SUBSCRIPTION_TO_CART_SUCCEEDED,
    RESET_COUPON_SUBSCRIPTION_CART_SUCCEEDED
  ], ComputeSubscriptionCart)
  yield takeLatest([ADD_SUBSCRIPTION_TO_CART_REQUESTED, SELECT_CHOICE], AddSubscriptionToCart)
  yield takeLatest(VERIFY_SUBSCRIPTION_COUPON_SUCCEEDED, ApplySubscriptionCoupon)
  yield takeLatest(RESET_COUPON_SUBSCRIPTION_CART_REQUESTED, RemoveSubscriptionCoupon)
}

const queries = {
  verifyCoupon: (name, couponType) => `${BASE_URL_WEB}/Coupon/VerifyCoupon?name=${name}&couponType=${couponType}`,
  compute: `${BASE_URL_WEB}/ShoppingCart/compute`,
  addSubscriptionToCart: `${BASE_URL_WEB}/ShoppingCart/addSubscriptionToCart`,
  applyCoupon: (couponId) => `${BASE_URL_WEB}/ShoppingCart/applyCouponDiscount/${couponId}`,
  removeCoupon: (couponId) => `${BASE_URL_WEB}/ShoppingCart/removeCouponDiscount/${couponId}`,
  finalizeSubscriptionOrder: `${BASE_URL_WEB}/ShoppingCart/finalizeSubscriptionOrder`,
  changeEuroAsDefault: (euroAsDefault) => `${BASE_URL_WEB}/ShoppingCart/changeEuroAsDefault/${euroAsDefault}`
}

const NEXT_STEP = 'NEXT_STEP'
const PREVIOUS_STEP = 'PREVIOUS_STEP'
const RESET_STEPS = 'RESET_STEPS'
const SELECT_INITIAL_CHOICE = 'SELECT_INITIAL_CHOICE'
const SELECT_CHOICE = 'SELECT_CHOICE'
const HANDLE_SUBSCRIPTION_COUPON_CHANGE = 'HANDLE_SUBSCRIPTION_COUPON_CHANGE'
const VERIFY_SUBSCRIPTION_COUPON = 'VERIFY_SUBSCRIPTION_COUPON'
const VERIFY_SUBSCRIPTION_COUPON_SUCCEEDED = 'VERIFY_SUBSCRIPTION_COUPON_SUCCEEDED'
const VERIFY_SUBSCRIPTION_COUPON_FAILED = 'VERIFY_SUBSCRIPTION_COUPON_FAILED'
const FINALIZE_SUBSCRIPTION_ORDER = 'FINALIZE_SUBSCRIPTION_ORDER'
const COMPUTE_SUBSCRIPTION_CART_REQUESTED = 'COMPUTE_SUBSCRIPTION_CART_REQUESTED'
export const FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED = 'FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED'
const FINALIZE_SUBSCRIPTION_ORDER_FAILED = 'FINALIZE_SUBSCRIPTION_ORDER_FAILED'
const COMPUTE_SUBSCRIPTION_CART_SUCCEEDED = 'COMPUTE_SUBSCRIPTION_CART_SUCCEEDED'
const COMPUTE_SUBSCRIPTION_CART_FAILED = 'COMPUTE_SUBSCRIPTION_CART_FAILED'
const ADD_SUBSCRIPTION_TO_CART_REQUESTED = 'ADD_SUBSCRIPTION_TO_CART_REQUESTED'
export const ADD_SUBSCRIPTION_TO_CART_SUCCEEDED = 'ADD_SUBSCRIPTION_TO_CART_SUCCEEDED'
const ADD_SUBSCRIPTION_TO_CART_FAILED = 'ADD_SUBSCRIPTION_TO_CART_FAILED'
export const GET_SUBSCRIPTION_CART_SUCCEEDED = 'GET_SUBSCRIPTION_CART_SUCCEEDED'
const APPLY_SUBSCRIPTION_COUPON_SUCCEEDED = 'APPLY_SUBSCRIPTION_COUPON_SUCCEEDED'
const APPLY_SUBSCRIPTION_COUPON_FAILED = 'APPLY_SUBSCRIPTION_COUPON_FAILED'
const RESET_COUPON_SUBSCRIPTION_CART_REQUESTED = 'RESET_COUPON_SUBSCRIPTION_CART_REQUESTED'
const RESET_COUPON_SUBSCRIPTION_CART_SUCCEEDED = 'RESET_COUPON_SUBSCRIPTION_CART_SUCCEEDED'
const RESET_COUPON_SUBSCRIPTION_CART_FAILED = 'RESET_COUPON_SUBSCRIPTION_CART_FAILED'
