import { takeLatest, put, select } from 'redux-saga/effects'
import { getUser, userLoaded, userUnloaded, accessTokenExpired, userSignedOut, signinSilent, signout, accessTokenExpiring } from 'identity/userManager'
import { HomePath, PreSignoutPath } from 'navigation/Routes'
import { FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED } from 'components/CheckoutSubscription/store'
import { FINALIZE_COURESES_ORDER_SUCCEEDED } from 'components/Cart/store'
import { initOnboarding, setIsCheckoutOnboarding, signUpWebhookAction } from 'pages/OnBoardingNext/store'
import { initUserAddressAction, saveAlreadySignedUsersAction, setUserEligibilityAction } from 'components/Profile/store'
import { LoginDataLayer, SignUpDataLayer } from 'helpers'
import { BASE_URL_WEB, OnBoardingStateEnum, freeTrialFeature } from 'constants/'
import { TurnOffLoaderAction } from 'store/config'
import { push } from 'connected-react-router'
import { decorateUrl } from 'components/common/UTMscript'
import fetch from 'core/fetch'
import { openFailNotification } from 'components/common/Notification/store'
import Text from './text.json'

export const SIGNIN = 'SIGNIN'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
const IDENTITY_CLEAN = 'IDENTITY_CLEAN'
const LOGOUT_FAILED = 'LOGOUT_FAILED'
export const TRY_LOGOUT = 'TRY_LOGOUT'
const SETUP_AND_SIGNIN = 'SETUP_AND_SIGNIN'
const SINGIN_SUCCESS = 'SINGIN_SUCCESS'
const SINGIN_FAILED = 'SINGIN_FAILED'

const initialState = {
  user: null,
  isConnected: false,
  ProfileType: null,
  logoutInProgress: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SIGNIN:
      return {
        user: data,
        ProfileType: data.ProfileType,
        isConnected: true
      }
    case TRY_LOGOUT:
      return {
        ...initialState,
        logoutInProgress: true
      }
    case LOGOUT_SUCCESS:
    case IDENTITY_CLEAN:
      return {
        ...initialState,
        logoutInProgress: false
      }
    case FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED:
      return {
        ...state,
        ProfileType: data.ProfileType,
        user: {
          ...state.user,
          ProfileType: data.ProfileType
        }
      }
    case FINALIZE_COURESES_ORDER_SUCCEEDED:
      return {
        ...state,
        ProfileType: data.ProfileType,
        user: {
          ...state.user,
          ProfileType: data.ProfileType
        }
      }
    default:
      return state
  }
}

const getUserEligibility = ({ user }) => user.isElligableForTrial
const getSignedUpState = ({ user }) => user.usersAlreadySignedUp
const getOnBoardingState = ({ onBoarding }) => onBoarding.onBoardingState
const getOnboardingTrialState = ({ user }) => user.showOnboardingTrial
const getCountryDetails = ({ referential }) => referential.CountryDetails

export const tryLogin = async (dispatch) => {
  try {
    let user = await getUser()
    if (user && !user.expired) {
      dispatch(signin(user))
    } else if (user) {
      user = await signinSilent()
      if (user && !user.expired) {
        dispatch(signin(user))
      }
    } else {
      dispatch({ type: IDENTITY_CLEAN })
      cleanAndLogout(false)
    }
  } catch (error) {
    cleanAndLogout()
  }
}

export const refreshTokenWhenExpiring = async (dispatch) => {
  try {
    const user = await signinSilent()
    if (user && !user.expired) {
      dispatch(signin(user))
    }
  } catch (error) {
    cleanAndLogout(false)
  }
}

export function * cleanAndLogout (logout = true) {
  try {
    if (logout) {
      yield put(push(PreSignoutPath))
      yield signout()
      yield put(logoutSuccess())
    }
    yield put({ type: IDENTITY_CLEAN })
    yield put(initOnboarding(null))
    yield put(setIsCheckoutOnboarding(false))
    yield put(setUserEligibilityAction(true))
    yield put(push(HomePath))
  } catch (err) {
    yield put({ type: LOGOUT_FAILED, data: err })
    yield put(push(HomePath))
  }
}

export function * setupAndSignIn ({ user, path }) {
  const registerStateNew = 'new'
  const noTimeOut = null
  const onBoardingState = yield select(getOnBoardingState)
  const usersAlreadySignedUp = yield select(getSignedUpState)
  const countryDetails = yield select(getCountryDetails)
  const showOnboardingTrial = yield select(getOnboardingTrialState)
  let isElligableForTrial = yield select(getUserEligibility)
  try {
    yield put(signin(user))
    yield put(initOnboarding(user.profile.OnBoardingState))
    yield put(setUserEligibilityAction(null))
    let isEligibleForTrial = false
    if (freeTrialFeature) {
      isEligibleForTrial = yield fetch(queries.isEligibleForTrial)
    }
    isElligableForTrial = yield select(getUserEligibility)
    if (isElligableForTrial === null) yield put(setUserEligibilityAction(isEligibleForTrial))
    const loginFrom = user?.profile?.login_from ? Array.isArray(user?.profile?.login_from) ? user.profile.login_from[0].toLowerCase() : user.profile.login_from.toLowerCase() : ''
    LoginDataLayer({ loginMethod: loginFrom, userId: user?.profile?.Id })
    yield put(signUpWebhookAction(user?.profile?.email))
    if (countryDetails.Id) yield put(initUserAddressAction(user?.profile?.given_name, user?.profile?.family_name, countryDetails.Id))
    if (user?.profile?.register_state === registerStateNew && !usersAlreadySignedUp[user?.profile?.Id]) {
      SignUpDataLayer({ userId: user?.profile?.Id, loginMethod: user?.profile?.register_from.toLowerCase() })
      yield put(saveAlreadySignedUsersAction(user?.profile?.Id))
    }
    if (isEligibleForTrial && showOnboardingTrial) {
      yield put(initOnboarding(OnBoardingStateEnum.Start))
      yield put(setIsCheckoutOnboarding(false))
    } else if (freeTrialFeature && !isEligibleForTrial && showOnboardingTrial) {
      yield put(openFailNotification(Text.notEligibleText, noTimeOut))
    }
    if (onBoardingState === OnBoardingStateEnum.Start) {
      yield put(push(decorateUrl(HomePath)))
    } else {
      yield put(push(decorateUrl(`${path || HomePath}`)))
    }
    yield put({ type: SINGIN_SUCCESS })
    yield put(TurnOffLoaderAction())
  } catch (err) {
    yield put(TurnOffLoaderAction())
    yield put({ type: SINGIN_FAILED })
  }
}

export const initIdentityStore = dispatch => {
  userSignedOut(() => dispatch(logoutSuccess()))
  userUnloaded(() => dispatch(logoutSuccess()))
  accessTokenExpired(() => dispatch(tryLogout()))
  accessTokenExpiring(() => refreshTokenWhenExpiring(dispatch))
  userLoaded((user) => dispatch(signin(user)))
  tryLogin(dispatch)
}

export function * IdentityRootSaga () {
  yield takeLatest(TRY_LOGOUT, cleanAndLogout)
  yield takeLatest(SETUP_AND_SIGNIN, setupAndSignIn)
}

export const signin = ({ profile }) => ({ type: SIGNIN, data: profile })
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })
export const tryLogout = () => ({ type: TRY_LOGOUT })
export const setupAndSignInAction = (user, path) => ({ type: SETUP_AND_SIGNIN, user, path })

const queries = {
  isEligibleForTrial: `${BASE_URL_WEB}/User/IsEligibleForTrial`
}
