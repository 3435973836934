import { connect } from 'react-redux'
import UI from './UI'
import { clearCertificateAction, fetchCertificateByIdAction, generateCertificateAction } from './store'
import { push } from 'connected-react-router'
import { addToFavoriteAction, removeFromFavorite } from 'components/Learning/store'
import { openQuiz } from 'pages/Course/Quizz/store'
import { decorateUrl } from 'components/common/UTMscript'

const mapStateToProps = ({ certificate, identity, course }) => ({
  isConnected: identity.isConnected,
  userId: identity.user && (identity.user.Id || ''),
  certificate,
  favorits: course.favoriteCoursesIds
})

const mapDispatchToProps = dispatch => ({
  fetchCertificateById: (id) => dispatch(fetchCertificateByIdAction(id)),
  goToLink: (url) => dispatch(push(decorateUrl(url))),
  clearCertificate: () => dispatch(clearCertificateAction()),
  addToFavorite: (id) => dispatch(addToFavoriteAction(id)),
  removeFromFavorite: (id) => dispatch(removeFromFavorite(id)),
  openQuiz: isOpen => dispatch(openQuiz(isOpen)),
  generateCertificate: (certificate) => dispatch(generateCertificateAction(certificate))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
