export default {
  rootClass: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  videoStyle: {
    width: '100%',
    height: '492px !important',
    borderRadius: 4
  }
}
