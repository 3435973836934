export default (theme) => ({
  item: {
    cursor: 'pointer',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: ({ stepWidth }) => stepWidth || 100
  },
  dot: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: ({ step, index }) => `3px solid ${step > index ? theme.primary[0] : theme.neutral[0]}`,
    backgroundColor: theme.neutral[400],
    width: 29,
    height: 29,
    margin: '0 0 7px 0',
    borderRadius: '50%'
  },
  icon: {
    fontSize: 'medium',
    lineHeight: 0,
    color: theme.primary[0]
  }
})
