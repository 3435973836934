import { Icons } from '../constants'

export const quizChoiceStatus = {
  default: 'default',
  active: 'active',
  correct: 'correct',
  incorrect: 'incorrect'
}

export const quizChoiceColorMap = (theme, status) => {
  switch (status) {
    case quizChoiceStatus.default:
      return {
        border: theme.neutral[0],
        background: theme.neutral[0],
        icon: theme.neutral[400],
        text: theme.primary[1000]
      }
    case quizChoiceStatus.active:
      return {
        border: theme.primary[0],
        background: theme.neutral[400],
        icon: theme.primary[0],
        text: theme.primary[0]
      }
    case quizChoiceStatus.correct:
      return {
        border: theme.success[0],
        background: theme.neutral[400],
        icon: theme.success[0],
        text: theme.success[0]
      }
    case quizChoiceStatus.incorrect:
      return {
        border: theme.error[0],
        background: theme.neutral[400],
        icon: theme.error[0],
        text: theme.error[0]
      }
    default:
      return {}
  }
}

export const getQuizChoiceIcon = (status) => {
  return status === quizChoiceStatus.default ? null : Icons.check
}
