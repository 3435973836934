import BreadcrumbListSD from 'components/common/SEO/BreadcrumbListSD'
import FacebookOGMetas from 'components/common/SEO/FacebookOGMetas'
import ItemListSD from 'components/common/SEO/ItemListSD'
import OrganisationSD from 'components/common/SEO/OrganisationSD'
import TwitterMarups from 'components/common/SEO/TwitterMarups'
import WebPageSD from 'components/common/SEO/WebPageSD'
import WebSiteSD from 'components/common/SEO/WebSiteSD'
import { WebinarNeutralPath, WebinarsPath } from 'navigation/Routes'
import { Helmet } from 'react-helmet'
import Text from './text.json'

const breadcrumbListCatalog = [
  {
    link: window.location.origin,
    name: 'Accueil'
  },
  {
    link: `${window.location.origin}/webinaires`,
    name: 'Webinaires'
  }
]

const breadcrumbListCategory = (selectedCategory) => [
  {
    link: window.location.origin,
    name: 'Accueil'
  },
  {
    link: `${window.location.origin}${WebinarsPath}`,
    name: 'Webinaires'
  },
  {
    link: `${window.location.origin}${WebinarNeutralPath}/${selectedCategory.Slug}`,
    name: selectedCategory.Name
  }
]

export default ({ selectedCategory, allCategoriesSelected, description, courses }) => {
  const title = `${Text.allWebinars} ${allCategoriesSelected ? Text.all : selectedCategory.Name}`
  const link = window.location.href
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <link rel='canonical' href={link} />
      </Helmet>
      <FacebookOGMetas
        link={link}
        title={title}
        description={description}
        image={Text.imageLink}
      />
      <TwitterMarups
        link={link}
        title={title}
        description={description}
        image={Text.imageLink}
      />
      <ItemListSD courses={courses} />
      <OrganisationSD />
      <WebSiteSD />
      <WebPageSD
        title={title}
        description={description}
      />
      <BreadcrumbListSD items={selectedCategory.Id ? breadcrumbListCategory(selectedCategory) : breadcrumbListCatalog} />
    </>
  )
}
