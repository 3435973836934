import { allWebinars, fetchWebinarsAction, otherWebinars, resetWebinarsAction } from 'pages/WebinarCatalog/store'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export const useQuery = queryParameter => {
  const query = new URLSearchParams(useLocation().search)
  return query.get(queryParameter)
}

export const useWebinarCatalogPagination = ({ defaultTake = 6, defaultSkip = 0, categoryDefault = '', categories }) => {
  const dispatch = useDispatch()
  const [take, setTake] = useState(defaultTake)
  const [skip, setSkip] = useState(defaultSkip)
  const [category, setCategory] = useState(categoryDefault)
  const [categoryTitle, setCategoryTitle] = useState(allWebinars.Name)
  const [sort, setSort] = useState('')
  const [hasCategory, setHasCategory] = useState(true)
  const { items, webinarsCount } = useSelector(({ webinars }) => webinars)

  useEffect(() => {
    const categoryFound = categories.find(c => c.Slug === category)
    dispatch(fetchWebinarsAction({ take, skip, category: categoryFound?.Id || '', status: sort || '', hasCategory: hasCategory }))
  }, [take, skip, category, sort])

  useEffect(() => {
    dispatch(resetWebinarsAction())
    const categoryFound = categories.find(c => c.Slug === category)
    setCategoryTitle(hasCategory ? categoryFound?.Name || allWebinars.Name : otherWebinars.Name)
  }, [category])
  const handleLoadMore = () => {
    setSkip(skip + take)
  }

  const handleChangeCategory = (category) => {
    setCategory(category)
    setSkip(0)
    setSort('')
    setHasCategory(true)
  }

  return { categoryTitle, setTake, setSort, setCategory, setHasCategory, handleLoadMore, handleChangeCategory, showLoadMore: items.length < webinarsCount, hasCategory }
}
