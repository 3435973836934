import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { inputMessageStyle } from './iconAndMessage.style'
import Icon from '../icon/icon'
import { TypographyElement, Spacing } from '../..'
import { textColorMap } from '../../constants'

const IconAndMessage = (props) => {
  const theme = useTheme()
  const useStyle = createUseStyles(inputMessageStyle)
  const { box, icon } = useStyle(props)
  return (
    <div className={box} data-test={props.dataTest}>
      <Icon iconName={props.iconName} style={props.style || icon} />
      <TypographyElement
        color={textColorMap(theme, props.status)}
        variant='caption'
        spacing={Spacing(0, 0, 0, 3)}
        {...props.typography}
      >
        {props.message}
      </TypographyElement>
    </div>
  )
}

IconAndMessage.propTypes = {
  /** the class passed to Icon */
  style: PropTypes.string,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string,
  /** message or label */
  message: PropTypes.string.isRequired,
  /** icon name from the library */
  iconName: PropTypes.string.isRequired,
  /** state of the message to get the color */
  status: PropTypes.oneOf(['info', 'success', 'error', 'alert', 'text'])
}

IconAndMessage.defaultProps = {
  message: 'icon message',
  iconName: 'ok-sign'
}

export default IconAndMessage
