import { MediaQueries } from '../..'

export default (theme) => ({
  root: {
    width: ({ width }) => width || '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    flex: ({ noFlex }) => noFlex ? null : 1,
    borderRadius: 8,
    borderLeft: `solid 1px ${theme.neutral[0]}`,
    borderRight: `solid 1px ${theme.neutral[0]}`,
    borderBottom: `solid 1px ${theme.neutral[0]}`,
    boxShadow: ({ shadowColor }) => `0 -2px 0 0 ${shadowColor || theme.extra.pink}`,
    margin: ({ margin }) => margin || null
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 15,
    textDecoration: 'none'
  },
  checkIconStyle: {
    color: theme.secondary[0],
    marginRight: 4,
    fontSize: 12
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 6
  },
  iconStyle: {
    color: theme.primary[0],
    marginLeft: 8,
    fontSize: 16
  },
  [`${MediaQueries.tablet}`]: {
    root: {
      margin: ({ tabletMargin }) => tabletMargin || null
    }
  },
  [`${MediaQueries.mobile}`]: {
    root: {
      margin: ({ mobileMargin }) => mobileMargin || null
    }
  }
})
