import {
  Card
} from 'UI'
import { createUseStyles } from 'react-jss'
import { defaultCurrency } from 'store/currency'
import style from './style'
import Text from './text.json'
import { ProfileTypes } from 'constants/'

const useStyle = createUseStyles(style)

const cardSettings = (
  progression,
  DiscountPercentage,
  DiscountPrice,
  AlternativePricePromo,
  HasActivePromotion,
  currency,
  isB2bUser,
  activeSubscription,
  IsFree,
  isSubscribed,
  IsNew
) => {
  switch (true) {
    case progression && Number(progression) === 100:
      return {
        primaryButtonLabel: Text.goToCertificat
      }
    case progression && Number(progression) < 100 && Number(progression) > 0:
      return {
        primaryButtonLabel: Text.continueCourse
      }
    case progression && Number(progression) === 0:
      return {
        primaryButtonLabel: Text.goToCourse
      }
    case IsFree && !isSubscribed:
      return {
        primaryButtonLabel: Text.startFree,
        freeText: Text.free
      }
    case HasActivePromotion && !isB2bUser && !activeSubscription:
      return {
        promoText: DiscountPercentage ? `-${DiscountPercentage}%` : `${currency === defaultCurrency ? DiscountPrice : AlternativePricePromo} ${currency}`,
        primaryButtonLabel: Text.discover
      }
    case IsNew:
      return {
        primaryButtonLabel: Text.new,
        newText: Text.new
      }
    default:
      return {
        primaryButtonLabel: Text.discover
      }
  }
}

export default ({
  course = {},
  certificats,
  handleCardClick,
  path,
  className,
  margin,
  marginDesktop,
  marginTablet,
  marginMobile,
  currency,
  isB2bUser,
  activeSubscription,
  loading,
  sliderCardsTestId,
  profileType,
  height,
  width = '100%',
  desktopWidth = '100%',
  tabletWidth = '100%',
  desktopHeight = '100%',
  mobileWidth = '100%',
  mobileHeight = '100%',
  tabletHeight = '100%',
  children,
  controls
}) => {
  const isSubscribed = profileType === ProfileTypes.BasicSubscriber || profileType === ProfileTypes.PremiumSubscriber
  const completionPercentage = course?.CourseUserContext?.CompletionPercentage || course?.CompletionPercentage || null
  const currentCardSettings = cardSettings(
    certificats ? 100 : completionPercentage,
    course.DiscountPercentage,
    course.DiscountPrice,
    course.AlternativePricePromo,
    course.HasActivePromotion,
    currency,
    isB2bUser,
    activeSubscription,
    course.IsFree,
    isSubscribed,
    course.IsNew
  )
  const { anchorClass } = useStyle({ margin, marginTablet, marginMobile, marginDesktop, width, tabletWidth, mobileWidth, desktopWidth, desktopHeight, mobileHeight, tabletHeight })
  return (
    course &&
      <a href={path} onClick={e => e.preventDefault()} className={anchorClass}>
        <Card
          className={className}
          duration={course.Duration}
          title={course.Title}
          promoText={currentCardSettings.promoText}
          freeText={currentCardSettings.freeText}
          newText={currentCardSettings.newText}
          progression={completionPercentage ? Number((completionPercentage).toFixed(0)) : 0}
          handleCardClick={() => !loading && handleCardClick(course)}
          loading={loading}
          dataTest={sliderCardsTestId}
          height={height}
          controls={controls}
          trainerName={course.Author}
        >
          {children}
        </Card>
      </a>
  )
}
