import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import { breadcrumbStyle } from './breadcrumb.style'
import { Icons } from '../../constants'
import Icon from '../icon/icon'

export const Breadcrumb = (props) => {
  const useStyle = createUseStyles(breadcrumbStyle)
  const { breadcrumbGroup, pathAndIcon, icon, path } = useStyle(props)
  const { breadcrumb } = props
  const lengthOfBreadcrumb = breadcrumb ? breadcrumb.length : 0
  return (
    <div className={breadcrumbGroup}>
      {
        lengthOfBreadcrumb && breadcrumb.map((bread, i) =>
          <div key={i} className={pathAndIcon}>
            <span className={path} onClick={() => props.handleClick(bread.Path)}>
              {bread.Title}
            </span>
            {i < lengthOfBreadcrumb - 1 &&
              <div className={icon}>
                <Icon iconName={Icons.roundedRight} />
              </div>}
          </div>
        )
      }
    </div>
  )
}

Breadcrumb.propTypes = {
  /** the array that hold all the informations about the breadcrumb */
  breadcrumb: PropTypes.array.isRequired
}

export default Breadcrumb
