
import { connect } from 'react-redux'
import UI from './UI'
import { push } from 'connected-react-router'
import { decorateUrl } from 'components/common/UTMscript'
import { createUserAction } from '../store'

const mapStateToProps = ({ onBoarding }) => ({
  Email: onBoarding.accountInfo.Email
})

const mapDispatchToProps = dispatch => ({
  submit: (values, next) => dispatch(createUserAction(values, next)),
  goTo: url => dispatch(push(decorateUrl(url)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
