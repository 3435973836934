import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { suggestionItemStyle } from './suggestionItem.style'
import { TypographyElement } from '../../..'
import { Spacing } from '../../../constants'

export const SuggestionItem = (props) => {
  const theme = useTheme()
  const useStyle = createUseStyles(suggestionItemStyle)
  const { suggestionItem, suggestionItemLabel } = useStyle(props)
  return (
    <div
      className={suggestionItem}
      onClick={props.handleClick}
      data-test='suggestion-item'
    >
      <TypographyElement
        variant='body2'
        cursor='pointer'
        spacing={Spacing(4)}
        className={suggestionItemLabel}
        color={props.selected ? theme.neutral[400] : theme.neutral[200]}
      >
        <label
          htmlFor={props.for}
          value={props.value}
          id={props.id}
        >
          {props.value}
        </label>
      </TypographyElement>
    </div>
  )
}

SuggestionItem.propTypes = {
  /** the click handler to fire when a user click on the item */
  handleClick: PropTypes.func.isRequired,
  /** its htmlFor to toggle a checkbox */
  for: PropTypes.string.isRequired,
  /** the text to show in the item */
  value: PropTypes.string.isRequired,
  /** a unique id */
  id: PropTypes.number.isRequired
}

export default SuggestionItem
