import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { switchCurrencyStyle } from './switchCurrency.style'

const useStyles = createUseStyles(switchCurrencyStyle)

const SwitchCurrency = ({
  checked,
  handleChange,
  devis,
  alterDevis,
  screen
}) => {
  const { inputGroup, switchButton } = useStyles({ devis, alterDevis, screen, checked })
  return (
    <div className={inputGroup} onClick={handleChange}>
      <label className={switchButton} />
    </div>
  )
}

SwitchCurrency.propTypes = {
  /** set checkbox checked by default */
  checked: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** the currency to show in the price tag */
  devis: PropTypes.string,
  /** the second currency to show in the switch currency */
  alterDevis: PropTypes.string,
  /** screen object of isMobile isTablet isDesktop */
  screen: PropTypes.object
}

export default SwitchCurrency
