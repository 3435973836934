import { loadingSkeleton, loadingSkeletonAnimation } from '../../helpers'

export const tagStyle = (theme) => ({
  root: ({ checked, disabled }) => ({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: disabled ? theme.neutral[0] : checked ? theme.warning[200] : theme.warning[300],
    borderRadius: 8,
    padding: '14px 30px',
    '&:hover': {
      backgroundColor: disabled ? theme.neutral[0] : theme.warning[200]
    }
  }),
  label: ({ loading, disabled }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    color: disabled ? theme.neutral[100] : theme.warning[0] + ' !important',
    ...(loading && loadingSkeleton().core)
  }),
  ...loadingSkeletonAnimation
})
