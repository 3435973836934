import {
  TypographyElement,
  neutral,
  Button,
  ButtonSize
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { useEffect, useState, useMemo } from 'react'
import Text from './text.json'
import { decorateUrl } from 'components/common/UTMscript'
import { checkUTMsInURL } from 'helpers'
import { countDownString } from 'constants/'

const useStyles = createUseStyles(style)

export default ({ topBarOpen, switchTopBarState, topBar, goToUrl, userTag, userCloseTopBar, userCloseTopBarAction }) => {
  const { pathname } = useLocation()
  const [timeLeft, setTimeLeft] = useState(null)

  useEffect(() => {
    const updateCountdown = () => {
      const futureDate = moment(topBar?.EndDate)
      const timeDiff = moment.duration(futureDate.diff(moment()))
      setTimeLeft(
        timeDiff.asDays() > 1
          ? `${Math.floor(timeDiff.asDays())} ${Text.days}`
          : timeDiff.seconds() >= 0 && timeDiff.minutes() >= 0 && timeDiff.hours() >= 0
            ? `${timeDiff.hours()} ${Text.hours} ${timeDiff.minutes()} ${Text.minutes} ${timeDiff.seconds()} ${Text.seconds}`
            : null
      )
    }
    updateCountdown()
    const interval = setInterval(updateCountdown, 1000)
    return () => clearInterval(interval)
  }, [topBar?.EndDate])

  const widgetMessage = topBar?.WidgetMessage && topBar.WidgetMessage.replace(countDownString, `<b>${timeLeft}</b>`)

  useMemo(() => {
    const currentDate = moment()
    const startDate = moment(topBar?.StartDate)
    const endDate = moment(topBar?.EndDate)
    const excludedUrls = topBar?.ExcludedUrls
    const excludedUtmSources = topBar?.ExcludedUtmSources
    const includedUserTags = topBar?.BuyerPersonas
    const widgetStatus = topBar?.WidgetStatus

    const isDateInRange = currentDate?.isBetween(startDate, endDate)
    const isUrlExcluded = excludedUrls?.some((url) => pathname?.includes(url))
    const isUtmExcluded = excludedUtmSources?.some((utm) => checkUTMsInURL(window.location.href, utm))
    const isUserTagIncluded = includedUserTags?.includes(userTag)
    const timerConstraint = timeLeft !== null
    const nexTopBarState = widgetStatus && isDateInRange && !isUrlExcluded && !isUtmExcluded && isUserTagIncluded && !userCloseTopBar && timerConstraint
    if (nexTopBarState !== topBarOpen) {
      switchTopBarState(nexTopBarState)
    }
  }, [pathname, topBar, topBarOpen, userTag, userCloseTopBar, timeLeft])
  const { container } = useStyles()
  if (!topBarOpen) {
    return null
  }
  return (
    <div className={container}>
      <TypographyElement
        Component='h1'
        variant='button'
        fontSizeTablet='16px'
        fontSizeMobile='16px'
        lineHeightTablet='20px'
        lineHeightMobile='20px'
        color={neutral[0]}
        fontWeight={300}
        dangerouslySetText={widgetMessage}
      />
      <Button
        marginButton='0 0 0 16px'
        label={topBar?.TextButton}
        size={ButtonSize.small}
        backgroundColor={neutral[0]}
        color={neutral[6]}
        heightMobile={30}
        heightTablet={30}
        height={32}
        handleClick={() => {
          topBar?.ActionExternal
            ? window.open(decorateUrl(topBar?.ActionLink), '_blank')
            : goToUrl(topBar?.ActionLink)
          userCloseTopBarAction()
        }}
      />
    </div>
  )
}
