import React from 'react'
import { createUseStyles } from 'react-jss'
import { viewStyle } from './view.style'

const View = (props) => {
  const useStyle = createUseStyles(viewStyle)
  const { view } = useStyle(props)
  return (
    <div className={view}>
      {props.children}
    </div>
  )
}

export default View
