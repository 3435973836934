import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { AddVisitedRouteAction, SwitchPopUpStateAction, UserClosePopUpAction } from 'store/marketing'
import { decorateUrl } from '../UTMscript'
import UI from './UI'

const mapStateToProps = ({ marketing, user }) => ({
  popUpOpen: marketing.popUpOpen,
  popUp: marketing.popUp,
  userTag: user.UserTag,
  userClosePopUp: marketing.userClosePopUp,
  userVisitedRoutes: marketing.userVisitedRoutes
})

const mapDispatchToProps = (dispatch) => ({
  switchPopUpState: (data) => dispatch(SwitchPopUpStateAction(data)),
  goToUrl: link => dispatch(push(decorateUrl(link))),
  userClosePopUpAction: () => dispatch(UserClosePopUpAction()),
  addVisitedRouteAction: data => dispatch(AddVisitedRouteAction(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
