import { Icons } from '../constants/icons'

export const VideoState = {
  locked: 'locked',
  unlocked: 'unlocked',
  video: 'video'
}

export const VideoStateMap = {
  locked: {
    icon: Icons.lock
  },
  unlocked: {
    icon: Icons.unlock
  },
  video: {
    icon: Icons.video
  }
}

export const ViewState = {
  done: 'done',
  playing: 'playing',
  unseen: 'unseen'
}
