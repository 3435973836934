import React from 'react'
import { createUseStyles } from 'react-jss'
import stepStyle from './step.style'
import TypographyElement from '../../typographyElement/typographyElement'
import { neutral, Icons } from '../../../constants'
import Icon from '../../icon/icon'

const Step = (props) => {
  const useStyle = createUseStyles(stepStyle)
  const { item, dot, icon } = useStyle(props)
  return (
    <div className={item} onClick={() => props.onHandleStepClick(props.index)}>
      <div className={dot}>
        {
          props.step <= props.index
            ? (
              <TypographyElement
                variant='caption'
              >{props.index + 1}
              </TypographyElement>
              )
            : <Icon iconName={Icons.check} style={icon} />
        }
      </div>
      <TypographyElement
        variant='caption'
        color={props.step > props.index ? neutral[6] : neutral[5]}
      >
        {props.label}
      </TypographyElement>
    </div>
  )
}

export default Step
