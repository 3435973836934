import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { tabsStyle } from './tabs.style'

const useStyles = createUseStyles(tabsStyle)

const Tabs = ({
  children,
  content,
  containerClass,
  groupTabClass,
  selectedTab
}) => {
  const classes = useStyles({ selectedTab })

  return (
    <div className={classNames(containerClass, classes.TabAndContent)}>
      <div className={classNames(groupTabClass, classes.GroupTab)}>
        {children}
      </div>
      <div className={classes.content}>
        {content}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  /** the children to render */
  children: PropTypes.node,
  /** the content to render */
  content: PropTypes.node,
  /** the class of the container */
  containerClass: PropTypes.string,
  /** the class of the tab group */
  groupTabClass: PropTypes.string,
  /** the id of the tab selected */
  selectedTab: PropTypes.number
}

export default Tabs
