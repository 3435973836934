import {
  PopUpBanner
} from 'UI'

export default ({ open, message, status, handleClose, time }) =>
  <PopUpBanner
    key={`${message}-${status}`}
    dataTest='popup-banner'
    topPos={150}
    rightPos={24}
    timeOut={time}
    mode='light'
    message={message}
    status={status}
    isOpen={open}
    handleClose={handleClose}
    handleTimeOut={handleClose}
    marginTablet='0 10px !important'
    marginMobile='0 10px !important'
    rightPosTablet='0px'
    rightPosMobile='0px'
    leftPosTablet='0px'
    leftPosMobile='0px'
    topPosTablet={100}
    topPosMobile={100}
  />
