import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import style from './review.style'
import {
  Paper,
  Avatar,
  RateStars,
  RateStarSize,
  TypographyElement
} from '../..'
import Icon from '../icon/icon'
import { Icons } from '../../constants'
import moment from 'moment'
import MediaQuery from '../mediaQuery'

const defaultNumberOfCharacters = 50
export const stripHtml = (htmlString = '') => htmlString ? htmlString.replace(/<[^>]+>|(&nbsp;)+/g, '') : ''
const FormattedText = (text) => {
  return text ? <div dangerouslySetInnerHTML={{ __html: text }} /> : null
}

const Review = ({
  authorImage,
  authorName,
  rating,
  opinion = '',
  showMoreLabel,
  showLessLabel,
  smallLogo,
  teamAlphormName,
  alphormResponse,
  reviewDate,
  responseDate,
  margin = 0,
  numberOfCharacters = defaultNumberOfCharacters
}) => {
  const theme = useTheme()
  const [showMore, setShowMore] = useState(stripHtml(opinion)?.length < numberOfCharacters)
  const [showMoreResponse, setShowMoreResponse] = useState(stripHtml(alphormResponse)?.length < numberOfCharacters)
  const useStyle = createUseStyles(style)
  const {
    reviewGroup,
    review,
    reviewBody,
    reponse,
    responseBody,
    avatarAndRating,
    reviewCore,
    reviewSide,
    ratingCore,
    time,
    iconStyle,
    avatarAndName
  } = useStyle({ margin })
  return (
    <div>
      <Paper noHover className={reviewGroup}>
        <div className={review}>
          <div className={reviewCore}>
            <div className={avatarAndName}>
              <Avatar
                icon={!authorImage}
                img={authorImage}
                widthTablet={28}
                heightTablet={28}
                fontSizeTablet='0.7rem'
                widthMobile={28}
                heightMobile={28}
                fontSizeMobile='0.7rem'
              />
              <TypographyElement
                variant='body1'
                align='left'
                spacing='0 0 0 16px'
                display='none'
                displayTablet='block'
                displayMobile='block'
              >
                {authorName}
              </TypographyElement>
            </div>
            <div className={reviewSide}>
              <div className={avatarAndRating}>
                <TypographyElement
                  variant='body1'
                  align='left'
                  spacing='8px 0 0 0'
                  display='block'
                  displayTablet='none'
                  displayMobile='none'
                >
                  {authorName}
                </TypographyElement>
                <div className={ratingCore}>
                  <TypographyElement
                    variant='caption'
                    align='left'
                    spacing='0 10px 0 0'
                  >
                    {rating}
                  </TypographyElement>
                  <RateStars value={rating} size={RateStarSize.small} />
                </div>
              </div>
              <div className={reviewBody}>
                <div>
                  <MediaQuery ShowOnDesktop>
                    <TypographyElement
                      variant='body1'
                      align='left'
                      spacing='19px 0 0 0'
                    >
                      {stripHtml(opinion)}
                    </TypographyElement>
                  </MediaQuery>
                  <MediaQuery HideOnDesktop>
                    <TypographyElement variant='body1' spacing='16px 0 0 0'>
                      {showMore ? stripHtml(opinion) : `${stripHtml(opinion).slice(0, numberOfCharacters)}...`}
                      {
                      opinion?.length > numberOfCharacters &&
                        <TypographyElement
                          variant='body1'
                          align='left'
                          color={theme.primary[0]}
                          fontWeight={500}
                          display='inline'
                          Component='span'
                          cursor='pointer'
                          handleClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? ` ${showLessLabel}` : ` ${showMoreLabel}`}
                        </TypographyElement>
                    }
                    </TypographyElement>
                  </MediaQuery>
                </div>
              </div>
              <div className={time}>
                <Icon iconName={Icons.clock} style={iconStyle} />
                <TypographyElement variant='body1' spacing='6px 0 0 0'>
                  {moment(reviewDate).locale('fr').fromNow()}
                </TypographyElement>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      {
        alphormResponse &&
          <Paper noHover className={reponse}>
            <div className={avatarAndName}>
              <Avatar
                width={44}
                height={44}
                img={smallLogo}
                backgroundSize='18px 18px'
                widthTablet={28}
                heightTablet={28}
                widthMobile={28}
                heightMobile={28}
              />
              <TypographyElement
                variant='button'
                spacing='0 0 0 16px'
                color={theme.primary[400]}
                display='none'
                displayTablet='block'
                displayMobile='block'
              >
                {teamAlphormName}
              </TypographyElement>
            </div>
            <div className={responseBody}>
              <TypographyElement
                variant='button'
                spacing='13px 0 0 0'
                color={theme.primary[400]}
                display='block'
                displayTablet='none'
                displayMobile='none'
              >
                {teamAlphormName}
              </TypographyElement>
              <div>
                <MediaQuery ShowOnDesktop>
                  <TypographyElement variant='body1' spacing='32px 0 0 0'>
                    {FormattedText(alphormResponse)}
                  </TypographyElement>
                </MediaQuery>
                <MediaQuery HideOnDesktop>
                  <TypographyElement variant='body1' spacing='16px 0 0 0'>
                    {showMoreResponse ? FormattedText(alphormResponse) : `${stripHtml(alphormResponse).slice(0, numberOfCharacters)}...`}
                    {
                      alphormResponse?.length > numberOfCharacters &&
                        <TypographyElement
                          variant='body1'
                          align='left'
                          color={theme.primary[0]}
                          fontWeight={500}
                          display='inline'
                          Component='span'
                          cursor='pointer'
                          handleClick={() => setShowMoreResponse(!showMoreResponse)}
                        >
                          {showMoreResponse ? ` ${showLessLabel}` : ` ${showMoreLabel}`}
                        </TypographyElement>
                      }
                  </TypographyElement>
                </MediaQuery>
              </div>
              <div className={time}>
                <Icon iconName={Icons.clock} style={iconStyle} />
                <TypographyElement variant='body1' spacing='6px 0 0 0'>
                  {moment(responseDate).locale('fr').fromNow()}
                </TypographyElement>
              </div>
            </div>
          </Paper>
      }
    </div>
  )
}

Review.propTypes = {
  /** image of the author */
  authorImage: PropTypes.string,
  /** name of the author */
  authorName: PropTypes.string,
  /** rating */
  rating: PropTypes.number,
  /** opinion is the text review */
  opinion: PropTypes.string,
  /** show more mabel */
  showMoreLabel: PropTypes.string,
  /** show less label */
  showLessLabel: PropTypes.string,
  /** small Logo */
  smallLogo: PropTypes.string,
  /** team alphorm name */
  teamAlphormName: PropTypes.string,
  /** alphorm response */
  alphormResponse: PropTypes.string,
  /** if no image the author initials shows */
  authorInitials: PropTypes.string
}

export default Review
