export const optionGroupStyle = theme => ({
  optionGroup: {
    width: '100%',
    maxHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    border: `solid 1px ${theme.neutral[0]}`,
    backgroundColor: theme.neutral[400],
    position: 'absolute',
    zIndex: 5,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.primary[1300],
      borderRadius: 4
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.primary[1200]
    }
  }
})
