import { Spacing } from '../..'

export const ExpanderStyle = (theme) => ({
  container: {
    cursor: 'pointer',
    marginBottom: ({ marginBottom }) => marginBottom || 16,
    overflow: 'hidden',
    flexDirection: 'column'
  },
  showIcon: {
    fontSize: 12,
    color: theme.primary[0],
    transform: ({ open }) => open ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.1s linear'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: ({ open }) => open ? Spacing(4, 4, 0, 4) : Spacing(4)
  },
  innerGroup: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.15s ease-out',
    overflow: 'hidden'
  },
  innerContainer: {
    padding: () => Spacing(0, 4, 4, 4)
  }
})
