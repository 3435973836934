import React from 'react'
import { createUseStyles } from 'react-jss'
import pathStyle from './path.style'

const Path = ({ step, numberOfSteps }) => {
  const useStyle = createUseStyles(pathStyle)
  const { container, fill } = useStyle({ step, numberOfSteps })
  return (
    <div className={container}>
      <div className={fill} />
    </div>
  )
}

export default Path
