import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { cardStyle } from './card.style'
import { Icons, Typography } from '../../constants'
import { formatMinutesDuration } from '../../constants/formatters'
import TypographyElement from '../typographyElement/typographyElement'
import classNames from 'classnames'
import Skeleton from '../skeleton/skeleton'
import Paper from '../paper/paper'
import Icon from '../icon/icon'
import LearningCardsVariants from '../../constants/learningCardsVariants'

const CardSkeleton = ({
  className,
  height,
  withSkeletonLastLine
}) => {
  const useStyles = createUseStyles(cardStyle)
  const classes = useStyles({ progression: 0, height })
  return (
    <Paper className={classNames(classes.cardGroup, className)}>
      <div className={classes.header}>
        <Skeleton width={24} height={24} wrapperWidth={50} />
        <Skeleton width='25%' height={20} />
      </div>
      <Skeleton width={['100%', '50%', '20%']} height={[20, 20, 15]} lines={3} />
      {withSkeletonLastLine
        ? (
          <div className={classes.progressionGroup}>
            <Skeleton width='100%' height={20} />
          </div>)
        : null}
    </Paper>
  )
}

const LearningSupplement = ({ variant, completionPercentage, handleIconClick }) => {
  const useStyles = createUseStyles(cardStyle)
  const { progressionGroupBought, progressionBarBought, completionPercentageAbsolute, binIcon, certificatIcon } = useStyles({ completionPercentage, height: '100%' })
  switch (variant) {
    case LearningCardsVariants.bought:
      return (
        <div className={progressionGroupBought}>
          <TypographyElement className={completionPercentageAbsolute} variant='caption' margin='0 0 0 10px'>{completionPercentage}%</TypographyElement>
          <div className={progressionBarBought} />
        </div>
      )
    case LearningCardsVariants.history:
      return (
        <h1>history</h1>
      )
    case LearningCardsVariants.certificat:
      return (
        <Icon iconName={Icons.download} style={certificatIcon} onHandleIconClick={handleIconClick} />
      )
    case LearningCardsVariants.favorits:
      return (
        <Icon iconName={Icons.bin} style={binIcon} onHandleIconClick={handleIconClick} />
      )
    default:
      return null
  }
}

const Card = ({
  loading,
  shadow,
  className,
  handleCardClick,
  title,
  label = 'Formation',
  dataTest,
  duration,
  trainerName,
  progression = 0,
  promoText,
  freeText,
  controls,
  children,
  height = '100%',
  variant,
  handleIconClick,
  withSkeletonLastLine = false,
  href,
  component,
  newText
}) => {
  progression = Math.round(progression)
  progression = progression > 100 ? 100 : progression

  const theme = useTheme()
  const useStyles = createUseStyles(cardStyle)
  const { shadowCard, cardGroup, header, headerLeft, playIcon, progressionGroup, progressionBar, freeBadge, promoBadge, newBadge, link } = useStyles({ loading, progression, height })

  const badgeClass = promoText ? promoBadge : freeText ? freeBadge : newText ? newBadge : null
  const badgeColor = promoText ? theme.error[0] : freeText ? theme.success[0] : newText ? theme.error[0] : null
  const badgeContent = promoText || freeText || newText

  if (loading) return (<CardSkeleton className={className} height={height} withSkeletonLastLine={withSkeletonLastLine} />)
  else if (shadow) return (<div className={classNames(shadowCard, className)} />)
  else {
    return (
      <LinkInternalComponent href={href} component={component} handleCardClick={handleCardClick} linkClassName={link}>
        <Paper
          onClick={handleCardClick}
          className={classNames(cardGroup, className)}
          title={title}
          data-test={dataTest && `${dataTest}-card`}
        >
          <div className={header}>
            <div className={headerLeft}>
              <Icon iconName={Icons.play} style={playIcon} />
              <TypographyElement variant='body2'>{label}</TypographyElement>
            </div>
            {variant && <LearningSupplement variant={variant} completionPercentage={progression} handleIconClick={handleIconClick} />}
            {
            badgeContent
              ? (
                <div className={badgeClass}>
                  <TypographyElement variant='caption' color={badgeColor} spacing='3px 8px'>{badgeContent}</TypographyElement>
                </div>
                )
              : null
            }
          </div>
          <TypographyElement variant='heading6' fontSize={Typography.body1.fontSize} maxLines={3}>{title}</TypographyElement>
          <TypographyElement variant='caption' spacing='8px 0 0 0'>{formatMinutesDuration(duration)}</TypographyElement>
          {
          controls
            ? null
            : progression && !variant
              ? (
                <div className={progressionGroup}>
                  <div className={progressionBar} />
                  <TypographyElement variant='caption' margin='0 0 0 10px'>{progression}%</TypographyElement>
                </div>
                )
              : <TypographyElement variant='body2' color={theme.primary[0]} margin='auto 0 0 0'>{trainerName}</TypographyElement>
        }
          {children}
        </Paper>
      </LinkInternalComponent>
    )
  }
}

const LinkInternalComponent = ({ component, href, handleCardClick, children, linkClassName }) => component === 'a'
  ? (
    <a
      className={linkClassName}
      href={href}
      onClick={(e) => {
        handleCardClick && handleCardClick(e)
        e.preventDefault()
      }}
    > {children}
    </a>)
  : children

Card.propTypes = {
  /** provide a className to put your style */
  className: PropTypes.string,
  /** provide a title to the card */
  title: PropTypes.string,
  /** provide a label to the card */
  label: PropTypes.string,
  /** provide a duration to the card */
  duration: PropTypes.number,
  /** provide a trainerName to the card */
  trainerName: PropTypes.string,
  /** provide a progression to the card */
  progression: PropTypes.number,
  /** provide a promoText to the card */
  promoText: PropTypes.string,
  /** provide a freeText to the card */
  freeText: PropTypes.string,
  /** provide a loading to the card */
  loading: PropTypes.bool,
  /** provide a shadow to the card */
  shadow: PropTypes.bool,
  /** provide a handleCardClick to the card */
  handleCardClick: PropTypes.func,
  /** provide a dataTest to the card */
  dataTest: PropTypes.string,
  /** provide a children to the card */
  children: PropTypes.node,
  /** provide a controls to the card */
  controls: PropTypes.bool,
  /** provide a height to the card */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Variant for learning cards */
  variant: PropTypes.oneOf(['learning', 'history', 'certificat', 'bought', 'favorit'])
}

export default Card
