export const red = ['#FFDCE4', '#E8839A', '#E91B4B', '#EA3424', '#ff0202', '#ff938d', '#FEF7F9', '#F52D3A', '#FE682E', '#FDDFCB']
export const green = ['#E8F7ED', '#7DDFC3 ', '#02C58C', '#4FB771', '#59CC7E', '#CCF3E8', '#00c887', '#57e6c4']
export const yellow = ['#FAEECF', '#FFE39D', '#FFD05C', '#F7B500', '#F7DB00']
export const orange = ['#fa6400']
export const neutral = [
  '#FFFFFF',
  '#F0F5FA',
  '#E8EEF1',
  '#D3DDE2',
  '#99ACBF',
  '#577592',
  '#183B56',
  '#B0B8C2',
  '#CCD4DC',
  '#F6F7F7',
  '#829BAE',
  '#E9EFFF',
  '#FFF8ED',
  '#E1F5FF',
  '#FCFCFC',
  '#F9F9F9',
  '#C8D5E2',
  '#F1F4F8',
  '#A4B5C6',
  '#585A7B'
]
export const blue = ['#3767da', '#5988F8', '#1c346d', '#0091ff', '#3609d8']
export const purple = ['#8211D1', '#6236ff']
export const socialMedia = {
  google: '#DF4930',
  facebook: '#507CC0',
  linkedin: '#5489CF'
}
export const Colors = {
  info: {
    darker: '#0680d9',
    dark: '#34a0ef',
    medium: '#4ab3ff ',
    light: '#57b8ff',
    lighter: '#f5faff'
  },
  success: {
    darker: '#01be55',
    dark: '#24d070',
    light: '#22d973',
    lighter: '#2be47d'
  },
  error: {
    darker: '#bd0303',
    dark: '#f03d3d',
    light: '#ff8d8d'
  },
  alert: {
    darker: '#f07300',
    dark: '#ffad0d',
    light: '#ffbb38'
  },
  text: {
    darker: '#0a1f44',
    dark: '#53627c',
    medium: '#8a94a6',
    light: '#a6aebc',
    lighter: '#c9ced6',
    white: '#fff',
    black: '#000',
    tag: '#6c7680',
    menuItem: '#6c7680',
    iconClose: '#6c798f',
    chapterTitle: '#34495E',
    selectedMenuItem: '#1e86f4'
  },
  core: {
    cardTitle: '#2d343c',
    border: '#c9ced6',
    roundedBorder: '#efefef',
    disabled: '#e1e4e8',
    background: '#c9ced6',
    white: '#fff',
    backgroundTag: '#f8f9fb',
    borderColorTag: '#e8e8e8',
    borderColorSelectedTag: '#d8e7f1',
    backgroundColorTagSelected: '#e8f2fc',
    star: '#fcbd04',
    disabledStar: '#eeeeee',
    cardBorder: '#f3f3f3',
    cardIconInfo: '#a0a0a0',
    cardTextInfo: '#1f2833',
    actionLink: '#007fff',
    tabBorder: '#f1f2f4',
    backgroundColorselectedMenuItem: '#e8f2fc',
    heart: '#f03d3d',
    icon: '#8492a6'
  },
  cardColor: {
    primary: '#34a0ef',
    secondary: '#fff',
    important: '#f43d3d',
    confirmation: '#24d070',
    information: '#f9ba02',
    hoverCheckoutButton: '#57b8ff',
    hoverCheckoutButtonPromo: '#ff8d8d'
  },
  buttonColor: {
    primary: '#34a0ef',
    secondary: '#fff',
    important: '#f43d3d',
    confirmation: '#01be55',
    information: '#f9ba02'
  },
  bannerColors: {
    dark: {
      info: '#34a0ef',
      success: '#24d070',
      error: '#f03d3d',
      alert: '#ffad0d'

    },
    light: {
      info: '#cce6ff',
      success: '#cff8eb',
      error: '#ffeaea',
      alert: '#fff6e4'

    }
  },
  bannerTextColors: {
    dark: {
      info: '#ffffff',
      success: '#ffffff',
      error: '#ffffff',
      alert: '#182c4f'

    },
    light: {
      info: '#34a0ef',
      success: '#24d070',
      error: '#f03d3d',
      alert: '#ff8f00'

    }
  },
  SearchableSelectListColors: {
    hoverItem: '#f8f9fb',
    scroll: '#C9CED6',
    scrollHover: '#314455',
    boxShadow1: 'rgba(10, 31, 68, 0.1)',
    boxShadow2: 'rgba(10, 31, 68, 0.08)',
    background: '#fff'
  },
  chapterColors: {
    chapterHeaderBackground: '#e1e4e8',
    chapterNumberBackground: '#d2d8e2',
    firstAndSecondHalfBackground: '#576987',
    chapterExpandColor: '#b5bbc6',
    courseStateBackground: '#ffb600'
  },
  videoColor: {
    courseBackground: '#f8f9fb',
    courseStateBackground: '#ffb600',
    firstAndSecondHalfBackground: '#576987',
    chapterExpandColor: '#b5bbc6',
    courseTitle: '#53627c',
    verticalLineColor: '#b7bdc7',
    downloadColor: '#bfc6d0',
    playAndPauseColor: '#778397'
  },
  loader: '#FF7731'
}

export const categoriesColor = {
  'e930ec21-08e6-4b42-a530-02f550265083': {
    backgroundColor: '#FAD1DB',
    primaryColor: 'rgba(233,27,75,0.99)'
  },
  'd7305f3f-ab63-4f23-abe2-3662fff390f2': {
    backgroundColor: '#ffe3d8',
    primaryColor: '#FA6400'
  },
  'ba45ab70-968f-4add-8756-35de31f325c0': {
    backgroundColor: '#dcf4ea',
    primaryColor: '#02C58C'
  },
  'a809f91b-244d-4183-8793-6faf329e7693': {
    backgroundColor: '#E0D7FF',
    primaryColor: '#6236FF'
  },
  '9d797625-4d1c-4a24-afa4-73b94eddeca3': {
    backgroundColor: '#ffe3d8',
    primaryColor: '#FA6400'
  },
  '977a26d2-89f2-41d5-be90-6a84365aabb3': {
    backgroundColor: '#d9eaff',
    primaryColor: '#0091FF'
  },
  '19bb7b7a-f80c-46ba-93d1-c52ea9d8f26e': {
    backgroundColor: '#FDF0CC',
    primaryColor: '#F7B500'
  },
  '0df9e561-41d4-4e5f-8bff-2c92ddfa9903': {
    backgroundColor: '#dbd5f8',
    primaryColor: '#3709D8'
  }
}
