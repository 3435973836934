import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { optionGroupStyle } from './optionGroup.style'

export const OptionGroup = forwardRef((props, ref) => {
  const useStyle = createUseStyles(optionGroupStyle)
  const { optionGroup } = useStyle(props)
  return (
    <div className={optionGroup} ref={ref}>
      {props.children}
    </div>
  )
})

export default OptionGroup
