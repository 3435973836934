import { connect } from 'react-redux'
import UI from './UI'
import { fetchUserAction, updateUserInfoAction, deleteUserPictureAction, updateAvatarAction } from 'components/Profile/store'
import { updateAccountInfoAction } from '../store'

const mapStateToProps = ({ user, referential, identity, onBoarding }) => ({
  BirthDate: user.BirthDate,
  Email: user.Email,
  FirstName: user.FirstName,
  LastName: user.LastName,
  Phone: user.Phone,
  Photo: user.Photo,
  functions: referential.Functions,
  initialUserFunction: user.FunctionId,
  validatedPhoneNumber: user.ValidatedPhoneNumber,
  loading: user.loading,
  countries: referential.Countries.filter(country => country.PhoneCode),
  detectedCountry: user.detectedCountry,
  initialPhoneCode: user.initialPhoneCode
})

const mapDispatchToProps = dispatch => ({
  FetchUser: () => dispatch(fetchUserAction()),
  DeleteUserPicture: () => dispatch(deleteUserPictureAction()),
  Submit: (values, next) => dispatch(updateUserInfoAction(values, next)),
  UpdateNewUser: (values) => dispatch(updateAccountInfoAction(values)),
  UpdateAvatar: (file) => dispatch(updateAvatarAction(file))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
