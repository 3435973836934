export const optionItemStyle = theme => ({
  optionItem: {
    width: '100%',
    backgroundColor: ({ active }) => active ? theme.primary[0] : theme.neutral[400]
  },
  optionItemLabel: {
    overflow: 'hidden',
    display: 'flex !important',
    alignItems: 'center'
  },
  flag: {
    width: 28,
    height: 20,
    marginRight: 6
  }
})
