export const suggestionGroupStyle = (theme) => ({
  suggestionGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    border: `1px solid ${theme.neutral[0]}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0,
    backgroundColor: theme.neutral[400],
    position: 'absolute',
    zIndex: 5,
    overflow: 'auto',
    transition: 'all 0.15s ease',
    maxHeight: ({ toggleSuggestionList }) => toggleSuggestionList ? 252 : 0,
    transform: ({ toggleSuggestionList }) => toggleSuggestionList ? 'scaleY(1)' : 'scaleY(0)',
    transformOrigin: 'top center',
    '&::-webkit-scrollbar': {
      width: 8
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.primary[1300],
      borderRadius: 4
    },
    '&::-webkit-scrollbar-track': {
      width: 12
    }
  }
})
