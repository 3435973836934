import { Spacing, MediaQueries } from '../../constants'

export default (theme) => ({
  paper: {
    borderRadius: 8,
    borderLeft: `solid 1px ${theme.neutral[0]}`,
    borderRight: `solid 1px ${theme.neutral[0]}`,
    borderBottom: `solid 1px ${theme.neutral[0]}`,
    padding: '49px 24px 24px 24px',
    width: props => props.width || '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    transition: 'all 0.3s ease-in-out',
    overflow: 'hidden',
    boxShadow: props => `0 -2px 0 0 ${props.shadowColor || theme.extra.pink}`,
    '&:hover': {
      transform: 'scale(1.02)'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.neutral[400],
    backgroundPosition: 'right top',
    transition: 'all 0.3s ease-in-out'
  },
  switchSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    visibility: ({ hideSwitchCurrency }) => hideSwitchCurrency && 'hidden'
  },
  titleAndPromo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '32px 0px 16px 0px'
  },
  body: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  infoClass: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 8
  },
  iconClass: {
    color: theme.secondary[0],
    fontSize: 12,
    padding: '0 4px 0 0'
  },
  promoTagContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 8,
    backgroundColor: theme.neutral[400],
    borderRadius: 4,
    border: `solid 1px ${theme.extra.pink}`,
    height: 21,
    width: 65
  },
  promoTag: {
    display: 'flex'
  },
  infoStyle: {
    transition: 'all 0.3s ease-in',
    padding: () => Spacing(5, 0, 0, 0)
  },
  expenderIcon: {
    transition: 'all 0.3s ease-in-out',
    transform: props => props.open ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 8
  },
  [`${MediaQueries.mobile}`]: {
    paper: {
      padding: '28px 24px 24px 24px'
    },
    promoTagContainer: {
      marginLeft: 0
    },
    switchSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    infoStyle: {
      overflow: 'hidden',
      maxHeight: props => props.open ? 1000 : 0,
      visibility: props => props.open ? 'visible' : 'hidden',
      padding: () => Spacing(0)
    }
  },
  [`${MediaQueries.tablet}`]: {
    paper: {
      padding: '28px 24px 24px 24px'
    },
    promoTagContainer: {
      marginLeft: 0
    },
    switchSection: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    infoStyle: {
      overflow: 'hidden',
      maxHeight: props => props.open ? 1000 : 0,
      visibility: props => props.open ? 'visible' : 'hidden',
      padding: () => Spacing(0)
    }
  }
})
