import {
  Typography
} from 'UI'
import { createUseStyles } from 'react-jss'

const styles = {
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    body: {
      fontFamily: `${Typography.fontFamily}, sans-serif`,
      fontWeight: Typography.fontWeight.regular,
      fontSize: Typography.body1.fontSize,
      minWidth: 380
    },
    '.grecaptcha-badge': {
      visibility: 'hidden'
    }
  }
}

const useStyles = createUseStyles(styles)

export default () => {
  useStyles()
  return null
}
