import { InputColors, Status, Typography } from '../../constants'

export const textAreaStyle = (theme) => ({
  inputGroup: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box'
  },
  input: {
    width: '100%',
    borderRadius: '4px',
    border: 'solid 1px ',
    padding: [10, 16],
    borderColor: props => (props.status) ? InputColors(theme)['input' + props.status] : InputColors(theme).inputborder,
    outline: 'none',
    color: InputColors(theme).inputText,
    transition: 'box-shadow .2s linear',
    font: {
      family: Typography.secondaryFontFamily,
      size: 14
    },
    '&::placeholder': {
      font: {
        family: Typography.secondaryFontFamily,
        size: 14
      },
      color: InputColors(theme).inputPlaceholder
    },
    '&:focus': {
      boxShadow: props => '0 0 0 2px ' + (props.status ? InputColors(theme)['input' + props.status] : InputColors(theme).inputfocus)
    },
    '&:disabled': {
      background: InputColors(theme).inputborder,
      borderColor: props => (props.status) ? InputColors(theme)['input' + props.status] : InputColors(theme).inputborder
    },
    '&:hover': {
      borderColor: ({ status }) => status ? InputColors(theme)['input' + status] : InputColors(theme).inputHoverBorder
    },
    '&:focus::placeholder': {
      transform: 'translateX(-100%)',
      opacity: 0,
      transition: 'transform .25s linear,opacity .25s linear'
    },
    transform: ({ status }) => status === Status.error ? 'translateX(10px)' : 'translateX(0px)',
    animation: ({ status }) => status === Status.error ? '$input-error-shake .25s ease forwards' : 'none'
  }
})
