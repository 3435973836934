export default (theme) => ({
  disabled: {
    cursor: 'no-drop !important'
  },
  dot: {
    width: 7,
    height: 7,
    borderRadius: '50%',
    cursor: 'pointer',
    background: ({ transparent }) => transparent ? theme.neutral[400] : theme.primary[0],
    opacity: 0.22,
    margin: '0 2.5px',
    '&:hover': {
      opacity: 1
    }
  },
  activeDot: {
    opacity: 1
  },
  viewsDots: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0 5.5px'
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: ({ secondaryController }) => secondaryController ? 4 : 0
  },
  icon: {
    fontSize: ({ secondaryController }) => secondaryController ? 12 : 6,
    lineHeight: 0,
    color: ({ secondaryController }) => secondaryController ? theme.secondary[0] : theme.primary[0]
  },
  iconWrapper: {
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: ({ secondaryController }) => `${secondaryController ? '2px' : '1px'} solid ${secondaryController ? theme.secondary[0] : theme.primary[0]}`,
    cursor: 'pointer'
  }
})
