import { fetchSpeakerWebinarsAction } from 'pages/Webinar/store'
import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ webinar, referential }) => ({
  WebinarSpeaker: webinar?.data?.WebinarSpeakers?.[0]?.Id,
  WebinarStatus: referential.WebinarStatus,
  FilteredWebinars: webinar?.speakerWebinar
})

const mapDispatchToProps = dispatch => ({
  fetchSpeakerWebinarsAction: (data) => dispatch(fetchSpeakerWebinarsAction((data)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
