import fetch from 'core/fetch'
import { put, takeLatest } from 'redux-saga/effects'
import { BASE_URL_WEB, FranceCountryId } from 'constants/'

const initialState = {
  AddressTypes: {
    Primary: 1,
    BillingAddress: 2,
    CreditCard: 3
  },
  error: false,
  IsFrance: true,
  CountryDetails: {
    Id: 65,
    Name: 'France',
    Code: 'FR',
    PhoneCode: '+33'
  }
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_REFERENTIALS_SUCCESS:
      return {
        ...state,
        ...data
      }
    case GET_REFERENTIALS_FAIL:
      return {
        ...state,
        err: data
      }
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        ...data
      }
    default:
      return state
  }
}

export const fetchReferentialAction = () => ({ type: GET_REFERENTIALS_REQUESTED })

function * fetchReferential () {
  try {
    const response = yield fetch(queries.getReferential)
    yield put({ type: GET_REFERENTIALS_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_REFERENTIALS_FAIL, err })
  }
  try {
    const response = yield fetch(queries.getDetectedCountry)
    yield put({ type: GET_COUNTRY_SUCCESS, data: { IsFrance: response.Id === FranceCountryId, CountryDetails: response } })
  } catch (err) {
    yield put({ type: GET_COUNTRY_FAIL, err })
  }
}

export function * referentialRootSaga () {
  yield takeLatest(GET_REFERENTIALS_REQUESTED, fetchReferential)
}

const queries = {
  getReferential: `${BASE_URL_WEB}/Referential/getReferential`,
  getDetectedCountry: `${BASE_URL_WEB}/User/GetDetectedCountry`
}

export const GET_REFERENTIALS_SUCCESS = 'GET_REFERENTIALS_SUCCESS'
export const GET_REFERENTIALS_FAIL = 'GET_REFERENTIALS_FAIL'
const GET_REFERENTIALS_REQUESTED = 'GET_REFERENTIALS_REQUESTED'
export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS'
export const GET_COUNTRY_FAIL = 'GET_COUNTRY_FAIL'
