import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { textAreaStyle } from './textArea.style'
import IconAndMessage from '../iconAndMessage/iconAndMessage'
import { Icons, Status, textColorMap } from '../../constants'
import classNames from 'classnames'
import TypographyElement from '../typographyElement/typographyElement'

const TextArea = (props) => {
  const theme = useTheme()
  const useStyle = createUseStyles(textAreaStyle)
  const classes = useStyle(props)
  return (
    <div className={classes.inputGroup}>
      {props.label &&
        (
          <TypographyElement
            color={textColorMap(theme, props.status)}
            variant='label'
            spacing='0 0 9px 0'
            {...props.typography}
          >{props.label}
          </TypographyElement>
        )}
      <textarea
        readOnly={props.readOnly}
        disabled={props.disabled}
        value={props.value}
        id={props.id}
        name={props.name}
        className={classNames(props.textareaClassName, classes.input)}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        data-test={props.dataTest}
      />
      {
        (props.message && props.status !== Status.success)
          ? (
            <IconAndMessage
              dataTest={props.ErrorMessageDataTest}
              background
              message={props.message}
              iconName={Icons[props.status]}
              status={props.status}
              typography={props.messageTypographyProps}
            />
            )
          : null
      }
    </div>
  )
}

TextArea.propTypes = {
  readOnly: PropTypes.bool,
  /** the state of the TextArea */
  status: PropTypes.oneOf(['info', 'success', 'error', 'alert', 'text']),
  /** the hint message that appears under the TextArea */
  message: PropTypes.string,
  /** the test Id of the input for end to end testing */
  dataTest: PropTypes.string,
  /** the test Id of the error message for end to end testing */
  ErrorMessageDataTest: PropTypes.string,
  /** the placeholder explanation text to be displayed when TextArea not filled yet */
  placeholder: PropTypes.string.isRequired,
  /**  value by default shiiped with the TextArea */
  value: PropTypes.string,
  /**  TextArea unique identifier */
  id: PropTypes.string,
  /**  the control label text to be displayed */
  label: PropTypes.string.isRequired,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** the method that control the state of the TextArea */
  handleChange: PropTypes.func,
  /** the method that controls the blur of the TextArea */
  handleBlur: PropTypes.func
  /** Specifies the visible width of a text area */
}

TextArea.defaultProps = {
  message: '',
  placeholder: 'input placeholder',
  disabled: false
}

export default TextArea
