import { BASE_URL_WEB } from 'constants/'
import { put, select, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { getCategoryDetailsByIds } from 'helpers'

const initialState = {
  data: {
    Id: '',
    Title: '',
    SubTitle: '',
    Description: null,
    Code: '',
    Slug: '',
    PlannifiedWebinarDate: '',
    PublicationDate: '',
    ResourcesLink: null,
    WebinarLink: null,
    PresentationVideoLink: '',
    SmallImage: '',
    LargeImage: null,
    WebinarSpeakers: [],
    Tags: [],
    Categories: [],
    Courses: [],
    Duration: 0,
    IsPublished: false,
    StartDate: null,
    EndDate: null,
    IsUserParticipating: false
  },
  isWebinarLoading: false,
  speakerWebinar: [],
  categoryWebinar: []
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case FETCH_WEBINAR_BY_SLUG_REQUESTED:
      return {
        ...state,
        isWebinarLoading: true
      }
    case FETCH_WEBINAR_BY_SLUG_SUCCESS:
      return {
        ...state,
        isWebinarLoading: false,
        data
      }
    case FETCH_WEBINAR_BY_SLUG_FAIL:
      return {
        ...state,
        isWebinarLoading: false
      }
    case REGISTER_TO_WEBINAR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          IsUserParticipating: true
        }
      }
    case UNFOLLOW_WEBINAR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          IsUserParticipating: false
        }
      }
    case GET_SPEAKER_WEBINARS_SUCCESS:
      return {
        ...state,
        speakerWebinar: data.Items
      }
    case GET_SPEAKER_WEBINARS_FAIL:
      return {
        ...state
      }
    case GET_CATEGORY_WEBINARS_SUCCESS:
      return {
        ...state,
        categoryWebinar: data.Items
      }
    case GET_CATEGORY_WEBINARS_FAIL:
      return {
        ...state
      }
    default:
      return state
  }
}

const getCategories = ({ catalog }) => catalog.categories

export const fetchWebinarBySlugAction = (slug) => ({ type: FETCH_WEBINAR_BY_SLUG_REQUESTED, slug })
export const registerToWebinarAction = (webinarId) => ({ type: REGISTER_TO_WEBINAR_REQUESTED, webinarId })
export const fetchSpeakerWebinarsAction = ({ take, skip, category, status, hasCategory, speakerId }) => ({ type: GET_SPEAKER_WEBINARS_REQUESTED, take, skip, category, status, hasCategory, speakerId })
export const fetchCategoryWebinarsAction = ({ take, skip, category, status, hasCategory, speakerId }) => ({ type: GET_CATEGORY_WEBINARS_REQUESTED, take, skip, category, status, hasCategory, speakerId })
export const unfollowWebinarAction = _ => ({ type: UNFOLLOW_WEBINAR_REQUESTED })

const getWebinar = ({ webinar }) => webinar

export function * fetchWebinarBySlug ({ slug }) {
  try {
    const response = yield fetch(queries.getWebinarBySlug(slug))
    yield put({ type: FETCH_WEBINAR_BY_SLUG_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: FETCH_WEBINAR_BY_SLUG_FAIL, err })
  }
}

export function * registerToWebinar ({ webinarId }) {
  try {
    const response = yield fetch(queries.registerToWebinar(webinarId), { method: 'post', nojson: true, headers: { 'Content-Type': 'application/json' } })
    yield put({ type: REGISTER_TO_WEBINAR_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: REGISTER_TO_WEBINAR_FAIL, err })
  }
}

export function * fetchSpeakerWebinars ({ take, skip, category, status, hasCategory, speakerId }) {
  const categories = yield select(getCategories)
  try {
    const response = yield fetch(queries.getWebinars(take, skip, category, status, hasCategory, speakerId))
    response.Items = response.Items.map(webinar => ({
      ...webinar,
      Categories: getCategoryDetailsByIds({ categories, categoryIds: webinar.CategoriesIds })
    }))
    yield put({ type: GET_SPEAKER_WEBINARS_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_SPEAKER_WEBINARS_FAIL, err })
  }
}

export function * fetchCategoryWebinars ({ take, skip, category, status, hasCategory, speakerId }) {
  const categories = yield select(getCategories)
  try {
    const response = yield fetch(queries.getWebinars(take, skip, category, status, hasCategory, speakerId))
    response.Items = response.Items.map(webinar => ({
      ...webinar,
      Categories: getCategoryDetailsByIds({ categories, categoryIds: webinar.CategoriesIds })
    }))
    yield put({ type: GET_CATEGORY_WEBINARS_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_CATEGORY_WEBINARS_FAIL, err })
  }
}

export function * unfollowWebinar () {
  const { data } = yield select(getWebinar)
  try {
    const response = yield fetch(queries.registerToWebinar(data.Id), { method: 'delete', nojson: true, headers: { 'Content-Type': 'application/json' } })
    yield put({ type: UNFOLLOW_WEBINAR_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: UNFOLLOW_WEBINAR_FAIL, err })
  }
}

export function * WebinarRootSaga () {
  yield takeLatest(FETCH_WEBINAR_BY_SLUG_REQUESTED, fetchWebinarBySlug)
  yield takeLatest(REGISTER_TO_WEBINAR_REQUESTED, registerToWebinar)
  yield takeLatest(GET_SPEAKER_WEBINARS_REQUESTED, fetchSpeakerWebinars)
  yield takeLatest(GET_CATEGORY_WEBINARS_REQUESTED, fetchCategoryWebinars)
  yield takeLatest(UNFOLLOW_WEBINAR_REQUESTED, unfollowWebinar)
}

const queries = {
  getWebinarBySlug: (slug) => `${BASE_URL_WEB}/Webinar/${slug}`,
  registerToWebinar: (webinarId) => `${BASE_URL_WEB}/Webinar/user?webinarId=${webinarId}`,
  getWebinars: (take, skip, category, status, hasCategory, speakerId) => `${BASE_URL_WEB}/Webinar?take=${take}&skip=${skip}&OrderBy=plannifiedwebinardate${category ? `&CategoryId=${category}` : ''}${status ? `&Status=${status}` : ''}${speakerId ? `&SpeakerId=${speakerId}` : ''}${!hasCategory ? `&HasCategory=${hasCategory}` : ''}`
}

const FETCH_WEBINAR_BY_SLUG_REQUESTED = 'FETCH_WEBINAR_BY_SLUG_REQUESTED'
const FETCH_WEBINAR_BY_SLUG_SUCCESS = 'FETCH_WEBINAR_BY_SLUG_SUCCESS'
const FETCH_WEBINAR_BY_SLUG_FAIL = 'FETCH_WEBINAR_BY_SLUG_FAIL'
const REGISTER_TO_WEBINAR_REQUESTED = 'REGISTER_TO_WEBINAR_REQUESTED'
const REGISTER_TO_WEBINAR_SUCCESS = 'REGISTER_TO_WEBINAR_SUCCESS'
const REGISTER_TO_WEBINAR_FAIL = 'REGISTER_TO_WEBINAR_FAIL'
const GET_SPEAKER_WEBINARS_REQUESTED = 'GET_SPEAKER_WEBINARS_REQUESTED'
const GET_SPEAKER_WEBINARS_SUCCESS = 'GET_SPEAKER_WEBINARS_SUCCESS'
const GET_SPEAKER_WEBINARS_FAIL = 'GET_SPEAKER_WEBINARS_FAIL'
const GET_CATEGORY_WEBINARS_REQUESTED = 'GET_CATEGORY_WEBINARS_REQUESTED'
const GET_CATEGORY_WEBINARS_SUCCESS = 'GET_CATEGORY_WEBINARS_SUCCESS'
const GET_CATEGORY_WEBINARS_FAIL = 'GET_CATEGORY_WEBINARS_FAIL'
const UNFOLLOW_WEBINAR_REQUESTED = 'UNFOLLOW_WEBINAR_REQUESTED'
const UNFOLLOW_WEBINAR_SUCCESS = 'UNFOLLOW_WEBINAR_SUCCESS'
const UNFOLLOW_WEBINAR_FAIL = 'UNFOLLOW_WEBINAR_FAIL'
