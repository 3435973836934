import { createUseStyles, useTheme } from 'react-jss'
import style from './style'
import {
  visaLogo,
  masterCardLogo,
  paypalLogo
} from 'assets'
import SocialMedia from '../../SocialMedia'
import Text from './text.json'
import { PrivacyPolicyPath, LegalNoticePath, TermsOfServicesPath } from 'navigation/Routes'
import { MediaQuery, TypographyElement } from 'UI'

const useStyle = createUseStyles(style)

export default ({ goToUrl }) => {
  const theme = useTheme()
  const { root, alphorm, conditionsAndPayment, visaClass, payment, paymentContainer } = useStyle()
  return (
    <div className={root}>
      <MediaQuery HideOnDesktop HideOnTablet><SocialMedia /></MediaQuery>
      <div className={alphorm}>
        <TypographyElement
          display='inline'
          variant='body1'
          color={theme.neutral[100]}
        >{Text.copyright}
        </TypographyElement>
        <TypographyElement
          display='inline'
          variant='body1'
          color={theme.neutral[100]}
        >{Text.alphorm}
        </TypographyElement>
        <TypographyElement
          display='inline'
          variant='body1'
          color={theme.neutral[100]}
        >{Text.allRightReserved}
        </TypographyElement>
      </div>
      <div className={conditionsAndPayment}>
        <div>
          <TypographyElement
            cursor='pointer'
            display='inline'
            variant='body2'
            color={theme.primary[400]}
            handleClick={(e) => {
              e.preventDefault()
              goToUrl(TermsOfServicesPath)
            }}
            href={TermsOfServicesPath}
            Component='a'
          >
            {Text.generaleCondition}
          </TypographyElement>
          <TypographyElement
            spacing='0 8px'
            cursor='pointer'
            display='inline'
            variant='body2'
            color={theme.primary[400]}
            handleClick={(e) => {
              e.preventDefault()
              goToUrl(LegalNoticePath)
            }}
            href={LegalNoticePath}
            Component='a'
          >
            {Text.legalMention}
          </TypographyElement>
          <TypographyElement
            cursor='pointer'
            display='inline'
            variant='body2'
            color={theme.primary[400]}
            handleClick={(e) => {
              e.preventDefault()
              goToUrl(PrivacyPolicyPath)
            }}
            href={PrivacyPolicyPath}
            Component='a'
          >
            {Text.confidentialChart}
          </TypographyElement>
        </div>
        <div className={paymentContainer}>
          <TypographyElement
            spacing='0 16px 0 0'
            display='inline'
            variant='body2'
            color={theme.primary[400]}
          >
            {Text.payment}
          </TypographyElement>
          <div className={payment}>
            <img src={masterCardLogo} alt='masterCardLogo' width={30} height={19} />
            <img src={visaLogo} alt='visaLogo' className={visaClass} width={70} height={19} />
            <img src={paypalLogo} alt='paypalLogo' width={60} height={19} />
          </div>
        </div>
      </div>
    </div>
  )
}
