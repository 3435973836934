import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import UI from './UI'
import { decorateUrl } from 'components/common/UTMscript'
import { AlgeriaId } from 'constants/'

const mapStateToProp = ({ referential, identity, support, catalog, user }) => ({
  isConnected: identity.isConnected,
  france: referential.IsFrance,
  isAlgeria: referential?.CountryDetails?.Id === AlgeriaId,
  email: support.Email,
  phone: support.Phone,
  categories: catalog.categories,
  activeSubscription: user?.Subscription?.IsActive
})

const mapDispatchToProps = (dispatch) => ({
  goToUrl: (url) => dispatch(push(decorateUrl(url)))
})

export default connect(mapStateToProp, mapDispatchToProps)(UI)
