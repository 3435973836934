import { connect } from 'react-redux'
import UI from './UI'
import { fetchWebinarBySlugAction } from './store'

const mapStateToProps = ({ webinar }) => ({
  isLoading: webinar.isWebinarLoading
})

const mapDispatchToProps = dispatch => ({
  fetchWebinarBySlug: (slug) => dispatch(fetchWebinarBySlugAction(slug))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
