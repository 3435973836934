import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { bannerStyle } from './banner.style'
import Icon from '../icon/icon'
import { Icons } from '../../constants'
import TypographyElement from '../typographyElement/typographyElement'
import { bannerTextColors } from '../../constants/banner'

const Banner = (props) => {
  const useStyle = createUseStyles(bannerStyle)
  const { bannerGroup, iconStyle, iconAndMessage, closeIcon } = useStyle(props)
  return (
    <div className={bannerGroup}>
      <div className={iconAndMessage}>
        <Icon iconName={Icons[props.status]} style={iconStyle} />
        <TypographyElement lineHeight='21px' color={bannerTextColors(props.status, props.secondary)}>
          {props.text}
        </TypographyElement>
      </div>
      {
        !props.hideCloseIcon
          ? <Icon iconName={Icons.close} style={closeIcon} onHandleIconClick={props.onClose} />
          : null
      }
    </div>
  )
}

Banner.propTypes = {
  /** the text to display */
  text: PropTypes.string.isRequired,
  /** the status of the banner (success, error ...) */
  status: PropTypes.oneOf(['info', 'success', 'alert', 'error']),
  /** secondary banner variation */
  secondary: PropTypes.bool,
  /** handles the onClose event */
  onClose: PropTypes.func,
  /** if true, the close icon will be hidden */
  hideCloseIcon: PropTypes.bool
}

Banner.defaultProps = {
  text: 'This is a banner',
  status: 'info',
  secondary: false,
  onClose: () => { console.log('close') }
}

export default Banner
