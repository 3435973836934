import { Helmet } from 'react-helmet'

export default _ =>
  <Helmet>
    <script type='application/ld+json'>
      {
        `
          [
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Alphorm",
                "url": "https://www.alphorm.com",
                "logo": "https://static.alphorm.com/logos/logosanslecture.png",
                "sameAs": [
                    "https://www.youtube.com/c/alphorm",
                    "https://www.facebook.com/Alphorm.Officiel",
                    "https://www.instagram.com/alphorm.officiel",
                    "https://www.linkedin.com/company/alphorm",
                    "https://twitter.com/alphorm",
                    "https://fr.viadeo.com/fr/company/alphorm"
                ],
                "address": {
                    "@type": "PostalAddress",
                    "addressCountry": "FR",
                    "addressLocality": "Paris",
                    "postalCode": "75008",
                    "streetAddress": "66 Avenue des Champs-Élysées"
                },
                "email": "contact@alphorm.com",
                "contactPoint": {
                    "@type": "ContactPoint",
                    "telephone": "+33 1 77 62 45 80", 
                    "contactType": "customer service", 
                    "areaServed": "FR", 
                    "availableLanguage": "French" 
                }
            }
          ]
        `
      }
    </script>
  </Helmet>
