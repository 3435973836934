import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { radioButtonStyle } from './radioButton.style'

const RadioButton = (props) => {
  const useStyle = createUseStyles(radioButtonStyle)
  const { inputGroup, input, radio, label } = useStyle(props)
  return (
    <div className={inputGroup}>
      <input
        readOnly={props.readOnly}
        onChange={props.handleChange}
        name={props.name}
        checked={props.checked}
        id={props.id}
        type='radio'
        className={input}
        disabled={props.disabled}
        data-test={props.dataTest}
      />
      <label className={radio} />
      {props.label && <label htmlFor={props.id} className={label}>{props.label}</label>}
    </div>
  )
}

RadioButton.propTypes = {
  readOnly: PropTypes.bool,
  /**  the control label text to be displayed */
  label: PropTypes.string.isRequired,
  /** the value true or false to signal if control is disabled or not */
  disabled: PropTypes.bool,
  /** element's identifier */
  id: PropTypes.string,
  /** the name identifier to group multiple radio */
  name: PropTypes.string.isRequired,
  /** the value shipped with the input when submitted */
  checked: PropTypes.bool,
  /** the method that control the state of the RadioButton */
  handleChange: PropTypes.func,
  /** the test Id for end to end testing */
  dataTest: PropTypes.string
}

RadioButton.defaultProps = {
  label: 'RadioButtonlabel',
  disabled: false
}

export default RadioButton
