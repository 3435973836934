import { connect } from 'react-redux'
import Helmet from './Helmet'

const mapStateToProps = ({ catalog }) => {
  const selectedCategory = catalog.selectedCategory
  const allCategoriesSelected = selectedCategory.Id === catalog.allCoursesCategory.Id
  const courses = catalog.courses
  const description = courses.length ? courses.map(course => course.Title).join(', ').substring(0, 155) : ''
  return {
    selectedCategory,
    allCategoriesSelected,
    description,
    courses
  }
}

export default connect(mapStateToProps, null)(Helmet)
