import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Layout from 'components/common/Layout'
import Description from 'components/Webinar/Description'
import { createUseStyles } from 'react-jss'
import style from './style'
import LogicHeader from 'components/Webinar/LogicHeader'
import LinkedCourses from 'components/Webinar/LinkedCourses'
import LearnerWebinars from 'components/Webinar/SpeakerWebinars'
import SameCategorieWebinars from 'components/Webinar/SameCategorieWebinars'
import IntersectionComponent from 'components/common/IntersectionComponent'
import { backgroundEntrepriseShape1, backgroundEntrepriseShape2 } from 'assets'

const useStyle = createUseStyles(style)

export default ({
  fetchWebinarBySlug,
  isLoading
}) => {
  const {
    mainContent,
    rightShape,
    leftShape
  } = useStyle()
  const { slug } = useParams()
  useEffect(() => { fetchWebinarBySlug(slug) }, [slug])
  return (
    <Layout mainContentClass={mainContent}>
      <img src={backgroundEntrepriseShape1} className={rightShape} />
      <img src={backgroundEntrepriseShape2} className={leftShape} />
      <LogicHeader />
      {
        !isLoading
          ? (
            <>
              <Description />
              <IntersectionComponent component={() => <LinkedCourses />} />
              <IntersectionComponent component={() => <LearnerWebinars />} />
              <IntersectionComponent component={() => <SameCategorieWebinars />} />
            </>)
          : null
      }
    </Layout>
  )
}
