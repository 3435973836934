export const searchableSelectListStyle = {
  searchableSelectList: {
    position: 'relative'
  },
  inputAndIcon: ({ toggleSuggestionList }) => ({
    position: 'relative',
    '& > div:last-child': {
      transition: 'all 0.3s linear',
      transform: toggleSuggestionList ? 'rotate(180deg)' : 'rotate(0deg)',
      cursor: 'pointer'
    }
  }),
  suggestionGroupContainer: {
    transition: 'all 0.2s ease',
    maxHeight: ({ toggleSuggestionList }) => toggleSuggestionList ? 252 : 0,
    transformOrigin: 'top center',
    position: 'absolute',
    zIndex: 5,
    width: '100%'
  }
}
