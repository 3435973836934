import { blue, neutral, Spacing } from '@alphorm/ui'

export default {
  container: {
    display: 'flex',
    gap: 16
  },
  chipStyle: {
    border: `1px solid ${blue[0]}`,
    borderRadius: 50,
    padding: () => Spacing(3, 5),
    color: blue[0],
    cursor: 'pointer',
    '&.active': {
      backgroundColor: blue[0],
      color: neutral[0]
    }
  }
}
