import {
  red,
  Spacing
} from '@alphorm/ui'

export default {
  container: {
    height: 60,
    backgroundColor: red[3],
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
    padding: () => Spacing(0, 4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 30
  }
}
