import {
  WebinarCard,
  ButtonVariation,
  ButtonSize,
  Icons,
  ButtonIconAlignemement
} from '@alphorm/ui'
import style from './style.js'
import { createUseStyles } from 'react-jss'
import { WebinarNeutralPath } from 'navigation/Routes.js'
import Text from './text.json'
import { getWebinarStatus } from 'helpers/index.js'

const useStyle = createUseStyles(style)

const cardSettings = (size, isUserParticipating, slug, handlePathClick, status, WebinarStatus) => {
  switch (true) {
    case isUserParticipating && status === WebinarStatus.Live:
      return {
        variation: ButtonVariation.confirmation,
        size: ButtonSize[size],
        label: Text.join,
        disableShadow: true,
        disableTransform: true,
        handleClick: () => handlePathClick(`${WebinarNeutralPath}/${slug}`)
      }
    case isUserParticipating && status === WebinarStatus?.Passed:
      return {
        variation: 'primary',
        size: ButtonSize[size],
        label: Text.reWatch,
        disableShadow: true,
        disableTransform: true
      }
    case !isUserParticipating && status === WebinarStatus?.Passed:
      return {
        variation: ButtonVariation.confirmation,
        size: ButtonSize[size],
        label: Text.registerToreWatch,
        disableShadow: true,
        disableTransform: true
      }
    case !isUserParticipating && status !== WebinarStatus?.Passed:
      return {
        variation: ButtonVariation.confirmation,
        size: ButtonSize[size],
        label: Text.register,
        disableShadow: true,
        disableTransform: true
      }
    case isUserParticipating && status === WebinarStatus.Coming:
      return {
        variation: ButtonVariation.confirmation,
        iconName: Icons.check,
        alignement: ButtonIconAlignemement.left,
        iconWidth: 0,
        iconHeight: 0,
        spanBackgroundColor: ButtonVariation.confirmation,
        size: ButtonSize[size],
        label: Text.registered,
        disableShadow: true,
        disableTransform: true
      }
    default:
      return {
        variation: 'primary',
        size: ButtonSize[size],
        label: Text.none,
        disableShadow: true,
        disableTransform: true
      }
  }
}

export default ({
  size = 'big',
  loading,
  shadow,
  className,
  title,
  description,
  handleCoverClick,
  image,
  clockText,
  dataTest,
  categoryImage,
  categoryName,
  categoryBackgroundColor,
  showDescription = true,
  slug,
  isUserParticipating,
  handlePathClick,
  status,
  WebinarStatus
}) => {
  const { anchorClass, shadowClassName } = useStyle()
  const currentCardSettings = cardSettings(size, isUserParticipating, slug, handlePathClick, status, WebinarStatus)
  if (shadow) return <WebinarCard shadow={shadow} className={shadowClassName} />
  return (
    <a
      onClick={e => e.preventDefault()}
      name={title}
      href={`${WebinarNeutralPath}/${slug}`}
      className={anchorClass}
    >
      <WebinarCard
        image={image}
        stateText={getWebinarStatus(WebinarStatus, status)?.text}
        clockText={clockText}
        title={title}
        description={description}
        categoryImage={categoryImage}
        categoryName={categoryName}
        categoryBackgroundColor={categoryBackgroundColor}
        size={size}
        variant={getWebinarStatus(WebinarStatus, status)?.state}
        loading={loading}
        shadow={shadow}
        className={className}
        handleCardClick={() => handlePathClick(`${WebinarNeutralPath}/${slug}`)}
        showDescription={showDescription}
        buttonProps={currentCardSettings}
        buttonWithIcon={isUserParticipating ? true : null}
        iconProps={isUserParticipating && status === WebinarStatus.Coming ? { iconName: Icons.check } : null}
        handleCoverClick={handleCoverClick}
        dataTest={dataTest}
      />
    </a>
  )
}
