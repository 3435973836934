import { put, debounce, takeLatest, select } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB } from 'constants/'
import { ViewItemListDataLayer } from 'helpers'

const maxHistoryLength = 5

const initialState = {
  Authors: [],
  Tags: [],
  Courses: [],
  LearningPaths: [],
  CoursesResults: [],
  LearningPathsResults: [],
  searchTerm: '',
  searchTermResult: '',
  searchTermsHistory: [],
  error: false,
  searchLoader: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SEARCH_FETCH_SUCCESS:
      return {
        ...state,
        ...data.response,
        searchLoader: false,
        error: false
      }
    case SEARCH_FETCH_FAIL:
      return {
        ...state,
        err: data,
        Authors: [],
        Tags: [],
        Courses: [],
        LearningPaths: [],
        searchLoader: false
      }
    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: data
      }
    case SET_LOADER_SEARCH_STATE:
      return {
        ...state,
        ...data
      }
    case SET_SEARCH_PAGE_DATA:
      return {
        ...state,
        CoursesResults: state.Courses,
        LearningPathsResults: state.LearningPaths,
        searchTermResult: state.searchTerm,
        ...data
      }
    case SEARCH_RESET:
      return {
        ...state,
        Authors: [],
        Tags: [],
        Courses: [],
        LearningPaths: [],
        searchTerm: '',
        error: false,
        searchLoader: false
      }
    default:
      return state
  }
}

export const setSearchTermAction = (searchTerm, isSearchPage, fillSearchDataPage) => ({ type: SET_SEARCH_TERM, data: searchTerm, searchTerm, isSearchPage, fillSearchDataPage })
export const setSearchPageDataAction = () => ({ type: SET_SEARCH_PAGE_DATA_ACTION })
export const turnOnLoaderSearchAction = () => ({ type: SET_LOADER_SEARCH_STATE, data: { searchLoader: true } })
export const turnOffLoaderSearchAction = () => ({ type: SET_LOADER_SEARCH_STATE, data: { searchLoader: false } })
const getSearchData = ({ search }) => search
const getShopData = ({ support }) => support

function * fetchSearch ({ searchTerm, isSearchPage, fillSearchDataPage }) {
  const shop = yield select(getShopData)
  const promo = shop?.PromotionCourse

  if (fillSearchDataPage) yield put(turnOnLoaderSearchAction())
  if (searchTerm.length) {
    try {
      const response = yield fetch(queries.search(searchTerm))
      if (promo && response?.Courses?.length) {
        response.Courses = response?.Courses.map(course => ({
          ...course,
          HasActivePromotion: true,
          PricePromo: promo.DiscountPrice,
          DiscountPrice: promo.DiscountPrice,
          AlternativePricePromo: promo.AlternativePricePromo,
          DiscountPercentage: promo.DiscountPercentage
        }))
      }
      yield put({ type: SEARCH_FETCH_SUCCESS, data: { response, searchTerm } })
      yield put(turnOffLoaderSearchAction())
      if (isSearchPage) ViewItemListDataLayer({ courses: response.Courses, listName: searchTerm })
      if (fillSearchDataPage) yield put(setSearchPageDataAction())
    } catch (err) {
      if (fillSearchDataPage) yield put(setSearchPageDataAction())
      yield put({ type: SEARCH_FETCH_FAIL, err })
      yield put(turnOffLoaderSearchAction())
    }
  } else {
    yield put({ type: SEARCH_RESET })
    yield put(turnOffLoaderSearchAction())
  }
}

function * setSearchPageData () {
  const { searchTermsHistory, searchTerm } = yield select(getSearchData)
  const nextSearchTermsHistory = searchTermsHistory.filter(term => term !== searchTerm)
  if (searchTerm?.length) nextSearchTermsHistory.unshift(searchTerm)
  if (nextSearchTermsHistory?.length > maxHistoryLength) nextSearchTermsHistory.pop()
  yield put({ type: SET_SEARCH_PAGE_DATA, data: { searchTermsHistory: nextSearchTermsHistory } })
}

export function * searchRootSaga () {
  yield debounce(800, [SET_SEARCH_TERM, SEARCH_FETCH_REQUESTED], fetchSearch)
  yield takeLatest(SET_SEARCH_PAGE_DATA_ACTION, setSearchPageData)
}

const queries = {
  search: searchTerm => `${BASE_URL_WEB}/Course/suggestions/${encodeURIComponent(searchTerm)}`
}
const SEARCH_FETCH_SUCCESS = 'SEARCH_FETCH_SUCCESS'
const SEARCH_FETCH_FAIL = 'SEARCH_FETCH_FAIL'
const SEARCH_FETCH_REQUESTED = 'SEARCH_FETCH_REQUESTED'
const SEARCH_RESET = 'SEARCH_RESET'
const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
const SET_LOADER_SEARCH_STATE = 'SET_LOADER_SEARCH_STATE'
const SET_SEARCH_PAGE_DATA_ACTION = 'SET_SEARCH_PAGE_DATA_ACTION'
const SET_SEARCH_PAGE_DATA = 'SET_SEARCH_PAGE_DATA'
