import { escapeRegExp } from 'helpers'
import { Helmet } from 'react-helmet'

export default ({ title, description }) =>
  <Helmet>
    <script type='application/ld+json'>
      {
        `{
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "${title}",
              "description": "${escapeRegExp(description)}"
          }
        `
      }
    </script>
  </Helmet>
