import {
  TypographyElement,
  Card,
  CardSize,
  Container
} from '@alphorm/ui'
import Text from './text.json'
import { CourseNeutralPath } from 'navigation/Routes'
import style from './style'
import { createUseStyles } from 'react-jss'
import CardRenderer from 'components/common/CardRenderer'

const useStyle = createUseStyles(style)

const maxCoursesToShow = 4

export default ({ linkedCourses = [], goto }) => {
  const { cardsClass, root } = useStyle()

  return (
    <Container className={root}>
      <TypographyElement
        variant='heading2'
        spacing='0 0 16px 0'
        Component='h2'
      >
        {Text.title}
      </TypographyElement>
      <div className={cardsClass}>
        {
          linkedCourses.slice(0, maxCoursesToShow).map((course, key) =>
            course
              ? (
                <CardRenderer
                  cardSize={CardSize.small}
                  path={`${CourseNeutralPath}/${course.Slug}`}
                  course={course}
                  key={course.Id}
                  handleCardClick={() => goto(`${CourseNeutralPath}/${course.Slug}`)}
                  marginTablet='0 16px 0 0'
                  marginMbile='0 16px 0 0'
                />
                )
              : <Card key={key} size={CardSize.small} shadow />
          )
        }
      </div>
    </Container>
  )
}
