import { MediaQueries } from 'UI/constants'
import { loadingSkeleton, loadingSkeletonAnimation } from '../../helpers'

export const skeletonStyle = {
  wrapper: (props) => ({
    display: 'flex',
    width: props.wrapperWidth || '100%',
    maxWidth: '100%',
    flexDirection: 'column',
    gap: props.gap,
    margin: props.margin,
    padding: props.padding
  }),
  line: (props) => ({
    position: 'relative',
    width: '100%',
    maxWidth: props.width || '100%',
    height: props.height || '100%',
    ...loadingSkeleton({ borderRadius: props.borderRadius }).core
  }),
  [`${MediaQueries.tablet}`]: {
    line: (props) => ({
      height: props.heightTablet || props.height || '100%'
    })
  },
  [`${MediaQueries.mobile}`]: {
    line: (props) => ({
      height: props.heightMobile || props.height || '100%'
    })
  },
  ...loadingSkeletonAnimation
}
