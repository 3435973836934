import {
  blue,
  Spacing,
  MediaQueries
} from '@alphorm/ui'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    padding: '100px 0'
  },
  categoriesContainer: {
    display: 'flex',
    gap: 8,
    padding: () => Spacing(3, 0)
  },
  icon: {
    color: blue[1]
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: () => Spacing(3, 0)
  },
  buttonIconClass: {
    fontSize: 20
  },
  socialsContainer: {
    padding: () => Spacing(10, 0, 0, 0)
  },
  rightShape: {
    position: 'absolute',
    zIndex: -1,
    right: 0,
    top: 10
  },
  leftShape: {
    position: 'absolute',
    zIndex: -1,
    left: 0,
    top: 100
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 16,
    padding: 16
  },
  [`${MediaQueries.mobile}`]: {
    container: {
      height: 'auto',
      padding: '50px 0 16px 0'
    }
  },
  [`${MediaQueries.tablet}`]: {
    container: {
      height: 'auto',
      padding: '50px 0 16px 0'
    }
  }
}
