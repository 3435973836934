import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import Styles from './layout.style'
import classNames from 'classnames'

const Col = ({
  Component = 'div',
  children,
  className,
  align,
  display,
  pos,
  mgrid,
  sgrid,
  grid,
  direction = 'column',
  directionTablet,
  directionMobile,
  debug,
  gap,
  mobileGap,
  tabletGap,
  alignMobile,
  alignTablet,
  posMobile,
  posTablet,
  start = 'auto',
  startTablet = 'auto',
  startMobile = 'auto',
  takeGapLeft = false,
  takeGapRight = false,
  takeGapLeftTablet = false,
  takeGapRightTablet = false,
  takeGapLeftMobile = false,
  takeGapRightMobile = false
}) => {
  const useStyle = createUseStyles(Styles)
  const { col } = useStyle({
    align,
    display,
    pos,
    posMobile,
    posTablet,
    mgrid,
    sgrid,
    grid,
    direction,
    directionTablet,
    directionMobile,
    debug,
    gap,
    mobileGap,
    tabletGap,
    alignMobile,
    alignTablet,
    start,
    startTablet,
    startMobile,
    takeGapLeft,
    takeGapRight,
    takeGapLeftTablet,
    takeGapRightTablet,
    takeGapLeftMobile,
    takeGapRightMobile
  })
  return (
    <Component className={classNames(className, col)}>
      {children}
    </Component>
  )
}

Col.proptypes = {
  /** flex direction */
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse', 'initial', 'inherit']),
  /** for debugging it turns the background color to red */
  debug: PropTypes.bool,
  /** a number 1->12 that represent the width of the col in the grid */
  grid: PropTypes.number,
  /** display style */
  display: PropTypes.oneOf(['inline', 'flex', 'inline-block']),
  /** justify content with the flex display */
  pos: PropTypes.oneOf(['center', 'left', 'right', 'evenly', 'end']),
  /** tablet justify content with the flex display */
  posTablet: PropTypes.oneOf(['center', 'left', 'right', 'evenly']),
  /** mobile justify content with the flex display */
  posMobile: PropTypes.oneOf(['center', 'left', 'right', 'evenly']),
  /** align content with the flex display */
  align: PropTypes.oneOf(['top', 'mid', 'bot']),
  /** align mobile content with the flex display */
  alignMobile: PropTypes.oneOf(['top', 'mid', 'bot']),
  /** align tablet content with the flex display */
  alignTablet: PropTypes.oneOf(['top', 'mid', 'bot']),
  /** gap */
  gap: PropTypes.number,
  /** gap tablet */
  mobileGap: PropTypes.number,
  /** gap mobile */
  tabletGap: PropTypes.number
}
export default Col
