import { MediaQueries } from '../..'

export const cardStyle = (theme) => ({
  cardGroup: {
    display: 'flex',
    flexDirection: 'column',
    height: ({ height }) => height,
    padding: 23,
    width: ({ width }) => width
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: ({ titleMargin }) => titleMargin || '0 0 12px 0'
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center'
  },
  playIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.neutral[0],
    fontSize: 6,
    backgroundColor: theme.primary[0],
    borderRadius: 8,
    marginRight: 8
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    marginBottom: 1
  },
  iconClass: {
    color: theme.success[0],
    fontSize: 14,
    marginLeft: 4,
    padding: '0 7px 0 0'
  },
  iconAndSecondaryText: {
    display: 'flex',
    alignItems: 'center'
  },
  [`${MediaQueries.tablet}`]: {
    header: {
      margin: '0 0 24px 0'
    },
    cardGroup: {
      height: ({ heightTablet }) => heightTablet,
      width: ({ widthTablet }) => widthTablet
    },
    row: {
      flexDirection: ({ hideSecondaryText }) => !hideSecondaryText && 'column-reverse',
      alignItems: 'flex-start',
      marginBottom: 7
    },
    iconAndSecondaryText: {
      marginBottom: 16
    }
  },
  [`${MediaQueries.mobile}`]: {
    header: {
      margin: '0 0 24px 0'
    },
    cardGroup: {
      height: ({ heightMobile }) => heightMobile,
      width: ({ widthMobile }) => widthMobile
    },
    row: {
      flexDirection: ({ hideSecondaryText }) => !hideSecondaryText && 'column-reverse',
      alignItems: 'flex-start',
      marginBottom: 7
    },
    iconAndSecondaryText: {
      marginBottom: 16
    }
  }
})
