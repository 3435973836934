export const tabsStyle = {
  GroupTab: {
    display: 'flex',
    position: 'relative',
    transition: 'all 0.1s linear',
    '& > :first-child': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    },
    '& > :last-child': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    }
  },
  content: {
    position: 'relative'
  },
  TabAndContent: {
    position: 'relative',
    width: '100%'
  }
}
