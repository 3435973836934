import {
  Typography,
  MediaQueries
} from 'UI'

export default {
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    body: {
      fontFamily: `${Typography.fontFamily}, sans-serif`,
      minWidth: 380
    },
    '.grecaptcha-badge': {
      visibility: 'hidden'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  mainContent: {
    minHeight: '100vh'
  },
  [`${MediaQueries.tablet}`]: {
    mainContent: {
      minHeight: 'calc(100vh - 164px)'
    }
  },
  [`${MediaQueries.mobile}`]: {
    mainContent: {
      minHeight: 'calc(100vh - 164px)'
    }
  }
}
