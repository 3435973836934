import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { TypographyElement } from '../..'
import { sliderStyle } from './slider.style'
import View from './view'
import Controller from './controller'
import classNames from 'classnames'

const Slider = (props) => {
  const viewRef = useRef(null)
  const useStyle = createUseStyles(sliderStyle)
  const classes = useStyle({
    screen: props.screen,
    padding: props.padding,
    secondaryController: props.secondaryController,
    gap: props.gap,
    gapTablet: props.gapTablet,
    gapMobile: props.gapMobile,
    marginMobile: props.marginMobile,
    marginTablet: props.marginTablet
  })
  const [currentView, setCurrentView] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)
  const viewsLength = props.views.length
  const scrollNextView = () => {
    if (isScrolling) return
    setIsScrolling(true)
    setCurrentView(currentView === viewsLength - 1 ? currentView : currentView + 1)
    viewRef.current.scrollLeft += viewRef.current.offsetWidth + props.gap
    setTimeout(() => setIsScrolling(false), 500)
  }
  const scrollPreviousView = () => {
    if (isScrolling) return
    setIsScrolling(true)
    setCurrentView(currentView ? currentView - 1 : currentView)
    viewRef.current.scrollLeft -= viewRef.current.offsetWidth + props.gap
    setTimeout(() => setIsScrolling(false), 500)
  }
  const scrollToView = (index) => {
    if (isScrolling) return
    setIsScrolling(true)
    setCurrentView(index)
    viewRef.current.scrollLeft = index * (viewRef.current.offsetWidth + props.gap)
    setTimeout(() => setIsScrolling(false), 500)
  }

  return (
    <div className={classes.root}>
      <div className={classNames(classes.header, props.headerStyle)}>
        {props.title
          ? (
            <TypographyElement
              variant='heading2'
              spacingTablet='0 0 24px 0'
              spacingMobile='0 0 24px 0'
              {...props.titleProps}
              component='span'
            >
              {props.title}
              {props.subtitleComponent
                ? props.subtitleComponent()
                : null}
            </TypographyElement>)
          : null}
        {
          !props.hideController && !props.controllerOnTheBottom &&
            <Controller
              secondaryController={props.secondaryController}
              controllerClassName={props.controllerClassName}
              transparent={props.transparentController}
              currentView={currentView}
              views={props.views}
              setCurrentView={scrollToView}
              viewsLength={viewsLength}
              scrollNextView={scrollNextView}
              scrollPreviousView={scrollPreviousView}
            />
        }
      </div>
      <div ref={viewRef} className={classes.slides}>
        {
        props.views.map((view, index) =>
          <View
            key={index}
            currentView={currentView}
            viewWidth={props.viewWidth}
            viewWidthTablet={props.viewWidthTablet}
            viewWidthMobile={props.viewWidthMobile}
          >
            {view}
          </View>
        )
        }
      </div>
      {
        !props.hideController && props.controllerOnTheBottom &&
          <Controller
            secondaryController={props.secondaryController}
            controllerClassName={props.controllerClassName}
            transparent={props.transparentController}
            currentView={currentView}
            views={props.views}
            setCurrentView={scrollToView}
            viewsLength={viewsLength}
            scrollNextView={scrollNextView}
            scrollPreviousView={scrollPreviousView}
          />
      }
    </div>
  )
}

Slider.propTypes = {
  /**  Views to put on each slid */
  views: PropTypes.array,
  /**  Title of the carousel */
  title: PropTypes.string,
  /**  Target Screen */
  screen: PropTypes.oneOf(['desktop', 'mobile']),
  /** classname for the controller container */
  controllerClassName: PropTypes.string,
  /** boolean to set a transparent controller */
  transparentController: PropTypes.bool,
  /** additional functions for potencial pagination */
  nextView: PropTypes.func,
  /** additional functions for potencial pagination */
  previousView: PropTypes.func
}

export default Slider
