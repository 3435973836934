import {
  MediaQueries
} from '@alphorm/ui'

export default {
  root: {
    width: '100%',
    margin: '100px 0'
  },
  cardsClass: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  [`${MediaQueries.tablet}`]: {
    cardsClass: {
      flexWrap: 'nowrap',
      marginRight: -15,
      marginBottom: 24,
      overflow: 'auto',
      width: 'unset',
      '& > div': {
        marginRight: 16
      }
    }
  },
  [`${MediaQueries.mobile}`]: {
    cardsClass: {
      flexWrap: 'nowrap',
      marginRight: -15,
      marginBottom: 24,
      overflow: 'auto',
      width: 'unset',
      '& > div': {
        marginRight: 16
      }
    }
  }
}
