import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { ExpanderStyle } from './expander.style'
import { Icon } from '../icon/icon'
import { Icons } from '../../constants/icons'
import TypographyElement from '../typographyElement/typographyElement'
import Paper from '../paper/paper'
import classNames from 'classnames'

const Expander = ({ handleOpenClick, title, children, marginBottom, className }) => {
  const useStyle = createUseStyles(ExpanderStyle)
  const [open, setOpen] = useState(false)
  const ref = useRef()
  const { container, header, showIcon, innerGroup, innerContainer } = useStyle({ open, marginBottom })
  const openClick = () => {
    handleOpenClick && handleOpenClick()
    setOpen(!open)
  }
  return (
    <Paper className={classNames(container, className)}>
      <div className={header} onClick={openClick}>
        <TypographyElement variant='heading6'>{title}</TypographyElement>
        <Icon iconName={Icons.roundedDown} style={showIcon} />
      </div>
      <div className={innerGroup} style={{ height: open ? ref.current.clientHeight : 0 }}>
        <div ref={ref} className={innerContainer}>
          {children}
        </div>
      </div>
    </Paper>
  )
}

Expander.propTypes = {
  /** the title of the expander */
  title: PropTypes.string,
  /** function that handle the click on a parent */
  handleOpenClick: PropTypes.func,
  /** the children to render */
  children: PropTypes.node,
  /** the margin bottom of the component */
  marginBottom: PropTypes.number
}

export default Expander
