import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Routes from '../navigation/Routes'
import { store, persistor } from 'store/index'
import UserIdTrackingDataLayer from 'components/common/SEO/UserIdTrackingDataLayer'
import Notification from 'components/common/Notification'
import CssBaseLine from 'components/common/CssBaseLine'
import { ThemeProvider } from 'react-jss'
import { Theme } from 'UI'

export default () =>
  <ThemeProvider theme={Theme}>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CssBaseLine />
        <UserIdTrackingDataLayer />
        <Notification />
        <Routes />
      </PersistGate>
    </ReduxProvider>
  </ThemeProvider>

if (window.Cypress) {
  window.store = store
}
