import { HomePath } from 'navigation/Routes'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'
import { Authority, ClientId } from './config'

const buildUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
export const SigninPath = '/signin'
export const SignoutPath = '/signout'

const userManagerConfig = {
  client_id: ClientId,
  redirect_uri: `${buildUrl}${SigninPath}`,
  post_logout_redirect_uri: `${buildUrl}${SignoutPath}`,
  response_type: 'code',
  scope: 'openid profile offline_access',
  authority: Authority,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  requirePkce: true,
  monitorSession: false,
  revokeAccessTokenOnSignout: true,
  userStore: new WebStorageStateStore({ store: window.localStorage, prefix: 'alphorm' })
}

const userManager = new UserManager(userManagerConfig)
window.userManager = userManager
export const getUser = () => userManager.getUser()
export const signin = () => userManager.signinRedirect({ redirect_uri: `${buildUrl}${SigninPath}?path=${window.location.pathname === HomePath ? window.location.pathname : `${window.location.pathname}/`}${window.location.search}` })
export const signup = () => userManager.signinRedirect({
  extraQueryParams: {
    signup: true
  }
})
export const signinSilent = () => userManager.signinSilent()
export const signout = () => userManager.signoutRedirect()
export const callback = () => userManager.signinRedirectCallback()
export const signoutCallback = () => userManager.signoutRedirectCallback()
export const userLoaded = (fn) => userManager.events.addUserLoaded(fn)
export const userUnloaded = (fn) => userManager.events.addUserUnloaded(fn)
export const accessTokenExpired = (fn) => userManager.events.addAccessTokenExpired(fn)
export const accessTokenExpiring = (fn) => userManager.events.addAccessTokenExpiring(fn)
export const userSignedOut = (fn) => userManager.events.addUserSignedOut(fn)
export const clean = async () => {
  await userManager.clearStaleState()
  await userManager.removeUser()
}
