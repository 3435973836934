export const ButtonSize = {
  small: 'small',
  big: 'big'
}

export const ButtonIcon = {
  small: {
    padding: 7,
    size: 30
  },
  big: {
    padding: 19,
    size: 54
  }
}

export const ButtonType = {
  button: 'button',
  submit: 'submit',
  reset: 'reset'
}

export const ButtonVariation = {
  primary: 'primary',
  text: 'text',
  secondary: 'secondary',
  important: 'important',
  confirmation: 'confirmation',
  information: 'information',
  purple: 'purple'
}

export const ButtonIconAlignemement = {
  left: 'left',
  right: 'right'
}

export const IconButtonSizeMap = {
  small: {
    width: 30,
    height: 30,
    fontSize: 'large',
    margin: '0 16px'
  },
  big: {
    width: 54,
    height: 54,
    fontSize: 'x-large',
    margin: '0 28px'
  }
}

export const IconButtonVariationMap = (theme, variation) => {
  switch (variation) {
    case 'primary': return {
      backgroundColor: theme.primary[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.primary[100],
      backgroundColorPressed: theme.primary[1300],
      activeScale: 0.95
    }
    case 'secondary': return {
      backgroundColor: theme.secondary[0],
      color: theme.neutral[800],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.secondary[100],
      backgroundColorPressed: theme.secondary[300],
      activeScale: 0.95
    }
    case 'important': return {
      backgroundColor: theme.error[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.error[100],
      backgroundColorPressed: theme.error[300],
      activeScale: 0.95
    }
    case 'confirmation': return {
      backgroundColor: theme.success[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.success[100],
      backgroundColorPressed: theme.success[200],
      activeScale: 0.95
    }
    default: return {
      backgroundColor: theme.primary[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.primary[100],
      backgroundColorPressed: theme.primary[1300],
      activeScale: 0.95
    }
  }
}

export const ButtonSizeMap = {
  big: {
    height: 70,
    fontSize: 14,
    lineHeight: 1.43,
    paddingLeft: 0,
    paddingRight: 0
  },
  small: {
    height: 54,
    fontSize: 14,
    lineHeight: 1.43,
    paddingLeft: 0,
    paddingRight: 0
  },
  extraSmall: {
    height: 31,
    fontSize: 8,
    lineHeight: 1.43,
    paddingLeft: 0,
    paddingRight: 0
  }
}

export const ButtonVariationMap = (theme, variation) => {
  switch (variation) {
    case 'primary': return {
      backgroundColor: theme.primary[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.primary[100],
      backgroundColorPressed: theme.primary[1300],
      activeScale: 0.95
    }
    case 'secondary': return {
      backgroundColor: theme.secondary[0],
      color: theme.primary[800],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.secondary[100],
      backgroundColorPressed: theme.secondary[300],
      activeScale: 0.95
    }
    case 'text': return {
      backgroundColor: 'transparent',
      color: theme.primary[0],
      backgroundColorDisabled: 'transparent',
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: 'transparent',
      backgroundColorPressed: 'transparent',
      colorHovered: theme.primary[100],
      colorPressed: theme.primary[1300],
      activeScale: 1
    }
    case 'important': return {
      backgroundColor: theme.error[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.error[100],
      backgroundColorPressed: theme.error[300],
      activeScale: 0.95
    }
    case 'confirmation': return {
      backgroundColor: theme.success[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.success[100],
      backgroundColorPressed: theme.success[200],
      activeScale: 0.95
    }
    default: return {
      backgroundColor: theme.primary[0],
      color: theme.neutral[400],
      backgroundColorDisabled: theme.neutral[0],
      colorDisabled: theme.neutral[100],
      backgroundColorHovered: theme.primary[100],
      backgroundColorPressed: theme.primary[1300],
      activeScale: 0.95
    }
  }
}

export const ButtonWithIconSizeMap = {
  small: {
    height: 22,
    fontSize: 12,
    lineHeight: 1.33,
    paddingLeft: 10,
    paddingRight: 10
  },
  big: {
    height: 40,
    fontSize: 14,
    lineHeight: 1.43,
    paddingLeft: 0,
    paddingRight: 15
  }
}
