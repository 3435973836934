import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { dropdownMenuStyle } from './dropdownMenu.style'
import TypographyElement from '../typographyElement/typographyElement'
import Icon from '../icon/icon'
import { Icons } from '../../constants'
import classNames from 'classnames'

export const DropdownMenu = (props) => {
  const useStyle = createUseStyles(dropdownMenuStyle)
  const { root, checkbox, title, expandIcon, menuItems } = useStyle(props)
  const { links } = props
  const linksArray = links && Array.isArray(links) ? links : []
  return (
    <div className={root}>
      <input type='checkbox' className={checkbox} />
      <TypographyElement variant='body1' className={classNames(title, props.className)}>
        <Icon iconName={props.iconName} style={props.iconClassName} />
        {props.title}
        <Icon iconName={Icons.roundedUp} style={expandIcon} />
      </TypographyElement>
      <div className={menuItems}>
        {props.children || linksArray.map((link, i) =>
          <span onClick={() => props.handleClick(link.path)} key={i} variant='body'>{link.title}</span>
        )}
      </div>
    </div>
  )
}

DropdownMenu.propTypes = {
  /** the title of the dropdown menu */
  title: PropTypes.string,
  /** the links of the dropdown menu */
  links: PropTypes.array,
  /** the Height of each item of the dropdown menu */
  itemHeight: PropTypes.number,
  /** set the borders */
  borders: PropTypes.oneOf(['top', 'left', 'right', 'bottom', 'all', 'none', 'topAndBottom', 'leftAndRight']),
  /** the item count of the menu items */
  itemCount: PropTypes.number,
  /** the function that handles the link click */
  handleClick: PropTypes.func,
  /** add Icon to the left side of the dropdown menu */
  iconName: PropTypes.string,
  /** class to style the icon in the left side of the drop down mwnu */
  iconClassName: PropTypes.string

}

DropdownMenu.defaultProps = {
  itemHeight: 25,
  borders: 'none'
}

export default DropdownMenu
