import {
  TypographyElement,
  neutral,
  blue,
  green
} from '@alphorm/ui'
import style from './style'
import { createUseStyles } from 'react-jss'
import { amazonBucket } from 'constants/'
import { freeCoursesCategory, learningPathCategory } from '../store'
import classNames from 'classnames'

const useStyle = createUseStyles(style)

export default ({ handleClick, Name, NumberOfCourses, active = false, catalog, Id, Photo, noMobile, path, loading, hideNumber, other }) => {
  const {
    categoryPhotoClass,
    container,
    freeCategory,
    tag,
    photoAndTag,
    core,
    anchorClass,
    learningPathClass
  } = useStyle({ active, Id, catalog, noMobile, loading, hideNumber })
  const isFreeCategory = freeCoursesCategory.Id === Id
  const isLearningPath = Name === learningPathCategory.Name
  const image = other ? Photo : `${amazonBucket.categories}${Photo}`
  return (
    <div
      onClick={!loading && handleClick}
      className={classNames(container,
        isFreeCategory && freeCategory,
        isLearningPath && learningPathClass
      )}
    >
      <a href={path} onClick={e => e.preventDefault()} className={anchorClass}>
        <div className={core}>
          {
            !catalog && !isFreeCategory && !isLearningPath &&
              <div className={photoAndTag}>
                <img src={image} alt={Name} className={categoryPhotoClass} />
                <div className={tag}>
                  <TypographyElement
                    variant='smallText'
                    fontSize='8px'
                    lineHeight='8px'
                    spacing='2px 0 0 0'
                    color={neutral[0]}
                  >
                    {NumberOfCourses}
                  </TypographyElement>
                </div>
              </div>
          }
          <TypographyElement
            variant='smallText'
            spacingTablet={(!catalog && !isFreeCategory && !isLearningPath) ? '12px 0 0 0' : null}
            spacingMobile={(!catalog && !isFreeCategory && !isLearningPath) ? '8px 0 0 0' : null}
            color={catalog ? blue[0] : isFreeCategory ? green[2] : isLearningPath ? neutral[0] : neutral[6]}
            align={(catalog || isFreeCategory || isLearningPath) && 'center'}
          >
            {Name}
          </TypographyElement>
        </div>
      </a>
    </div>
  )
}
