import { Typography, InputColors } from '../../constants'

export const InputDatePickerStyle = {
  inputDatePickerContainer: {
    position: 'relative',
    width: '100%'
  },
  select: {
    '& select': {
      height: '38px',
      borderRadius: '4px',
      border: 'solid 1px ',
      borderColor: InputColors.inputborder,
      padding: [0, 0, 0, 8],
      outline: 'none',
      transition: 'box-shadow .2s linear',
      font: {
        family: Typography.fontFamily,
        size: Typography.inputPlaceholder
      },
      '&:placeholder': {
        font: {
          family: Typography.fontFamily,
          size: Typography.inputPlaceholder
        },
        color: InputColors.placeholder
      },
      '&:focus': {
        boxShadow: `0 0 0 2px ${InputColors.inputfocus}`
      }
    },
    '& select:first-child': {
      width: '45%',
      marginRight: 5
    },
    '& select:last-child': {
      width: '30%'
    }
  },
  'DayPicker-NavBar': {
    marginTop: 9
  }
}
