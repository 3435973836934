import { escapeRegExp } from 'helpers'
import { Helmet } from 'react-helmet'

export default ({ title, description, image = null }) =>
  <Helmet>
    <meta name='twitter:site' content='@alphorm' />
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={escapeRegExp(description)} />
    <meta name='twitter:image' content={image} />
    <meta name='twitter:card' content='summary_large_image' />
  </Helmet>
