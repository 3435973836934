import { fetchCategoryWebinarsAction } from 'pages/Webinar/store'
import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ webinar, referential }) => ({
  WebinarCategory: webinar?.data?.Categories?.[0]?.Id,
  WebinarStatus: referential.WebinarStatus,
  FilteredWebinars: webinar?.categoryWebinar
})

const mapDispatchToProps = dispatch => ({
  fetchCategoryWebinarsAction: (data) => dispatch(fetchCategoryWebinarsAction((data)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
