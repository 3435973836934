import React, { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import InputFieldWithVerificationStyle from './inputFieldWithVerification.style'
import { Button } from '../..'
import Icon from '../icon/icon'
import TextInput from '../textInput/textInput'
import classNames from 'classnames'
import { Icons } from '../../constants'
import OptionItem from './optionItem'
import OptionGroup from './optionGroup'

const InputFieldWithVerification = ({ textInputProps, buttonProps, valid, iconClassName, countriesInfo, handleChangePhoneCode, initialCountry, gutterBottom, disableOptions }) => {
  const [openOptions, setOpenOptions] = useState(false)
  const [country, setCountry] = useState(initialCountry)
  const node = useRef()
  const listRef = useRef()
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideContainer)
    return () => {
      document.removeEventListener('click', handleClickOutsideContainer)
    }
  }, [])
  const handleClickOutsideContainer = e => (node && node.current && (!node.current?.contains(e.target) || e?.target?.tagName === 'INPUT') && listRef && !listRef.current?.contains(e.target) && !openOptions) && setOpenOptions(false)
  const useStyle = createUseStyles(InputFieldWithVerificationStyle)
  const { root, greenCheck, buttonClass, container, input, countryCode, flag, arrowClass, suggestionGroupContainer } = useStyle({ openOptions, gutterBottom })

  return (
    <div className={container} ref={node}>
      <div className={root}>
        <div className={countryCode} onClick={() => !disableOptions && setOpenOptions(!openOptions)}>
          <span className={classNames(`flag-icon flag-icon-${country.Code.toLowerCase()}`, flag)} />
          <Icon iconName={Icons.roundedDown} style={arrowClass} />
        </div>
        <TextInput textInputClassName={input} {...textInputProps} />
        {valid && <Icon style={classNames(iconClassName, greenCheck)} iconName={Icons.check} />}
      </div>
      <Button
        className={classNames(buttonProps?.className, buttonClass)}
        topLeft={0}
        bottomLeft={0}
        height={56}
        {...buttonProps}
      />
      {
        openOptions
          ? (
            <div className={suggestionGroupContainer} id='optionsId'>
              <OptionGroup ref={listRef}>
                {
                  countriesInfo.map((option, i) =>
                    <OptionItem
                      idField={option.Code}
                      valueField={option.PhoneCode}
                      for='optionsId'
                      key={i}
                      id={option.Id}
                      value={option.PhoneCode}
                      countryName={option.Name}
                      countryCode={option.Code}
                      active={option.Code === country.Code}
                      handleClick={() => {
                        setCountry(option)
                        handleChangePhoneCode(option.PhoneCode)
                        setOpenOptions(false)
                      }}
                    />
                  )
                }
              </OptionGroup>
            </div>
            )
          : null
      }
    </div>
  )
}

InputFieldWithVerification.defaultProps = {
  countriesInfo: [],
  initialCountry: { Id: 65, Name: 'France', Code: 'FR', PhoneCode: '+33' }
}

export default InputFieldWithVerification
