import { blue } from '../../constants'

export const loaderStyle = {
  spiner: {
    backgroundColor: '#b0b0b014',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: 64,
    '& div': {
      boxSizing: 'border-box',
      display: 'block',
      position: 'absolute',
      width: 51,
      height: 51,
      border: '4px solid #fff',
      borderRadius: '50%',
      animation: 'spiner 1.2s cubic-bezier(0.39, 0.58, 0.57, 1) infinite',
      borderColor: `${blue[0]} transparent transparent transparent`
    },
    '& div:nth-child(1)': {
      animationDelay: '-0.45s'
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.3s'
    },
    '& div:nth-child(3)': {
      animationDelay: '-0.15s'
    }
  },
  '@keyframes spiner': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: ' rotate(360deg)'
    }
  }
}
