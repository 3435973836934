export const suggestionItemStyle = theme => ({
  suggestionItem: {
    width: '100%',
    backgroundColor: ({ selected }) => selected ? theme.primary[0] : theme.neutral[400],
    '&:hover': {
      backgroundColor: ({ selected }) => selected ? theme.primary[0] : theme.neutral[0]
    }
  },
  suggestionItemLabel: {
    overflow: 'hidden'
  }
})
