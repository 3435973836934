import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { downloadAppStyle } from './downloadApp.style'
import { DownloadAppTypes } from '../../constants'

const DownloadApp = (props) => {
  const useStyle = createUseStyles(downloadAppStyle)
  const { button } = useStyle(props)
  return (
    <a
      href={DownloadAppTypes.link[props.type]}
      className={button}
    >
      <img src={DownloadAppTypes.image[props.type]} alt={DownloadAppTypes.type[props.type]} />
    </a>
  )
}

DownloadApp.propTypes = {
  /**  the type of the application the button represent */
  type: PropTypes.oneOf(['google', 'apple', 'desktop'])
}

export default DownloadApp
