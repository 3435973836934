import { Spacing } from 'UI'

export default (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 90,
    padding: () => Spacing(5, 0),
    borderBottom: `1px solid ${theme.neutral[100]}`
  },
  logoClass: {
    cursor: 'pointer'
  }
})
