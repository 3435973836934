import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import style from './clickableRateStars.style.js'

const useStyle = createUseStyles(style)

const StarIcon = ({
  width = 38,
  height = 36
}) => {
  return (
    <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M37.836 13.975c-.713.738-1.404 1.476-2.125 2.184-1.936 1.907-3.894 3.796-5.843 5.69-.307.299-.636.584-.917.913a.736.736 0 0 0-.154.533c.708 4.21 1.429 8.416 2.15 12.626.004.017 0 .038-.004.068-.009.038-.188-.026-.406-.14-.755-.393-1.497-.781-2.239-1.174-2.939-1.544-5.882-3.088-8.812-4.645-.256-.136-.431-.072-.653.043a8976.66 8976.66 0 0 1-11.022 5.8 2.773 2.773 0 0 1-.196.095c-.11.05-.166-.107-.124-.35.167-.994.325-1.962.49-2.926.543-3.157 1.097-6.309 1.626-9.465.026-.15-.081-.371-.196-.487-1.907-1.876-3.84-3.723-5.729-5.617-1.032-1.037-2.013-2.124-2.977-3.16-.166-.18-.107-.355.137-.393.507-.077 1.096-.167 1.689-.252 2.58-.375 5.165-.75 7.746-1.13.866-.128 1.727-.277 2.597-.367.329-.034.487-.17.623-.452 1.8-3.673 3.613-7.341 5.421-11.01.039-.08.081-.157.128-.242.073-.133.184-.158.252-.047.043.068.081.132.115.2 1.83 3.707 3.664 7.414 5.486 11.129.132.268.281.384.588.426 3.788.542 7.571 1.1 11.359 1.655.286.043.572.081.857.12.243.025.303.196.133.375Z'
        fill='gray'
        fillRule='nonzero'
      />
    </svg>
  )
}

const ClickableRateStars = ({
  value,
  onStarClick,
  numberOfStars
}) => {
  const {
    stars,
    starIcon,
    activeStar
  } = useStyle()

  return (
    <div className={stars}>
      {
        [...Array(numberOfStars)].map((_, index) => (
          <div
            key={index}
            className={classNames(starIcon, Math.abs(numberOfStars - value) <= index && activeStar)}
            onClick={(() => onStarClick(numberOfStars - index))}
          >
            <StarIcon key={index} />
          </div>
        ))
      }
    </div>
  )
}

ClickableRateStars.propTypes = {
  /** Number of filled stars to display */
  value: PropTypes.number.isRequired,
  /** Event handler for clicking a star */
  onStarClick: PropTypes.func.isRequired,
  /** Number of stars to display */
  numberOfStars: PropTypes.number
}

ClickableRateStars.defaultProps = {
  numberOfStars: 5
}

export default ClickableRateStars
