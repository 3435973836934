import fetch from 'core/fetch'
import { debounce, put, takeLatest } from 'redux-saga/effects'
import { BASE_URL_WEB } from 'constants/'

const initialState = {
  topBarOpen: false,
  popUpOpen: false,
  userCloseTopBar: false,
  userClosePopUp: false,
  userVisitedRoutes: [],
  popUp: {
    Id: 0,
    Title: '',
    StartDate: '',
    EndDate: '',
    BuyerPersonas: [],
    WidgetMessage: '',
    Image: '',
    ActionLink: '',
    ActionExternal: false,
    ScrollDepth: 0,
    ExcludedUrls: [],
    ExcludedUtmSources: [],
    WidgetType: '',
    NumberOfPagesTrigger: 0,
    TextButton: '',
    WidgetStatus: false
  },
  topBar: {
    Id: 0,
    title: '',
    StartDate: '',
    EndDate: '',
    BuyerPersonas: [],
    WidgetMessage: '',
    Image: '',
    ActionLink: '',
    ActionExternal: false,
    ScrollDepth: 0,
    ExcludedUrls: [],
    ExcludedUtmSources: [],
    WidgetType: '',
    NumberOfPagesTrigger: 0,
    TextButton: '',
    WidgetStatus: false
  },
  error: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case GET_MARKETING_WIDGETS_SUCCESS:
      return {
        ...state,
        ...data
      }
    case GET_MARKETING_WIDGETS_FAIL:
      return {
        ...state,
        error: data
      }
    case SWITCH_TOP_BAR_STATE:
      return {
        ...state,
        topBarOpen: data
      }
    case USER_CLOSE_TOP_BAR:
      return {
        ...state,
        userCloseTopBar: true
      }
    case SWITCH_POPUP_STATE:
      return {
        ...state,
        popUpOpen: data
      }
    case USER_CLOSE_POPUP:
      return {
        ...state,
        userClosePopUp: true
      }
    case ADD_VISITED_ROUTE:
      return {
        ...state,
        userVisitedRoutes: [...state.userVisitedRoutes, data]
      }
    default:
      return state
  }
}

export const fetchWidgetsAction = () => ({ type: GET_MARKETING_WIDGETS_REQUESTED })
export const SwitchTopBarStateAction = data => ({ type: SWITCH_TOP_BAR_STATE_ACTION, data })
export const SwitchPopUpStateAction = data => ({ type: SWITCH_POPUP_STATE_ACTION, data })
export const UserCloseTopBarAction = _ => ({ type: USER_CLOSE_TOP_BAR })
export const UserClosePopUpAction = _ => ({ type: USER_CLOSE_POPUP })
export const AddVisitedRouteAction = data => ({ type: ADD_VISITED_ROUTE, data })

function * fetchWidgets () {
  try {
    const response = yield fetch(queries.getMarketingWidgets)
    yield put({
      type: GET_MARKETING_WIDGETS_SUCCESS,
      data: {
        topBar: response.TopBars[0] || initialState.topBar,
        popUp: response.PopUps[0] || initialState.popUp
      }
    })
  } catch (err) {
    yield put({ type: GET_MARKETING_WIDGETS_FAIL, err })
  }
}

function * switchPopUpState ({ data }) {
  yield put({ type: SWITCH_POPUP_STATE, data })
}

function * switchTopBarState ({ data }) {
  yield put({ type: SWITCH_TOP_BAR_STATE, data })
}

export function * marketingRootSaga () {
  yield takeLatest(GET_MARKETING_WIDGETS_REQUESTED, fetchWidgets)
  yield debounce(500, SWITCH_POPUP_STATE_ACTION, switchPopUpState)
  yield debounce(500, SWITCH_TOP_BAR_STATE_ACTION, switchTopBarState)
}

const queries = {
  getMarketingWidgets: `${BASE_URL_WEB}/MarketingWidget`
}

const GET_MARKETING_WIDGETS_SUCCESS = 'GET_MARKETING_WIDGETS_SUCCESS'
const GET_MARKETING_WIDGETS_FAIL = 'GET_MARKETING_WIDGETS_FAIL'
const GET_MARKETING_WIDGETS_REQUESTED = 'GET_MARKETING_WIDGETS_REQUESTED'
const SWITCH_TOP_BAR_STATE = 'SWITCH_TOP_BAR_STATE'
const SWITCH_POPUP_STATE = 'SWITCH_POPUP_STATE'
const USER_CLOSE_TOP_BAR = 'USER_CLOSE_TOP_BAR'
const USER_CLOSE_POPUP = 'USER_CLOSE_POPUP'
const SWITCH_POPUP_STATE_ACTION = 'SWITCH_POPUP_STATE_ACTION'
const SWITCH_TOP_BAR_STATE_ACTION = 'SWITCH_TOP_BAR_STATE_ACTION'
const ADD_VISITED_ROUTE = 'ADD_VISITED_ROUTE'
