import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { switchWithLabelsStyle } from './switchWithLabels.style'

const useStyle = createUseStyles(switchWithLabelsStyle)

const SwitchWithLabels = ({
  id,
  value,
  checked,
  handleChange,
  leftLabel,
  rightLabel,
  screen,
  dataTest
}) => {
  const { inputGroup, input, switchButton } = useStyle({ leftLabel, rightLabel, screen })

  return (
    <div className={inputGroup}>
      <input
        data-test={dataTest}
        onChange={handleChange}
        checked={checked}
        id={id}
        type='checkbox'
        value={value}
        className={input}
      />
      <label className={switchButton} />
    </div>
  )
}

SwitchWithLabels.propTypes = {
  /** element's identifier */
  id: PropTypes.string,
  /** the value shipped with the input when submitted */
  value: PropTypes.string,
  /** set checkbox checked by default */
  checked: PropTypes.bool,
  /** the method that control the state of the checkbox */
  handleChange: PropTypes.func,
  /** the label to show in the left tag */
  leftLabel: PropTypes.string,
  /** the label to show in the right tag */
  rightLabel: PropTypes.string
}

export default SwitchWithLabels
