import { Spacing } from '@alphorm/ui'

export default {
  col: {
    flexWrap: 'nowrap !important',
    overflow: 'auto',
    padding: () => Spacing(0, 0, 3, 0),
    marginRight: -15,
    minHeight: 'unset',
    '& > div': {
      marginRight: 16
    }
  }
}
