import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  TypographyElement,
  Avatar,
  Icon,
  Icons,
  Button,
  TextInput,
  SelectList,
  InputFieldWithVerification,
  Col
} from 'UI'
import style from './style'
import Text from './text.json'
import { createUseStyles, useTheme } from 'react-jss'
import { useFormik } from 'formik'
import { validateGenral } from 'helpers/validator'
import moment from 'moment'
import { amazonBucket, CheckPhoneNumberStatus } from 'constants/'
import { verifyPhoneNumber } from 'helpers/'
import { isEmpty } from 'lodash'
import { NameInitials } from 'UI/helpers'

const useStyle = createUseStyles(style)

export default ({
  Submit,
  FetchUser,
  BirthDate,
  Email,
  FirstName,
  LastName,
  Phone,
  Photo,
  functions = [],
  initialUserFunction = 6,
  DeleteUserPicture,
  UpdateAvatar,
  goToValidationPhone,
  validatedPhoneNumber,
  loading,
  countries,
  detectedCountry,
  initialPhoneCode,
  nextStep,
  UpdateNewUser,
  b2bNewUser
}) => {
  useEffect(FetchUser, [])
  useEffect(() => { setPhoneCode(initialPhoneCode) }, [initialPhoneCode])
  const userFn = functions?.find(fn => fn.Id === initialUserFunction)
  const [phoneCode, setPhoneCode] = useState(initialPhoneCode)
  const theme = useTheme()
  const {
    rootClass,
    iconClass,
    avatarGroupClass,
    socialGroupClass,
    centerIconClass,
    formRootClass,
    inputClass,
    pointerClass,
    subContainerClass,
    submitClass,
    rowClass
  } = useStyle({ Photo })
  const { handleChange: onFormikHandleChange, handleBlur: onFormikHandleBlur, handleSubmit, touched, errors, values, setErrors, setFieldValue } = useFormik({
    initialValues: {
      FirstName: FirstName || '',
      BirthDate: BirthDate ? moment(BirthDate).format('DD/MM/YYYY') : '',
      LastName: LastName || '',
      Email: Email || '',
      Phone: phoneCode ? validatedPhoneNumber?.replace(phoneCode, '') : Phone || '',
      FunctionId: userFn?.Id || 0,
      FunctionName: userFn?.Name || ''
    },
    enableReinitialize: true,
    validate: () => validateGenral(values, Email, Phone),
    onSubmit: values => {
      if (values.BirthDate) {
        values.BirthDate = moment(values.BirthDate).toISOString()
      }
      if (b2bNewUser) {
        UpdateNewUser(values)
        nextStep()
      } else {
        Submit(values, nextStep)
      }
    }
  })

  const validatePhone = async () => {
    if (values.Phone.length !== 0) {
      const response = await verifyPhoneNumber(`${phoneCode}${values.Phone}`)
      if (response === CheckPhoneNumberStatus.Success) {
        goToValidationPhone(`${phoneCode}${values.Phone}`)
      } else {
        setErrors({ Phone: Text.phoneNumberInvalid })
      }
    }
  }
  return (
    <Container nopadding className={rootClass}>
      <div>
        <TypographyElement
          component='h3'
          spacing='0 0 16px 0'
          variant='heading1'
        >{Text.header}
        </TypographyElement>
        <TypographyElement
          component='h3'
          spacing='0 0 32px 0'
          spacingTablet='0 0 24px 0'
          spacingMobile='0 0 24px 0'
          variant='body1'
        >{Text.subHeader}
        </TypographyElement>
        <TypographyElement
          component='h3'
          variant='label'
          spacing='0 0 8px 0'
        >{Text.profilePicture}
        </TypographyElement>
        <Row>
          <Col grid={12} align='mid' direction='row'>
            <div className={avatarGroupClass}>
              <Avatar
                width={100}
                height={100}
                widthTablet={100}
                heightTablet={100}
                widthMobile={100}
                heightMobile={100}
                img={Photo && `${amazonBucket.bucketBaseUrl}${Photo}`}
                name={NameInitials(`${FirstName} ${LastName}`)}
              />
            </div>
            <div className={subContainerClass}>
              <div className={socialGroupClass}>
                <span onClick={Photo ? DeleteUserPicture : null} className={iconClass}>
                  <Icon iconName={Icons.bin} style={centerIconClass} />
                </span>
                <span className={iconClass}>
                  <label htmlFor='avatar' className={pointerClass}>
                    <Icon iconName={Icons.edit} style={centerIconClass} />
                  </label>
                  <input
                    type='file'
                    id='avatar'
                    name='avatar'
                    accept='image/png, image/jpeg'
                    className={inputClass}
                    onChange={(e) => UpdateAvatar(e.target.files[0])}
                  />
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <form className={formRootClass}>
          <Row
            repeatCols={2}
            mrepeatCols={1}
            srepeatCols={1}
            tabletGap={16}
            mobileGap={16}
          >
            <TextInput
              label={Text.inputLabels.lastName}
              placeholder={Text.inputPlaceHolder.lastName}
              handleChange={onFormikHandleChange}
              value={values.LastName}
              name='LastName'
              status={errors.LastName && touched.LastName ? 'error' : null}
              handleBlur={onFormikHandleBlur}
            />
            <TextInput
              label={Text.inputLabels.firstName}
              placeholder={Text.inputPlaceHolder.firstName}
              handleChange={onFormikHandleChange}
              name='FirstName'
              value={values.FirstName}
              status={errors.FirstName && touched.FirstName ? 'error' : null}
              handleBlur={onFormikHandleBlur}
            />
          </Row>
          <Row
            repeatCols={2}
            mrepeatCols={1}
            srepeatCols={1}
            tabletGap={16}
            mobileGap={16}
          >
            <SelectList
              valueField='Name'
              items={functions}
              selectedItem={values.FunctionName}
              onSelectedItem={(item) => {
                setFieldValue('FunctionId', item.Id)
                setFieldValue('FunctionName', item.Name)
              }}
              label={Text.inputLabels.function}
            />
            {
              phoneCode && detectedCountry
                ? <InputFieldWithVerification
                    countriesInfo={countries}
                    valid={validatedPhoneNumber ? values.Phone === validatedPhoneNumber.replace(phoneCode, '') : false}
                    handleChangePhoneCode={(phoneCode) => setPhoneCode(phoneCode)}
                    errorMessage={!!errors.Phone}
                    initialCountry={detectedCountry}
                    textInputProps={{
                      label: Text.inputLabels.phone,
                      placeholder: Text.inputPlaceHolder.phone,
                      handleChange: onFormikHandleChange,
                      name: 'Phone',
                      value: values.Phone,
                      status: errors.Phone && touched.Phone ? 'error' : null,
                      handleBlur: onFormikHandleBlur,
                      message: errors.Phone && touched.Phone ? errors.Phone : null,
                      inputPadding: '0 42px 0 16px'
                    }}
                    buttonProps={{
                      onHandleClick: validatePhone,
                      disabled: (validatedPhoneNumber && values.Phone === validatedPhoneNumber.replace(phoneCode, '')) || !values.Phone || (errors.Phone && touched.Phone),
                      label: Text.ValidatePhoneNumber,
                      marginButton: errors.Phone && touched.Phone ? '0 0 26px 0' : null,
                      height: 56
                    }}
                  />
                : null
            }
          </Row>
        </form>
      </div>
      <div className={rowClass}>
        <Button
          height={54}
          width={154}
          widthMobile='100%'
          widthTablet='100%'
          className={submitClass}
          onHandleClick={handleSubmit}
          disabled={!isEmpty(errors) || loading}
          label={Text.save}
        />
        {!b2bNewUser &&
          <TypographyElement
            component='h3'
            variant='body2'
            color={theme.neutral[200]}
            cursor='pointer'
            handleClick={nextStep}
          >
            {Text.skipThisStep}
          </TypographyElement>}
      </div>
    </Container>
  )
}
