import { put, takeLatest, all, select } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB, amazonBucket, profileTypesDataLayer, defaultProfileTypeDataLayer, ReviewsEnum, ReviewStatusEnum, videoSources, ItemTypesEnum } from 'constants/'
import { fetchSimilarCoursesAction } from 'components/Learning/store'
import { CertificateNeutralPath, CourseNeutralPath, pageNotFoundPath } from 'navigation/Routes'
import { push, replace } from 'connected-react-router'
import { SIGNIN } from 'identity/store'
import { TurnOffLoaderAction, TurnOnLoaderAction } from 'store/config'
import { GET_CERTIFICATE_FAILED, GET_CERTIFICATE_SUCCESS } from 'pages/Certificate/store'
import { TutorialBegin, TutorialComplete, ViewItemDataLayer } from 'helpers'
import { decorateUrl } from 'components/common/UTMscript'

const rating = [5, 4, 3, 2, 1]

const initialState = {
  showPopUp: false,
  courseError: false,
  reviewsError: false,
  ratingError: false,
  quality: videoSources.HD,
  Course: {
    Author: {},
    Categories: [],
    Chapters: [],
    Code: '',
    CompletionPercentage: 0,
    ConcernedAudiences: [],
    CourseProgressionState: 0,
    CreationDate: '',
    Description: '',
    DiscountPercentage: 0,
    DiscountPrice: 0,
    Duration: 0,
    FormattedUrl: null,
    HasActivePromotion: false,
    Id: '',
    Image: '',
    IsActive: false,
    IsFavorite: false,
    IsPublished: false,
    LevelId: '',
    NewPrice: 0,
    Objectives: [],
    Prerequirements: [],
    PresentationReadOnlyVideo: {},
    Price: 0,
    PricePromo: 0,
    PublicationDate: '',
    Rating: 0,
    ResourcesDownloadUrl: '',
    ReviewersCount: 0,
    SlideShareUrl: null,
    Slug: '',
    Subtitle: '',
    Tags: [],
    TagsIds: [],
    CourseTags: [],
    Title: '',
    Type: null,
    UserId: null,
    ViewersCount: 0,
    LearningPaths: []
  },
  Reviews: [],
  Rating: [],
  gettingCoursesInfos: false,
  currentVideo: {},
  unfollowCourseModalState: false,
  favoriteCoursesIds: [],
  AlreadyReviewedByUser: true,
  ReviewStatus: 1,
  createReview: {
    isStarting: false,
    isLoading: false
  },
  CoursePaths: [],
  userRating: 0,
  showReview: false,
  courseProgressionLoading: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case CLEAN_COURSE_STORE:
      return {
        ...state,
        gettingCoursesInfos: true,
        Course: {
          ...state.Course,
          IsAccessible: false
        }
      }
    case COURSE_FETCH_REQUESTED:
      return {
        ...state,
        gettingCoursesInfos: true,
        courseProgressionLoading: true,
        Course: {
          ...state.Course,
          IsAccessible: false
        }
      }
    case GET_COURSE_DATA_SUCCESS:
      return {
        ...state,
        Course: data
      }
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        Reviews: data
      }
    case GET_RATING_SUCCESS:
      return {
        ...state,
        Rating: data
      }
    case GET_COURSE_DATA_FAIL:
      return {
        ...state,
        courseError: data
      }
    case GET_REVIEWS_FAIL:
      return {
        ...state,
        reviewsError: data
      }
    case GET_RATING_FAIL:
      return {
        ...state,
        ratingError: data
      }
    case CHANGE_POPUP_STATE:
      return {
        ...state,
        showPopUp: data
      }
    case CHANGE_GETTING_COURSE_INFOS_STATE:
      return {
        ...state,
        gettingCoursesInfos: data
      }
    case GET_VIDEO_SUCCESS:
      return {
        ...state,
        currentVideo: data || {}
      }
    case GET_VIDEO_FAIL:
      return {
        ...state,
        currentVideo: {}
      }
    case MARK_VIDEO_AS_WATCHED:
      return {
        ...state,
        currentVideo: {
          ...state.currentVideo,
          WasAlreadyWatched: true
        }
      }
    case VIDEO_MARKED_AS_WATCHED_SUCCESS:
      return {
        ...state,
        Course: {
          ...state.Course,
          Chapters: data
        }
      }
    case CHANGE_FOLLOW_STATE_SUCCEEDED:
      return {
        ...state,
        Course: {
          ...state.Course,
          IsVisible: !state.Course.IsVisible
        }
      }
    case ADD_COURSE_TO_FAVORITE_SUCCEEDED:
      return {
        ...state,
        Course: {
          ...state.Course,
          IsFavorite: true
        }
      }
    case REMOVE_COURSE_FROM_FAVORITE_SUCCEEDED:
      return {
        ...state,
        Course: {
          ...state.Course,
          IsFavorite: false
        }
      }
    case CHANGE_UNFOLLOW_MODAL_STATE:
      return {
        ...state,
        unfollowCourseModalState: data
      }
    case UPDATE_COMPLETION_PERCENTAGE:
      return {
        ...state,
        Course: {
          ...state.Course,
          CompletionPercentage: data
        }
      }
    case GET_FAVORITE_COURSES_IDS_SUCCEEDED:
      return {
        ...state,
        favoriteCoursesIds: data
      }
    case FETCH_COURSE_REVIEW_SUCCEEDED:
      return {
        ...state,
        UserReview: data
      }
    case COURSE_CREATE_REVIEW_START:
      return {
        ...state,
        createReview: {
          ...state.createReview,
          isStarting: true
        }
      }
    case COURSE_CREATE_REVIEW_CANCEL:
      return {
        ...state,
        createReview: {
          ...state.createReview,
          isStarting: false
        }
      }
    case CREATE_COURSE_REVIEW_SUCCEEDED:
      return {
        ...state,
        UserReview: data
      }
    case SET_RATING_STARS:
      return {
        ...state,
        userRating: data
      }
    case SHOW_REVIEW:
      return {
        ...state,
        showReview: data
      }
    case SET_QUALITY_VIDEO:
      return {
        ...state,
        quality: data
      }
    case GET_COURSE_LEARNING_PATHS_SUCCEEDED:
      return {
        ...state,
        CoursePaths: data
      }
    case MAP_VIDEOS_WITH_PROGRESSION_SUCCEEDED:
      return {
        ...state,
        courseProgressionLoading: false,
        Course: {
          ...state.Course,
          ...data
        }
      }
    default:
      return state
  }
}

const courseStarted = 0
const courseEnded = 100

const getCourseLevels = ({ referential }) => referential.CourseLevels

const getCourseContentReviewCriteria = ({ referential }) => referential.CourseContentReviewCriteria

const getTrainerReviewCriteria = ({ referential }) => referential.TrainerReviewCriteria

const getReviewStatus = ({ referential }) => referential.ReviewStatus

const getCourse = ({ course }) => course.Course

const getfavorites = ({ course }) => course.favoriteCoursesIds

const getCurrentVideo = ({ course }) => course.currentVideo

const getUser = ({ user }) => user

const getAutoAppliedCoupon = ({ user }) => user?.AutoAppliedCoupons

const getReferential = ({ referential }) => referential

const getShop = ({ support }) => support

const getIsConnected = ({ identity }) => identity?.isConnected

const gettingCoursesInfosAction = state => ({ type: CHANGE_GETTING_COURSE_INFOS_STATE, data: state })

export const fetchCourseAction = (slug, videoSlug) => ({ type: COURSE_FETCH_REQUESTED, slug, videoSlug })

export const showPopUp = (state) => ({ type: CHANGE_POPUP_STATE, data: state })

export const selectVideoToWatch = (video, noSlug) => ({ type: SELECT_VIDEO_TO_WATCH, video, noSlug })

export const handleMarkAsWatched = () => ({ type: MARK_VIDEO_AS_WATCHED })

export const addToFavorite = _ => ({ type: ADD_COURSE_TO_FAVORITE })

export const removeFromFavorite = _ => ({ type: REMOVE_COURSE_FROM_FAVORITE })

export const changeFollowStateAction = _ => ({ type: CHANGE_FOLLOW_STATE })

export const changeUnfollowModalStateAction = state => ({ type: CHANGE_UNFOLLOW_MODAL_STATE, data: state })

export const getFavoriteCoursesIdsAction = _ => ({ type: GET_FAVORITE_COURSES_IDS })

export const getCertificateAction = _ => ({ type: GET_CERTIFICATE_REQUESTED })

export const playNextVideoAction = _ => ({ type: PLAY_NEXT_VIDEO_REQUESTED })

export const fetchCurrentCourseReviewAction = slug => ({ type: FETCH_COURSE_REVIEW_REQUESTED, slug })

export const cleanStoreAction = _ => ({ type: CLEAN_COURSE_STORE })

export const startReviewingAction = _ => ({ type: COURSE_CREATE_REVIEW_START })

export const cancelReviewingAction = _ => ({ type: COURSE_CREATE_REVIEW_CANCEL })

export const submitReviewAction = data => ({ type: COURSE_CREATE_REVIEW_SUBMIT_REQUESTED, data })

export const setRatingStarsAction = stars => ({ type: SET_RATING_STARS, data: stars })

export const setShowReviewAction = show => ({ type: SHOW_REVIEW, data: show })

export const SetQualityVideo = quality => ({ type: SET_QUALITY_VIDEO, data: quality })

const fetchCourseLearningPathsAction = courseId => ({ type: GET_COURSE_LEARNING_PATHS_REQUESTED, courseId })

const mapVideosWithProgressionAction = (slug, course) => ({ type: MAP_VIDEOS_WITH_PROGRESSION, slug, course })

function * fetchCourseData ({ slug, videoSlug }) {
  yield put(TurnOnLoaderAction())
  yield put(cancelReviewingAction())
  yield put(gettingCoursesInfosAction(true))
  yield put(setShowReviewAction(false))
  yield all([
    fetchCourse(slug, videoSlug),
    fetchCourseReviewsDetails(slug),
    fetchReviewsRatingDetails(slug)
  ])
  yield put(TurnOffLoaderAction())
}

export const numberOfSimilarCoursesToGet = 4

function * fetchCourse (slug, videoSlug) {
  const courseLevels = yield select(getCourseLevels)
  const favorits = yield select(getfavorites)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const referential = yield select(getReferential)
  const shop = yield select(getShop)
  const courseCoupon = autoAppliedCoupon?.length > 0 ? autoAppliedCoupon[0]?.Values?.find(coupon => coupon.SubjectType === referential.ItemTypes[ItemTypesEnum.Course]) : null
  try {
    const response = yield fetch(queries.getCourseBySlug(slug))
    const promoResponse = yield fetch(queries.getCourseValidPromotion(slug))
    const videoPresentationId = response.PresentationReadOnlyVideo.Id
    const videoPresentationLink = yield fetch(queries.getVideoLink(response.Id, videoPresentationId))
    response.PresentationReadOnlyVideo.Link = videoPresentationLink
    response.Image = response.LargeImageUrl
    response.Author.Photo = `${amazonBucket.bucketBaseUrl}${response.Author.Photo}`
    response.LevelId = courseLevels ? courseLevels[response.LevelId] : ''
    response.NewPrice = response.PricePromo
    response.IsFavorite = !!favorits.find(item => item === response.Id)
    if (courseCoupon) {
      response.HasActivePromotion = true
      response.PricePromo = courseCoupon.Value
      response.DiscountPrice = courseCoupon.Value
      response.AlternativePricePromo = courseCoupon.Value * shop.PriceCoefficient
    } else if (promoResponse) {
      response.HasActivePromotion = true
      response.PricePromo = promoResponse.PricePromo
      response.DiscountPrice = promoResponse.DiscountPrice
      response.AlternativePricePromo = promoResponse.AlternativePricePromo
    }
    yield put({ type: GET_COURSE_DATA_SUCCESS, data: response })
    yield put(mapVideosWithProgressionAction(slug, response))
    yield put(gettingCoursesInfosAction(false))
    const arrayOfVideos = response.Chapters.flatMap(chapter => chapter.Videos)
    const selectedVideo = arrayOfVideos.find(video => video.Slug === videoSlug)
    const firstVideo = response.Chapters[0].Videos[0]
    yield put({ type: SELECT_VIDEO_TO_WATCH, video: selectedVideo || firstVideo, noSlug: true })
    yield put(fetchCourseLearningPathsAction(response.Id))
    yield put(fetchSimilarCoursesAction([response.Id], numberOfSimilarCoursesToGet))
    ViewItemDataLayer({ item: response })
  } catch (err) {
    yield put(push(decorateUrl(pageNotFoundPath)))
    yield put({ type: GET_COURSE_DATA_FAIL, err })
  }
}

function * mapVideosWithProgression ({ slug, course }) {
  const isConnected = yield select(getIsConnected)
  try {
    if (isConnected) {
      const response = yield fetch(queries.getCourseProgression(slug))
      const CompletionPercentage = response.CompletionPercentage || 0
      const LastAccessedDate = response.LastAccessedDate || null
      const Chapters = course.Chapters.map(chapter => {
        chapter.Videos = chapter.Videos.map(video => {
          response.Videos.map(videoProgression => videoProgression.VideoId === video.Id && (video.WasAlreadyWatched = true))
          return video
        })
        return chapter
      })
      yield put({ type: MAP_VIDEOS_WITH_PROGRESSION_SUCCEEDED, data: { CompletionPercentage, LastAccessedDate, Chapters } })
    }
  } catch (err) {
    yield put({ type: MAP_VIDEOS_WITH_PROGRESSION_FAILED, err })
  }
}

function * fetchCourseReviewsDetails (slug) {
  try {
    const response = yield fetch(queries.getCourseReviewsDetails(slug))
    yield put({ type: GET_REVIEWS_SUCCESS, data: response.Items })
  } catch (err) {
    yield put({ type: GET_REVIEWS_FAIL, err })
  }
}
function * fetchReviewsRatingDetails (slug) {
  try {
    const response = yield fetch(queries.getReviewsRatingDetails(slug))
    const formattedResponse = rating.map(rate => ({ label: rate, value: response[rate] || 0 }))
    yield put({ type: GET_RATING_SUCCESS, data: formattedResponse })
  } catch (err) {
    yield put({ type: GET_RATING_FAIL, err })
  }
}

function * selectVideo ({ video, noSlug }) {
  const course = yield select(getCourse)
  try {
    const response = yield fetch(queries.getVideoLink(course.Id, video.Id))
    video.Link = response
    yield put({ type: GET_VIDEO_SUCCESS, data: video })
    if (!noSlug) yield put(replace(`${CourseNeutralPath}/${course.Slug}/${video.Slug}`))
  } catch (err) {
    yield put({ type: GET_VIDEO_FAIL, err })
  }
}

function * markVideoAsWatched () {
  const course = yield select(getCourse)
  const currentVideo = yield select(getCurrentVideo)
  const { Id, ProfileType } = yield select(getUser)
  const isConnected = yield select(getIsConnected)
  try {
    if (isConnected) {
      yield fetch(queries.markVideoAsCompleted(course.Id, currentVideo.Id), { method: 'post', nojson: true })
      if (Number(course.CompletionPercentage) === courseStarted) TutorialBegin({ accountType: ProfileType ? profileTypesDataLayer[ProfileType] : profileTypesDataLayer[defaultProfileTypeDataLayer], courseTitle: course.Title, userId: Id })
      let totalVideos = 0
      let totalVideosWatched = 0
      const chapters = course.Chapters.map(chapter => {
        const formattedVideos = chapter.Videos.map(video => {
          totalVideos += video.Duration
          if (video.WasAlreadyWatched) totalVideosWatched += video.Duration
          if (video.Id === currentVideo.Id) {
            video.WasAlreadyWatched = true
            totalVideosWatched += video.Duration
          }
          return video
        })
        chapter.Videos = formattedVideos
        return chapter
      })
      const completionPercentage = Math.round((totalVideosWatched / totalVideos) * 100)
      const formattedCompletionPercentage = completionPercentage > 100 ? 100 : completionPercentage
      if (formattedCompletionPercentage === courseEnded) TutorialComplete({ accountType: ProfileType ? profileTypesDataLayer[ProfileType] : profileTypesDataLayer[defaultProfileTypeDataLayer], courseTitle: course.Title, userId: Id })
      yield put({ type: VIDEO_MARKED_AS_WATCHED_SUCCESS, data: chapters })
      yield put({ type: UPDATE_COMPLETION_PERCENTAGE, data: formattedCompletionPercentage })
    }
  } catch (err) {
    yield put({ type: VIDEO_MARKED_AS_WATCHED_FAIL, err })
  }
}

function * addCourseToFavorite () {
  const favorits = yield select(getfavorites)
  const course = yield select(getCourse)
  try {
    yield fetch(queries.addCourseToFavorite(course.Id), { method: 'post', nojson: true })
    favorits.push(course.Id)
    yield put({ type: ADD_COURSE_TO_FAVORITE_SUCCEEDED })
    yield put({ type: GET_FAVORITE_COURSES_IDS_SUCCEEDED, data: favorits })
  } catch (err) {
    yield put({ type: ADD_COURSE_TO_FAVORITE_FAILED, err })
  }
}

function * removeCourseFromFavorite () {
  const favorits = yield select(getfavorites)
  const course = yield select(getCourse)
  try {
    yield fetch(queries.removeCourseToFavorite(course.Id), { method: 'delete', nojson: true })
    const filteredFavorits = favorits.filter(item => item !== course.Id)
    yield put({ type: REMOVE_COURSE_FROM_FAVORITE_SUCCEEDED })
    yield put({ type: GET_FAVORITE_COURSES_IDS_SUCCEEDED, data: filteredFavorits })
  } catch (err) {
    yield put({ type: REMOVE_COURSE_FROM_FAVORITE_FAILED, err })
  }
}

function * changeFollowState () {
  try {
    yield put({ type: CHANGE_FOLLOW_STATE_SUCCEEDED })
  } catch (err) {
    yield put({ type: CHANGE_FOLLOW_STATE_FAILED, err })
  }
}

function * getFavoriteCoursesIds () {
  const { Id } = yield select(getCourse)
  try {
    const response = yield fetch(queries.getFavoriteCoursesIds)
    yield put({ type: GET_FAVORITE_COURSES_IDS_SUCCEEDED, data: response })
    if (Id) {
      const courseFound = response.find(item => item === Id)
      if (courseFound) yield put({ type: ADD_COURSE_TO_FAVORITE_SUCCEEDED })
    }
  } catch (err) {
    yield put({ type: GET_FAVORITE_COURSES_IDS_FAILED, err })
  }
}

function * getCertificate () {
  const { Slug } = yield select(getCourse)
  try {
    const response = yield fetch(queries.getCertificateById(Slug))
    if (response.Id) {
      yield put(push(decorateUrl(`${CertificateNeutralPath}/${response.Id}`)))
      yield put(fetchSimilarCoursesAction([response.CourseId], 4))
      yield put({ type: GET_CERTIFICATE_SUCCESS })
      yield put(TurnOffLoaderAction())
    }
  } catch (err) {
    yield put(push(decorateUrl(pageNotFoundPath)))
    yield put(TurnOffLoaderAction())
    yield put({ type: GET_CERTIFICATE_FAILED, err })
  }
}

function * playNextVideo () {
  const { Chapters } = yield select(getCourse)
  const { Order } = yield select(getCurrentVideo)
  try {
    const flatVideos = Chapters.flatMap(chapter => chapter.Videos)
    const nextVideo = flatVideos.find(video => video.Order === Order + 1)
    if (!nextVideo) throw new Error('enOfCourse')
    if (!nextVideo.IsLocked) yield put(selectVideoToWatch(nextVideo))
    yield put({ type: PLAY_NEXT_VIDEO_SUCCEEDED })
  } catch (err) {
    yield put({ type: PLAY_NEXT_VIDEO_FAILED, err })
  }
}

function * fetchCurrentReview ({ slug }) {
  try {
    const response = yield fetch(queries.getReviewBySlug(slug))
    yield put({ type: FETCH_COURSE_REVIEW_SUCCEEDED, data: response.UserReviewStatus })
  } catch (err) {
    yield put({ type: FETCH_COURSE_REVIEW_FAILED, err })
  }
}

function * createReview ({ data }) {
  const { Id } = yield select(getCourse)
  const courseContentReviewCriteria = yield select(getCourseContentReviewCriteria)
  const trainerReviewCriteria = yield select(getTrainerReviewCriteria)
  const reviewStatus = yield select(getReviewStatus)
  const body = {
    courseId: Id,
    rating: data.Rating,
    opinion: data.Opinion,
    date: new Date().toISOString(),
    courseContentReview: {
      [`${courseContentReviewCriteria[`${ReviewsEnum.Content}`]}`]: data[`${ReviewsEnum.Content}`],
      [`${courseContentReviewCriteria[`${ReviewsEnum.Level}`]}`]: data[`${ReviewsEnum.Level}`],
      [`${courseContentReviewCriteria[`${ReviewsEnum.Duration}`]}`]: data[`${ReviewsEnum.Duration}`],
      [`${courseContentReviewCriteria[`${ReviewsEnum.TroubleVideoAudio}`]}`]: data[`${ReviewsEnum.TroubleVideoAudio}`]
    },
    trainerReview: {
      [`${trainerReviewCriteria[`${ReviewsEnum.Style}`]}`]: data[`${ReviewsEnum.Style}`],
      [`${trainerReviewCriteria[`${ReviewsEnum.Accent}`]}`]: data[`${ReviewsEnum.Accent}`],
      [`${trainerReviewCriteria[`${ReviewsEnum.MissingInfos}`]}`]: data[`${ReviewsEnum.MissingInfos}`]
    }
  }
  try {
    yield fetch(queries.createReview, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body), nojson: true })
    yield put({ type: CREATE_COURSE_REVIEW_SUCCEEDED, data: reviewStatus[ReviewStatusEnum.Pending] })
    yield put(cancelReviewingAction())
    yield (fetchCurrentCourseReviewAction(Id))
  } catch (err) {
    yield put({ type: CREATE_COURSE_REVIEW_FAILED, err })
    yield put(cancelReviewingAction())
  }
}

function * fetchCourseLearningPaths ({ courseId }) {
  try {
    const response = yield fetch(queries.getCourseLearningPaths(courseId))
    yield put({ type: GET_COURSE_LEARNING_PATHS_SUCCEEDED, data: response.Data })
  } catch (err) {
    yield put({ type: GET_COURSE_LEARNING_PATHS_FAILED, err })
  }
}

export function * CourseRootSaga () {
  yield takeLatest(COURSE_FETCH_REQUESTED, fetchCourseData)
  yield takeLatest(SELECT_VIDEO_TO_WATCH, selectVideo)
  yield takeLatest(MARK_VIDEO_AS_WATCHED, markVideoAsWatched)
  yield takeLatest(ADD_COURSE_TO_FAVORITE, addCourseToFavorite)
  yield takeLatest(REMOVE_COURSE_FROM_FAVORITE, removeCourseFromFavorite)
  yield takeLatest(CHANGE_FOLLOW_STATE, changeFollowState)
  yield takeLatest([SIGNIN, GET_FAVORITE_COURSES_IDS], getFavoriteCoursesIds)
  yield takeLatest(GET_CERTIFICATE_REQUESTED, getCertificate)
  yield takeLatest(PLAY_NEXT_VIDEO_REQUESTED, playNextVideo)
  yield takeLatest(FETCH_COURSE_REVIEW_REQUESTED, fetchCurrentReview)
  yield takeLatest(COURSE_CREATE_REVIEW_SUBMIT_REQUESTED, createReview)
  yield takeLatest(GET_COURSE_LEARNING_PATHS_REQUESTED, fetchCourseLearningPaths)
  yield takeLatest(MAP_VIDEOS_WITH_PROGRESSION, mapVideosWithProgression)
}

const queries = {
  getCourseBySlug: slug => `${BASE_URL_WEB}/Course/${slug}`,
  getCourseReviewsDetails: slug => `${BASE_URL_WEB}/Review/getCourseReviewsDetails/${slug}?take=0`,
  getReviewsRatingDetails: slug => `${BASE_URL_WEB}/Review/getReviewsRatingDetails/${slug}`,
  getVideoLink: (courseId, videoId) => `${BASE_URL_WEB}/Video/${courseId}/${videoId}`,
  markVideoAsCompleted: (courseId, videoId) => `${BASE_URL_WEB}/Video/completed/${courseId}/${videoId}`,
  addCourseToFavorite: courseId => `${BASE_URL_WEB}/Course/addCourseToFavorite/${courseId}`,
  removeCourseToFavorite: courseId => `${BASE_URL_WEB}/Course/removeCourseToFavorite/${courseId}`,
  changeFollowState: `${BASE_URL_WEB}/Course/changefollowstate`,
  getFavoriteCoursesIds: `${BASE_URL_WEB}/Course/favoriteCoursesIds`,
  getCertificateById: slug => `${BASE_URL_WEB}/User/GetCertificate/${slug}`,
  getReviewBySlug: slug => `${BASE_URL_WEB}/Review/status/${slug}`,
  createReview: `${BASE_URL_WEB}/Review/createReview`,
  getCourseLearningPaths: id => `${BASE_URL_WEB}/CoursePath?CourseId=${id}&OrderedByAsc=false&OrderBy=ActivationDate`,
  getCourseProgression: slug => `${BASE_URL_WEB}/Course/GetCourseProgression/${slug}`,
  getCourseValidPromotion: slug => `${BASE_URL_WEB}/Course/validPromotion/${slug}`
}

export const GET_COURSE_DATA_SUCCESS = 'GET_COURSE_DATA_SUCCESS'
const GET_COURSE_DATA_FAIL = 'GET_COURSE_DATA_FAIL'
const COURSE_FETCH_REQUESTED = 'COURSE_FETCH_REQUESTED'
const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS'
const GET_REVIEWS_FAIL = 'GET_REVIEWS_FAIL'
const GET_RATING_SUCCESS = 'GET_RATING_SUCCESS'
const GET_RATING_FAIL = 'GET_RATING_FAIL'
const CHANGE_POPUP_STATE = 'CHANGE_POPUP_STATE'
const CHANGE_GETTING_COURSE_INFOS_STATE = 'CHANGE_GETTING_COURSE_INFOS_STATE'
const SELECT_VIDEO_TO_WATCH = 'SELECT_VIDEO_TO_WATCH'
const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS'
const GET_VIDEO_FAIL = 'GET_VIDEO_FAIL'
const MARK_VIDEO_AS_WATCHED = 'MARK_VIDEO_AS_WATCHED'
const VIDEO_MARKED_AS_WATCHED_SUCCESS = 'VIDEO_MARKED_AS_WATCHED_SUCCESS'
const VIDEO_MARKED_AS_WATCHED_FAIL = 'VIDEO_MARKED_AS_WATCHED_FAIL'
const ADD_COURSE_TO_FAVORITE = 'ADD_COURSE_TO_FAVORITE'
const ADD_COURSE_TO_FAVORITE_SUCCEEDED = 'ADD_COURSE_TO_FAVORITE_SUCCEEDED'
const ADD_COURSE_TO_FAVORITE_FAILED = 'ADD_COURSE_TO_FAVORITE_FAILED'
const REMOVE_COURSE_FROM_FAVORITE = 'REMOVE_COURSE_FROM_FAVORITE'
const REMOVE_COURSE_FROM_FAVORITE_SUCCEEDED = 'REMOVE_COURSE_FROM_FAVORITE_SUCCEEDED'
const REMOVE_COURSE_FROM_FAVORITE_FAILED = 'REMOVE_COURSE_FROM_FAVORITE_FAILED'
const CHANGE_FOLLOW_STATE = 'CHANGE_FOLLOW_STATE'
const CHANGE_FOLLOW_STATE_SUCCEEDED = 'CHANGE_FOLLOW_STATE_SUCCEEDED'
const CHANGE_FOLLOW_STATE_FAILED = 'CHANGE_FOLLOW_STATE_FAILED'
const CHANGE_UNFOLLOW_MODAL_STATE = 'CHANGE_UNFOLLOW_MODAL_STATE'
const UPDATE_COMPLETION_PERCENTAGE = 'UPDATE_COMPLETION_PERCENTAGE'
const GET_FAVORITE_COURSES_IDS = 'GET_FAVORITE_COURSES_IDS'
export const GET_FAVORITE_COURSES_IDS_SUCCEEDED = 'GET_FAVORITE_COURSES_IDS_SUCCEEDED'
const GET_FAVORITE_COURSES_IDS_FAILED = 'GET_FAVORITE_COURSES_IDS_FAILED'
const GET_CERTIFICATE_REQUESTED = 'GET_CERTIFICATE_REQUESTED'
const PLAY_NEXT_VIDEO_REQUESTED = 'PLAY_NEXT_VIDEO_REQUESTED'
const PLAY_NEXT_VIDEO_SUCCEEDED = 'PLAY_NEXT_VIDEO_SUCCEEDED'
const PLAY_NEXT_VIDEO_FAILED = 'PLAY_NEXT_VIDEO_FAILED'
const CLEAN_COURSE_STORE = 'CLEAN_COURSE_STORE'
const FETCH_COURSE_REVIEW_REQUESTED = 'FETCH_COURSE_REVIEW_REQUESTED'
const FETCH_COURSE_REVIEW_SUCCEEDED = 'FETCH_COURSE_REVIEW_SUCCEEDED'
const FETCH_COURSE_REVIEW_FAILED = 'FETCH_COURSE_REVIEW_FAILED'
const COURSE_CREATE_REVIEW_START = 'COURSE_CREATE_REVIEW_START'
const COURSE_CREATE_REVIEW_CANCEL = 'COURSE_CREATE_REVIEW_CANCEL'
const COURSE_CREATE_REVIEW_SUBMIT_REQUESTED = 'COURSE_CREATE_REVIEW_SUBMIT_REQUESTED'
const CREATE_COURSE_REVIEW_SUCCEEDED = 'CREATE_COURSE_REVIEW_SUCCEEDED'
const CREATE_COURSE_REVIEW_FAILED = 'CREATE_COURSE_REVIEW_FAILED'
const SET_RATING_STARS = 'SET_RATING_STARS'
const SHOW_REVIEW = 'SHOW_REVIEW'
const SET_QUALITY_VIDEO = 'SET_QUALITY_VIDEO'
const GET_COURSE_LEARNING_PATHS_REQUESTED = 'GET_COURSE_LEARNING_PATHS_REQUESTED'
const GET_COURSE_LEARNING_PATHS_SUCCEEDED = 'GET_COURSE_LEARNING_PATHS_SUCCEEDED'
const GET_COURSE_LEARNING_PATHS_FAILED = 'GET_COURSE_LEARNING_PATHS_FAILED'
const MAP_VIDEOS_WITH_PROGRESSION = 'MAP_VIDEOS_WITH_PROGRESSION'
const MAP_VIDEOS_WITH_PROGRESSION_SUCCEEDED = 'MAP_VIDEOS_WITH_PROGRESSION_SUCCEEDED'
const MAP_VIDEOS_WITH_PROGRESSION_FAILED = 'MAP_VIDEOS_WITH_PROGRESSION_FAILED'
