import { CheckoutPriceSizeMap, CheckoutButtonVariationMap, CheckoutButtonVariation, Colors, Typography } from '../../constants'

export const CheckoutButtonStyle = {
  iconAndText: ({ size }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: `${CheckoutPriceSizeMap[size].padding}px`,
    paddingRight: '27px',
    '& span:first-child': {
      fontSize: CheckoutPriceSizeMap[size].iconFontSize,
      marginRight: CheckoutPriceSizeMap[size].iconMarginRight,
      fontWeight: Typography.fontWeight.semibold
    },
    '& span:last-child': {
      fontWeight: Typography.fontWeight.bold
    }
  }),
  price: {
    width: 'fit-content',
    padding: '0 5px 0 5px',
    fontSize: 18,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ promo }) => CheckoutButtonVariationMap[promo ? CheckoutButtonVariation.important : CheckoutButtonVariation.primary].priceBackgroundColor,
    color: Colors.text.white,
    right: 0
  },
  button: ({ promo, size }) => ({
    paddingRight: CheckoutPriceSizeMap[size].width,
    paddingLeft: 0,
    borderColor: CheckoutButtonVariationMap[promo ? CheckoutButtonVariation.important : CheckoutButtonVariation.primary].priceBackgroundColor,
    color: CheckoutButtonVariationMap[promo ? CheckoutButtonVariation.important : CheckoutButtonVariation.primary].priceBackgroundColor,
    '&:hover': {
      backgroundColor: CheckoutButtonVariationMap[promo ? CheckoutButtonVariation.important : CheckoutButtonVariation.primary].hoverBackground
    }
  })
}
