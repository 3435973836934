import MediaQueries from '../../constants/mediaQueries'
import { Spacing } from '../../constants/spacing'
import { quizChoiceColorMap, quizChoiceStatus } from '../../constants/quizQuestion'

export const quizQuestionStyle = (theme) => ({
  wrapper: {
    '& > label': {
      display: 'flex',
      gap: 25,
      alignItems: 'center',
      margin: Spacing(0, 0, 3, 0),
      backgroundColor: ({ choiceStatus }) => quizChoiceColorMap(theme, choiceStatus || quizChoiceStatus.default).background,
      border: ({ choiceStatus }) => `1px solid ${quizChoiceColorMap(theme, choiceStatus || quizChoiceStatus.default).border}`,
      borderRadius: '8px',
      padding: '16px 20px',
      userSelect: 'none',
      cursor: ({ disabled }) => disabled ? 'default' : 'pointer'
    },
    '& > label input': {
      visibility: 'hidden',
      position: 'absolute',
      opacity: 0,
      width: 0,
      height: 0,
      appearance: 'none',
      margin: 0
    }
  },
  iconAndInput: {
    display: 'flex',
    alignItems: 'center',
    width: '1%'
  },
  icon: {
    '& > *': {
      display: 'block'
    },
    display: 'block',
    borderRadius: 24,
    color: ({ choiceStatus }) => quizChoiceColorMap(theme, choiceStatus || quizChoiceStatus.default).icon,
    fontSize: 14
  },
  quizQuestionsGroup: {
    '& > *:last-child label': {
      marginBottom: 0
    }
  },
  [`${MediaQueries.tablet}`]: {
    wrapper: {
      '& > label': {
        padding: '11px 19px'
      }
    }
  },
  [`${MediaQueries.mobile}`]: {
    wrapper: {
      '& > label': {
        padding: '11px 19px'
      }
    }
  }
})
