import { Typography, Colors } from '../../constants'

export const linkStyle = {
  link: {
    width: props => props.fullWidth ? '100%' : 'auto',
    fontFamily: Typography.fontFamily,
    fontSize: Typography.link,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal',
    color: props => props.color ? props.color : Colors.core.actionLink,
    textDecoration: props => props.iconName ? 'line' : 'blink',
    display: ({ display }) => display || 'block',
    '& span': {
      marginRight: 4,
      textDecoration: 'blink !important'
    },
    '&:visited': {
      color: props => props.color ? props.color : Colors.core.actionLink
    }
  },
  icon: {
    color: props => props.color ? props.color : Colors.core.actionLink,
    display: 'inline-block',
    textDecoration: 'blink !important'
  }
}
