import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import stepsStyle from './steps.style'
import Step from './step'
import Path from './path'

const Steps = (props) => {
  const useStyle = createUseStyles(stepsStyle)
  const { stepsContainer } = useStyle(props)
  return (
    <div className={stepsContainer}>
      <Path step={props.step} numberOfSteps={props.labels.length} />
      {
        props.labels.map((label, index) =>
          <Step
            key={index}
            onHandleStepClick={props.handleStepClick}
            step={props.step}
            label={label}
            index={index}
            stepWidth={props.stepWidth}
          />
        )
      }
    </div>
  )
}

Steps.propTypes = {
  /** current step starting from 0 */
  step: PropTypes.number,
  /** array of sorted labels of steps  */
  labels: PropTypes.array,
  /** handleStepClick when u click a step it return the index */
  handleStepClick: PropTypes.func
}

export default Steps
