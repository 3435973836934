import {
  MediaQueries
} from 'UI/'

export default {
  root: {
    margin: ({ margin }) => margin,
    padding: ({ padding }) => padding,
    width: '100%'
  },
  view: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: 40
  },
  [`${MediaQueries.tablet}`]: {
    view: {
      gap: 16
    }
  },
  [`${MediaQueries.mobile}`]: {
    view: {
      gap: 16
    }
  }
}
