import { MediaQueries, Typography } from '../../constants'
import { loadingSkeleton, loadingSkeletonAnimation } from '../../helpers'

export const avatarStyle = (theme) => ({
  container: ({ loading, img, width, height, backgroundSize, backgroundColor, imageAsBackground }) => ({
    position: 'relative',
    minWidth: width || 54,
    minHeight: height || 54,
    maxWidth: width || 54,
    maxHeight: height || 54,
    backgroundImage: img && imageAsBackground ? `url('${img}')` : null,
    backgroundSize: backgroundSize || (img ? 'cover' : null),
    backgroundPosition: img ? 'center' : null,
    backgroundRepeat: img ? 'no-repeat' : null,
    backgroundColor: backgroundColor || theme.neutral[400],
    borderRadius: '50%',
    cursor: 'pointer',
    ...(loading && loadingSkeleton({ borderRadius: '50%' }).core)
  }),
  avatarGroup: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: Typography.body1.fontFamily,
    fontSize: ({ fontSize }) => fontSize || Typography.body1.fontSize,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.neutral[400],
    userSelect: 'none',
    minWidth: ({ width }) => width || 54,
    minHeight: ({ height }) => height || 54,
    maxWidth: ({ width }) => width || 54,
    maxHeight: ({ height }) => height || 54,
    backgroundSize: 'cover',
    borderRadius: '50%',
    backgroundColor: ({ img, loading }) => !img && !loading && theme.warning[0]
  },
  [`${MediaQueries.tablet}`]: {
    container: {
      minWidth: ({ widthTablet }) => widthTablet || 40,
      minHeight: ({ heightTablet }) => heightTablet || 40,
      maxWidth: ({ widthTablet }) => widthTablet || 40,
      maxHeight: ({ heightTablet }) => heightTablet || 40
    },
    avatarGroup: {
      minWidth: ({ widthTablet }) => widthTablet || 40,
      minHeight: ({ heightTablet }) => heightTablet || 40,
      maxWidth: ({ widthTablet }) => widthTablet || 40,
      maxHeight: ({ heightTablet }) => heightTablet || 40,
      fontSize: ({ fontSizeTablet }) => fontSizeTablet || Typography.body1.fontSize
    }
  },
  [`${MediaQueries.mobile}`]: {
    container: {
      minWidth: ({ widthMobile }) => widthMobile || 40,
      minHeight: ({ heightMobile }) => heightMobile || 40,
      maxWidth: ({ widthMobile }) => widthMobile || 40,
      maxHeight: ({ heightMobile }) => heightMobile || 40
    },
    avatarGroup: {
      minWidth: ({ widthMobile }) => widthMobile || 40,
      minHeight: ({ heightMobile }) => heightMobile || 40,
      maxWidth: ({ widthMobile }) => widthMobile || 40,
      maxHeight: ({ heightMobile }) => heightMobile || 40,
      fontSize: ({ fontSizeMobile }) => fontSizeMobile || Typography.body1.fontSize
    }
  },
  ...loadingSkeletonAnimation
})
