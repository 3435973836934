import { put, select, takeLatest } from 'redux-saga/effects'
import { BASE_URL_WEB } from 'constants/'
import fetch from 'core/fetch'
import { getCategoryDetailsByIds } from 'helpers'

export const allWebinars = {
  Id: '',
  Name: 'Toutes les webinaires',
  Slug: null
}
export const otherWebinars = {
  Id: 'other-webinars-id',
  Name: 'Autres webinars',
  Slug: 'autres-webinars'
}

const initialState = {
  items: [],
  webinarsTotal: 0,
  webinarsCount: 0,
  error: false,
  isLoading: true
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case WEBINARS_FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true
      }
    case GET_WEBINARS_SUCCESS:
      return {
        ...state,
        items: data.Items,
        webinarsTotal: data.Total,
        webinarsCount: data.FilteredCount,
        isLoading: false
      }
    case GET_MORE_WEBINARS_SUCCESS:
      return {
        ...state,
        items: [...state.items, ...data.Items],
        webinarsTotal: data.Total,
        webinarsCount: data.FilteredCount,
        isLoading: false
      }
    case GET_WEBINARS_FAIL:
      return {
        ...state,
        error: data,
        isLoading: false
      }
    case RESET_WEBINARS_ACTION:
      return {
        ...state,
        items: [],
        webinarsTotal: 0,
        webinarsCount: 0,
        error: false,
        isLoading: true
      }
    default:
      return state
  }
}

const getCategories = ({ catalog }) => catalog.categories

export const fetchWebinarsAction = ({ take, skip, category, status, hasCategory }) => ({ type: WEBINARS_FETCH_REQUESTED, take, skip, category, status, hasCategory })
export const resetWebinarsAction = () => ({ type: RESET_WEBINARS_ACTION })

function * fetchWebinars ({ take, skip, category, status, hasCategory }) {
  const categories = yield select(getCategories)
  try {
    const response = yield fetch(queries.getWebinars(take, skip, category, status, hasCategory))
    response.Items = response.Items.map(webinar => ({
      ...webinar,
      Categories: getCategoryDetailsByIds({ categories, categoryIds: webinar.CategoriesIds })
    }))
    if (skip === 0) yield put({ type: GET_WEBINARS_SUCCESS, data: response })
    else yield put({ type: GET_MORE_WEBINARS_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_WEBINARS_FAIL, err })
  }
}

export function * webinarsRootSaga () {
  yield takeLatest(WEBINARS_FETCH_REQUESTED, fetchWebinars)
}

const queries = {
  getWebinars: (take, skip, category, status, hasCategory) => `${BASE_URL_WEB}/Webinar?take=${take}&skip=${skip}&OrderBy=plannifiedwebinardate${category ? `&CategoryId=${category}` : ''}${status ? `&Status=${status}` : ''}${!hasCategory ? `&HasCategory=${hasCategory}` : ''}`
}

const WEBINARS_FETCH_REQUESTED = 'WEBINARS_FETCH_REQUESTED'
const GET_WEBINARS_SUCCESS = 'GET_WEBINARS_SUCCESS'
const GET_MORE_WEBINARS_SUCCESS = 'GET_MORE_WEBINARS_SUCCESS'
const GET_WEBINARS_FAIL = 'GET_WEBINARS_FAIL'
const RESET_WEBINARS_ACTION = 'RESET_WEBINARS_ACTION'
