export const config = {
  gridColumns: 12,
  gutterWidth: 44,
  gutterWidthResponsive: 16,
  breakPoints: {
    xs: 0,
    sm: 600,
    md: 992,
    lg: 1200,
    xl: 1200
  },
  containerMaxWidth: {
    sm: 540,
    md: 720,
    lg: '100%',
    xl: 1140
  }
}
