import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import styles from './sliderBar.styles'
import { Icon, Icons } from '../..'
import classNames from 'classnames'

const SliderBar = ({ offsetToScroll = 200, children, className }) => {
  const rootRef = useRef(null)
  const [lastScrollPosition, setLastScrollPosition] = React.useState(0)
  const useStyle = createUseStyles(styles)
  const scrollLeft = (e) => {
    e.stopPropagation()
    rootRef.current.scrollLeft -= offsetToScroll
    setLastScrollPosition(rootRef.current.scrollLeft -= offsetToScroll)
  }
  const scrollRight = (e) => {
    e.stopPropagation()
    rootRef.current.scrollLeft += offsetToScroll
    setLastScrollPosition(rootRef.current.scrollLeft += offsetToScroll)
  }
  const { root, icon, left, right } = useStyle({ hideLeft: lastScrollPosition <= 0, hideRight: lastScrollPosition >= rootRef.current?.scrollWidth - rootRef.current?.clientWidth })

  return (
    <div className={classNames(className, root)} ref={rootRef}>
      <Icon iconName={Icons.roundedLeft} style={classNames(icon, left)} onHandleIconClick={scrollLeft} />
      {children}
      <Icon iconName={Icons.roundedRight} style={classNames(icon, right)} onHandleIconClick={scrollRight} />
    </div>
  )
}

SliderBar.propTypes = {
  /** children */
  children: PropTypes.node,
  /** offset to scroll */
  offsetToScroll: PropTypes.number
}

export default SliderBar
