import {
  Spacing,
  MediaQueries
} from '@alphorm/ui'

export default {
  container: {
    height: '92vh',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0 0 0'
  },
  iframeClass: {
    width: '100%',
    height: '100%',
    margin: () => Spacing(0, 0, 4, 0)
  },
  statusTagContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: () => Spacing(0, 0, 4, 0)
  },
  categoriesContainer: {
    display: 'flex',
    gap: 8,
    padding: () => Spacing(0, 0, 4, 0)
  },
  [`${MediaQueries.mobile}`]: {
    container: {
      height: 'auto',
      padding: 0
    },
    iframeClass: {
      height: 390
    }
  },
  [`${MediaQueries.tablet}`]: {
    container: {
      height: 'auto',
      padding: 0
    },
    iframeClass: {
      height: 390
    }
  }
}
