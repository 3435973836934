import { Typography } from '../../constants'

export const tabStyle = (theme) => ({
  tab: {
    flex: 1,
    fontFamily: Typography.fontFamily,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: ({ tabWidth }) => tabWidth || '100%',
    height: 57,
    cursor: 'pointer',
    border: ({ active }) => `1px solid ${active ? theme.primary[1300] : theme.neutral[0]}`,
    '&:hover': {
      borderColor: theme.primary[1300],
      '& > *': {
        color: theme.primary[0] + ' !important'
      }
    }
  }
})
