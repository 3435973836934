import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { createUseStyles } from 'react-jss'
import style from './style'
import { QUALITY_REQUESTED } from '@silvermine/videojs-quality-selector/src/js/events'
import { videoPlayerQualities } from 'helpers/'
import { isObject } from 'lodash'

const useStyles = createUseStyles(style)

const videoJsOptions = {
  preload: 'auto',
  controls: true,
  language: 'fr',
  fill: true,
  playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
  html5: {
    vhs: {
      overrideNative: true
    }
  },
  plugins: {
    qualityLevels: {},
    seekButtons: {
      forward: 10,
      back: 10
    }
  },
  controlBar: {
    pictureInPictureToggle: false
  }
}

let timeStarted = -1
let timePlayed = 0
const duration = 10
const maxDuration = 12
let videoAlreadyWatched = false

const videoStartedPlaying = () => {
  timeStarted = new Date().getTime() / 1000
}

const videoStoppedPlaying = (handleMarkAsWatched, nextVideo) => {
  if (timeStarted > 0) {
    const playedFor = new Date().getTime() / 1000 - timeStarted
    timeStarted = -1
    timePlayed += playedFor
  }
  if (timePlayed >= duration) {
    handleMarkAsWatched && !videoAlreadyWatched && handleMarkAsWatched()
    nextVideo && nextVideo()
  }
}

const videoPlaying = (handleMarkAsWatched) => {
  if (timeStarted > 0) {
    const playedFor = new Date().getTime() / 1000 - timeStarted
    timePlayed += playedFor
  }
  if (timePlayed > duration && timePlayed < maxDuration) {
    handleMarkAsWatched && !videoAlreadyWatched && handleMarkAsWatched()
  }
}

export default ({ handleMarkAsWatched, videoLink, posterLink, nextVideo, wasAlreadyWatched, mp4 = false, externalClass, defaultAutoPlay = false, selectedQuality, onQualitySelected }) => {
  const [videojs, setVideojs] = useState(null)

  useEffect(() => {
    if (!videojs) {
      import('videojs-seek-buttons/dist/videojs-seek-buttons.css')
      import('videojs-contrib-quality-levels')
      import('videojs-seek-buttons')
      import('videojs-hotkeys')
      import('@silvermine/videojs-quality-selector/dist/css/quality-selector.css')
      import('video.js')
        .then(({ default: videojs }) => {
          setVideojs(() => videojs)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  const videoRef = useRef(null)
  const [player, setPlayer] = useState(null)
  const [started, setStarted] = useState(false)
  const [rate, setRate] = useState(1)
  const [autoPlay, setAutoPlay] = useState(defaultAutoPlay)
  const [alreadyPlayed, setAlreadyPlayed] = useState(false)
  useEffect(() => {
    if (videoRef && videoRef.current && videojs) {
      require('@silvermine/videojs-quality-selector')(videojs)
      const videoJS = videojs(videoRef.current, { ...videoJsOptions })
      videoJS.poster(posterLink)
      videoJS.src(videoPlayerQualities(videoLink, selectedQuality))
      videoJS.on('play', () => {
        videoStartedPlaying()
        setStarted(true)
        setAlreadyPlayed(true)
      })
      if (isObject(videoLink)) {
        const lastPositionOnControlBar = videoJS.controlBar.children().length - 1
        videoJS.controlBar.addChild('QualitySelector', {}, lastPositionOnControlBar)
      }
      videoJS.on('playing', videoStartedPlaying)
      videoJS.on(QUALITY_REQUESTED, (e, sourceSelected) => onQualitySelected(sourceSelected.label))
      videoJS.on('ended', () => videoStoppedPlaying(handleMarkAsWatched, nextVideo))
      videoJS.on('timeupdate', () => videoPlaying(handleMarkAsWatched))
      videoJS.on('pause', () => videoStoppedPlaying(handleMarkAsWatched))
      videoJS.on('ratechange', () => { setRate(videoJS.playbackRate()) })
      videoJS.autoplay(autoPlay)
      videoJS.hotkeys({
        volumeStep: 0.1,
        seekStep: 5,
        enableModifiersForNumbers: false
      })
      setPlayer(videoJS)
    }
    return player && player?.dispose()
  }, [videojs])
  useEffect(() => {
    if (player !== null) {
      timeStarted = -1
      timePlayed = 0
      setStarted(false)
      player.src(videoPlayerQualities(videoLink, selectedQuality))
      player.poster(posterLink)
      player.defaultPlaybackRate(rate)
      player.playbackRate(rate)
      if (alreadyPlayed) {
        setAutoPlay(true)
      }
    }
  }, [videoLink, posterLink])
  useEffect(() => { setAlreadyPlayed(true) }, [videoLink])
  useEffect(() => { player && alreadyPlayed && player.autoplay(autoPlay) }, [autoPlay, alreadyPlayed])
  useEffect(() => { videoAlreadyWatched = wasAlreadyWatched }, [wasAlreadyWatched])
  const { video } = useStyles({ started })
  return (
    <div data-vjs-player>
      <video ref={videoRef} className={classNames('video-js', video, externalClass)} />
    </div>
  )
}
