import { Spacing } from '../..'

const firstChapterIndex = 1

export const courseProgressionHeaderStyle = (theme) => ({
  chaptersGroup: {
    width: '100%',
    cursor: 'pointer',
    overflow: 'hidden',
    borderTop: ({ chapterIndex }) => chapterIndex !== firstChapterIndex && `1px solid ${theme.neutral[0]}`
  },
  showIcon: {
    fontSize: 'medium',
    color: theme.primary[0],
    transform: ({ open }) => open ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'all 0.1s linear'
  },
  chapterHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.neutral[400],
    padding: () => Spacing(5, 5, 4, 5)
  },
  videosGroup: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'height 0.15s ease-out',
    overflow: 'hidden'
  }
})
