
export default {
  anchorClass: {
    textDecoration: 'none',
    color: 'unset',
    width: '100%'
  },
  shadowClassName: {
    maxWidth: 'unset !important',
    minWidth: 'unset !important',
    width: '100%',
    height: 'unset !important'
  }
}
