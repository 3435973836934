import { ProfileTypes, UserRoles } from 'constants/'
import { connect } from 'react-redux'
import UI from './UI'

const mapStateToProps = ({ currency, identity, referential, user }) => ({
  currency: currency.initialCurrency,
  isB2bUser: identity?.user?.role?.includes(String(referential.UserRoles[UserRoles.OrganizationLearner])),
  activeSubscription: user?.Subscription?.IsActive,
  profileType: user?.ProfileType ? ProfileTypes[referential?.ProfileTypes[user.ProfileType]] : null
})

export default connect(mapStateToProps, null)(UI)
