import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import UI from './UI'
import { decorateUrl } from 'components/common/UTMscript'

const mapDispatchToProps = (dispatch) => ({
  goToUrl: (url) => dispatch(push(decorateUrl(url)))
})

export default connect(null, mapDispatchToProps)(UI)
