import React, { useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { SwitchCurrency, TypographyElement, Button, MediaQuery } from '../..'
import { Icons } from '../../constants'
import Icon from '../icon/icon'
import style from './unitPricingVerticalCard.style'

const useStyle = createUseStyles(style)

const UnitPricingVerticalCard = ({
  shadowColor,
  title,
  secondTitle,
  infos,
  handleStartSubscriptionClick,
  ctaText,
  handleChangeCurrency,
  currency1,
  currency2,
  selectedCurrency,
  hideSwitchCurrency,
  price,
  currency,
  promo,
  pricePromo,
  promoText,
  width,
  expenderMessage,
  isLearningPath
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const { paper, header, titleAndPromo, priceAndCurrency, body, infoClass, iconClass, switchSection, promoTag, promoTagContainer, infoStyle, expenderIcon } = useStyle({ promo, shadowColor, width, open })
  return (
    <div className={paper}>
      <div className={header}>
        <div className={switchSection}>
          <MediaQuery HideOnDesktop>
            <TypographyElement
              variant='heading4'
              color={isLearningPath ? theme.extra.pink : theme.secondary[0]}
            >
              {title}
            </TypographyElement>
          </MediaQuery>
          {
            !hideSwitchCurrency && (
              <SwitchCurrency
                handleChange={handleChangeCurrency}
                devis={currency1}
                alterDevis={currency2}
                checked={currency1 !== selectedCurrency}
              />
            )
          }
        </div>
        <div className={titleAndPromo}>
          <MediaQuery ShowOnDesktop>
            <TypographyElement
              variant='heading4'
              color={isLearningPath ? theme.extra.pink : theme.secondary[0]}
            >
              {title}
            </TypographyElement>
          </MediaQuery>
          {
          promo &&
          (
            <div className={promoTagContainer}>
              <TypographyElement
                display='inline'
                variant='caption'
                color={theme.extra.pink}
                className={promoTag}
              >
                {promoText.toUpperCase()}
              </TypographyElement>
            </div>)
        }
        </div>
        <TypographyElement
          variant='body1'
          color={theme.neutral[100]}
          spacing='0px 0px 24px 0px'
          spacingTablet='0px 0px 17px 0px'
          spacingMobile='0px 0px 17px 0px'
        >
          {secondTitle}
        </TypographyElement>
        {!promo
          ? (
            <div className={priceAndCurrency}>
              <TypographyElement
                variant='heading4'
                color={theme.primary[400]}
                key={`${price}-${currency}`}
                spacing='0px 8px 0px 0px'
              >
                {price}
                <TypographyElement
                  variant='heading6'
                  color={theme.primary[400]}
                  display='inline-block'
                  spacing='0 0 0 6px'
                >
                  {`${currency}`}
                </TypographyElement>
              </TypographyElement>
            </div>
            )
          : (
            <div className={priceAndCurrency}>
              <div>
                <TypographyElement
                  variant='heading4'
                  color={theme.extra.pink}
                  key={`${pricePromo}-${currency}`}
                >
                  {pricePromo}
                  <TypographyElement
                    variant='body1'
                    color={theme.extra.pink}
                    display='inline-block'
                    spacing='0 0 0 6px'
                  >
                    {`${currency}`}
                  </TypographyElement>
                </TypographyElement>
                <TypographyElement
                  variant='caption'
                  color={theme.neutral[200]}
                  textDecoration='line-through'
                >
                  {`${price} ${currency}`}
                </TypographyElement>
              </div>
            </div>
            )}
        <MediaQuery HideOnDesktop>
          <TypographyElement
            variant='link'
            fontWeight={500}
            cursor='pointer'
            spacing={open ? '16px 0 12px 0' : '16px 0 0 0'}
            handleClick={() => setOpen(!open)}
          >
            {expenderMessage}
            <Icon iconName={Icons.roundedUp} style={expenderIcon} />
          </TypographyElement>
        </MediaQuery>
      </div>
      <div className={body}>
        <div className={infoStyle}>
          {
            infos.map(info => (
              <div className={infoClass} key={info}>
                <Icon iconName={Icons.check} style={iconClass} />
                <TypographyElement
                  variant='body1'
                >
                  {info}
                </TypographyElement>
              </div>
            ))
          }
        </div>
        <Button
          dataTest='start-subscription-button'
          label={ctaText}
          width='100%'
          Component='a'
          href='/nouvelle-achat'
          height='54px'
          onHandleClick={handleStartSubscriptionClick}
          marginButton='16px 0 0 0'
        />
      </div>
    </div>
  )
}

UnitPricingVerticalCard.propTypes = {
  /** the big title */
  title: PropTypes.string,
  /** second Title */
  secondTitle: PropTypes.string,
  /** maine price */
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** currency to show next price */
  currency: PropTypes.string,
  /** first currency to show in the switch currency */
  currency1: PropTypes.string,
  /** second currency to show in the switch currency */
  currency2: PropTypes.string,
  /** its either currency1 or currency2 to change switch state */
  selectedCurrency: PropTypes.string,
  /** function that handle the currency change */
  handleChangeCurrency: PropTypes.func,
  /** label of the button start subscription */
  ctaText: PropTypes.string,
  /** function that handle the start subscription */
  handleStartSubscriptionClick: PropTypes.func,
  /** array of string informations */
  infos: PropTypes.array,
  /** information about engagement */
  engagement: PropTypes.string,
  /** width */
  width: PropTypes.number
}

export default UnitPricingVerticalCard
