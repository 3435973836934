import { Typography } from '../../constants/typography'

export const switchButtonStyle = (theme) => ({
  inputGroup: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center'
  },
  switchButton: ({ disabled, transparent, screen = {} }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: (screen.isTablet || screen.isMobile) && transparent ? 18 : 32,
      height: (screen.isTablet || screen.isMobile) && transparent ? 10 : 16,
      borderRadius: 9,
      position: 'relative',
      backgroundColor: transparent ? 'transparent' : disabled ? theme.neutral[0] : theme.neutral[400],
      border: transparent ? 'none' : '1px solid',
      borderColor: disabled ? theme.neutral[0] : theme.secondary[0],
      boxSizing: 'border-box',
      transition: 'all .2s linear'
    },
    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      animation: '$slideLeft 0.2s ease-in forwards',
      width: (screen.isTablet || screen.isMobile) && transparent ? 6 : 12,
      height: (screen.isTablet || screen.isMobile) && transparent ? 6 : 12,
      borderRadius: '50%',
      backgroundColor: theme.secondary[0],
      transition: 'all .2s linear'
    }
  }),
  input: ({ disabled, transparent, screen = {} }) => ({
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    cursor: 'pointer',
    width: (screen.isTablet || screen.isMobile) && transparent ? 18 : 32,
    height: (screen.isTablet || screen.isMobile) && transparent ? 10 : 16,
    margin: 0,
    zIndex: 999,
    '&:checked + label::before': {
      backgroundColor: transparent ? 'transparent' : disabled ? theme.neutral[0] : theme.secondary[0]
    },
    '&:checked + label::after': {
      animation: '$slideRight 0.2s ease-in forwards',
      backgroundColor: theme.neutral[400]
    },
    '&:disabled + label::after': {
      backgroundColor: theme.neutral[100]
    }
  }),
  label: {
    marginLeft: 13,
    width: 56,
    height: 20,
    fontFamily: Typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal'
  },
  '@keyframes slideRight': {
    '0%': {
      opacity: 1,
      left: 2
    },
    '50%': {
      opacity: 0,
      left: 18
    },
    '51%': {
      opacity: 0,
      left: 2
    },
    '100%': {
      opacity: 1,
      left: 18
    }
  },
  '@keyframes slideLeft': {
    '0%': {
      opacity: 1,
      left: 18
    },
    '50%': {
      opacity: 0,
      left: 2
    },
    '51%': {
      opacity: 0,
      left: 18
    },
    '100%': {
      opacity: 1,
      left: 2
    }
  }
})
