import React from 'react'
import { createUseStyles } from 'react-jss'
import { inputIconStyle } from './inputIcon.style'
import Icon from '../icon/icon'

const InputIcon = (props) => {
  const useStyle = createUseStyles(inputIconStyle)
  const { icon, iconStyle } = useStyle(props)
  return (
    <div
      className={icon}
      onClick={props.handleClick}
    >
      <Icon
        iconName={props.iconName}
        style={iconStyle}
        dataTest={props.dataTest}
      />
    </div>
  )
}

export default InputIcon
