export const dataTableStyle = {
  link: {
    cursor: 'pointer',
    color: 'blue',
    display: 'flex',
    alignItems: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatar: {
    margin: 10
  },
  highlighted: {
    backgroundColor: 'rgba(255, 17, 0, 0.60)'
  },
  tableRow: {
    cursor: 'pointer'
  },
  CB: {
    width: 25
  },
  categoryImage: {
    margin: 10,
    width: 71,
    height: 46,
    borderRadius: 4
  },
  categoryContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  importUsersLink: {
    cursor: 'pointer',
    color: 'blue'
  },
  headWithCheckbox: {
    display: 'flex',
    alignItems: 'center'
  },
  courseImage: {
    margin: 10,
    width: 71,
    height: 34,
    borderRadius: 4
  },
  nameRow: {
    display: 'flex',
    alignItems: 'center'
  },
  imageAndName: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }
}
