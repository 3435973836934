import { connect } from 'react-redux'
import UI from './UI'
import { push } from 'connected-react-router'
import { decorateUrl } from 'components/common/UTMscript'

const mapStateToProps = ({ referential }) => ({
  WebinarStatus: referential.WebinarStatus
})

const mapDispatchToProps = (dispatch) => ({
  handlePathClick: (url) => dispatch(push(decorateUrl(url)))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
