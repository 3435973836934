import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { Icon, Icons, TypographyElement } from '../..'
import { endOfCourseQuizStyle } from './endOfCourseQuiz.style'

const useStyle = createUseStyles(endOfCourseQuizStyle)

const EndOfCourseQuiz = ({
  tag,
  title,
  isActive = false,
  onClick,
  isDisabled
}) => {
  const theme = useTheme()
  const { root, wrapper, icon } = useStyle({ isActive, isDisabled })

  return (
    <div className={root} onClick={(e) => (!isDisabled && onClick) && onClick(e)}>
      <div className={wrapper}>
        <Icon iconName={Icons.quiz} style={icon} />
        <TypographyElement
          variant='body1'
          color={isActive ? theme.neutral[400] : theme.neutral[100]}
        >
          {title}
        </TypographyElement>
      </div>
    </div>
  )
}

EndOfCourseQuiz.propTypes = {
  /** Tag text */
  tag: PropTypes.string,
  /** Title text */
  title: PropTypes.string,
  /** Active when set to true */
  isActive: PropTypes.bool,
  /** Function that handle the end of course quiz click */
  onClick: PropTypes.func,
  /** Disabeled when set to true */
  isDisabled: PropTypes.bool
}

export default EndOfCourseQuiz
