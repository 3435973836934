import { InputColors, Typography, textColorMap, Status, MediaQueries } from '../../constants'

export const textInputStyle = (theme) => ({
  '@keyframes input-error-shake': {
    '0%': {
      transform: 'translateX(0px)'
    },
    '33%': {
      transform: 'translateX(8px)'
    },
    '66%': {
      transform: 'translateX(-8px)'
    },
    '100%': {
      transform: 'translateX(0px)'
    }
  },
  inputGroup: {
    width: '100%',
    height: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: ({ gutterBottom }) => gutterBottom && `${gutterBottom}px`
  },
  input: {
    width: '100%',
    height: ({ height }) => height || 54,
    borderRadius: 8,
    border: 'solid 1px',
    borderColor: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputborder,
    borderBottomWidth: ({ searchInputResult }) => searchInputResult ? 0 : 1,
    borderBottomRightRadius: ({ searchInputResult }) => searchInputResult && '0px',
    borderBottomLeftRadius: ({ searchInputResult }) => searchInputResult && '0px',
    padding: ({ inputPadding, status }) => inputPadding || status ? '0 42px 0 16px' : '0 16px',
    outline: 'none',
    color: ({ status, message }) => status || message ? textColorMap(theme, status) : InputColors(theme).inputext,
    transition: 'transform .25s linear,opacity .25s linear, border-color .25s linear',
    background: ({ background }) => background || theme.neutral[400],
    font: {
      family: Typography.fontFamily,
      size: Typography.inputPlaceholder
    },
    '&::placeholder': {
      fontFamily: Typography.secondaryFontFamily,
      fontSize: 14,
      color: ({ placeholderColor }) => placeholderColor || theme.neutral[200]
    },
    '&:disabled': {
      background: ({ disabledBackground }) => disabledBackground || theme.neutral[0],
      borderColor: ({ status }) => status ? textColorMap(theme, status) : InputColors(theme).inputDisabledBorder
    },
    '&:hover': {
      borderColor: ({ status, disabled, disableHoverEffect }) => disableHoverEffect ? InputColors(theme).inputborder : disabled ? InputColors(theme).inputDisabledBorder : status ? textColorMap(theme, status) : InputColors(theme).inputHoverBorder
    },
    '&:focus::placeholder': {
      transform: 'translateX(-100%)',
      opacity: 0,
      transition: 'transform .25s linear,opacity .25s linear'
    },
    transform: ({ status }) => status === Status.error ? 'translateX(10px)' : 'translateX(0px)',
    animation: ({ status }) => status === Status.error ? '$input-error-shake .25s ease forwards' : 'none'
  },
  status: {
    position: 'absolute',
    right: 15,
    top: ({ label }) => label ? 30 : 16,
    color: ({ status }) => status ? textColorMap(theme, status) : null
  },
  [`${MediaQueries.tablet}`]: {
    inputGroup: {
      marginBottom: ({ gutterBottomTablet }) => gutterBottomTablet && `${gutterBottomTablet}px`
    }
  },
  [`${MediaQueries.mobile}`]: {
    inputGroup: {
      marginBottom: ({ gutterBottomMobile }) => gutterBottomMobile && `${gutterBottomMobile}px`
    }
  }
})
