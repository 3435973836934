import Theme from './theme'

export const Typography = {
  fontFamily: '"SF Pro Display", sans-serif',
  secondaryFontFamily: '"Roboto", sans-serif',
  tertiaryFontFamily: '"Open Sans", sans-serif',
  fontWeight: {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700
  },
  heading1: {
    fontSize: '2.986rem', // 48px
    tabletFontSize: '2.25rem', // 36px
    mobileFontSize: '2.25rem', // 36px
    fontWeight: 500,
    lineHeight: '56px',
    lineHeightTablet: '47px',
    lineHeightMobile: '47px',
    color: Theme.primary[100]
  },
  heading2: {
    fontSize: '2.488rem', // 40px
    tabletFontSize: '2.125rem', // 36px
    mobileFontSize: '2.125rem', // 36px
    fontWeight: 500,
    lineHeight: '55px',
    lineHeightTablet: '42px',
    lineHeightMobile: '42px',
    color: Theme.primary[1000]
  },
  heading3: {
    fontSize: '2.074rem', // 33px
    tabletFontSize: '1.25rem', // 20px
    mobileFontSize: '1.25rem', // 20px
    fontWeight: 500,
    lineHeight: '46px',
    lineHeightTablet: '28px',
    lineHeightMobile: '28px',
    color: Theme.primary[1000]
  },
  heading4: {
    fontSize: '1.728rem', // 28px
    tabletFontSize: '1.728rem', // 36px ::TODO
    mobileFontSize: '1.728rem', // 36px ::TODO
    fontWeight: 500,
    lineHeight: '29px',
    lineHeightTablet: '29px',
    lineHeightMobile: '29px',
    color: Theme.primary[100]
  },
  heading5: {
    fontSize: '1.44rem', // 23px
    tabletFontSize: '1.25rem', // 36px
    mobileFontSize: '1.25rem', // 36px
    fontWeight: 500,
    lineHeight: '29px',
    lineHeightTablet: '29px',
    lineHeightMobile: '29px',
    color: Theme.primary[1000]
  },
  heading6: {
    fontSize: '1rem', // 16px
    tabletFontSize: '1rem', // 36px
    mobileFontSize: '1rem', // 36px
    fontWeight: 500,
    lineHeight: '25px',
    lineHeightTablet: '25px',
    lineHeightMobile: '25px',
    color: Theme.primary[1000]
  },
  body1: {
    fontSize: '1rem', // 16px
    tabletFontSize: '1rem', // 16px
    mobileFontSize: '1rem', // 16px
    fontWeight: 400,
    lineHeight: '30px',
    lineHeightTablet: '24px',
    lineHeightMobile: '24px',
    color: Theme.neutral[100],
    fontFamily: '"Roboto", sans-serif'
  },
  body2: {
    fontSize: '0.9rem', // 14.4px
    tabletFontSize: '0.9rem', // 14.4px
    mobileFontSize: '0.9rem', // 14.4px
    fontWeight: 400,
    lineHeight: '22px',
    lineHeightTablet: '22px',
    lineHeightMobile: '22px',
    color: Theme.primary[400],
    fontFamily: '"Roboto", sans-serif'
  },
  button: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: '0.875rem', // 14px
    tabletFontSize: '0.875rem', // 14px
    mobileFontSize: '0.875rem', // 14px
    fontWeight: 700,
    lineHeight: '19px',
    lineHeightTablet: '19px',
    lineHeightMobile: '19px'
  },
  caption: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: '0.6875rem', // 11px
    tabletFontSize: '0.6875rem', // 11px
    mobileFontSize: '0.6875rem', // 11px
    fontWeight: 400,
    lineHeight: '15px',
    lineHeightTablet: '15px',
    lineHeightMobile: '15px',
    color: Theme.primary[400]
  },
  label: {
    fontFamily: '"Roboto", sans-serif',
    fontSize: '0.8125rem', // 13px
    tabletFontSize: '0.8125rem', // 13px
    mobileFontSize: '0.8125rem', // 13px
    fontWeight: 400,
    lineHeight: '12px',
    lineHeightTablet: '12px',
    lineHeightMobile: '12px',
    color: Theme.primary[400]
  },
  link: {
    fontSize: 16, // 16px
    tabletFontSize: 16, // 16px
    mobileFontSize: 16, // 16px
    fontWeight: 600,
    lineHeight: '26px',
    lineHeightTablet: '26px',
    lineHeightMobile: '26px',
    color: Theme.primary[0]
  },
  tab: {
    fontSize: '1rem', // 16px
    tabletFontSize: '1rem', // 16px
    mobileFontSize: '1rem',
    fontWeight: 500,
    lineHeight: '17px',
    lineHeightTablet: '17px',
    lineHeightMobile: '17px',
    color: Theme.primary[1000]
  }
}

export const typographyTypes = {
  heading1: 'heading1',
  heading2: 'heading2',
  heading3: 'heading3',
  heading4: 'heading4',
  heading5: 'heading5',
  heading6: 'heading6',
  body1: 'body1',
  body2: 'body2',
  button: 'button',
  caption: 'caption',
  label: 'label',
  link: 'link',
  tab: 'tab'
}
