import { escapeRegExp } from 'helpers'
import { Helmet } from 'react-helmet'

export default ({ link, title, description, image = null }) =>
  <Helmet>
    <meta property='og:url' content={link} />
    <meta property='og:type' content='website' />
    <meta property='og:site_name' content='Alphorm' />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={escapeRegExp(description)} />
    <meta property='og:image' content={image} />
    <meta property='og:locale' content='fr_FR' />
    <meta property='fb:app_id' content='258754584475346' />
  </Helmet>
