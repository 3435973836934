import { GET_CURRENT_SHOP_FAIL, GET_CURRENT_SHOP_SUCCESS } from 'components/common/Support/store'
import { GET_REFERENTIALS_FAIL, GET_REFERENTIALS_SUCCESS } from './referential'
import { all, put, take } from 'redux-saga/effects'

const initialState = {
  sideBarOpen: false,
  categorySelected: false,
  loader: false,
  banner: true,
  sessionId: null
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case SWITCH_SIDEBAR_STATE:
      return {
        ...state,
        sideBarOpen: !state.sideBarOpen
      }
    case CATEGORY_SELECTED:
      return {
        ...state,
        categorySelected: data
      }
    case TRN_ON_LOADER_STATE:
      return {
        ...state,
        loader: true
      }
    case TRN_OFF_LOADER_STATE:
      return {
        ...state,
        loader: false
      }
    case TRN_OFF_BANNER_STATE:
      return {
        ...state,
        banner: false
      }
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: data
      }
    default:
      return state
  }
}

export const SwitchSideBarStateAction = _ => ({ type: SWITCH_SIDEBAR_STATE })
export const TurnOnLoaderAction = _ => ({ type: TRN_ON_LOADER_STATE })
export const TurnOffLoaderAction = _ => ({ type: TRN_OFF_LOADER_STATE })
export const SetMobileAppsBannerVisiblity = _ => ({ type: TRN_OFF_BANNER_STATE })
export const SetSessionId = sessionId => ({ type: SET_SESSION_ID, data: sessionId })

export function * loadingAppRootSaga () {
  yield put(TurnOnLoaderAction())
  yield all([
    take([GET_REFERENTIALS_SUCCESS, GET_REFERENTIALS_FAIL]),
    take([GET_CURRENT_SHOP_SUCCESS, GET_CURRENT_SHOP_FAIL])
  ])
  yield put(TurnOffLoaderAction())
}

const SWITCH_SIDEBAR_STATE = 'SWITCH_SIDEBAR_STATE'
export const CATEGORY_SELECTED = 'CATEGORY_SELECTED'
const TRN_ON_LOADER_STATE = 'TRN_ON_LOADER_STATE'
const TRN_OFF_LOADER_STATE = 'TRN_OFF_LOADER_STATE'
const TRN_OFF_BANNER_STATE = 'TRN_OFF_BANNER_STATE'
const SET_SESSION_ID = 'SET_SESSION_ID'
