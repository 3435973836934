import React from 'react'

const TableCell = (props) => {
  return (
    <td className={props.className}>
      {props.children}
    </td>
  )
}

export default TableCell
