import { MediaQueries } from 'UI'

export default (theme) => ({
  icon: {
    lineHeight: 0
  },
  socialMediaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10
  },
  socialMedia: {
    width: 33,
    height: 33,
    backgroundColor: theme.secondary[0],
    color: theme.primary[800],
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  linkClass: {
    textDecoration: 'none',
    lineHeight: 0,
    color: theme.neutral[400]
  },
  [`${MediaQueries.tablet}`]: {
    socialMediaContainer: {
      width: 86,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      gap: 10
    },
    socialMedia: {
      width: 32,
      height: 32
    }
  },
  [`${MediaQueries.mobile}`]: {
    socialMediaContainer: {
      width: 220,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      gap: 10
    },
    socialMedia: {
      width: 29,
      height: 29
    }
  }
})
