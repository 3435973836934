import { connect } from 'react-redux'
import UI from './UI'
import { push } from 'connected-react-router'
import { decorateUrl } from 'components/common/UTMscript'

const mapStateToProps = ({ onBoarding }) => ({
  b2bNewUser: !!onBoarding?.b2bNewUserOrganizationInfo?.organizationId
})

const mapDispatchToProps = dispatch => ({
  goTo: (url, state) => dispatch(push({ pathname: decorateUrl(url), state }))
})

export default connect(mapStateToProps, mapDispatchToProps)(UI)
