export const DropdownmenuBorders = {
  top: 'top',
  right: 'right',
  left: 'left',
  bottom: 'bottom',
  all: 'all',
  topAndBottom: 'topAndBottom',
  leftAndRight: 'leftAndRight',
  none: 'none'
}
export const DropdownmenuBordersMap = {
  top: '1px 0 0 0',
  right: '0 1px 0 0',
  left: ' 0 0 0 1px',
  bottom: '0 0 1px 0',
  all: '1px 1px 1px 1px',
  topAndBottom: '1px 0 1px 0',
  leftAndRight: '0 1px 0 1px',
  none: '0 0 0 0'
}
