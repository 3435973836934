import fetch from 'core/fetch'
import { BASE_URL_WEB, couponNotAppliedDataLayer, ItemTypesDataLayer, ItemTypesEnum, minPriceForThreeTimesPayment, paymentMethodTypesDataLayer, ProfileTypes } from 'constants/'
import { takeLatest, select, put, all, takeEvery } from 'redux-saga/effects'
import { SIGNIN, LOGOUT_SUCCESS } from 'identity/store'
import { defaultCurrency } from 'store/currency'
import { push } from 'connected-react-router'
import { AddItemToCartPath, CartPath, CartPaymentSuccessPath } from 'navigation/Routes'
import { FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED } from 'components/CheckoutSubscription/store'
import { AddToCartDataLayer, PurchaseDataLayer, RemoveFromCartDataLayer } from 'helpers'
import { TurnOffLoaderAction, TurnOnLoaderAction } from 'store/config'
import { openAlertNotification, openFailNotification, openSuccessNotification } from 'components/common/Notification/store'
import Text from './text.json'
import { decorateUrl } from 'components/common/UTMscript'

const initialState = {
  step: 1,
  Id: null,
  lastSelectedItem: null,
  cartItems: [],
  CouponStillValid: null,
  coupon: '',
  couponId: '',
  Total: 0,
  finalizedCoursesOrder: {},
  finalizedCartItems: [],
  loadingCart: false,
  finalizingCoursesOrder: false,
  isThreeTimesPaymentSelected: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case NEXT_STEP:
      return {
        ...state,
        step: state.step + 1
      }
    case RESET_STEPS:
      return {
        ...state,
        step: 1
      }
    case GO_TO_STEP:
      return {
        ...state,
        step: data
      }
    case FINALIZE_COURESES_ORDER:
      return {
        ...state,
        finalizingCoursesOrder: true
      }
    case GET_SHOPING_CART_REQUESTED:
      return {
        ...state,
        loadingCart: true
      }
    case TURN_OFF_LOADER_SHOPING_CART:
      return {
        ...state,
        loadingCart: false
      }
    case ADD_ITEM_TO_CART:
      return {
        ...state,
        lastSelectedItem: data,
        cartItems: [
          data,
          ...state.cartItems
        ]
      }
    case REMOVE_ITEM_FROM_CART:
      return {
        ...state,
        cartItems: data
      }
    case COMPUTE_CART_SUCCEEDED:
      return {
        ...state,
        Total: data.Total
      }
    case GET_SHOPING_CART_SUCCEEDED:
      return {
        ...state,
        ...data,
        loadingCart: false
      }
    case GET_SHOPING_CART_FAILED:
      return {
        ...state,
        loadingCart: false
      }
    case HANDLE_COUPON_CHANGE:
      return {
        ...state,
        ...data
      }
    case VERIFY_COUPON_SUCCEEDED:
      return {
        ...state,
        ...data
      }
    case VERIFY_COUPON_FAILED:
      return {
        ...state,
        ...data
      }
    case FINALIZE_COURESES_ORDER_SUCCEEDED:
      return {
        ...state,
        cartItems: [],
        finalizedCartItems: data.finalizedCartItems,
        finalizingCoursesOrder: false,
        Id: null,
        lastSelectedItem: null,
        CouponStillValid: null,
        coupon: '',
        couponId: '',
        finalizedCoursesOrder: {},
        loadingCart: false
      }
    case FINALIZE_COURESES_ORDER_FAILED:
      return {
        ...state,
        finalizingCoursesOrder: false
      }
    case RESET_FINILIZED_CART_ITEMS:
      return {
        ...state,
        cartItems: [],
        finalizedCartItems: []
      }
    case RESET_COUPON_CART_CART_SUCCEEDED:
      return {
        ...state,
        CouponStillValid: null,
        coupon: '',
        couponId: ''
      }
    case SET_THREE_TIMES_PAYMENT:
      return {
        ...state,
        isThreeTimesPaymentSelected: data
      }
    case LOGOUT_SUCCESS:
    case RESET_COURSE_CART:
    case RESET_SHOPPING_CART_SUCCEEDED:
    case FINALIZE_SUBSCRIPTION_ORDER_SUCCEEDED:
      return initialState
    default:
      return state
  }
}

export const lastStep = 2

export const paymentStep = 2
export const paymentResponsiveStep = 3

const getCartState = ({ cart }) => cart

const getIdentity = ({ identity }) => identity

const getCreditCards = ({ payment }) => payment.creditCards

const getCurrencyState = ({ currency }) => currency

const getReferential = ({ referential }) => referential

const getSupportState = ({ support }) => support

const getAutoAppliedCoupon = ({ user }) => user.AutoAppliedCoupons

export const AddItemToCartAction = (data, noRedirection) => ({ type: ADD_ITEM_TO_CART_REQUESTED, data, noRedirection })

export const RemoveItemFromCartAction = id => ({ type: REMOVE_ITEM_FROM_CART_REQUESTED, id })

export const GoToNextStep = _ => ({ type: NEXT_STEP })

export const GoToStep = step => ({ type: GO_TO_STEP, data: step })

export const ResetStep = _ => ({ type: RESET_STEPS })

export const FinalizeCoursesOrderAction = _ => ({ type: FINALIZE_COURESES_ORDER })

export const ComputeShopingCartAction = _ => ({ type: COMPUTE_SHOPING_CART_REQUESTED })

export const GetShopingCartAction = (itemType) => ({ type: GET_SHOPING_CART_REQUESTED, itemType })

export const PostShopingCartAction = _ => ({ type: POST_SHOPING_CART_REQUESTED })

export const HandleCouponChangeAction = id => id ? ({ type: HANDLE_COUPON_CHANGE, data: { coupon: id } }) : ({ type: RESET_COUPON_CART_CART_REQUESTED })

export const VerifyCouponAction = _ => ({ type: VERIFY_COUPON })

export const ResetFinalizedCartItems = _ => ({ type: RESET_FINILIZED_CART_ITEMS })

export const clearCartAction = _ => ({ type: RESET_SHOPPING_CART_REQUESTED })

export const clearCouponStateAction = _ => ({ type: RESET_COUPON_CART_CART_REQUESTED })

export const addToCartWebhookAction = email => ({ type: ADD_TO_CART_WEBHOOK_REQUESTED, email })

export const removeFromCartWebhookAction = email => ({ type: REMOVE_FROM_CART_WEBHOOK_REQUESTED, email })

export const beginCheckoutWebhookAction = email => ({ type: BEGIN_CHECKOUT_WEBHOOK_REQUESTED, email })

export const purchaseWebhookAction = email => ({ type: PURCHASE_WEBHOOK_REQUESTED, email })

export const addLearningPathToCartAction = data => ({ type: ADD_LEARNING_PATH_TO_CART_REQUESTED, data })

export const setThreeTimesPayment = isSelected => ({ type: SET_THREE_TIMES_PAYMENT, data: isSelected })

function * AddItemToCart ({ data, noRedirection }) {
  const cart = yield select(getCartState)
  const { isConnected, user } = yield select(getIdentity)
  const findInCart = cart.cartItems.find(item => item.Id === data.Id)
  if (!findInCart) {
    try {
      if (isConnected) {
        const body = {
          itemId: data.Id,
          itemType: 1
        }
        yield fetch(queries.addItemToCart, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
        const item = {
          item_name: data.Title,
          item_id: data.Id,
          item_category: ItemTypesDataLayer.course,
          price: data.Price,
          quantity: 1
        }
        yield put(addToCartWebhookAction(user.email))
        AddToCartDataLayer({ item })
      }
      yield put({ type: ADD_ITEM_TO_CART, data })
      if (!noRedirection) yield put(push(decorateUrl(AddItemToCartPath)))
      yield put(openSuccessNotification(Text.courseAddedToCartAlert))
    } catch (err) {
      yield put({ type: ADD_ITEM_TO_CART_FAILED })
    }
  } else {
    yield put(openAlertNotification(Text.courseAlreadyInCartAlert))
  }
}

function * RemoveItemFromCart ({ id }) {
  const cart = yield select(getCartState)
  const { isConnected, user } = yield select(getIdentity)
  const filterCart = cart.cartItems.filter(item => item.Id !== id)
  const itemToDelete = cart.cartItems.find(item => item.Id === id)
  try {
    if (isConnected) {
      yield fetch(queries.removeItemFromCart(id), { method: 'delete', headers: { 'Content-Type': 'application/json' } })
      yield put(removeFromCartWebhookAction(user.email))
      const item = {
        item_name: itemToDelete.Title,
        item_id: itemToDelete.Id,
        item_category: ItemTypesDataLayer.course,
        price: itemToDelete.Price,
        quantity: 1
      }
      RemoveFromCartDataLayer({ item })
    }
    yield put({ type: REMOVE_ITEM_FROM_CART, data: filterCart })
    if (filterCart.length === 0) yield put(clearCouponStateAction())
  } catch (err) {
    yield put({ type: REMOVE_ITEM_FROM_CART_FAILED })
  }
}

function * FinalizeCoursesOrder () {
  yield put(TurnOnLoaderAction())
  const { user } = yield select(getIdentity)
  const support = yield select(getSupportState)
  const cart = yield select(getCartState)
  const creditCards = yield select(getCreditCards)
  const defaultCreditCard = creditCards.find(card => card.IsDefault)
  const currency = yield select(getCurrencyState)
  const useEuro = currency.initialCurrency === defaultCurrency
  const { PaymentScheduleTypes } = yield select(getReferential)
  const { ItemTypes } = yield select(getReferential)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const autoAppliedCouponId = autoAppliedCoupon?.length > 0 ? autoAppliedCoupon?.find(coupon => coupon.SubjectType === ItemTypes[ItemTypesEnum.Course])?.Id : null
  try {
    const body = {
      PaymentMethodId: defaultCreditCard.Id,
      paymentMethodType: defaultCreditCard.PaymentMethodType,
      paymentScheduleType: cart.isThreeTimesPaymentSelected ? PaymentScheduleTypes.ThreeTimes : PaymentScheduleTypes.OneTime,
      autoAppliedCoupon: !!autoAppliedCouponId
    }
    yield fetch(queries.changeEuroAsDefault(useEuro), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    const response = yield fetch(queries.finalizeCoursesOrder, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    if (!response.IsOrderProcessedSuccesfull) throw new Error('Order not processed')
    yield put({ type: FINALIZE_COURESES_ORDER_SUCCEEDED, data: { finalizedCartItems: cart.cartItems, ProfileType: ProfileTypes.Buyer } })
    yield put(push(decorateUrl(`${CartPath}${CartPaymentSuccessPath}`), { fromCart: true }))
    yield put(TurnOffLoaderAction())
    const items = cart.cartItems.map(item => ({
      item_name: item.Title,
      item_id: item.Id,
      item_category: 'FORMATION',
      price: item.Price,
      quantity: 1
    }))
    const affiliation = paymentMethodTypesDataLayer[creditCards.find(item => item.IsDefault)?.PaymentMethodType]
    const coupon = cart.coupon || couponNotAppliedDataLayer
    const value = cart.Total
    const tax = support.Vat
    yield put(purchaseWebhookAction(user.email))
    PurchaseDataLayer({ items, affiliation, coupon, currency: currency.initialCurrency, value, tax })
  } catch (err) {
    yield put({ type: FINALIZE_COURESES_ORDER_FAILED })
    yield put(TurnOffLoaderAction())
    yield put(openFailNotification(Text.finalizeCoursesOrderFailed))
  }
}

function * ComputeShopingCart () {
  const shop = yield select(getSupportState)
  const cart = yield select(getCartState)
  const currency = yield select(getCurrencyState)
  const useEuro = currency.initialCurrency === defaultCurrency
  const { ItemTypes } = yield select(getReferential)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const autoAppliedCouponId = autoAppliedCoupon?.length > 0 ? autoAppliedCoupon?.find(coupon => coupon.SubjectType === ItemTypes[ItemTypesEnum.Course])?.Id : null
  const promotion = shop?.PromotionCourse
  const body = {
    couponId: useEuro ? (cart.couponId || autoAppliedCouponId || null) : null,
    autoAppliedCoupon: !!autoAppliedCouponId,
    useEuro,
    cartItems: [],
    itemType: ItemTypes[ItemTypesEnum.Course],
    IsPromotionActive: !!promotion
  }
  body.cartItems = cart.cartItems.map(item => ({ itemId: item.Id, itemType: ItemTypes[ItemTypesEnum.Course] }))
  try {
    const response = yield fetch(queries.compute, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    yield put({ type: COMPUTE_CART_SUCCEEDED, data: response })
    yield put({ type: SET_THREE_TIMES_PAYMENT, data: response.Total < minPriceForThreeTimesPayment ? false : cart.isThreeTimesPaymentSelected })
    if (autoAppliedCouponId) yield put({ type: APPLY_COUPON_ACTION, data: { couponId: autoAppliedCouponId } })
  } catch (err) {
    yield put({ type: COMPUTE_CART_FAILED })
  }
}

const sameItemsInCart = (localCart = [], serverCart = []) => {
  const sameLength = localCart.length === serverCart.length
  let sameContent = true
  localCart.map(localCartItem => {
    const itemFound = serverCart.find(serverCartItem => serverCartItem.Id === localCartItem.Id)
    if (sameContent && !itemFound) sameContent = false
    return null
  })
  return sameContent && sameLength
}

function * GetShopingCart ({ itemType }) {
  const { isConnected } = yield select(getIdentity)
  const cart = yield select(getCartState)
  const { ItemTypes } = yield select(getReferential)
  const { SubscriptionTypePrices, PriceCoefficient } = yield select(getSupportState)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const referential = yield select(getReferential)
  const courseCoupon = autoAppliedCoupon?.length ? autoAppliedCoupon[0]?.Values?.find(coupon => coupon.SubjectType === referential.ItemTypes[ItemTypesEnum.Course]) : null
  if (isConnected) {
    let serversCart
    try {
      serversCart = yield fetch(queries.getShopingCart)
    } catch (error) {
      serversCart = { CartItems: [], ItemType: ItemTypes[ItemTypesEnum.Course] }
      yield put({ type: GET_SHOPING_CART_FAILED, error })
    }
    try {
      if (serversCart.ItemType === ItemTypes[ItemTypesEnum.Course] || (itemType && itemType === ItemTypesEnum.Course)) {
        const serverCartItems = serversCart.CartItems.length ? serversCart.CartItems.map(item => ({ ...item, Image: item.Photo, Id: item.ItemId })) : []
        let filteredServerCartItems = serverCartItems.filter(item => item.ItemType === ItemTypes[ItemTypesEnum.Course])
        if (cart.cartItems.length > 0 && !sameItemsInCart(cart.cartItems, serverCartItems)) {
          yield fetch(queries.removeShopingCart, { method: 'delete', nojson: true })
          yield all(cart.cartItems.map(item => {
            fetch(queries.addItemToCart, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ itemId: item.Id, itemType: ItemTypes[ItemTypesEnum.Course] }) })
            AddToCartDataLayer({
              item: {
                item_name: item.Title,
                item_id: item.Id,
                item_category: ItemTypesDataLayer.course,
                price: item.Price,
                quantity: 1
              }
            })
            return null
          }))
          if (courseCoupon) {
            cart.cartItems = cart?.cartItems?.map(course => ({
              ...course,
              PromotionPrice: courseCoupon.Value,
              DiscountPrice: courseCoupon.Value,
              AlternativePricePromo: courseCoupon.Value * PriceCoefficient
            }))
          }
          yield put({ type: GET_SHOPING_CART_SUCCEEDED, data: { cartItems: cart.cartItems, couponId: serversCart.CouponId || '', coupon: serversCart.CouponName || '' } })
        } else {
          if (courseCoupon) {
            filteredServerCartItems = filteredServerCartItems?.map(course => ({
              ...course,
              PromotionPrice: courseCoupon.Value,
              DiscountPrice: courseCoupon.Value,
              AlternativePricePromo: courseCoupon.Value * PriceCoefficient
            }))
          }
          yield put({ type: GET_SHOPING_CART_SUCCEEDED, data: { cartItems: filteredServerCartItems, couponId: serversCart.CouponId || '', coupon: serversCart.CouponName || '' } })
        }
      } else {
        const subscription = SubscriptionTypePrices.find(subscriptionType => subscriptionType.Id === serversCart.CartItems[0].ItemId)
        yield put({
          type: GET_SUBSCRIPTION_CART_SUCCEEDED,
          data: {
            initialSubscriptionChoice: subscription.BillingCycle,
            subscriptionChoice: subscription.BillingCycle,
            CouponStillValid: !!serversCart.CouponId,
            couponId: serversCart.CouponId || '',
            coupon: serversCart.CouponName || '',
            inCart: true
          }
        })
        yield put({ type: TURN_OFF_LOADER_SHOPING_CART })
      }
    } catch (err) {
      yield put({ type: GET_SHOPING_CART_FAILED })
    }
  } else {
    yield put({ type: TURN_OFF_LOADER_SHOPING_CART })
  }
}

function * VerifyCoupon () {
  const { coupon } = yield select(getCartState)
  const { ItemTypes } = yield select(getReferential)
  if (coupon) {
    try {
      const response = yield fetch(queries.verifyCoupon(coupon, ItemTypes[ItemTypesEnum.Course]))
      if (response.status === 404) throw new Error()
      yield put({ type: VERIFY_COUPON_SUCCEEDED, data: { CouponStillValid: true, couponId: response.Id } })
    } catch (error) {
      yield put({ type: VERIFY_COUPON_FAILED, data: { CouponStillValid: coupon.length === 0 ? null : false, couponId: '', coupon: coupon } })
    }
  }
}

function * ApplyCoupon ({ data }) {
  const { isConnected } = yield select(getIdentity)
  if (isConnected) {
    try {
      yield fetch(queries.applyCoupon(data.couponId), { method: 'post', headers: { 'Content-Type': 'application/json' } })
      yield put({ type: APPLY_COUPON_SUCCEEDED })
    } catch (error) {
      yield put({ type: APPLY_COUPON_FAILED })
    }
  }
}

function * clearCart () {
  const { isConnected } = yield select(getIdentity)
  if (isConnected) {
    try {
      yield fetch(queries.removeShopingCart, { method: 'delete', nojson: true })
      yield put({ type: RESET_SHOPPING_CART_SUCCEEDED })
    } catch (error) {
      yield put({ type: RESET_SHOPPING_CART_FAILED })
    }
  } else {
    yield put({ type: RESET_SHOPPING_CART_SUCCEEDED })
  }
}

function * RemoveCartCoupon () {
  const { couponId } = yield select(getCartState)
  try {
    if (couponId) yield fetch(queries.removeCoupon(couponId), { method: 'post', headers: { 'Content-Type': 'application/json' } })
    yield put({ type: RESET_COUPON_CART_CART_SUCCEEDED })
  } catch (error) {
    yield put({ type: RESET_COUPON_CART_CART_FAILED })
  }
}

function * addToCartWebhook ({ email }) {
  try {
    yield fetch(queries.addToCartWebhook(email), { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true })
    yield put({ type: ADD_TO_CART_WEBHOOK_SUCCEEDED })
  } catch (err) {
    yield put({ type: ADD_TO_CART_WEBHOOK_FAILED, err })
  }
}

function * removeFromCartWebhook ({ email }) {
  try {
    yield fetch(queries.removeFromCartWebhook(email), { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true })
    yield put({ type: REMOVE_FROM_CART_WEBHOOK_SUCCEEDED })
  } catch (err) {
    yield put({ type: REMOVE_FROM_CART_WEBHOOK_FAILED, err })
  }
}

function * BeginCheckoutWebhook ({ email }) {
  try {
    yield fetch(queries.BeginCheckoutWebhook(email), { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true })
    yield put({ type: BEGIN_CHECKOUT_WEBHOOK_SUCCEEDED })
  } catch (err) {
    yield put({ type: BEGIN_CHECKOUT_WEBHOOK_FAILED, err })
  }
}

function * purchaseWebhook ({ email }) {
  try {
    yield fetch(queries.purchaseWebhook(email), { method: 'post', headers: { 'Content-Type': 'application/json' }, nojson: true })
    yield put({ type: PURCHASE_WEBHOOK_SUCCEEDED })
  } catch (err) {
    yield put({ type: PURCHASE_WEBHOOK_FAILED, err })
  }
}

function * addLearningPathToCart ({ data }) {
  try {
    const inaccessibleCourses = data.filter(({ IsAccessible, IsFree }) => !IsAccessible && !IsFree)
    if (inaccessibleCourses.length > 0) {
      yield put(push(decorateUrl(CartPath)))
      yield all(inaccessibleCourses.map((course) => AddItemToCart({
        noRedirection: true,
        data: {
          Id: course.Id,
          Title: course.Title,
          Image: course.Image,
          MobileImageUrl: course.MobileImageUrl,
          Price: course.Price,
          Rating: course.Rating,
          ReviewersCount: course.ReviewersCount,
          Duration: course.Duration,
          ViewersCount: course.ViewersCount,
          Slug: course.Slug,
          AlternativePrice: course.AlternativePrice,
          PromotionPrice: course.PricePromo,
          AlternativePricePromo: course.AlternativePricePromo
        }
      })))
    } else {
      yield put(openAlertNotification(Text.learningPathAlreadyPurchasedAlert))
    }
    yield put({ type: ADD_LEARNING_PATH_TO_CART_SUCCEEDED })
  } catch (err) {
    yield put({ type: ADD_LEARNING_PATH_TO_CART_FAILED, err })
  }
}

export function * cartRootSaga () {
  yield takeLatest(ADD_ITEM_TO_CART_REQUESTED, AddItemToCart)
  yield takeEvery(REMOVE_ITEM_FROM_CART_REQUESTED, RemoveItemFromCart)
  yield takeLatest(FINALIZE_COURESES_ORDER, FinalizeCoursesOrder)
  yield takeLatest([
    GET_SHOPING_CART_SUCCEEDED,
    COMPUTE_SHOPING_CART_REQUESTED,
    VERIFY_COUPON_SUCCEEDED,
    VERIFY_COUPON_FAILED,
    ADD_ITEM_TO_CART,
    REMOVE_ITEM_FROM_CART,
    RESET_COUPON_CART_CART_SUCCEEDED
  ], ComputeShopingCart)
  yield takeLatest([SIGNIN, GET_SHOPING_CART_REQUESTED], GetShopingCart)
  yield takeLatest([GET_SHOPING_CART_SUCCEEDED, VERIFY_COUPON], VerifyCoupon)
  yield takeLatest([APPLY_COUPON_ACTION, VERIFY_COUPON_SUCCEEDED], ApplyCoupon)
  yield takeLatest(RESET_SHOPPING_CART_REQUESTED, clearCart)
  yield takeLatest(RESET_COUPON_CART_CART_REQUESTED, RemoveCartCoupon)
  yield takeLatest(ADD_TO_CART_WEBHOOK_REQUESTED, addToCartWebhook)
  yield takeLatest(REMOVE_FROM_CART_WEBHOOK_REQUESTED, removeFromCartWebhook)
  yield takeLatest(BEGIN_CHECKOUT_WEBHOOK_REQUESTED, BeginCheckoutWebhook)
  yield takeLatest(PURCHASE_WEBHOOK_REQUESTED, purchaseWebhook)
  yield takeLatest(ADD_LEARNING_PATH_TO_CART_REQUESTED, addLearningPathToCart)
}

const queries = {
  getShopingCart: `${BASE_URL_WEB}/ShoppingCart`,
  finalizeCoursesOrder: `${BASE_URL_WEB}/ShoppingCart/finalizeCoursesOrder`,
  compute: `${BASE_URL_WEB}/ShoppingCart/compute`,
  addItemToCart: `${BASE_URL_WEB}/ShoppingCart/addItemToCart`,
  removeShopingCart: `${BASE_URL_WEB}/ShoppingCart`,
  removeItemFromCart: itemId => `${BASE_URL_WEB}/ShoppingCart/removeItemFromCart/${itemId}`,
  verifyCoupon: (name, couponType) => `${BASE_URL_WEB}/Coupon/VerifyCoupon?name=${name}&couponType=${couponType}`,
  applyCoupon: (couponId) => `${BASE_URL_WEB}/ShoppingCart/applyCouponDiscount/${couponId}`,
  removeCoupon: (couponId) => `${BASE_URL_WEB}/ShoppingCart/removeCouponDiscount/${couponId}`,
  addToCartWebhook: (email) => `${BASE_URL_WEB}/Zapier/addToCart/${email}`,
  removeFromCartWebhook: (email) => `${BASE_URL_WEB}/Zapier/removeFromCart/${email}`,
  BeginCheckoutWebhook: (email) => `${BASE_URL_WEB}/Zapier/beginCheckout/${email}`,
  purchaseWebhook: (email) => `${BASE_URL_WEB}/Zapier/purchase/${email}`,
  changeEuroAsDefault: (euroAsDefault) => `${BASE_URL_WEB}/ShoppingCart/changeEuroAsDefault/${euroAsDefault}`
}

export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART'
const ADD_ITEM_TO_CART_REQUESTED = 'ADD_ITEM_TO_CART_REQUESTED'
const REMOVE_ITEM_FROM_CART_REQUESTED = 'REMOVE_ITEM_FROM_CART_REQUESTED'
const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART'
const NEXT_STEP = 'NEXT_STEP'
const RESET_STEPS = 'RESET_STEPS'
const FINALIZE_COURESES_ORDER = 'FINALIZE_COURESES_ORDER'
export const FINALIZE_COURESES_ORDER_SUCCEEDED = 'FINALIZE_COURESES_ORDER_SUCCEEDED'
const FINALIZE_COURESES_ORDER_FAILED = 'FINALIZE_COURESES_ORDER_FAILED'
const COMPUTE_SHOPING_CART_REQUESTED = 'COMPUTE_SHOPING_CART_REQUESTED'
const COMPUTE_CART_SUCCEEDED = 'COMPUTE_CART_SUCCEEDED'
const COMPUTE_CART_FAILED = 'COMPUTE_CART_FAILED'
const GET_SHOPING_CART_REQUESTED = 'GET_SHOPING_CART_REQUESTED'
const POST_SHOPING_CART_REQUESTED = 'POST_SHOPING_CART_REQUESTED'
const GET_SHOPING_CART_SUCCEEDED = 'GET_SHOPING_CART_SUCCEEDED'
const GET_SHOPING_CART_FAILED = 'GET_SHOPING_CART_FAILED'
const HANDLE_COUPON_CHANGE = 'HANDLE_COUPON_CHANGE'
const VERIFY_COUPON = 'VERIFY_COUPON'
const VERIFY_COUPON_SUCCEEDED = 'VERIFY_COUPON_SUCCEEDED'
const VERIFY_COUPON_FAILED = 'VERIFY_COUPON_FAILED'
const APPLY_COUPON_SUCCEEDED = 'APPLY_COUPON_SUCCEEDED'
const APPLY_COUPON_FAILED = 'APPLY_COUPON_FAILED'
const ADD_ITEM_TO_CART_FAILED = 'ADD_ITEM_TO_CART_FAILED'
const REMOVE_ITEM_FROM_CART_FAILED = 'REMOVE_ITEM_FROM_CART_FAILED'
export const RESET_SUBSCRIPTION_CART = 'RESET_SUBSCRIPTION_CART'
const RESET_COURSE_CART = 'RESET_COURSE_CART'
const RESET_FINILIZED_CART_ITEMS = 'RESET_FINILIZED_CART_ITEMS'
const RESET_SHOPPING_CART_REQUESTED = 'RESET_SHOPPING_CART_REQUESTED'
export const RESET_SHOPPING_CART_SUCCEEDED = 'RESET_SHOPPING_CART_SUCCEEDED'
const RESET_SHOPPING_CART_FAILED = 'RESET_SHOPPING_CART_FAILED'
const GO_TO_STEP = 'GO_TO_STEP'
const TURN_OFF_LOADER_SHOPING_CART = 'TURN_OFF_LOADER_SHOPING_CART'
const GET_SUBSCRIPTION_CART_SUCCEEDED = 'GET_SUBSCRIPTION_CART_SUCCEEDED'
const RESET_COUPON_CART_CART_REQUESTED = 'RESET_COUPON_CART_CART_REQUESTED'
const RESET_COUPON_CART_CART_SUCCEEDED = 'RESET_COUPON_CART_CART_SUCCEEDED'
const RESET_COUPON_CART_CART_FAILED = 'RESET_COUPON_CART_CART_FAILED'
const ADD_TO_CART_WEBHOOK_REQUESTED = 'ADD_TO_CART_WEBHOOK_REQUESTED'
const REMOVE_FROM_CART_WEBHOOK_REQUESTED = 'REMOVE_FROM_CART_WEBHOOK_REQUESTED'
const BEGIN_CHECKOUT_WEBHOOK_REQUESTED = 'BEGIN_CHECKOUT_WEBHOOK_REQUESTED'
const PURCHASE_WEBHOOK_REQUESTED = 'PURCHASE_WEBHOOK_REQUESTED'
const ADD_TO_CART_WEBHOOK_SUCCEEDED = 'ADD_TO_CART_WEBHOOK_SUCCEEDED'
const REMOVE_FROM_CART_WEBHOOK_SUCCEEDED = 'REMOVE_FROM_CART_WEBHOOK_SUCCEEDED'
const BEGIN_CHECKOUT_WEBHOOK_SUCCEEDED = 'BEGIN_CHECKOUT_WEBHOOK_SUCCEEDED'
const PURCHASE_WEBHOOK_SUCCEEDED = 'PURCHASE_WEBHOOK_SUCCEEDED'
const ADD_TO_CART_WEBHOOK_FAILED = 'ADD_TO_CART_WEBHOOK_FAILED'
const REMOVE_FROM_CART_WEBHOOK_FAILED = 'REMOVE_FROM_CART_WEBHOOK_FAILED'
const BEGIN_CHECKOUT_WEBHOOK_FAILED = 'BEGIN_CHECKOUT_WEBHOOK_FAILED'
const PURCHASE_WEBHOOK_FAILED = 'PURCHASE_WEBHOOK_FAILED'
const ADD_LEARNING_PATH_TO_CART_REQUESTED = 'ADD_LEARNING_PATH_TO_CART_REQUESTED'
const ADD_LEARNING_PATH_TO_CART_SUCCEEDED = 'ADD_LEARNING_PATH_TO_CART_SUCCEEDED'
const ADD_LEARNING_PATH_TO_CART_FAILED = 'ADD_LEARNING_PATH_TO_CART_FAILED'
const SET_THREE_TIMES_PAYMENT = 'SET_THREE_TIMES_PAYMENT'
const APPLY_COUPON_ACTION = 'APPLY_COUPON_ACTION'
