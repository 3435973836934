import mediaQueries from 'UI/constants/mediaQueries'

export default theme => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    position: 'relative',
    marginBottom: ({ gutterBottom }) => gutterBottom && `${gutterBottom}px`
  },
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
    flexBasis: '77%'
  },
  greenCheck: {
    position: 'absolute',
    top: 35,
    right: 8,
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.neutral[400],
    fontSize: 12,
    padding: 8,
    backgroundColor: theme.success[0],
    borderRadius: '50%'
  },
  buttonClass: {
    flexBasis: '23%'
  },
  input: {
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    borderRight: 'none !important',
    padding: '0 42px 0 87px !important',
    height: '56px !important'
  },
  countryCode: {
    position: 'absolute',
    top: 36,
    left: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    cursor: 'pointer'
  },
  flag: {
    width: 40,
    height: 27,
    marginRight: 13,
    borderRadius: 8
  },
  arrowClass: {
    color: theme.primary[0]
  },
  suggestionGroupContainer: {
    transition: 'all 0.2s ease',
    maxHeight: ({ openOptions }) => openOptions ? 252 : 0,
    transformOrigin: 'top center',
    position: 'absolute',
    zIndex: 5,
    width: '77%'
  },
  [`${mediaQueries.tablet}`]: {
    greenCheck: {
      top: 36
    },
    countryCode: {
      top: 37
    }
  },
  [`${mediaQueries.mobile}`]: {
    greenCheck: {
      top: 36
    },
    countryCode: {
      top: 37
    }
  }
})
