import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import fetch from 'core/fetch'
import { BASE_URL_WEB, ItemTypesEnum } from 'constants/'
import { LOGOUT_SUCCESS } from 'identity/store'
import { GET_FAVORITE_COURSES_IDS_SUCCEEDED } from 'pages/Course/store'

const initTake = 8
const initSkip = 0
const newCoursesTake = 12
const newCoursesSkip = 0

const initialState = {
  userGlobalProgression: {
    CoursesCompletedCount: 0,
    GlobalCompletionPercentage: 0,
    LastAccessedDate: '',
    TotalCoursesInProgressCount: 0
  },
  lastSeenCourseAndHistory: {
    lastSeen: {},
    history: [],
    isLoadingCourse: true
  },
  history: {
    Data: [],
    Count: 0,
    Skip: initSkip
  },
  recommended: [],
  recommendedCount: 0,
  certificats: {
    Data: [],
    Count: 0,
    Skip: initSkip
  },
  courses: {
    Data: [],
    Count: 0,
    Skip: initSkip
  },
  favorits: {
    Data: [],
    Count: 0,
    Skip: initSkip
  },
  newCourses: {
    Data: [],
    Count: 0,
    Skip: initSkip
  },
  similarCourses: [],
  freeCourses: {
    Data: [],
    Count: 0
  },
  learningPaths: {
    Data: [],
    Count: 0,
    FilteredCount: null
  },
  loadingGlobalProgression: true,
  loadingSimilarCourses: true,
  loadingHistory: true,
  loadingFavories: true,
  loadingRecommended: true,
  loadingCertificats: true,
  loadingCourses: true,
  loadingNewCourses: true,
  loadingCounts: false,
  loadingFreeCourses: true,
  loadingLearningPaths: false,
  error: false
}

export const reducer = (state = initialState, { type, data }) => {
  switch (type) {
    case INIT_COUNTS:
      return {
        ...state,
        loadingCounts: true
      }
    case GET_SIMILAR_COURSES_REQUESTED:
      return {
        ...state,
        loadingSimilarCourses: true
      }
    case HISTORY_FETCH_REQUESTED:
      return {
        ...state,
        loadingHistory: true
      }
    case RECOMMENDED_FETCH_REQUESTED:
      return {
        ...state,
        loadingRecommended: true
      }
    case FAVORITES_FETCH_REQUESTED:
      return {
        ...state,
        loadingFavories: true
      }
    case CERTIFICATS_FETCH_REQUESTED:
      return {
        ...state,
        loadingCertificats: true
      }
    case BOUGHT_FETCH_REQUESTED:
      return {
        ...state,
        loadingCourses: true
      }
    case GET_NEW_COURSES_REQUESTED:
      return {
        ...state,
        loadingCourses: true
      }
    case GET_FREE_COURSES_LEARNING_REQUESTED:
      return {
        ...state,
        loadingFreeCourses: true
      }
    case HISTORY_FETCH_SUCCESS:
      return {
        ...state,
        history: {
          Data: data.Data,
          Count: data.Count,
          Skip: data.Skip
        },
        loadingHistory: false
      }
    case RECOMMENDED_FETCH_SUCCESS:
      return {
        ...state,
        recommended: data,
        recommendedCount: data?.length || 0,
        loadingRecommended: false
      }
    case CERTIFICATS_FETCH_SUCCESS:
      return {
        ...state,
        certificats: {
          Data: data.Data,
          Count: data.Count,
          Skip: data.Skip
        },
        loadingCertificats: false
      }
    case FAVORITES_FETCH_SUCCESS:
      return {
        ...state,
        favorits: {
          Data: data.Data,
          Count: data.Count,
          Skip: data.Skip
        },
        loadingFavories: false
      }
    case BOUGHT_FETCH_SUCCESS:
      return {
        ...state,
        courses: {
          Data: data.Data,
          Count: data.Count,
          Skip: data.Skip
        },
        loadingCourses: false
      }
    case HISTORY_FETCH_MORE_SUCCESS:
      return {
        ...state,
        history: {
          Data: [
            ...state.history.Data,
            ...data.Data
          ],
          Count: data.Count,
          Skip: data.Skip
        }
      }
    case CERTIFICATS_FETCH_MORE_SUCCESS:
      return {
        ...state,
        certificats: {
          Data: [
            ...state.certificats.Data,
            ...data.Data
          ],
          Count: data.Count,
          Skip: data.Skip
        }
      }
    case FAVORITES_FETCH_MORE_SUCCESS:
      return {
        ...state,
        favorits: {
          Data: [
            ...state.favorits.Data,
            ...data.Data
          ],
          Count: data.Count,
          Skip: data.Skip
        }
      }
    case BOUGHT_FETCH_MORE_SUCCESS:
      return {
        ...state,
        courses: {
          Data: [
            ...state.courses.Data,
            ...data.Data
          ],
          Count: data.Count,
          Skip: data.Skip
        }
      }
    case GET_NEW_COURSES_SUCCESS:
      return {
        ...state,
        newCourses: {
          Data: data.Data,
          Count: data.Count,
          Skip: data.Skip
        },
        loadingNewCourses: false
      }
    case GET_SIMILAR_COURSES_SUCCESS:
      return {
        ...state,
        similarCourses: data,
        loadingSimilarCourses: false
      }
    case REMOVE_COURSE_FROM_FAVORITES_SUCCEEDED:
      return {
        ...state,
        favorits: {
          ...state.favorits,
          ...data
        }
      }
    case INIT_COUNTS_SUCCEEDED:
      return {
        ...state,
        history: {
          ...state.history,
          Count: data.history.Count
        },
        certificats: {
          ...state.certificats,
          Count: data.certificats.Count
        },
        courses: {
          ...state.courses,
          Count: data.courses.Count
        },
        favorits: {
          ...state.favorits,
          Count: data.favorits.Count
        },
        loadingCounts: false
      }
    case LOGOUT_SUCCESS:
      return initialState
    case GET_FREE_COURSES_LEARNING_SUCCESS:
      return {
        ...state,
        freeCourses: {
          Data: data.Data,
          Count: data.Count
        },
        loadingFreeCourses: false
      }
    case INIT_COUNTS_FAILED:
      return {
        ...state,
        loadingCounts: false
      }
    case GET_SIMILAR_COURSES_FAIL:
      return {
        ...state,
        loadingSimilarCourses: false
      }
    case HISTORY_FETCH_FAIL:
      return {
        ...state,
        loadingHistory: false
      }
    case RECOMMENDED_FETCH_FAIL:
      return {
        ...state,
        loadingRecommended: false
      }
    case FAVORITES_FETCH_FAIL:
      return {
        ...state,
        loadingFavories: false
      }
    case CERTIFICATS_FETCH_FAIL:
      return {
        ...state,
        loadingCertificats: false
      }
    case BOUGHT_FETCH_FAIL:
      return {
        ...state,
        loadingCourses: false
      }
    case GET_NEW_COURSES_FAIL:
      return {
        ...state,
        loadingNewCourses: false
      }
    case GET_FREE_COURSES_LEARNING_FAIL:
      return {
        ...state,
        loadingFreeCourses: false
      }
    case CLEAR_LEARNING:
      return {
        ...initialState
      }
    case CLEAR_HISTORY:
      return {
        ...state,
        history: {
          Data: [...initialState.history.Data],
          Count: state.history.Count,
          Skip: initSkip
        },
        loadingHistory: true
      }
    case CLEAR_FAVORIES:
      return {
        ...state,
        favorits: {
          Data: [...initialState.favorits.Data],
          Count: state.favorits.Count,
          Skip: initSkip
        },
        loadingFavories: true
      }
    case CLEAR_RECOMMENDED:
      return {
        ...state,
        recommended: [],
        loadingRecommended: true
      }
    case CLEAR_CERTIFICATS:
      return {
        ...state,
        certificats: {
          Data: [...initialState.certificats.Data],
          Count: state.certificats.Count,
          Skip: initSkip
        },
        loadingCertificats: true
      }
    case GET_LEARNING_PATHS_LEARNING_REQUESTED:
      return {
        ...state,
        loadingLearningPaths: true
      }
    case GET_LEARNING_PATHS_LEARNING_SUCCESS:
      return {
        ...state,
        learningPaths: {
          Data: data.Data,
          Count: data.Count,
          FilteredCount: data.FilteredCount
        },
        loadingLearningPaths: false
      }
    case GET_LEARNING_PATHS_LEARNING_FAIL:
      return {
        ...state,
        loadingLearningPaths: false
      }
    case CLEAR_COURSES:
      return {
        ...state,
        courses: {
          Data: [...initialState.courses.Data],
          Count: state.courses.Count,
          Skip: initSkip
        },
        loadingCourses: true
      }
    case GET_PROGRESSION_DETAILS_REQUESTED:
      return {
        ...state,
        loadingGlobalProgression: true
      }
    case GET_PROGRESSION_DETAILS_SUCCESS:
      return {
        ...state,
        userGlobalProgression: data,
        loadingGlobalProgression: false
      }
    case GET_PROGRESSION_DETAILS_FAIL:
      return {
        ...state,
        loadingGlobalProgression: false
      }
    case GET_LAST_SEEN_AND_HISTORY_REQUESTED:
      return {
        ...state,
        lastSeenCourseAndHistory: {
          ...state.lastSeenCourseAndHistory,
          isLoadingCourse: true
        }
      }
    case GET_LAST_SEEN_AND_HISTORY_SUCCESS:
      return {
        ...state,
        lastSeenCourseAndHistory: {
          lastSeen: data.lastSeen,
          history: data.history,
          isLoadingCourse: false
        }
      }
    case GET_LAST_SEEN_AND_HISTORY_FAIL:
      return {
        ...state,
        lastSeenCourseAndHistory: {
          ...state.lastSeenCourseAndHistory,
          isLoadingCourse: false
        }
      }
    default:
      return state
  }
}

export const getLearning = ({ learning }) => learning
export const getShop = ({ support }) => support
const getfavorites = ({ course }) => course.favoriteCoursesIds
const getfavoriteCourses = ({ learning }) => learning.favorits
const getReferential = ({ referential }) => referential
const getAutoAppliedCoupon = ({ user }) => user?.AutoAppliedCoupons

export const fetchHistorySeeMoreAction = () => ({ type: HISTORY_FETCH_MORE })
export const fetchFavoritesSeeMoreAction = () => ({ type: FAVORITES_FETCH_MORE })
export const fetchCertificatsSeeMoreAction = () => ({ type: CERTIFICATS_FETCH_MORE })
export const fetchUserCoursesSeeMoreAction = () => ({ type: BOUGHT_FETCH_MORE })
export const fetchNewCoursesAction = _ => ({ type: GET_NEW_COURSES_REQUESTED })
export const fetchSimilarCoursesAction = (courseIds, take) => ({ type: GET_SIMILAR_COURSES_REQUESTED, data: { courseIds, take } })
export const initCountsAction = _ => ({ type: INIT_COUNTS })
export const addToFavoriteAction = courseId => ({ type: ADD_COURSE_TO_FAVORITES, courseId })
export const removeFromFavorite = courseId => ({ type: REMOVE_COURSE_FROM_FAVORITES, courseId })
export const fetchHistoryAction = (take, skip) => ({ type: HISTORY_FETCH_REQUESTED, data: { take, skip } })
export const clearLearningAction = () => ({ type: CLEAR_LEARNING })
export const clearHistoryAction = () => ({ type: CLEAR_HISTORY })
export const clearFavoriesAction = () => ({ type: CLEAR_FAVORIES })
export const clearRecommendedAction = () => ({ type: CLEAR_RECOMMENDED })
export const clearCertificatsAction = () => ({ type: CLEAR_CERTIFICATS })
export const clearCoursesAction = () => ({ type: CLEAR_COURSES })
export const fetchFreeCoursesAction = () => ({ type: GET_FREE_COURSES_LEARNING_REQUESTED })
export const fetchLearningPathsAction = (take, skip) => ({ type: GET_LEARNING_PATHS_LEARNING_REQUESTED, data: { take, skip } })
export const getProgressionDetailsAction = () => ({ type: GET_PROGRESSION_DETAILS_REQUESTED })
export const getLastSeenCourseAndHistoryAction = (take, skip) => ({ type: GET_LAST_SEEN_AND_HISTORY_REQUESTED, data: { take, skip } })

function * fetchLearningPaths ({ data }) {
  try {
    const response = yield fetch(queries.getLearningPaths(data.take, data.skip))
    yield put({ type: GET_LEARNING_PATHS_LEARNING_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_LEARNING_PATHS_LEARNING_FAIL, err })
  }
}

function * fetchHistory ({ type, data }) {
  const { history } = yield select(getLearning)
  const Skip = data && data.skip ? data.skip : type === HISTORY_FETCH_MORE ? history.Skip += initTake : initSkip
  const actionType = type === HISTORY_FETCH_MORE ? HISTORY_FETCH_MORE_SUCCESS : HISTORY_FETCH_SUCCESS
  try {
    const response = yield fetch(queries.getUserHistory(data ? data.take : null, Skip))
    yield put({ type: actionType, data: { ...response, Skip } })
  } catch (err) {
    yield put({ type: HISTORY_FETCH_FAIL, err })
  }
}

export const fetchRecommendedAction = () => ({ type: RECOMMENDED_FETCH_REQUESTED })

function * fetchRecommended () {
  const shop = yield select(getShop)
  const promo = shop?.PromotionCourse
  try {
    const response = yield fetch(queries.getRecommendedCourses)
    if (promo) {
      response?.Items?.forEach(course => {
        course.HasActivePromotion = true
        course.PricePromo = promo.DiscountPrice
        course.DiscountPrice = promo.DiscountPrice
        course.AlternativePricePromo = promo.AlternativePricePromo
        course.DiscountPercentage = promo.DiscountPercentage
      })
    }
    yield put({ type: RECOMMENDED_FETCH_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: RECOMMENDED_FETCH_FAIL, err })
  }
}

export const fetchCertificatsAction = () => ({ type: CERTIFICATS_FETCH_REQUESTED })

function * fetchCertificats ({ type }) {
  const { certificats } = yield select(getLearning)
  const Skip = type === CERTIFICATS_FETCH_MORE ? certificats.Skip += initTake : initSkip
  const actionType = type === CERTIFICATS_FETCH_MORE ? CERTIFICATS_FETCH_MORE_SUCCESS : CERTIFICATS_FETCH_SUCCESS
  try {
    const response = yield fetch(queries.getUserCertificates(Skip))
    yield put({ type: actionType, data: { ...response, Skip } })
  } catch (err) {
    yield put({ type: CERTIFICATS_FETCH_FAIL, err })
  }
}

export const fetchUserCoursesAction = () => ({ type: BOUGHT_FETCH_REQUESTED })

function * fetchUserCourses ({ type }) {
  const { courses } = yield select(getLearning)
  const Skip = type === BOUGHT_FETCH_MORE ? courses.Skip += initTake : initSkip
  const actionType = type === BOUGHT_FETCH_MORE ? BOUGHT_FETCH_MORE_SUCCESS : BOUGHT_FETCH_SUCCESS
  try {
    const response = yield fetch(queries.getFollowedByUser(Skip))
    yield put({ type: actionType, data: { ...response, Skip } })
  } catch (err) {
    yield put({ type: BOUGHT_FETCH_FAIL, err })
  }
}

export const fetchFavoritesAction = () => ({ type: FAVORITES_FETCH_REQUESTED })

function * fetchFavorites ({ type }) {
  const { favorits } = yield select(getLearning)
  const Skip = type === FAVORITES_FETCH_MORE ? favorits.Skip += initTake : initSkip
  const actionType = type === FAVORITES_FETCH_MORE ? FAVORITES_FETCH_MORE_SUCCESS : FAVORITES_FETCH_SUCCESS
  try {
    const response = yield fetch(queries.getFavoriteCourses(Skip))
    yield put({ type: actionType, data: { ...response, Skip } })
  } catch (err) {
    yield put({ type: FAVORITES_FETCH_FAIL, err })
  }
}

function * fecthNewCourses () {
  const referential = yield select(getReferential)
  const shop = yield select(getShop)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const courseCoupon = autoAppliedCoupon?.length > 0 ? autoAppliedCoupon[0]?.Values?.find(coupon => coupon.SubjectType === referential.ItemTypes[ItemTypesEnum.Course]) : null
  const promo = shop?.PromotionCourse
  try {
    const response = yield fetch(queries.getCourses('', '', shop.Id, newCoursesTake, newCoursesSkip))
    if (courseCoupon) {
      response.Data = response?.Data?.map(item => ({
        ...item,
        HasActivePromotion: true,
        PricePromo: courseCoupon.Value,
        DiscountPrice: courseCoupon.Value,
        AlternativePricePromo: courseCoupon.Value * shop.PriceCoefficient,
        PromotionPrice: courseCoupon.Value
      }))
    } else if (promo) {
      response.Data = response?.Data?.map(item => ({
        ...item,
        HasActivePromotion: true,
        PricePromo: promo.DiscountPrice,
        DiscountPrice: promo.DiscountPrice,
        AlternativePricePromo: promo.AlternativePricePromo,
        PromotionPrice: promo.DiscountPrice,
        DiscountPercentage: promo.DiscountPercentage
      }))
    }
    yield put({ type: GET_NEW_COURSES_SUCCESS, data: { Data: response.Data, Count: response.Count, Skip: newCoursesSkip } })
  } catch (err) {
    yield put({ type: GET_NEW_COURSES_FAIL, err })
  }
}

function * fetchSimilarCourses ({ data }) {
  const referential = yield select(getReferential)
  const shop = yield select(getShop)
  const autoAppliedCoupon = yield select(getAutoAppliedCoupon)
  const courseCoupon = autoAppliedCoupon?.length > 0 ? autoAppliedCoupon[0]?.Values?.find(coupon => coupon.SubjectType === referential.ItemTypes[ItemTypesEnum.Course]) : null
  const body = { CourseIds: data.courseIds, Take: data.take, Skip: 0, OrderedByAsc: true, OrderBy: 'Id' }
  try {
    const response = yield fetch(queries.getSimilarCourses, { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) })
    if (courseCoupon) {
      response.Items = response.Items.map(item => ({
        ...item,
        HasActivePromotion: true,
        PricePromo: courseCoupon.Value,
        DiscountPrice: courseCoupon.Value,
        AlternativePricePromo: courseCoupon.Value * shop.PriceCoefficient,
        PromotionPrice: courseCoupon.Value
      }))
    }
    yield put({ type: GET_SIMILAR_COURSES_SUCCESS, data: response.Items })
  } catch (err) {
    yield put({ type: GET_SIMILAR_COURSES_FAIL, err })
  }
}

function * fetchCounts () {
  try {
    const favorits = yield fetch(queries.getFavoriteCourses(initTake))
    const courses = yield fetch(queries.getFollowedByUser(initTake))
    const certificats = yield fetch(queries.getUserCertificates(initTake))
    const history = yield fetch(queries.getUserHistory(initTake, 0))
    yield put({ type: INIT_COUNTS_SUCCEEDED, data: { favorits, courses, certificats, history } })
  } catch (err) {
    yield put({ type: INIT_COUNTS_FAILED, err })
  }
}

function * removeCourseFromFavorite ({ courseId }) {
  const favorits = yield select(getfavorites)
  const favoriteCourses = yield select(getfavoriteCourses)
  try {
    yield fetch(queries.removeCourseToFavorite(courseId), { method: 'delete', nojson: true })
    const filteredFavorits = favorits.filter(item => item !== courseId)
    const filteredFavoritCourses = favoriteCourses?.Data?.filter(item => item.Id !== courseId)
    if (!filteredFavoritCourses) throw new Error('no courses found')
    yield put({ type: REMOVE_COURSE_FROM_FAVORITES_SUCCEEDED, data: { Data: filteredFavoritCourses, Count: favoriteCourses.Count - 1 } })
    yield put({ type: GET_FAVORITE_COURSES_IDS_SUCCEEDED, data: filteredFavorits })
    yield put({ type: HISTORY_FETCH_REQUESTED })
    yield put({ type: BOUGHT_FETCH_REQUESTED })
  } catch (err) {
    yield put({ type: REMOVE_COURSE_FROM_FAVORITES_FAILED, err })
  }
}

function * addCourseToFavorits ({ courseId }) {
  const favorits = yield select(getfavorites)
  const optimisticFavorits = [courseId, ...favorits]
  try {
    yield put({ type: GET_FAVORITE_COURSES_IDS_SUCCEEDED, data: optimisticFavorits })
    yield fetch(queries.addCourseToFavorite(courseId), { method: 'post', nojson: true })
    yield put({ type: ADD_COURSE_TO_FAVORITE_SUCCEEDED })
    yield put({ type: FAVORITES_FETCH_REQUESTED })
    yield put({ type: HISTORY_FETCH_REQUESTED })
    yield put({ type: BOUGHT_FETCH_REQUESTED })
  } catch (err) {
    yield put({ type: ADD_COURSE_TO_FAVORITE_FAILED, err })
  }
}

function * fecthFreeCourses () {
  const support = yield select(getShop)
  try {
    const isFree = true
    const response = yield fetch(queries.getCourses('', '', support.Id, initTake, initSkip, isFree))
    yield put({ type: GET_FREE_COURSES_LEARNING_SUCCESS, data: response })
  } catch (err) {
    yield put({ type: GET_FREE_COURSES_LEARNING_FAIL, err })
  }
}

function * fetchProgressionDetails () {
  try {
    const response = yield fetch(queries.getProgressionDetails())
    if (response.GlobalCompletionPercentage) {
      yield put({ type: GET_PROGRESSION_DETAILS_SUCCESS, data: response })
    } else {
      yield put({ type: GET_PROGRESSION_DETAILS_SUCCESS, data: initialState.userGlobalProgression })
    }
  } catch (err) {
    yield put({ type: GET_PROGRESSION_DETAILS_FAIL, err })
  }
}

function * fetchLastSeenCourse ({ data }) {
  try {
    const response = yield fetch(queries.getUserHistory(data.take, data.skip))
    yield put(fetchSimilarCoursesAction(response?.Data.map(item => item.Id), 8))
    yield put({ type: GET_LAST_SEEN_AND_HISTORY_SUCCESS, data: { lastSeen: response?.Data[0], history: response?.Data } })
  } catch (err) {
    yield put({ type: GET_LAST_SEEN_AND_HISTORY_FAIL, err })
  }
}

export function * LearningRootSaga () {
  yield takeEvery([HISTORY_FETCH_REQUESTED, HISTORY_FETCH_MORE], fetchHistory)
  yield takeEvery([RECOMMENDED_FETCH_REQUESTED, INIT_COUNTS], fetchRecommended)
  yield takeEvery([CERTIFICATS_FETCH_REQUESTED, CERTIFICATS_FETCH_MORE], fetchCertificats)
  yield takeEvery([BOUGHT_FETCH_REQUESTED, BOUGHT_FETCH_MORE], fetchUserCourses)
  yield takeEvery([FAVORITES_FETCH_REQUESTED, FAVORITES_FETCH_MORE], fetchFavorites)
  yield takeEvery(GET_NEW_COURSES_REQUESTED, fecthNewCourses)
  yield takeEvery(GET_SIMILAR_COURSES_REQUESTED, fetchSimilarCourses)
  yield takeLatest(INIT_COUNTS, fetchCounts)
  yield takeLatest(REMOVE_COURSE_FROM_FAVORITES, removeCourseFromFavorite)
  yield takeLatest(ADD_COURSE_TO_FAVORITES, addCourseToFavorits)
  yield takeEvery(GET_FREE_COURSES_LEARNING_REQUESTED, fecthFreeCourses)
  yield takeLatest(GET_LEARNING_PATHS_LEARNING_REQUESTED, fetchLearningPaths)
  yield takeLatest(GET_PROGRESSION_DETAILS_REQUESTED, fetchProgressionDetails)
  yield takeLatest(GET_LAST_SEEN_AND_HISTORY_REQUESTED, fetchLastSeenCourse)
}

const queries = {
  getFollowedByUser: (skip) => `${BASE_URL_WEB}/Course/getFollowedByUser?Skip=${skip}&Take=${initTake}&OrderedByAsc=true&OrderBy=LastAccessedDate`,
  getRecommendedCourses: `${BASE_URL_WEB}/Course/getRecommendedCourses?OrderedByAsc=true&OrderBy=PublicationDate`,
  getSimilarCourses: `${BASE_URL_WEB}/Course/getSimilarCourses`,
  getUserCertificates: (skip) => `${BASE_URL_WEB}/User/GetCertificates?Skip=${skip}&Take=${initTake}&OrderedByAsc=true&OrderBy=CertificationDate`,
  getFavoriteCourses: (skip) => `${BASE_URL_WEB}/Course/FavoriteCoursesDetail?Skip=${skip}&Take=${initTake}&OrderedByAsc=true&OrderBy=LastAccessedDate`,
  getUserHistory: (take, skip) => `${BASE_URL_WEB}/Course/getHistoryCourses?Skip=${skip}&Take=${take || initTake}&OrderedByAsc=true&OrderBy=LastAccessedDate`,
  getCourses: (category, subCategory, shopId, take, skip, isFree) => `${BASE_URL_WEB}/Course/?${category ? `&CategorySlug=${category}` : ''}${subCategory ? `&SubCategorySlug=${subCategory}` : ''}${take ? `&Take=${take}` : ''}&Skip=${skip}${isFree ? `&IsFree=${isFree}` : ''}&OrderedByAsc=false&OrderBy=PublicationDate`,
  removeCourseToFavorite: courseId => `${BASE_URL_WEB}/Course/removeCourseToFavorite/${courseId}`,
  addCourseToFavorite: courseId => `${BASE_URL_WEB}/Course/addCourseToFavorite/${courseId}`,
  getLearningPaths: (take, skip) => `${BASE_URL_WEB}/CoursePath?OrderBy=ActivationDate&Take=${take}&Skip=${skip}`,
  getProgressionDetails: () => `${BASE_URL_WEB}/Course/globalprogression`
}
const HISTORY_FETCH_SUCCESS = 'HISTORY_FETCH_SUCCESS'
const HISTORY_FETCH_FAIL = 'HISTORY_FETCH_FAIL'
const HISTORY_FETCH_REQUESTED = 'HISTORY_FETCH_REQUESTED'
const FAVORITES_FETCH_SUCCESS = 'FAVORITES_FETCH_SUCCESS'
const FAVORITES_FETCH_FAIL = 'FAVORITES_FETCH_FAIL'
const FAVORITES_FETCH_REQUESTED = 'FAVORITES_FETCH_REQUESTED'
const CERTIFICATS_FETCH_SUCCESS = 'CERTIFICATS_FETCH_SUCCESS'
const CERTIFICATS_FETCH_FAIL = 'CERTIFICATS_FETCH_FAIL'
const CERTIFICATS_FETCH_REQUESTED = 'CERTIFICATS_FETCH_REQUESTED'
const RECOMMENDED_FETCH_SUCCESS = 'RECOMMENDED_FETCH_SUCCESS'
const RECOMMENDED_FETCH_FAIL = 'RECOMMENDED_FETCH_FAIL'
const RECOMMENDED_FETCH_REQUESTED = 'RECOMMENDED_FETCH_REQUESTED'
const BOUGHT_FETCH_SUCCESS = 'BOUGHT_FETCH_SUCCESS'
const BOUGHT_FETCH_FAIL = 'BOUGHT_FETCH_FAIL'
const BOUGHT_FETCH_REQUESTED = 'BOUGHT_FETCH_REQUESTED'
const HISTORY_FETCH_MORE = 'HISTORY_FETCH_MORE'
const FAVORITES_FETCH_MORE = 'FAVORITES_FETCH_MORE'
const CERTIFICATS_FETCH_MORE = 'CERTIFICATS_FETCH_MORE'
const BOUGHT_FETCH_MORE = 'BOUGHT_FETCH_MORE'
const HISTORY_FETCH_MORE_SUCCESS = 'HISTORY_FETCH_MORE_SUCCESS'
const FAVORITES_FETCH_MORE_SUCCESS = 'FAVORITES_FETCH_MORE_SUCCESS'
const CERTIFICATS_FETCH_MORE_SUCCESS = 'CERTIFICATS_FETCH_MORE_SUCCESS'
const BOUGHT_FETCH_MORE_SUCCESS = 'BOUGHT_FETCH_MORE_SUCCESS'
const GET_NEW_COURSES_SUCCESS = 'GET_NEW_COURSES_SUCCESS'
const GET_NEW_COURSES_FAIL = 'GET_NEW_COURSES_FAIL'
const GET_NEW_COURSES_REQUESTED = 'NEW_COURSES_FETCH_REQUESTED'
const GET_SIMILAR_COURSES_REQUESTED = 'GET_SIMILAR_COURSES_REQUESTED'
const GET_SIMILAR_COURSES_SUCCESS = 'GET_SIMILAR_COURSES_SUCCESS'
const GET_SIMILAR_COURSES_FAIL = 'GET_SIMILAR_COURSES_FAIL'
const INIT_COUNTS = 'INIT_COUNTS'
const INIT_COUNTS_SUCCEEDED = 'INIT_COUNTS_SUCCEEDED'
const INIT_COUNTS_FAILED = 'INIT_COUNTS_FAILED'
const REMOVE_COURSE_FROM_FAVORITES = 'REMOVE_COURSE_FROM_FAVORITES'
const REMOVE_COURSE_FROM_FAVORITES_SUCCEEDED = 'REMOVE_COURSE_FROM_FAVORITES_SUCCEEDED'
const REMOVE_COURSE_FROM_FAVORITES_FAILED = 'REMOVE_COURSE_FROM_FAVORITES_FAILED'
const ADD_COURSE_TO_FAVORITES = 'ADD_COURSE_TO_FAVORITES'
const ADD_COURSE_TO_FAVORITE_SUCCEEDED = 'ADD_COURSE_TO_FAVORITE_SUCCEEDED'
const ADD_COURSE_TO_FAVORITE_FAILED = 'ADD_COURSE_TO_FAVORITE_FAILED'
const CLEAR_LEARNING = 'CLEAR_LEARNING'
const CLEAR_HISTORY = 'CLEAR_HISTORY'
const CLEAR_FAVORIES = 'CLEAR_FAVORIES'
const CLEAR_RECOMMENDED = 'CLEAR_RECOMMENDED'
const CLEAR_CERTIFICATS = 'CLEAR_CERTIFICATS'
const CLEAR_COURSES = 'CLEAR_COURSES'
const GET_FREE_COURSES_LEARNING_REQUESTED = 'GET_FREE_COURSES_LEARNING_REQUESTED'
const GET_FREE_COURSES_LEARNING_SUCCESS = 'GET_FREE_COURSES_LEARNING_SUCCESS'
const GET_FREE_COURSES_LEARNING_FAIL = 'GET_FREE_COURSES_LEARNING_FAIL'
const GET_LEARNING_PATHS_LEARNING_REQUESTED = 'GET_LEARNING_PATHS_LEARNING_REQUESTED'
const GET_LEARNING_PATHS_LEARNING_SUCCESS = 'GET_LEARNING_PATHS_LEARNING_SUCCESS'
const GET_LEARNING_PATHS_LEARNING_FAIL = 'GET_LEARNING_PATHS_LEARNING_FAIL'
const GET_PROGRESSION_DETAILS_REQUESTED = 'GET_PROGRESSION_DETAILS_REQUESTED'
const GET_PROGRESSION_DETAILS_SUCCESS = 'GET_PROGRESSION_DETAILS_SUCCESS'
const GET_PROGRESSION_DETAILS_FAIL = 'GET_PROGRESSION_DETAILS_FAIL'
const GET_LAST_SEEN_AND_HISTORY_REQUESTED = 'GET_LAST_SEEN_AND_HISTORY_REQUESTED'
const GET_LAST_SEEN_AND_HISTORY_SUCCESS = 'GET_LAST_SEEN_AND_HISTORY_SUCCESS'
const GET_LAST_SEEN_AND_HISTORY_FAIL = 'GET_LAST_SEEN_AND_HISTORY_FAIL'
