import {
  Container,
  TypographyElement,
  MediaQuery
} from '@alphorm/ui'
import { createUseStyles } from 'react-jss'
import style from './style'
import CategoryTag from 'components/Webinar/CategoryTag'
import ShareOnSocials from 'components/Webinar/ShareOnSocials'
import Text from './text.json'
import StatusTag from '../StatusTag'

const useStyle = createUseStyles(style)

export default ({
  categories,
  title,
  webinarLink
}) => {
  const {
    container,
    categoriesContainer,
    statusTagContainer,
    iframeClass
  } = useStyle()
  return (
    <>
      <MediaQuery HideOnDesktop>
        <iframe src={webinarLink} className={iframeClass} />
      </MediaQuery>
      <Container>
        <div className={container}>
          <MediaQuery ShowOnDesktop>
            <div className={statusTagContainer}>
              <StatusTag />
            </div>
          </MediaQuery>
          <MediaQuery ShowOnDesktop>
            <iframe src={webinarLink} className={iframeClass} />
          </MediaQuery>
          <div className={categoriesContainer}>
            <MediaQuery HideOnDesktop>
              <StatusTag />
            </MediaQuery>
            {categories?.map((category, key) => (
              <CategoryTag
                key={key}
                name={category.Name}
                categoryId={category.Id}
                photo={category.ImageUrl}
              />
            ))}
          </div>
          <TypographyElement
            component='h2'
            variant='heading2'
            lineHeight='55px'
            align='left'
            spacing='0 0 12px 0'
            lineHeightTablet='40px'
            lineHeightMobile='40px'
            spacingMobile='0 0 33px 0'
            spacingTablet='0 0 33px 0'
          >
            {title}
          </TypographyElement>
          <ShareOnSocials title={Text.shareWebinar} />
        </div>
      </Container>
    </>
  )
}
