import mediaQueries from '../../constants/mediaQueries'

const ColorMap = (theme) => ({
  error: {
    color: theme.neutral[400],
    backgroundColor: theme.error[0]
  },
  success: {
    color: theme.neutral[400],
    backgroundColor: theme.success[0]
  },
  alert: {
    color: theme.neutral[400],
    backgroundColor: theme.warning[0]
  }
})
export const bannerStyle = theme => ({
  bannerGroup: {
    height: 60,
    width: props => props.width ? props.width : 'auto',
    padding: [0, 18],
    display: 'flex',
    position: props => props.position ? props.position : 'fixed',
    right: props => props.rightPos ? props.rightPos : '',
    top: props => props.topPos ? props.topPos : '',
    bottom: props => props.bottomPos ? props.bottomPos : '',
    left: props => props.leftPos ? props.leftPos : '',
    zIndex: 1000,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: props => ColorMap(theme)[props.status]?.backgroundColor,
    '& > div': {
      boxShadow: 'none',
      padding: '0 16px 0 2px',
      '& span': {
        color: props => ColorMap(theme)[props.status]?.color
      }
    }
  },
  box: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.neutral[400],
    fontSize: 32
  },
  cross: {
    display: 'inline-block',
    cursor: 'pointer',
    color: props => ColorMap(theme)[props.status]?.color,
    opacity: 0.5
  },
  [`${mediaQueries.tablet}`]: {
    bannerGroup: {
      right: props => props.rightPosTablet ? props.rightPosTablet : '',
      top: props => props.topPosTablet ? props.topPosTablet : '',
      bottom: props => props.bottomPosTablet ? props.bottomPosTablet : '',
      left: props => props.leftPosTablet ? props.leftPosTablet : '',
      width: props => props.widthTablet ? props.widthTablet : 'auto',
      margin: props => props.marginTablet ? props.marginTablet : 0
    }
  },
  [`${mediaQueries.mobile}`]: {
    bannerGroup: {
      right: props => props.rightPosMobile ? props.rightPosMobile : '',
      top: props => props.topPosMobile ? props.topPosMobile : '',
      bottom: props => props.bottomPosMobile ? props.bottomPosMobile : '',
      left: props => props.leftPosMobile ? props.leftPosMobile : '',
      width: props => props.widthMobile ? props.widthMobile : 'auto',
      margin: props => props.marginMobile ? props.marginMobile : 0
    }
  }
})
