import {
  loadingSkeleton,
  loadingSkeletonAnimation
} from 'UI'

export default theme => ({
  tag: ({ loading, margin, active }) => ({
    position: 'relative',
    borderRadius: 8,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: margin || 'unset',
    cursor: 'pointer',
    backgroundColor: active ? theme.warning[0] : `${theme.warning[0]}1f`,
    textWrap: 'nowrap',
    '&:hover': {
      backgroundColor: !active && theme.warning[200]
    },
    ...(loading && loadingSkeleton().core)
  }),
  ...loadingSkeletonAnimation
})
