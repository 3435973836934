export default {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000
  },
  overlayContent: {
    position: 'relative',
    cursor: 'pointer'
  },
  imageStyle: {
    width: '100%',
    height: '100%',
    padding: 25
  },
  closeButton: {
    position: 'absolute',
    top: -5,
    right: 25,
    fontSize: 16,
    color: 'white',
    cursor: 'pointer'
  }
}
