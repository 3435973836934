import { Typography } from '../../constants/typography'

export const radioButtonStyle = (theme) => ({
  inputGroup: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  radio: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'inline-block',
      width: 18,
      height: 18,
      borderRadius: '50%',
      borderWidth: 2,
      borderColor: theme.neutral[0],
      borderStyle: 'solid',
      position: 'relative',
      transition: 'border-color .2s linear'
    },
    '&::after': {
      content: '""',
      backgroundColor: 'white',
      display: 'block',
      position: 'absolute',
      border: 0,
      width: 8,
      height: 8,
      borderRadius: '50%',
      transition: 'all .1s ease-out '
    }
  },
  input: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    cursor: ({ disabled }) => disabled ? 'default' : 'pointer',
    width: 22,
    height: 22,
    margin: 0,
    zIndex: 999,

    '&:checked + label::before': {
      borderColor: theme.secondary[100]
    },
    '&:checked + label::after': {
      backgroundColor: theme.secondary[100],
      transform: 'scale(1.5)',
      opacity: 1
    },
    '&:checked:hover + label::after': {
      backgroundColor: theme.secondary[100],
      transform: 'scale(1.5)',
      opacity: 1
    },
    '&:hover + label::before': {
      borderColor: theme.secondary[100]
    },
    '&:disabled + label::before': {
      borderColor: theme.neutral[0]
    },
    '&:disabled + label::after': {
      backgroundColor: theme.neutral[0],
      transform: 'scale(1.5)',
      opacity: 1
    }
  },
  label: {
    marginLeft: 13,
    width: 56,
    height: 20,
    fontFamily: Typography.fontFamily,
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.43,
    letterSpacing: 'normal'
  }
})
